
import HeroButton from '@/components/HeroButton'

export default {
  components: {
    HeroButton,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  methods: {
    navigate({ link }) {
      if (link) {
        this.$router.push(this.$getLink(link))
      } else if (this.$getExternalLink(this.content.button)) {
        window.open(this.$getExternalLink(this.content.button), '_blank')
      }
    },
  },
}
