
import HeroButton from '@/components/HeroButton'
import OverlayContainer from '@/components/OverlayContainer'

export default {
  components: {
    HeroButton,
    OverlayContainer,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    primary: {
      type: String,
      default: '',
    },
    secondary: {
      type: String,
      default: '',
    },
    displayModalDialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    displayModalDialog() {
      if (this.displayModalDialog) return this.openModalOverlay()
      this.closeModalOverlay()
    },
  },
  methods: {
    secondaryClicked() {
      this.$emit('secondary-clicked')
    },
    primaryClicked() {
      this.$emit('primary-clicked')
    },
    openModalOverlay() {
      this.$refs.modalContainer.open()
    },
    closeModalOverlay() {
      this.$refs.modalContainer.close()
    },
  },
}
