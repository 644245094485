import Vue from 'vue'

import { defaultParams } from '@/utils/constants'
import { getDeserializedCategoryProducts } from '@/utils/deserialization'

export const state = () => ({
  id: -1,
  breadcrumb: [],
  name: '',
  description: '',
  collapsedContent: '',
  products: [],
  meta: '',
})

export const mutations = {
  SET_BRAND(state, brand) {
    Vue.set(state, 'breadcrumb', brand.breadcrumb)
    Vue.set(state, 'id', brand.id)
    Vue.set(state, 'meta', brand.meta)
    Vue.set(state, 'name', brand.name)
    Vue.set(state, 'description', brand.description)
    Vue.set(state, 'collapsedContent', brand.collapsedContent)
    Vue.set(state, 'products', [...brand.products])
  },
}

export const actions = {
  async getBrand({ commit, rootState, dispatch }, brandId) {
    commit('REMOVE_ERROR', 'brand', { root: true })
    commit('ADD_LOADING', 'brand', { root: true })

    const { couponCode } = rootState
    const params = { ...(couponCode && { couponCode }), ...defaultParams }

    try {
      const response = await this.$axios.get(`brand/${brandId}`, { params })
      const { data: brand } = response.data
      const { products } = brand
      const deserializedProducts = getDeserializedCategoryProducts(products)
      const deserializedBrand = { ...brand, products: deserializedProducts }

      commit('SET_BRAND', deserializedBrand)
    } catch (error) {
      if (error.response?.status === 404) {
        this.app.context.error({ statusCode: 404, message: 'Brand not found' })
      } else if (error.response?.status === 401) {
        await dispatch('silentLogOut', { source: 'brand/getBrand' }, { root: true })

        try {
          const response = await this.$axios.get(`brand/${brandId}`, { params })
          const { data: brand } = response.data
          const { products } = brand
          const deserializedProducts = getDeserializedCategoryProducts(products)
          const deserializedBrand = { ...brand, products: deserializedProducts }

          commit('SET_BRAND', deserializedBrand)
        } catch (error) {
          commit('ADD_ERROR', { id: 'brand', error }, { root: true })
        }
      }

      commit('ADD_ERROR', { id: 'brand', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'brand', { root: true })
  },
}
