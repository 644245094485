import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3747f11c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "" */))
const _57e27b0e = () => interopDefault(import('../pages/search/_id.vue' /* webpackChunkName: "" */))
const _635948ec = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "" */))
const _1d4dba72 = () => interopDefault(import('../pages/acuvue-brand/acuvue-trueye-upgrade.vue' /* webpackChunkName: "" */))
const _bb855ab2 = () => interopDefault(import('../pages/acuvue-brand/astigmatism.vue' /* webpackChunkName: "" */))
const _42dc4340 = () => interopDefault(import('../pages/acuvue-brand/daily-disposable.vue' /* webpackChunkName: "" */))
const _3f851294 = () => interopDefault(import('../pages/acuvue-brand/index.vue' /* webpackChunkName: "" */))
const _01f4bb8c = () => interopDefault(import('../pages/acuvue-brand/presbyopia.vue' /* webpackChunkName: "" */))
const _51c77b9c = () => interopDefault(import('../pages/acuvue-brand/reusable-lenses.vue' /* webpackChunkName: "" */))
const _1502f9db = () => interopDefault(import('../pages/personal-details.vue' /* webpackChunkName: "" */))
const _387cb151 = () => interopDefault(import('../pages/brand.vue' /* webpackChunkName: "" */))

const _25921ca4 = () => interopDefault(import('../pages/category.vue' /* webpackChunkName: "" */))
const _974a4b7e = () => interopDefault(import('../pages/document.vue' /* webpackChunkName: "" */))
const _1b085a39 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "" */))
const _12d211f4 = () => interopDefault(import('../pages/store-locator.vue' /* webpackChunkName: "" */))
const _829d8442 = () => interopDefault(import('../pages/order-history.vue' /* webpackChunkName: "" */))
const _00ef9433 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "" */))
const _3d98be02 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "" */))
const _4f90837a = () => interopDefault(import('../pages/order-confirmation.vue' /* webpackChunkName: "" */))
const _99c1be28 = () => interopDefault(import('../pages/basket.vue' /* webpackChunkName: "" */))
const _b0dd6f12 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "" */))
const _89a7e070 = () => interopDefault(import('../pages/address-book.vue' /* webpackChunkName: "" */))
const _08e7896a = () => interopDefault(import('../pages/communication-preferences.vue' /* webpackChunkName: "" */))
const _0b6e7d80 = () => interopDefault(import('../pages/auto-reorders.vue' /* webpackChunkName: "" */))
const _b33f986e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "" */))
const _18205bd0 = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "" */))
const _ccd8623c = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "" */))
const _34d0109a = () => interopDefault(import('../pages/delivery.vue' /* webpackChunkName: "" */))
const _c4961b62 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "" */))
const _1fe318b9 = () => interopDefault(import('../pages/free-eye-test.vue' /* webpackChunkName: "" */))
const _6171e290 = () => interopDefault(import('../pages/payment-management.vue' /* webpackChunkName: "" */))
const _2030f21f = () => interopDefault(import('../pages/payment-management-child.vue' /* webpackChunkName: "" */))
const _74a9990f = () => interopDefault(import('../pages/auto-reorders-child.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _3747f11c,
    name: "index"
  }, {
    path: "/search/:id?",
    component: _57e27b0e,
    name: "search-id"
  }, {
    path: "/checkout",
    component: _635948ec,
    name: "checkout"
  }, {
    path: "/acuvue-brand/acuvue-trueye-upgrade",
    component: _1d4dba72,
    name: "en-GB-acuvue-brand-acuvue-trueye-upgrade"
  }, {
    path: "/acuvue-brand/astigmatism",
    component: _bb855ab2,
    name: "en-GB-acuvue-brand-astigmatism"
  }, {
    path: "/acuvue-brand/daily-disposable",
    component: _42dc4340,
    name: "en-GB-acuvue-brand-daily-disposable"
  }, {
    path: "/acuvue-brand",
    component: _3f851294,
    name: "en-GB-acuvue-brand"
  }, {
    path: "/acuvue-brand/presbyopia",
    component: _01f4bb8c,
    name: "en-GB-acuvue-brand-presbyopia"
  }, {
    path: "/acuvue-brand/reusable-lenses",
    component: _51c77b9c,
    name: "en-GB-acuvue-brand-reusable-lenses"
  }, {
    path: "/patient-details",
    component: _1502f9db,
    name: "en-GB/profile"
  }, {
    path: "/acuvue-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/1"
  }, {
    path: "/bausch-lomb",
    component: _387cb151,
    name: "en-GB/brand/2"
  }, {
    path: "/ciba-vision",
    component: _387cb151,
    name: "en-GB/brand/3"
  }, {
    path: "/cooper-vision",
    component: _387cb151,
    name: "en-GB/brand/4"
  }, {
    path: "/1-day-acuvue-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/6"
  }, {
    path: "/acuvue-2-contacts",
    component: _387cb151,
    name: "en-GB/brand/7"
  }, {
    path: "/acuvue-advance-astigmatism",
    component: _387cb151,
    name: "en-GB/brand/8"
  }, {
    path: "/acuvue-moist",
    component: _387cb151,
    name: "en-GB/brand/15"
  }, {
    path: "/acuvue-oasys-contacts",
    component: _387cb151,
    name: "en-GB/brand/16"
  }, {
    path: "/air-optix-astigmatism",
    component: _387cb151,
    name: "en-GB/brand/18"
  }, {
    path: "/air-optix-aqua-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/19"
  }, {
    path: "/biofinity-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/27"
  }, {
    path: "/contact-lens-solutions",
    component: _387cb151,
    name: "en-GB/brand/46"
  }, {
    path: "/focus-dailies",
    component: _387cb151,
    name: "en-GB/brand/62"
  }, {
    path: "/johnson-johnson",
    component: _387cb151,
    name: "en-GB/brand/65"
  }, {
    path: "/proclear-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/75"
  }, {
    path: "/purevision-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/76"
  }, {
    path: "/acuvue-advance",
    component: _387cb151,
    name: "en-GB/brand/83"
  }, {
    path: "/biomedics",
    component: _387cb151,
    name: "en-GB/brand/84"
  }, {
    path: "/soflens",
    component: _387cb151,
    name: "en-GB/brand/85"
  }, {
    path: "/frequency",
    component: _387cb151,
    name: "en-GB/brand/86"
  }, {
    path: "/avaira",
    component: _387cb151,
    name: "en-GB/brand/90"
  }, {
    path: "/tesco-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/91"
  }, {
    path: "/freshlook-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/92"
  }, {
    path: "/prescription-coloured-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/96"
  }, {
    path: "/silicone-hydrogel-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/110"
  }, {
    path: "/freshlook-dailies",
    component: _387cb151,
    name: "en-GB/brand/117"
  }, {
    path: "/daily-toric-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/123"
  }, {
    path: "/blue-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/129"
  }, {
    path: "/green-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/130"
  }, {
    path: "/blink-eye-drops",
    component: _387cb151,
    name: "en-GB/brand/134"
  }, {
    path: "/contact-lenses-next-day-delivery",
    component: _387cb151,
    name: "en-GB/brand/137"
  }, {
    path: "/brown-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/141"
  }, {
    path: "/opaque-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/142"
  }, {
    path: "/grey-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/143"
  }, {
    path: "/coopervision-expressions",
    component: _387cb151,
    name: "en-GB/brand/149"
  }, {
    path: "/alternative-coloured-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/alcon",
    component: _387cb151,
    name: "en-GB/brand/158"
  }, {
    path: "/systane-eye-drops",
    component: _387cb151,
    name: "en-GB/brand/170"
  }, {
    path: "/biotrue-solution",
    component: _387cb151,
    name: "en-GB/brand/173"
  }, {
    path: "/contact-lenses-in-london",
    component: _387cb151,
    name: "en-GB/brand/178"
  }, {
    path: "/solo-care-aqua",
    component: _387cb151,
    name: "en-GB/brand/185"
  }, {
    path: "/prescription-halloween-lenses",
    component: _387cb151,
    name: "en-GB/brand/211"
  }, {
    path: "/daily-disposables",
    component: _25921ca4,
    name: "en-GB/category/1"
  }, {
    path: "/two-weekly-disposables",
    component: _25921ca4,
    name: "en-GB/category/2"
  }, {
    path: "/monthly-disposables",
    component: _25921ca4,
    name: "en-GB/category/3"
  }, {
    path: "/extended-wear-lenses",
    component: _25921ca4,
    name: "en-GB/category/4"
  }, {
    path: "/toric-lenses",
    component: _25921ca4,
    name: "en-GB/category/5"
  }, {
    path: "/multifocal-lenses",
    component: _25921ca4,
    name: "en-GB/category/6"
  }, {
    path: "/solutions-accessories",
    component: _25921ca4,
    name: "en-GB/category/7"
  }, {
    path: "/acuvue/daily-disposables",
    component: _25921ca4,
    name: "en-GB/category/11"
  }, {
    path: "/bausch-lomb/daily-disposables",
    component: _25921ca4,
    name: "en-GB/category/12"
  }, {
    path: "/ciba-vision/daily-disposables",
    component: _25921ca4,
    name: "en-GB/category/13"
  }, {
    path: "/cooper-vision/daily-disposables",
    component: _25921ca4,
    name: "en-GB/category/14"
  }, {
    path: "/acuvue/two-weekly-disposables",
    component: _25921ca4,
    name: "en-GB/category/21"
  }, {
    path: "/bausch-lomb/two-weekly-disposables",
    component: _25921ca4,
    name: "en-GB/category/22"
  }, {
    path: "/ciba-vision/two-weekly-disposables",
    component: _25921ca4,
    name: "en-GB/category/23"
  }, {
    path: "/cooper-vision/two-weekly-disposables",
    component: _25921ca4,
    name: "en-GB/category/24"
  }, {
    path: "/bausch-lomb/monthly-disposables",
    component: _25921ca4,
    name: "en-GB/category/32"
  }, {
    path: "/ciba-vision/monthly-disposables",
    component: _25921ca4,
    name: "en-GB/category/33"
  }, {
    path: "/cooper-vision/monthly-disposables",
    component: _25921ca4,
    name: "en-GB/category/34"
  }, {
    path: "/acuvue/extended-wear-lenses",
    component: _25921ca4,
    name: "en-GB/category/41"
  }, {
    path: "/bausch-lomb/extended-wear-lenses",
    component: _25921ca4,
    name: "en-GB/category/42"
  }, {
    path: "/ciba-vision/extended-wear-lenses",
    component: _25921ca4,
    name: "en-GB/category/43"
  }, {
    path: "/cooper-vision/extended-wear-lenses",
    component: _25921ca4,
    name: "en-GB/category/44"
  }, {
    path: "/acuvue/toric-lenses",
    component: _25921ca4,
    name: "en-GB/category/51"
  }, {
    path: "/bausch-lomb/toric-lenses",
    component: _25921ca4,
    name: "en-GB/category/52"
  }, {
    path: "/ciba-vision/toric-lenses",
    component: _25921ca4,
    name: "en-GB/category/53"
  }, {
    path: "/cooper-vision/toric-lenses",
    component: _25921ca4,
    name: "en-GB/category/54"
  }, {
    path: "/acuvue/multifocal-lenses",
    component: _25921ca4,
    name: "en-GB/category/61"
  }, {
    path: "/bausch-lomb/multifocal-lenses",
    component: _25921ca4,
    name: "en-GB/category/62"
  }, {
    path: "/ciba-vision/multifocal-lenses",
    component: _25921ca4,
    name: "en-GB/category/63"
  }, {
    path: "/cooper-vision/multifocal-lenses",
    component: _25921ca4,
    name: "en-GB/category/64"
  }, {
    path: "/contact-lens-cases",
    component: _25921ca4,
    name: "en-GB/category/103"
  }, {
    path: "/eye-drops",
    component: _25921ca4,
    name: "en-GB/category/104"
  }, {
    path: "/multi-purpose-solutions",
    component: _25921ca4,
    name: "en-GB/category/105"
  }, {
    path: "/hydrogen-peroxide-solutions",
    component: _25921ca4,
    name: "en-GB/category/106"
  }, {
    path: "/other-accessories",
    component: _25921ca4,
    name: "en-GB/category/107"
  }, {
    path: "/eye-vitamins",
    component: _25921ca4,
    name: "en-GB/category/108"
  }, {
    path: "/hard-rgp-solutions",
    component: _25921ca4,
    name: "en-GB/category/109"
  }, {
    path: "/contact-us",
    component: _974a4b7e,
    name: "en-GB/document/2"
  }, {
    path: "/how-to-order",
    component: _974a4b7e,
    name: "en-GB/document/5"
  }, {
    path: "/faqs",
    component: _974a4b7e,
    name: "en-GB/document/6"
  }, {
    path: "/lens-wearing-guide",
    component: _974a4b7e,
    name: "en-GB/document/7"
  }, {
    path: "/terms-conditions",
    component: _974a4b7e,
    name: "en-GB/document/8"
  }, {
    path: "/privacy-policy",
    redirect: "/privacy"
  }, {
    path: "/delivery-postage",
    component: _974a4b7e,
    name: "en-GB/document/10"
  }, {
    path: "/refund-policy",
    component: _974a4b7e,
    name: "en-GB/document/11"
  }, {
    path: "/careers",
    component: _974a4b7e,
    name: "en-GB/document/12"
  }, {
    path: "/tell-a-friend",
    component: _974a4b7e,
    name: "en-GB/document/13"
  }, {
    path: "/opticians-own-brand-conversion",
    component: _974a4b7e,
    name: "en-GB/document/14"
  }, {
    path: "/reminder",
    component: _974a4b7e,
    name: "en-GB/document/15"
  }, {
    path: "/externalresources",
    component: _974a4b7e,
    name: "en-GB/document/16"
  }, {
    path: "/externalresources/optician",
    component: _974a4b7e,
    name: "en-GB/document/17"
  }, {
    path: "/externalresources/products",
    component: _974a4b7e,
    name: "en-GB/document/18"
  }, {
    path: "/externalresources/commentary",
    component: _974a4b7e,
    name: "en-GB/document/19"
  }, {
    path: "/externalresources/other",
    component: _974a4b7e,
    name: "en-GB/document/20"
  }, {
    path: "/myths-about-contact-lenses",
    redirect: "/eyecare/busting-10-myths-about-contact-lenses"
  }, {
    path: "/about-conjunctivitis",
    component: _974a4b7e,
    name: "en-GB/document/22"
  }, {
    path: "/affiliates",
    component: _974a4b7e,
    name: "en-GB/document/42"
  }, {
    path: "/the-student-room",
    redirect: "/unidays-student-discount"
  }, {
    path: "/error/404",
    component: _974a4b7e,
    name: "en-GB/document/44"
  }, {
    path: "/search-buy-contact-lenses",
    component: _974a4b7e,
    name: "en-GB/document/50"
  }, {
    path: "/coloured-lens-advice",
    component: _974a4b7e,
    name: "en-GB/document/51"
  }, {
    path: "/coloured-contact-lenses-archive-document",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/yeszv67qh",
    redirect: "/campaign-ended"
  }, {
    path: "/yesofene7",
    redirect: "/campaign-ended"
  }, {
    path: "/yeslreh6h",
    redirect: "/campaign-ended"
  }, {
    path: "/yesm2urgd",
    redirect: "/campaign-ended"
  }, {
    path: "/yesiyz3a7",
    redirect: "/campaign-ended"
  }, {
    path: "/yesv48gbn",
    redirect: "/campaign-ended"
  }, {
    path: "/yesov28g2",
    redirect: "/campaign-ended"
  }, {
    path: "/yesa4lb6l",
    redirect: "/campaign-ended"
  }, {
    path: "/yesc4jiwb",
    redirect: "/campaign-ended"
  }, {
    path: "/yess1jjle",
    redirect: "/campaign-ended"
  }, {
    path: "/yesstring",
    redirect: "/campaign-ended"
  }, {
    path: "/nozv67qh",
    redirect: "/campaign-ended"
  }, {
    path: "/noofene7",
    redirect: "/campaign-ended"
  }, {
    path: "/nolreh6h",
    redirect: "/campaign-ended"
  }, {
    path: "/nom2urgd",
    redirect: "/campaign-ended"
  }, {
    path: "/noiyz3a7",
    redirect: "/campaign-ended"
  }, {
    path: "/nov48gbn",
    redirect: "/campaign-ended"
  }, {
    path: "/noov28g2",
    redirect: "/campaign-ended"
  }, {
    path: "/noa4lb6l",
    redirect: "/campaign-ended"
  }, {
    path: "/noc4jiwb",
    redirect: "/campaign-ended"
  }, {
    path: "/nos1jjle",
    redirect: "/campaign-ended"
  }, {
    path: "/nostring",
    redirect: "/campaign-ended"
  }, {
    path: "/sitemap",
    component: _974a4b7e,
    name: "en-GB/document/60"
  }, {
    path: "/bausch-lomb/daily-disposables/soflens-daily-disposable_p1",
    component: _1b085a39,
    name: "en-GB/product/1"
  }, {
    path: "/bausch-lomb/daily-disposables/soflens-daily-disposable_p2",
    component: _1b085a39,
    name: "en-GB/product/2"
  }, {
    path: "/bausch-lomb/monthly-disposables/purevision_p3",
    component: _1b085a39,
    name: "en-GB/product/3"
  }, {
    path: "/bausch-lomb/toric-lenses/purevision-toric_p4",
    component: _1b085a39,
    name: "en-GB/product/4"
  }, {
    path: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p5",
    component: _1b085a39,
    name: "en-GB/product/5"
  }, {
    path: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p6",
    component: _1b085a39,
    name: "en-GB/product/6"
  }, {
    path: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p7",
    component: _1b085a39,
    name: "en-GB/product/7"
  }, {
    path: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8",
    component: _1b085a39,
    name: "en-GB/product/8"
  }, {
    path: "/ciba-vision/toric-lenses/focus-dailies-toric_p10",
    component: _1b085a39,
    name: "en-GB/product/10"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue-moist_p13",
    component: _1b085a39,
    name: "en-GB/product/13"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue-moist_p14",
    component: _1b085a39,
    name: "en-GB/product/14"
  }, {
    path: "/bausch-lomb/extended-wear-lenses/purevision_p15",
    component: _1b085a39,
    name: "en-GB/product/15"
  }, {
    path: "/bausch-lomb/multifocal-lenses/purevision-multi-focal_p16",
    component: _1b085a39,
    name: "en-GB/product/16"
  }, {
    path: "/bausch-lomb/monthly-disposables/soflens-38_p17",
    component: _1b085a39,
    name: "en-GB/product/17"
  }, {
    path: "/bausch-lomb/monthly-disposables/soflens-59_p18",
    component: _1b085a39,
    name: "en-GB/product/18"
  }, {
    path: "/bausch-lomb/toric-lenses/soflens-for-astigmatism_p19",
    component: _1b085a39,
    name: "en-GB/product/19"
  }, {
    path: "/bausch-lomb/multifocal-lenses/soflens-multi-focal_p20",
    component: _1b085a39,
    name: "en-GB/product/20"
  }, {
    path: "/ciba-vision/extended-wear-lenses/air-optix-night-day-aqua_p22",
    component: _1b085a39,
    name: "en-GB/product/22"
  }, {
    path: "/contact-lens-cases/lens-case_p25",
    component: _1b085a39,
    name: "en-GB/product/25"
  }, {
    path: "/cooper-vision/monthly-disposables/frequency-55_p29",
    component: _1b085a39,
    name: "en-GB/product/29"
  }, {
    path: "/cooper-vision/toric-lenses/frequency-55-aspheric_p30",
    component: _1b085a39,
    name: "en-GB/product/30"
  }, {
    path: "/cooper-vision/toric-lenses/frequency-xcel-toric-xr_p32",
    component: _1b085a39,
    name: "en-GB/product/32"
  }, {
    path: "/cooper-vision/toric-lenses/frequency-xcel-toric_p33",
    component: _1b085a39,
    name: "en-GB/product/33"
  }, {
    path: "/cooper-vision/monthly-disposables/proclear_p34",
    component: _1b085a39,
    name: "en-GB/product/34"
  }, {
    path: "/cooper-vision/toric-lenses/proclear-toric_p35",
    component: _1b085a39,
    name: "en-GB/product/35"
  }, {
    path: "/acuvue/toric-lenses/acuvue-advance-for-astigmatism_p36",
    component: _1b085a39,
    name: "en-GB/product/36"
  }, {
    path: "/acuvue/extended-wear-lenses/acuvue-oasys_p38",
    component: _1b085a39,
    name: "en-GB/product/38"
  }, {
    path: "/cooper-vision/monthly-disposables/biomedics-55-evolution_p39",
    component: _1b085a39,
    name: "en-GB/product/39"
  }, {
    path: "/bausch-lomb/extended-wear-lenses/purevision-multi-focal_p40",
    component: _1b085a39,
    name: "en-GB/product/40"
  }, {
    path: "/bausch-lomb/extended-wear-lenses/purevision-toric_p41",
    component: _1b085a39,
    name: "en-GB/product/41"
  }, {
    path: "/multi-purpose-solutions/renu-multi-purpose-solution_p42",
    component: _1b085a39,
    name: "en-GB/product/42"
  }, {
    path: "/eye-drops/focus-aquify-comfort-drops-10ml_p43",
    component: _1b085a39,
    name: "en-GB/product/43"
  }, {
    path: "/bausch-lomb/toric-lenses/soflens-for-astigmatism_p44",
    component: _1b085a39,
    name: "en-GB/product/44"
  }, {
    path: "/bausch-lomb/multifocal-lenses/soflens-multi-focal_p45",
    component: _1b085a39,
    name: "en-GB/product/45"
  }, {
    path: "/multi-purpose-solutions/renu-multi-purpose-solution-handy-pack_p46",
    component: _1b085a39,
    name: "en-GB/product/46"
  }, {
    path: "/ciba-vision/toric-lenses/focus-dailies-toric_p47",
    component: _1b085a39,
    name: "en-GB/product/47"
  }, {
    path: "/ciba-vision/toric-lenses/air-optix-for-astigmatism_p48",
    component: _1b085a39,
    name: "en-GB/product/48"
  }, {
    path: "/acuvue/two-weekly-disposables/acuvue-advance_p49",
    component: _1b085a39,
    name: "en-GB/product/49"
  }, {
    path: "/acuvue/extended-wear-lenses/acuvue-2_p51",
    component: _1b085a39,
    name: "en-GB/product/51"
  }, {
    path: "/cooper-vision/toric-lenses/biomedics-toric_p54",
    component: _1b085a39,
    name: "en-GB/product/54"
  }, {
    path: "/cooper-vision/toric-lenses/biomedics-1-day-toric_p57",
    component: _1b085a39,
    name: "en-GB/product/57"
  }, {
    path: "/cooper-vision/daily-disposables/proclear-1-day_p60",
    component: _1b085a39,
    name: "en-GB/product/60"
  }, {
    path: "/cooper-vision/multifocal-lenses/proclear-multifocal_p61",
    component: _1b085a39,
    name: "en-GB/product/61"
  }, {
    path: "/cooper-vision/extended-wear-lenses/biofinity_p63",
    component: _1b085a39,
    name: "en-GB/product/63"
  }, {
    path: "/multi-purpose-solutions/opti-free-replenish_p64",
    component: _1b085a39,
    name: "en-GB/product/64"
  }, {
    path: "/multi-purpose-solutions/opti-free-replenish-travel-pack_p65",
    component: _1b085a39,
    name: "en-GB/product/65"
  }, {
    path: "/multi-purpose-solutions/opti-free-replenish-twin-pack_p66",
    component: _1b085a39,
    name: "en-GB/product/66"
  }, {
    path: "/eye-drops/blink-contacts_p67",
    component: _1b085a39,
    name: "en-GB/product/67"
  }, {
    path: "/eye-drops/blink-contacts-preservative-free-vials_p68",
    component: _1b085a39,
    name: "en-GB/product/68"
  }, {
    path: "/eye-drops/blink-revitalising_p69",
    component: _1b085a39,
    name: "en-GB/product/69"
  }, {
    path: "/eye-drops/blink-refreshing-preservative-free-vials_p70",
    component: _1b085a39,
    name: "en-GB/product/70"
  }, {
    path: "/saline-solutions/sensitive-eyes-plus_p71",
    component: _1b085a39,
    name: "en-GB/product/71"
  }, {
    path: "/multi-purpose-solutions/renu-multi-purpose-flight-pack_p72",
    component: _1b085a39,
    name: "en-GB/product/72"
  }, {
    path: "/hydrogen-peroxide-solutions/aoseptplus_p73",
    component: _1b085a39,
    name: "en-GB/product/73"
  }, {
    path: "/cooper-vision/monthly-disposables/proclear-multifocal-toric_p74",
    component: _1b085a39,
    name: "en-GB/product/74"
  }, {
    path: "/hydrogen-peroxide-solutions/aosept-plus_p75",
    component: _1b085a39,
    name: "en-GB/product/75"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue-trueye_p76",
    component: _1b085a39,
    name: "en-GB/product/76"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-aqua_p77",
    component: _1b085a39,
    name: "en-GB/product/77"
  }, {
    path: "/cooper-vision/multifocal-lenses/proclear-multifocal-xr_p79",
    component: _1b085a39,
    name: "en-GB/product/79"
  }, {
    path: "/cooper-vision/toric-lenses/proclear-toric-xr_p80",
    component: _1b085a39,
    name: "en-GB/product/80"
  }, {
    path: "/multi-purpose-solutions/renu-multi-purpose-solution-small-pack_p82",
    component: _1b085a39,
    name: "en-GB/product/82"
  }, {
    path: "/cooper-vision/multifocal-lenses/proclear-multifocal_p83",
    component: _1b085a39,
    name: "en-GB/product/83"
  }, {
    path: "/cooper-vision/daily-disposables/proclear-1-day_p84",
    component: _1b085a39,
    name: "en-GB/product/84"
  }, {
    path: "/cooper-vision/toric-lenses/proclear-tailor-made-toric_p85",
    component: _1b085a39,
    name: "en-GB/product/85"
  }, {
    path: "/acuvue/toric-lenses/acuvue-oasys-for-astigmatism_p86",
    component: _1b085a39,
    name: "en-GB/product/86"
  }, {
    path: "/cooper-vision/toric-lenses/biofinity-toric_p87",
    component: _1b085a39,
    name: "en-GB/product/87"
  }, {
    path: "/bausch-lomb/toric-lenses/soflens-daily-disposable-toric_p88",
    component: _1b085a39,
    name: "en-GB/product/88"
  }, {
    path: "/cooper-vision/monthly-disposables/avaira_p89",
    component: _1b085a39,
    name: "en-GB/product/89"
  }, {
    path: "/ciba-vision/multifocal-lenses/air-optix-aqua-multifocal_p90",
    component: _1b085a39,
    name: "en-GB/product/90"
  }, {
    path: "/coloured-contact-lenses/freshlook-colors_p92",
    component: _1b085a39,
    name: "en-GB/product/92"
  }, {
    path: "/coloured-contact-lenses/freshlook-colorblends_p93",
    component: _1b085a39,
    name: "en-GB/product/93"
  }, {
    path: "/coloured-contact-lenses/freshlook-dimensions_p94",
    component: _1b085a39,
    name: "en-GB/product/94"
  }, {
    path: "/coloured-contact-lenses/freshlook-dimensions_p95",
    component: _1b085a39,
    name: "en-GB/product/95"
  }, {
    path: "/coloured-contact-lenses/freshlook-one-day_p96",
    component: _1b085a39,
    name: "en-GB/product/96"
  }, {
    path: "/coloured-contact-lenses/soflens-natural-colors_p97",
    component: _1b085a39,
    name: "en-GB/product/97"
  }, {
    path: "/coloured-contact-lenses/expressions-colour_p100",
    component: _1b085a39,
    name: "en-GB/product/100"
  }, {
    path: "/coloured-contact-lenses/expressions-colour_p101",
    component: _1b085a39,
    name: "en-GB/product/101"
  }, {
    path: "/cooper-vision/multifocal-lenses/proclear-ep_p103",
    component: _1b085a39,
    name: "en-GB/product/103"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue-trueye_p104",
    component: _1b085a39,
    name: "en-GB/product/104"
  }, {
    path: "/acuvue/toric-lenses/1-day-acuvue-moist-for-astigmatism_p106",
    component: _1b085a39,
    name: "en-GB/product/106"
  }, {
    path: "/multi-purpose-solutions/biotrue_p109",
    component: _1b085a39,
    name: "en-GB/product/109"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/biotrue-small-pack_p110",
    component: _1b085a39,
    name: "en-GB/product/110"
  }, {
    path: "/eye-drops/clens-100_p111",
    component: _1b085a39,
    name: "en-GB/product/111"
  }, {
    path: "/eye-vitamins/i-caps_p112",
    component: _1b085a39,
    name: "en-GB/product/112"
  }, {
    path: "/multi-purpose-solutions/opti-free-express_p113",
    component: _1b085a39,
    name: "en-GB/product/113"
  }, {
    path: "/multi-purpose-solutions/opti-free-express-twin-pack_p114",
    component: _1b085a39,
    name: "en-GB/product/114"
  }, {
    path: "/multi-purpose-solutions/opti-free-replenish-flight-pack_p115",
    component: _1b085a39,
    name: "en-GB/product/115"
  }, {
    path: "/other-accessories/supranettes_p116",
    component: _1b085a39,
    name: "en-GB/product/116"
  }, {
    path: "/eye-drops/systane-lubricating-eye-drops_p117",
    component: _1b085a39,
    name: "en-GB/product/117"
  }, {
    path: "/eye-drops/systane-preservative-free-eye-drops_p118",
    component: _1b085a39,
    name: "en-GB/product/118"
  }, {
    path: "/eye-drops/blink-n-clean_p119",
    component: _1b085a39,
    name: "en-GB/product/119"
  }, {
    path: "/eye-drops/blink-intensive-tears_p120",
    component: _1b085a39,
    name: "en-GB/product/120"
  }, {
    path: "/eye-drops/solutions-accessories/blink-intensive-tears-eye-drops-vials_p121",
    component: _1b085a39,
    name: "en-GB/product/121"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/complete-multi-purpose-solution_p122",
    component: _1b085a39,
    name: "en-GB/product/122"
  }, {
    path: "/multi-purpose-solutions/complete-solution_p123",
    component: _1b085a39,
    name: "en-GB/product/123"
  }, {
    path: "/multi-purpose-solutions/complete-solution-twin-pack_p124",
    component: _1b085a39,
    name: "en-GB/product/124"
  }, {
    path: "/multi-purpose-solutions/complete-revitalens_p125",
    component: _1b085a39,
    name: "en-GB/product/125"
  }, {
    path: "/multi-purpose-solutions/complete-revitalens-twin-pack_p126",
    component: _1b085a39,
    name: "en-GB/product/126"
  }, {
    path: "/multi-purpose-solutions/lens-plus-ocupure_p127",
    component: _1b085a39,
    name: "en-GB/product/127"
  }, {
    path: "/hydrogen-peroxide-solutions/oxysept_p128",
    component: _1b085a39,
    name: "en-GB/product/128"
  }, {
    path: "/hard-rgp-solutions/total-care-disinfecting-solution_p129",
    component: _1b085a39,
    name: "en-GB/product/129"
  }, {
    path: "/hard-rgp-solutions/total-care-daily-cleaner_p130",
    component: _1b085a39,
    name: "en-GB/product/130"
  }, {
    path: "/other-accessories/ultrazyme-universal-protein-cleaner_p131",
    component: _1b085a39,
    name: "en-GB/product/131"
  }, {
    path: "/multi-purpose-solutions/biotrue-twin-pack_p132",
    component: _1b085a39,
    name: "en-GB/product/132"
  }, {
    path: "/hard-rgp-solutions/boston-cleaner_p133",
    component: _1b085a39,
    name: "en-GB/product/133"
  }, {
    path: "/hard-rgp-solutions/boston-conditioning-solution_p134",
    component: _1b085a39,
    name: "en-GB/product/134"
  }, {
    path: "/hard-rgp-solutions/boston-multi-action-solution-simplus_p135",
    component: _1b085a39,
    name: "en-GB/product/135"
  }, {
    path: "/hard-rgp-solutions/boston-simplus-flight-pack_p136",
    component: _1b085a39,
    name: "en-GB/product/136"
  }, {
    path: "/multi-purpose-solutions/daily-cleaner_p137",
    component: _1b085a39,
    name: "en-GB/product/137"
  }, {
    path: "/hydrogen-peroxide-solutions/easysept-peroxide-solution_p138",
    component: _1b085a39,
    name: "en-GB/product/138"
  }, {
    path: "/multi-purpose-solutions/renu-multi-plus-starter-kit_p139",
    component: _1b085a39,
    name: "en-GB/product/139"
  }, {
    path: "/multi-purpose-solutions/renu-multi-plus_p140",
    component: _1b085a39,
    name: "en-GB/product/140"
  }, {
    path: "/multi-purpose-solutions/renu-multi-plus-triple-pack_p141",
    component: _1b085a39,
    name: "en-GB/product/141"
  }, {
    path: "/multi-purpose-solutions/renu-multi-purpose-solution-triple-pack_p142",
    component: _1b085a39,
    name: "en-GB/product/142"
  }, {
    path: "/eye-drops/rewetting-drops_p143",
    component: _1b085a39,
    name: "en-GB/product/143"
  }, {
    path: "/multi-purpose-solutions/solocare-aqua_p144",
    component: _1b085a39,
    name: "en-GB/product/144"
  }, {
    path: "/multi-purpose-solutions/solocare-aqua-twin-pack_p145",
    component: _1b085a39,
    name: "en-GB/product/145"
  }, {
    path: "/multi-purpose-solutions/options-multi_p146",
    component: _1b085a39,
    name: "en-GB/product/146"
  }, {
    path: "/multi-purpose-solutions/complete-revitalens-starter-kit_p147",
    component: _1b085a39,
    name: "en-GB/product/147"
  }, {
    path: "/ocular-vitamins/ocuvite-complete_p148",
    component: _1b085a39,
    name: "en-GB/product/148"
  }, {
    path: "/bausch-lomb/extended-wear-lenses/purevision-2-hd_p149",
    component: _1b085a39,
    name: "en-GB/product/149"
  }, {
    path: "/bausch-lomb/extended-wear-lenses/purevision-2-hd_p150",
    component: _1b085a39,
    name: "en-GB/product/150"
  }, {
    path: "/cooper-vision/monthly-disposables/options-xc_p151",
    component: _1b085a39,
    name: "en-GB/product/151"
  }, {
    path: "/acuvue/multifocal-lenses/acuvue-oasys-for-presbyopia_p152",
    component: _1b085a39,
    name: "en-GB/product/152"
  }, {
    path: "/daily-disposables/acuvue",
    redirect: "/acuvue/daily-disposables"
  }, {
    path: "/daily-disposables/bausch-lomb",
    redirect: "/bausch-lomb/daily-disposables"
  }, {
    path: "/daily-disposables/ciba-vision",
    redirect: "/ciba-vision/daily-disposables"
  }, {
    path: "/daily-disposables/cooper-vision",
    redirect: "/cooper-vision/daily-disposables"
  }, {
    path: "/two-weekly-disposables/acuvue",
    redirect: "/acuvue/two-weekly-disposables"
  }, {
    path: "/two-weekly-disposables/bausch-lomb",
    redirect: "/bausch-lomb/two-weekly-disposables"
  }, {
    path: "/two-weekly-disposables/ciba-vision",
    redirect: "/ciba-vision/two-weekly-disposables"
  }, {
    path: "/two-weekly-disposables/cooper-vision",
    redirect: "/cooper-vision/two-weekly-disposables"
  }, {
    path: "/monthly-disposables/bausch-lomb",
    redirect: "/bausch-lomb/monthly-disposables"
  }, {
    path: "/monthly-disposables/ciba-vision",
    redirect: "/ciba-vision/monthly-disposables"
  }, {
    path: "/monthly-disposables/cooper-vision",
    redirect: "/cooper-vision/monthly-disposables"
  }, {
    path: "/extended-wear-lenses/acuvue",
    redirect: "/acuvue/extended-wear-lenses"
  }, {
    path: "/extended-wear-lenses/bausch-lomb",
    redirect: "/bausch-lomb/extended-wear-lenses"
  }, {
    path: "/extended-wear-lenses/ciba-vision",
    redirect: "/ciba-vision/extended-wear-lenses"
  }, {
    path: "/extended-wear-lenses/cooper-vision",
    redirect: "/cooper-vision/extended-wear-lenses"
  }, {
    path: "/toric-lenses/acuvue",
    redirect: "/acuvue/toric-lenses"
  }, {
    path: "/toric-lenses/bausch-lomb",
    redirect: "/bausch-lomb/toric-lenses"
  }, {
    path: "/toric-lenses/ciba-vision",
    redirect: "/ciba-vision/toric-lenses"
  }, {
    path: "/toric-lenses/cooper-vision",
    redirect: "/cooper-vision/toric-lenses"
  }, {
    path: "/multifocal-lenses/acuvue",
    redirect: "/acuvue/multifocal-lenses"
  }, {
    path: "/multifocal-lenses/bausch-lomb",
    redirect: "/bausch-lomb/multifocal-lenses"
  }, {
    path: "/multifocal-lenses/ciba-vision",
    redirect: "/ciba-vision/multifocal-lenses"
  }, {
    path: "/multifocal-lenses/cooper-vision",
    redirect: "/cooper-vision/multifocal-lenses"
  }, {
    path: "/freshlook",
    redirect: "/freshlook-contact-lenses"
  }, {
    path: "/ciba-vision/extended-wear-lenses/air-optix-night-day_p22",
    redirect: "/ciba-vision/extended-wear-lenses/air-optix-night-day-aqua_p22"
  }, {
    path: "/multi-purpose-solutions/biotrue-triple-pack_p190",
    component: _1b085a39,
    name: "en-GB/product/190"
  }, {
    path: "/cooper-vision/multifocal-lenses/biofinity-multifocal_p191",
    component: _1b085a39,
    name: "en-GB/product/191"
  }, {
    path: "/daysoft-contact-lenses",
    redirect: "//"
  }, {
    path: "/contact-lenses-without-prescription",
    component: _387cb151,
    name: "en-GB/brand/221"
  }, {
    path: "/contact-lenses-free-shipping",
    component: _387cb151,
    name: "en-GB/brand/215"
  }, {
    path: "/discount-code",
    component: _387cb151,
    name: "en-GB/brand/214"
  }, {
    path: "/toric-lenses/bausch-lomb/purevision-toric_p4",
    redirect: "/bausch-lomb/toric-lenses/purevision-toric_p4"
  }, {
    path: "/toric-lenses/cooper-vision/biofinity-toric_p87",
    redirect: "/cooper-vision/toric-lenses/biofinity-toric_p87"
  }, {
    path: "/toric-lenses/cooper-vision/proclear-toric-xr_p80",
    redirect: "/cooper-vision/toric-lenses/proclear-toric-xr_p80"
  }, {
    path: "/daily-disposables/acuvue/1-day-acuvue-moist_p14",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-moist_p14"
  }, {
    path: "/daily-disposables/ciba-vision/focus-dailies-all-day-comfort_p8",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8"
  }, {
    path: "/solutions-accessories/sensitive-eyes-plus-saline_p71?trid=1257557853z117965z0a3c083528079z",
    redirect: "/saline-solutions/sensitive-eyes-plus_p71"
  }, {
    path: "/solutions-accessories/blink-revitalising-eye-drops_p69",
    redirect: "/eye-drops/blink-revitalising_p69"
  }, {
    path: "/solutions-accessories/blink-revitalising-eye-drops-vials_p70",
    redirect: "/eye-drops/blink-refreshing-preservative-free-vials_p70"
  }, {
    path: "/solutions-accessories/daily-cleaner_p137",
    redirect: "/multi-purpose-solutions/daily-cleaner_p137"
  }, {
    path: "/solutions-accessories/blink-contacts-eye-drops_p67",
    redirect: "/eye-drops/blink-contacts_p67"
  }, {
    path: "/solutions-accessories/renu-multi-plus-starter-kit_p139",
    redirect: "/multi-purpose-solutions/renu-multi-plus-starter-kit_p139"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/complete-multi-purpose-solution-starter-kit_p123",
    redirect: "/multi-purpose-solutions/complete-solution_p123"
  }, {
    path: "/solutions-accessories/boston-multi-action-solution-simplus_p135",
    redirect: "/hard-rgp-solutions/boston-multi-action-solution-simplus_p135"
  }, {
    path: "/solutions-accessories/i-caps_p112",
    redirect: "/eye-vitamins/i-caps_p112"
  }, {
    path: "/solutions-accessories/complete-revitalens-starter-kit_p147",
    redirect: "/multi-purpose-solutions/complete-revitalens-starter-kit_p147"
  }, {
    path: "/solutions-accessories/renu-multipurpose-solution-handy-pack_p46",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution-handy-pack_p46"
  }, {
    path: "/solutions-accessories/renu-multi-purpose-solution-triple-pack_p142",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution-triple-pack_p142"
  }, {
    path: "/solutions-accessories/easysept-peroxide-solution_p138",
    redirect: "/hydrogen-peroxide-solutions/easysept-peroxide-solution_p138"
  }, {
    path: "/solutions-accessories/aoseptplus_p75",
    redirect: "/hydrogen-peroxide-solutions/aosept-plus_p75"
  }, {
    path: "/solutions-accessories/oxysept_p128",
    redirect: "/hydrogen-peroxide-solutions/oxysept_p128"
  }, {
    path: "/solutions-accessories/lens-plus-ocupure_p127",
    redirect: "/multi-purpose-solutions/lens-plus-ocupure_p127"
  }, {
    path: "/solutions-accessories/blink-n-clean-eye-drops_p119",
    redirect: "/eye-drops/blink-n-clean_p119"
  }, {
    path: "/multifocal-lenses/cooper-vision/proclear-multifocal_p83",
    redirect: "/cooper-vision/multifocal-lenses/proclear-multifocal_p83"
  }, {
    path: "/solutions-accessories/boston-simplus-flight-pack_p136",
    redirect: "/hard-rgp-solutions/boston-simplus-flight-pack_p136"
  }, {
    path: "/toric-lenses/bausch-lomb/purevision-toric_p45",
    redirect: "/bausch-lomb/extended-wear-lenses/purevision-toric_p41"
  }, {
    path: "/solutions-accessories/aoseptplus_p73",
    redirect: "/hydrogen-peroxide-solutions/aoseptplus_p73"
  }, {
    path: "/bausch-lomb/toric-lenses/purevision-toric_p45",
    redirect: "/bausch-lomb/extended-wear-lenses/purevision-toric_p41"
  }, {
    path: "/daily-disposables/cooper-vision/biomedics-1-day_p55",
    redirect: "/cooper-vision/daily-disposables/biomedics-1-day-extra_p200"
  }, {
    path: "/solutions-accessories/opti-free-replenish_p64",
    redirect: "/multi-purpose-solutions/opti-free-replenish_p64"
  }, {
    path: "/solutions-accessories/blink-intensive-tears-eye-drops-vials_p121",
    redirect: "/eye-drops/solutions-accessories/blink-intensive-tears-eye-drops-vials_p121"
  }, {
    path: "/solutions-accessories/total-care-daily-cleaner_p130",
    redirect: "/hard-rgp-solutions/total-care-daily-cleaner_p130"
  }, {
    path: "/solutions-accessories/focus-aquify-comfort-drops-10ml_p43",
    redirect: "/eye-drops/focus-aquify-comfort-drops-10ml_p43"
  }, {
    path: "/solutions-accessories/complete-revitalens_p125",
    redirect: "/multi-purpose-solutions/complete-revitalens_p125"
  }, {
    path: "/solutions-accessories/solocare-aqua_p144",
    redirect: "/multi-purpose-solutions/solocare-aqua_p144"
  }, {
    path: "/extended-wear-lenses/bausch-lomb/purevision_p15",
    redirect: "/bausch-lomb/extended-wear-lenses/purevision_p15"
  }, {
    path: "/daily-disposables/ciba-vision/focus-dailies-basic_p62",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8"
  }, {
    path: "/extended-wear-lenses/bausch-lomb/purevision-toric_p41",
    redirect: "/bausch-lomb/extended-wear-lenses/purevision-toric_p41"
  }, {
    path: "/extended-wear-lenses/acuvue/acuvue-2_p51",
    redirect: "/acuvue/extended-wear-lenses/acuvue-2_p51"
  }, {
    path: "/daily-disposables/ciba-vision/dailies-aqua-comfort-plus_p6",
    redirect: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p6"
  }, {
    path: "/lens-cases/solutions-accessories/options-multi_p146",
    redirect: "/multi-purpose-solutions/options-multi_p146"
  }, {
    path: "/solutions-accessories/options-multi_p146",
    redirect: "/multi-purpose-solutions/options-multi_p146"
  }, {
    path: "/daily-disposables/bausch-lomb/soflens-daily-disposable_p1",
    redirect: "/bausch-lomb/daily-disposables/soflens-daily-disposable_p1"
  }, {
    path: "/toric-lenses/cooper-vision/proclear-compatibles-toric_p35",
    redirect: "/cooper-vision/toric-lenses/proclear-toric_p35"
  }, {
    path: "/multifocal-lenses/ciba-vision/air-optix-aqua-multifocal_p90",
    redirect: "/ciba-vision/multifocal-lenses/air-optix-aqua-multifocal_p90"
  }, {
    path: "/monthly-disposables/cooper-vision/biomedics-55-evolution_p39",
    redirect: "/cooper-vision/monthly-disposables/biomedics-55-evolution_p39"
  }, {
    path: "/daily-disposables/acuvue/1-day-acuvue_p11",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-moist_p13"
  }, {
    path: "/bausch-lomb/toric.../purevision-toric_p4",
    redirect: "/bausch-lomb/toric-lenses/purevision-toric_p4"
  }, {
    path: "/daily-disposables/cooper-vision/proclear-1-day_p60",
    redirect: "/cooper-vision/daily-disposables/proclear-1-day_p60"
  }, {
    path: "/toric-lenses/cooper-vision/frequency-xcel-toric-xr_p32",
    redirect: "/cooper-vision/toric-lenses/frequency-xcel-toric-xr_p32"
  }, {
    path: "/daily-disposables/ciba-vision/dailies-aqua-comfort-plus_p5",
    redirect: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p6"
  }, {
    path: "/toric-lenses/acuvue/acuvue-oasys-for-astigmatism_p86",
    redirect: "/acuvue/toric-lenses/acuvue-oasys-for-astigmatism_p86"
  }, {
    path: "/extended-wear-lenses/ciba-vision/air-optix-night-day_p22",
    redirect: "/ciba-vision/extended-wear-lenses/air-optix-night-day-aqua_p22"
  }, {
    path: "/monthly-disposables/ciba-vision/air-optix_p21",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p77"
  }, {
    path: "/toric-lenses/cooper-vision/frequency-xcel-toric_p33",
    redirect: "/cooper-vision/toric-lenses/frequency-xcel-toric_p33"
  }, {
    path: "/extended-wear-lenses/cooper-vision/biofinity_p63",
    redirect: "/cooper-vision/extended-wear-lenses/biofinity_p63"
  }, {
    path: "/toric-lenses/acuvue/acuvue-advance-for-astigmatism_p36",
    redirect: "/acuvue/toric-lenses/acuvue-advance-for-astigmatism_p36"
  }, {
    path: "/toric-lenses/ciba-vision/air-optix-for-astigmatism_p48",
    redirect: "/ciba-vision/toric-lenses/air-optix-for-astigmatism_p48"
  }, {
    path: "/monthly-disposables/ciba-vision/air-optix-aqua_p77",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p77"
  }, {
    path: "/daily-disposables/acuvue/1-day-acuvue-trueye_p76",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-trueye_p76"
  }, {
    path: "/solutions-accessories/opti-free-express-twin-pack_p114",
    redirect: "/multi-purpose-solutions/opti-free-express-twin-pack_p114"
  }, {
    path: "/solutions-accessories/complete-multi-purpose-solution-twin-pack_p124",
    redirect: "/multi-purpose-solutions/complete-solution-twin-pack_p124"
  }, {
    path: "/solutions-accessories/boston-conditioning-solution_p134",
    redirect: "/hard-rgp-solutions/boston-conditioning-solution_p134"
  }, {
    path: "/solutions-accessories/systane-lubricating-eye-drops-vials_p118",
    redirect: "/eye-drops/systane-preservative-free-eye-drops_p118"
  }, {
    path: "/solutions-accessories/blink-contacts-eye-drops-vials_p68",
    redirect: "/eye-drops/blink-contacts-preservative-free-vials_p68"
  }, {
    path: "/solutions-accessories/biotrue-twin-pack_p132",
    redirect: "/multi-purpose-solutions/biotrue-twin-pack_p132"
  }, {
    path: "/solutions-accessories/systane-lubricating-eye-drops_p117",
    redirect: "/eye-drops/systane-lubricating-eye-drops_p117"
  }, {
    path: "/solutions-accessories/sensitive-eyes-plus-saline_p71",
    redirect: "/saline-solutions/sensitive-eyes-plus_p71"
  }, {
    path: "/solutions-accessories/biotrue_p109",
    redirect: "/multi-purpose-solutions/biotrue_p109"
  }, {
    path: "/solutions-accessories/supranettes_p116",
    redirect: "/other-accessories/supranettes_p116"
  }, {
    path: "/solutions-accessories/blink-intensive-tears-eye-drops_p120",
    redirect: "/eye-drops/blink-intensive-tears_p120"
  }, {
    path: "/monthly-disposables/bausch-lomb/soflens-38_p17",
    redirect: "/bausch-lomb/monthly-disposables/soflens-38_p17"
  }, {
    path: "/monthly-disposables/cooper-vision/frequency-55_p29",
    redirect: "/cooper-vision/monthly-disposables/frequency-55_p29"
  }, {
    path: "/toric-lenses/ciba-vision/focus-dailies-toric_p47",
    redirect: "/ciba-vision/toric-lenses/focus-dailies-toric_p47"
  }, {
    path: "/multifocal-lenses/cooper-vision/biomedics-73-multifocal_p59",
    redirect: "/biomedics"
  }, {
    path: "/monthly-disposables/cooper-vision/proclear-multifocal-toric_p74",
    redirect: "/cooper-vision/monthly-disposables/proclear-multifocal-toric_p74"
  }, {
    path: "/lens-wearing-..",
    redirect: "/lens-wearing-guide"
  }, {
    path: "/daily-disposab..",
    redirect: "/daily-disposables"
  }, {
    path: "/coloured-contact-lenses/soflens-natural-c..",
    redirect: "/coloured-contact-lenses/soflens-natural-colors_p97"
  }, {
    path: "/hard-/-rgp-lens-solutions/",
    redirect: "/hard-rgp-solutions"
  }, {
    path: "/focus-dailies..",
    redirect: "/focus-dailies"
  }, {
    path: "/coloured-contact-lensesbuy",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/coloured-contact-lenses-ukcomplete",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/buy-online/disposable-contact-lens",
    redirect: "/daily-disposables"
  }, {
    path: "/acuv",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/biotrue-2",
    component: _974a4b7e,
    name: "en-GB/document/63"
  }, {
    path: "/acuvue/toric-lenses/1-day-acuvue-moist-for-astigmatism_p108",
    redirect: "/acuvue/toric-lenses/1-day-acuvue-moist-for-astigmatism_p106"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/daily-cleaner_p137",
    redirect: "/multi-purpose-solutions/daily-cleaner_p137"
  }, {
    path: "/daily-disposables/cooper-vision/biomedics-1-day_p56",
    redirect: "/cooper-vision/daily-disposables/biomedics-1-day-extra_p200"
  }, {
    path: "/toric-lenses/ciba-vision/focus-toric-visitint_p24",
    redirect: "/ciba-vision/toric-lenses/air-optix-for-astigmatism_p48"
  }, {
    path: "/toric-lenses/ciba-vision/focus-dailies-toric_p10",
    redirect: "/ciba-vision/toric-lenses/focus-dailies-toric_p10"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/biotrue_p190",
    redirect: "/multi-purpose-solutions/biotrue-triple-pack_p190"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/total-care-disinfecting-storing-and-wetting-solution",
    redirect: "/hard-rgp-solutions/total-care-disinfecting-solution_p129"
  }, {
    path: "/monthly-disposables/bausch-lomb/purevision_p3",
    redirect: "/bausch-lomb/monthly-disposables/purevision_p3"
  }, {
    path: "/solutions-accessories/total-care-disinfecting-storing-and-wetting-solution_p129",
    redirect: "/hard-rgp-solutions/total-care-disinfecting-solution_p129"
  }, {
    path: "/toric-lenses/ciba-vision/focus-toric-visitint_p52",
    redirect: "/ciba-vision/toric-lenses/air-optix-for-astigmatism_p48"
  }, {
    path: "/solutions-accessories/renu-multi-purpose-flight-pack_p72",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-flight-pack_p72"
  }, {
    path: "/solutions-accessories/complete-multi-purpose-solution_p122",
    redirect: "/multi-purpose-solutions/solutions-accessories/complete-multi-purpose-solution_p122"
  }, {
    path: "/toric-lenses/acuvue/1-day-acuvue-for-astigmatism_p50",
    redirect: "/acuvue/toric-lenses/1-day-acuvue-moist-for-astigmatism_p106"
  }, {
    path: "/daily-disposables/acuvue/1-day-acuvue-moist_p13",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-moist_p13"
  }, {
    path: "/other-accessories/solutions-accessories/sensitive-eyes-plus-saline_p71",
    redirect: "/saline-solutions/sensitive-eyes-plus_p71"
  }, {
    path: "/other-accessories/solutions-accessories/lens-plus-ocupure_p127",
    redirect: "/multi-purpose-solutions/lens-plus-ocupure_p127"
  }, {
    path: "/solutions-accessories/solocare-aqua-twin-pack_p145",
    redirect: "/multi-purpose-solutions/solocare-aqua-twin-pack_p145"
  }, {
    path: "/solutions-accessories/renu-multi-plus_p140",
    redirect: "/multi-purpose-solutions/renu-multi-plus_p140"
  }, {
    path: "/other-accessories/solutions-accessories/daily-cleaner_p137",
    redirect: "/multi-purpose-solutions/daily-cleaner_p137"
  }, {
    path: "/toric-lenses/cooper-vision/biomedics-toric_p54",
    redirect: "/cooper-vision/toric-lenses/biomedics-toric_p54"
  }, {
    path: "/solutions-accessories/renu-multipurpose-solution_p42",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution_p42"
  }, {
    path: "/multifocal-lenses/bausch-lomb/purevision-multi-focal_p16",
    redirect: "/bausch-lomb/multifocal-lenses/purevision-multi-focal_p16"
  }, {
    path: "/toric-lenses/cooper-vision/frequency-55-aspheric_p30",
    redirect: "/cooper-vision/toric-lenses/frequency-55-aspheric_p30"
  }, {
    path: "/solutions-accessories/renu-multi-purpose-solution-small-pack_p82",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution-small-pack_p82"
  }, {
    path: "/solutions-accessories/renu-multi-plus-triple-pack_p141",
    redirect: "/multi-purpose-solutions/renu-multi-plus-triple-pack_p141"
  }, {
    path: "/solutions-accessories/complete-revitalens-twin-pack_p126",
    redirect: "/multi-purpose-solutions/complete-revitalens-twin-pack_p126"
  }, {
    path: "/toric-lenses/bausch-lomb/soflens-toric_p44",
    redirect: "/bausch-lomb/toric-lenses/soflens-for-astigmatism_p44"
  }, {
    path: "/solutions-accessories/ultrazyme-universal-protein-cleaner_p131",
    redirect: "/other-accessories/ultrazyme-universal-protein-cleaner_p131"
  }, {
    path: "/monthly-disposables/cooper-vision/avaira_p89",
    redirect: "/cooper-vision/monthly-disposables/avaira_p89"
  }, {
    path: "/two-weekly-disposables/acuvue/acuvue-advance_p49",
    redirect: "/acuvue/two-weekly-disposables/acuvue-advance_p49"
  }, {
    path: "/daily-disposables/acuvue/1-day-acuvue_p12/",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-moist_p13"
  }, {
    path: "/daily-disposables/ciba-vision/focus-dailies-all-day-comfort_p7",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p7"
  }, {
    path: "/monthly-disposables/bausch-lomb/soflens-59_p18",
    redirect: "/bausch-lomb/monthly-disposables/soflens-59_p18"
  }, {
    path: "/solutions-accessories/opti-free-replenish-travel-pack_p65",
    redirect: "/multi-purpose-solutions/opti-free-replenish-travel-pack_p65"
  }, {
    path: "/monthly-disposables/cooper-vision/frequency-38_p28",
    redirect: "/frequency"
  }, {
    path: "/monthly-disposables/ciba-vision/air-optix-individual_p81",
    redirect: "/air-optix-aqua-contact-lenses"
  }, {
    path: "/monthly-disposables/cooper-vision/biomedics-38_p58",
    redirect: "/biomedics"
  }, {
    path: "/solutions-accessories/lens-case_p25",
    redirect: "/contact-lens-cases/lens-case_p25"
  }, {
    path: "/toric-lenses/bausch-lomb/soflens-toric_p19",
    redirect: "/bausch-lomb/toric-lenses/soflens-for-astigmatism_p19"
  }, {
    path: "/solutions-accessories/opti-free-replenish-flight-pack_p115",
    redirect: "/multi-purpose-solutions/opti-free-replenish-flight-pack_p115"
  }, {
    path: "/multifocal-lenses/bausch-lomb/soflens-multi-focal_p45",
    redirect: "/bausch-lomb/multifocal-lenses/soflens-multi-focal_p45"
  }, {
    path: "/multifocal-lenses/cooper-vision/proclear-multifocal-xr_p79",
    redirect: "/cooper-vision/multifocal-lenses/proclear-multifocal-xr_p79"
  }, {
    path: "/hydrogen-peroxide-solutions/solutions-accessories/aoseptplus_p75",
    redirect: "/hydrogen-peroxide-solutions/aosept-plus_p75"
  }, {
    path: "/toric-lenses/cooper-vision/biomedics-1-day-toric_p57",
    redirect: "/cooper-vision/toric-lenses/biomedics-1-day-toric_p57"
  }, {
    path: "/toric-lenses/bausch-lomb/soflens-daily-disposable-toric_p88",
    redirect: "/bausch-lomb/toric-lenses/soflens-daily-disposable-toric_p88"
  }, {
    path: "/solutions-accessories/opti-free-replenish-twin-pack_p66",
    redirect: "/multi-purpose-solutions/opti-free-replenish-twin-pack_p66"
  }, {
    path: "/solutions-accessories/opti-free-express_p113",
    redirect: "/multi-purpose-solutions/opti-free-express_p113"
  }, {
    path: "/solutions-accessories/rewetting-drops_p143",
    redirect: "/eye-drops/rewetting-drops_p143"
  }, {
    path: "/multifocal-lenses/ciba-vision/focus-dailies-progressives_p9",
    redirect: "/ciba-vision/multifocal-lenses/focus-dailies-progressives_p9"
  }, {
    path: "/monthly-disposables/ciba-vision/precision-uv_p53",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p77"
  }, {
    path: "/monthly-disposables/acuvue/surevue_p78",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/extended-wear-lenses/bausch-lomb/purevision-multi-focal_p40",
    redirect: "/bausch-lomb/extended-wear-lenses/purevision-multi-focal_p40"
  }, {
    path: "/daily-disposables/cooper-vision/proclear-1-day_p84",
    redirect: "/cooper-vision/daily-disposables/proclear-1-day_p84"
  }, {
    path: "/extended-wear-lenses/acuvue/acuvue-oasys_p38",
    redirect: "/acuvue/extended-wear-lenses/acuvue-oasys_p38"
  }, {
    path: "/free-shipping",
    redirect: "/contact-lenses-free-shipping"
  }, {
    path: "/ciba-vision/extended-wear-lenses/air-optix-night-day_p107",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/comfilcon-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/232"
  }, {
    path: "/all-fashion-lenses",
    component: _387cb151,
    name: "en-GB/brand/233"
  }, {
    path: "/sale",
    component: _25921ca4,
    name: "en-GB/category/9999"
  }, {
    path: "/get-lenses",
    redirect: "//"
  }, {
    path: "/focus-dailies-all-day-comfort-2011",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8"
  }, {
    path: "/1-day-acuvue-trueye-2011",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-trueye_p76"
  }, {
    path: "/find-your-brand",
    component: _974a4b7e,
    name: "en-GB/document/64"
  }, {
    path: "/saline-solutions",
    component: _25921ca4,
    name: "en-GB/category/112"
  }, {
    path: "/bausch-lomb/extended-wear-lenses/purevision-2-hd-astigmatism_p192",
    component: _1b085a39,
    name: "en-GB/product/192"
  }, {
    path: "/multi-purpose-solutions/biotrue-flight-pack_p193",
    component: _1b085a39,
    name: "en-GB/product/193"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/biotrue-flight-pack_p194",
    redirect: "/multi-purpose-solutions/biotrue-flight-pack_p193"
  }, {
    path: "/multi-purpose-solutions/opti-free-puremoist_p194",
    component: _1b085a39,
    name: "en-GB/product/194"
  }, {
    path: "/multi-purpose-solutions/opti-free-puremoist-twin-pack_p195",
    component: _1b085a39,
    name: "en-GB/product/195"
  }, {
    path: "/coloured-contact-lenses/freshlook-illuminate_p196",
    component: _1b085a39,
    name: "en-GB/product/196"
  }, {
    path: "/bausch-lomb/extended-wear-lenses/purevision-2-hd-for-astigmatism_p197",
    component: _1b085a39,
    name: "en-GB/product/197"
  }, {
    path: "/http-//www-lenstore-co-uk/bausch-lomb/extended-wear-lenses/purevision-2-hd-for-astigmatism_p197",
    redirect: "/bausch-lomb/extended-wear-lenses/purevision-2-hd-for-astigmatism_p197"
  }, {
    path: "/acuvue",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/flight-pack-solutions",
    component: _387cb151,
    name: "en-GB/brand/238"
  }, {
    path: "/about-us",
    component: _974a4b7e,
    name: "en-GB/document/77"
  }, {
    path: "/cooper-vision/multifocal-lenses/proclear-1-day-multifocal_p198",
    component: _1b085a39,
    name: "en-GB/product/198"
  }, {
    path: "/graphic-designer-internship-to-permanent-",
    redirect: "/careers"
  }, {
    path: "/cooper-vision/daily-disposables/biomedics-1-day-extra_p200",
    component: _1b085a39,
    name: "en-GB/product/200"
  }, {
    path: "/cooper-vision/daily-disposables/biomedics-1-day-extra_p201",
    component: _1b085a39,
    name: "en-GB/product/201"
  }, {
    path: "/fashion-crazy-lenses",
    redirect: "/fashion-contact-lenses"
  }, {
    path: "/click-and-collect",
    redirect: "/delivery-postage"
  }, {
    path: "/same-day-express-delivery",
    redirect: "/delivery-postage"
  }, {
    path: "/collections",
    redirect: "/click-and-collect"
  }, {
    path: "/click-collect",
    redirect: "/click-and-collect"
  }, {
    path: "/optical-clinic",
    component: _3747f11c
  }, {
    path: "/wordpress-support",
    redirect: "//"
  }, {
    path: "/online-marketing-and-experience-manager",
    redirect: "/careers"
  }, {
    path: "/copywriter-internship-to-permanent",
    redirect: "/careers"
  }, {
    path: "/customer-services-representative-polish-speaking",
    redirect: "/careers"
  }, {
    path: "/warehouse-operative-part-time-initially",
    redirect: "/careers"
  }, {
    path: "/graphic-designer-internship-to-permanent",
    redirect: "/careers"
  }, {
    path: "/copywriter-internship-to-permanent-",
    redirect: "/careers"
  }, {
    path: "/customer-services-representative-polish-speaking-",
    redirect: "/careers"
  }, {
    path: "/warehouse-operative-part-time-initially-",
    redirect: "/careers"
  }, {
    path: "/eye-drops/blink-intensive-tears-plus_p202",
    component: _1b085a39,
    name: "en-GB/product/202"
  }, {
    path: "/eye-drops/solutions-accessories/blink-contacts-eye-drops_p202",
    redirect: "/eye-drops/blink-intensive-tears-plus_p202"
  }, {
    path: "/asda-contact-lenses",
    redirect: "//"
  }, {
    path: "/multifocal-lenses/acuvue/acuvue-bifocal_p37",
    redirect: "/acuvue/multifocal-lenses/acuvue-oasys-for-presbyopia_p152"
  }, {
    path: "/monthly-disposables/ciba-vision/precision-uv_p26",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p77"
  }, {
    path: "/buy-online/cheap-contact-lenses",
    redirect: "//"
  }, {
    path: "/biomedics-daily-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/242"
  }, {
    path: "/proclear-daily-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/243"
  }, {
    path: "/soflens-daily-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/244"
  }, {
    path: "/acuvue-oasys-two-weekly-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/246"
  }, {
    path: "/acuvue-advance-two-weekly-contact-lenses",
    redirect: "/acuvue-advance"
  }, {
    path: "/soflens-monthly-lenses",
    component: _387cb151,
    name: "en-GB/brand/248"
  }, {
    path: "/proclear-monthly-lenses",
    component: _387cb151,
    name: "en-GB/brand/249"
  }, {
    path: "/biomedics-monthly-lenses",
    component: _387cb151,
    name: "en-GB/brand/250"
  }, {
    path: "/options-xc-monthly-lenses",
    component: _387cb151,
    name: "en-GB/brand/251"
  }, {
    path: "/air-optix-extended-wear-lenses",
    component: _387cb151,
    name: "en-GB/brand/254"
  }, {
    path: "/focus-dailies-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/257"
  }, {
    path: "/1-day-acuvue-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/258"
  }, {
    path: "/acuvue-oasys-for-astigmatism",
    component: _387cb151,
    name: "en-GB/brand/259"
  }, {
    path: "/biofinity-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/260"
  }, {
    path: "/frequency-xcel-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/261"
  }, {
    path: "/purevision-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/262"
  }, {
    path: "/proclear-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/263"
  }, {
    path: "/soflens-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/264"
  }, {
    path: "/biomedics-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/265"
  }, {
    path: "/purevision-multifocal-lenses",
    component: _387cb151,
    name: "en-GB/brand/266"
  }, {
    path: "/air-optix-multifocal-lenses",
    component: _387cb151,
    name: "en-GB/brand/267"
  }, {
    path: "/focus-dailies-multifocal-lenses",
    component: _387cb151,
    name: "en-GB/brand/268"
  }, {
    path: "/proclear-multifocal-lenses",
    component: _387cb151,
    name: "en-GB/brand/269"
  }, {
    path: "/soflens-multifocal-lenses",
    component: _387cb151,
    name: "en-GB/brand/270"
  }, {
    path: "/acuvue-oasys-multifocal",
    component: _387cb151,
    name: "en-GB/brand/271"
  }, {
    path: "/biofinity-multifocal",
    component: _387cb151,
    name: "en-GB/brand/273"
  }, {
    path: "/acuvue-2-extended-wear-lenses",
    redirect: "/acuvue-2-contacts"
  }, {
    path: "/acuvue-oasys-extended-wear-lenses",
    redirect: "/acuvue-oasys-contacts"
  }, {
    path: "/biofinity-extended-wear-lenses",
    redirect: "/biofinity-contact-lenses"
  }, {
    path: "/purevision-extended-wear-lenses",
    redirect: "/purevision-contact-lenses"
  }, {
    path: "/air-optix-monthly-lenses",
    redirect: "/air-optix-aqua-contact-lenses"
  }, {
    path: "/purevision-monthly-lenses",
    redirect: "/purevision-contact-lenses"
  }, {
    path: "/array/saline-solutions",
    redirect: "/saline-solutions"
  }, {
    path: "/lens-cases/solutions-accessories",
    redirect: "/contact-lens-cases"
  }, {
    path: "/eye-drops/solutions-accessories",
    redirect: "/eye-drops"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories",
    redirect: "/multi-purpose-solutions"
  }, {
    path: "/hydrogen-peroxide-solutions/solutions-accessories",
    redirect: "/hydrogen-peroxide-solutions"
  }, {
    path: "/ocular-vitamins/solutions-accessories",
    redirect: "/eye-vitamins"
  }, {
    path: "/other-accessories/solutions-accessories",
    redirect: "/other-accessories"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories",
    redirect: "/hard-rgp-solutions"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/boston-cleaner_p133",
    redirect: "/hard-rgp-solutions/boston-cleaner_p133"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/boston-conditioning-solution_p134",
    redirect: "/hard-rgp-solutions/boston-conditioning-solution_p134"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/boston-multi-action-solution-simplus_p135",
    redirect: "/hard-rgp-solutions/boston-multi-action-solution-simplus_p135"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/boston-simplus-flight-pack_p136",
    redirect: "/hard-rgp-solutions/boston-simplus-flight-pack_p136"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/total-care-daily-cleaner_p130",
    redirect: "/hard-rgp-solutions/total-care-daily-cleaner_p130"
  }, {
    path: "/hydrogen-peroxide-solutions/solutions-accessories/aoseptplus_p73",
    redirect: "/hydrogen-peroxide-solutions/aoseptplus_p73"
  }, {
    path: "/hydrogen-peroxide-solutions/solutions-accessories/aosept-plus_p75",
    redirect: "/hydrogen-peroxide-solutions/aosept-plus_p75"
  }, {
    path: "/hydrogen-peroxide-solutions/solutions-accessories/easysept-peroxide-solution_p138",
    redirect: "/hydrogen-peroxide-solutions/easysept-peroxide-solution_p138"
  }, {
    path: "/hydrogen-peroxide-solutions/solutions-accessories/oxysept_p128",
    redirect: "/hydrogen-peroxide-solutions/oxysept_p128"
  }, {
    path: "/lens-cases/solutions-accessories/lens-case_p25",
    redirect: "/contact-lens-cases/lens-case_p25"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/biotrue_p109",
    redirect: "/multi-purpose-solutions/biotrue_p109"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/biotrue-flight-pack_p193",
    redirect: "/multi-purpose-solutions/biotrue-flight-pack_p193"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/biotrue-triple-pack_p190",
    redirect: "/multi-purpose-solutions/biotrue-triple-pack_p190"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/biotrue-twin-pack_p132",
    redirect: "/multi-purpose-solutions/biotrue-twin-pack_p132"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/complete-multi-purpose-solution_p123",
    redirect: "/multi-purpose-solutions/complete-solution_p123"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/complete-multi-purpose-solution-twin-pack_p124",
    redirect: "/multi-purpose-solutions/complete-solution-twin-pack_p124"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/complete-revitalens_p125",
    redirect: "/multi-purpose-solutions/complete-revitalens_p125"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/complete-revitalens-starter-kit_p147",
    redirect: "/multi-purpose-solutions/complete-revitalens-starter-kit_p147"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/complete-revitalens-twin-pack_p126",
    redirect: "/multi-purpose-solutions/complete-revitalens-twin-pack_p126"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/daily-cleaner_p137",
    redirect: "/multi-purpose-solutions/daily-cleaner_p137"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/lens-plus-ocupure_p127",
    redirect: "/multi-purpose-solutions/lens-plus-ocupure_p127"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/opti-free-express_p113",
    redirect: "/multi-purpose-solutions/opti-free-express_p113"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/opti-free-express-twin-pack_p114",
    redirect: "/multi-purpose-solutions/opti-free-express-twin-pack_p114"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/opti-free-puremoist_p194",
    redirect: "/multi-purpose-solutions/opti-free-puremoist_p194"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/opti-free-puremoist-twin-pack_p195",
    redirect: "/multi-purpose-solutions/opti-free-puremoist-twin-pack_p195"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/opti-free-replenish_p64",
    redirect: "/multi-purpose-solutions/opti-free-replenish_p64"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/opti-free-replenish-flight-pack_p115",
    redirect: "/multi-purpose-solutions/opti-free-replenish-flight-pack_p115"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/opti-free-replenish-travel-pack_p65",
    redirect: "/multi-purpose-solutions/opti-free-replenish-travel-pack_p65"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/opti-free-replenish-twin-pack_p66",
    redirect: "/multi-purpose-solutions/opti-free-replenish-twin-pack_p66"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/options-multi_p146",
    redirect: "/multi-purpose-solutions/options-multi_p146"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/renu-multi-plus_p140",
    redirect: "/multi-purpose-solutions/renu-multi-plus_p140"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/renu-multi-plus-starter-kit_p139",
    redirect: "/multi-purpose-solutions/renu-multi-plus-starter-kit_p139"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/renu-multi-plus-triple-pack_p141",
    redirect: "/multi-purpose-solutions/renu-multi-plus-triple-pack_p141"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/renu-multi-purpose-flight-pack_p72",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-flight-pack_p72"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/renu-multipurpose-solution_p42",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution_p42"
  }, {
    path: "/freshlook-dimensions",
    component: _387cb151,
    name: "en-GB/brand/274"
  }, {
    path: "/purevision-2-hd",
    component: _387cb151,
    name: "en-GB/brand/275"
  }, {
    path: "/proclear-xr-lenses",
    component: _387cb151,
    name: "en-GB/brand/277"
  }, {
    path: "/frequency-55-by-coopervision",
    component: _387cb151,
    name: "en-GB/brand/278"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/renu-multipurpose-solution-handy-pack_p46",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution-handy-pack_p46"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/renu-multi-purpose-solution-small-pack_p82",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution-small-pack_p82"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/renu-multi-purpose-solution-triple-pack_p142",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution-triple-pack_p142"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/sensitive-eyes-plus-saline_p71",
    redirect: "/saline-solutions/sensitive-eyes-plus_p71"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/solocare-aqua_p144",
    redirect: "/multi-purpose-solutions/solocare-aqua_p144"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/solocare-aqua-twin-pack_p145",
    redirect: "/multi-purpose-solutions/solocare-aqua-twin-pack_p145"
  }, {
    path: "/purevision-2-hd-astigmatism_p192",
    redirect: "/bausch-lomb/extended-wear-lenses/purevision-2-hd-astigmatism_p192"
  }, {
    path: "/eye-drops/solutions-accessories/blink-contacts-eye-drops_p67",
    redirect: "/eye-drops/blink-contacts_p67"
  }, {
    path: "/eye-drops/solutions-accessories/blink-contacts-eye-drops-vials_p68",
    redirect: "/eye-drops/blink-contacts-preservative-free-vials_p68"
  }, {
    path: "/eye-drops/solutions-accessories/blink-intensive-tears-eye-drops_p120",
    redirect: "/eye-drops/blink-intensive-tears_p120"
  }, {
    path: "/eye-drops/solutions-accessories/blink-intensive-tears-plus-eye-drops_p202",
    redirect: "/eye-drops/blink-intensive-tears-plus_p202"
  }, {
    path: "/eye-drops/solutions-accessories/blink-n-clean-eye-drops_p119",
    redirect: "/eye-drops/blink-n-clean_p119"
  }, {
    path: "/eye-drops/solutions-accessories/blink-revitalising-eye-drops_p69",
    redirect: "/eye-drops/blink-revitalising_p69"
  }, {
    path: "/eye-drops/solutions-accessories/blink-revitalising-eye-drops-vials_p70",
    redirect: "/eye-drops/blink-refreshing-preservative-free-vials_p70"
  }, {
    path: "/eye-drops/solutions-accessories/clens-100-eye-drops_p111",
    redirect: "/eye-drops/clens-100_p111"
  }, {
    path: "/eye-drops/solutions-accessories/focus-aquify-comfort-drops-10ml_p43",
    redirect: "/eye-drops/focus-aquify-comfort-drops-10ml_p43"
  }, {
    path: "/eye-drops/solutions-accessories/rewetting-drops_p143",
    redirect: "/eye-drops/rewetting-drops_p143"
  }, {
    path: "/eye-drops/solutions-accessories/systane-lubricating-eye-drops_p117",
    redirect: "/eye-drops/systane-lubricating-eye-drops_p117"
  }, {
    path: "/eye-drops/solutions-accessories/systane-lubricating-eye-drops-vials_p118",
    redirect: "/eye-drops/systane-preservative-free-eye-drops_p118"
  }, {
    path: "/ocular-vitamins/solutions-accessories/i-caps_p112",
    redirect: "/eye-vitamins/i-caps_p112"
  }, {
    path: "/ocular-vitamins/solutions-accessories/ocuvite-complete_p148",
    redirect: "/ocular-vitamins/ocuvite-complete_p148"
  }, {
    path: "/other-accessories/solutions-accessories/supranettes_p116",
    redirect: "/other-accessories/supranettes_p116"
  }, {
    path: "/other-accessories/solutions-accessories/ultrazyme-universal-protein-cleaner_p131",
    redirect: "/other-accessories/ultrazyme-universal-protein-cleaner_p131"
  }, {
    path: "/lenses-online",
    redirect: "//"
  }, {
    path: "/optrex",
    component: _387cb151,
    name: "en-GB/brand/279"
  }, {
    path: "/eye-drops/optrex-rehydrating-eye-drops-10ml_p203",
    component: _1b085a39,
    name: "en-GB/product/203"
  }, {
    path: "/optrex-rehydrating-eye-drops-10ml",
    redirect: "/eye-drops/optrex-rehydrating-eye-drops-10ml_p203"
  }, {
    path: "/eye-drops/optrex-rehydrating-eye-drops-10ml",
    redirect: "/eye-drops/optrex-rehydrating-eye-drops-10ml_p203"
  }, {
    path: "/our-company-name",
    redirect: "//"
  }, {
    path: "/eye-drops/optrex-dry-eye-spray_p204",
    component: _1b085a39,
    name: "en-GB/product/204"
  }, {
    path: "/eye-drops/optrex-actimist-tired-eye-spray_p205",
    component: _1b085a39,
    name: "en-GB/product/205"
  }, {
    path: "/eye-drops/optrex-tired-eye-spray_p204",
    redirect: "/eye-drops/optrex-actimist-tired-eye-spray_p205"
  }, {
    path: "/hycosan",
    component: _387cb151,
    name: "en-GB/brand/280"
  }, {
    path: "/eye-drops/hycosan-eye-drops_p206",
    component: _1b085a39,
    name: "en-GB/product/206"
  }, {
    path: "/eye-drops/hycosan-extra-eye-drops_p207",
    component: _1b085a39,
    name: "en-GB/product/207"
  }, {
    path: "/order-contact-lenses",
    redirect: "//"
  }, {
    path: "/buy-online/contact-lens-case",
    redirect: "/contact-lens-cases"
  }, {
    path: "/buy-online/contact-lense",
    redirect: "//"
  }, {
    path: "/acuvue-moist-contact-lenses",
    redirect: "/acuvue-moist"
  }, {
    path: "/buy-contact-lense",
    redirect: "//"
  }, {
    path: "/buy-online/contact-lenses-uk",
    redirect: "//"
  }, {
    path: "/buy-online/soft-contact-lenses",
    redirect: "//"
  }, {
    path: "/buy-online/disposable-contact-lenses",
    redirect: "/daily-disposables"
  }, {
    path: "/bausch-and-lomb-contact-lenses",
    redirect: "/bausch-lomb"
  }, {
    path: "/ciba-vision-contact-lenses",
    redirect: "/ciba-vision"
  }, {
    path: "/fresh-look-coloured-contact-lenses",
    redirect: "/freshlook-contact-lenses"
  }, {
    path: "/colored-contacts",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/acuvue-contact-lens",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/buy-online/prescription-lenses",
    redirect: "//"
  }, {
    path: "/order-contacts-online",
    redirect: "//"
  }, {
    path: "/cheap-daily-contact-lenses",
    redirect: "/daily-disposables"
  }, {
    path: "/coloured-eye-contacts",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/daily-contact-lenses",
    redirect: "/daily-disposables"
  }, {
    path: "/blue-eye-contacts",
    redirect: "/blue-contact-lenses"
  }, {
    path: "/brown-coloured-contact-lenses",
    redirect: "/brown-contact-lenses"
  }, {
    path: "/edit-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/bausch-and-lomb-uk",
    redirect: "/bausch-lomb"
  }, {
    path: "/buy-online/eye-contact-lens",
    redirect: "//"
  }, {
    path: "/coloured-contact-lenses-uk",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/focus-progressive-contact-lenses",
    redirect: "/focus-dailies-multifocal-lenses"
  }, {
    path: "/acuvue-contacts",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/acuvue-trueye",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-trueye_p76"
  }, {
    path: "/buy-coloured-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/blue-coloured-contact-lenses",
    redirect: "/blue-contact-lenses"
  }, {
    path: "/contact-lens-online",
    redirect: "//"
  }, {
    path: "/ciba-vision-air-optix",
    redirect: "/air-optix-aqua-contact-lenses"
  }, {
    path: "/buy-online/bausch-and-lomb",
    redirect: "/bausch-lomb"
  }, {
    path: "/cheap-dailies-contact-lenses",
    redirect: "/focus-dailies"
  }, {
    path: "/purevision-toric-contact-lenses",
    redirect: "/purevision-toric-lenses"
  }, {
    path: "/colored-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/freshlook-colourblends",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/freshlook-coloured-contact-lenses",
    redirect: "/freshlook-contact-lenses"
  }, {
    path: "/johnson-and-johnson-contact-lenses",
    redirect: "/johnson-johnson"
  }, {
    path: "/blue-contacts",
    redirect: "/blue-contact-lenses"
  }, {
    path: "/focus-contact-lenses",
    redirect: "/focus-dailies"
  }, {
    path: "/freshlook-daily",
    redirect: "/freshlook-dailies"
  }, {
    path: "/astigmatism-contact-lenses",
    redirect: "/toric-lenses"
  }, {
    path: "/cheap-acuvue-contact-lenses",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/contact-lense",
    redirect: "//"
  }, {
    path: "/disposable-contacts",
    redirect: "/daily-disposables"
  }, {
    path: "/focus-contacts",
    redirect: "/focus-dailies"
  }, {
    path: "/green-contacts",
    redirect: "/green-contact-lenses"
  }, {
    path: "/varifocal-lenses",
    redirect: "/varifocal-contact-lenses"
  }, {
    path: "/acuvue-lenses",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/buy-contacts",
    redirect: "//"
  }, {
    path: "/contact-lenses-coloured",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/toric-contact-lenses",
    redirect: "/toric-lenses"
  }, {
    path: "/bausch-lomb-contact-lenses",
    redirect: "/bausch-lomb"
  }, {
    path: "/buy-lenses",
    redirect: "//"
  }, {
    path: "/coloured-contacts",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/softlens",
    redirect: "/soflens"
  }, {
    path: "/xcel-toric",
    redirect: "/frequency-xcel-toric-lenses"
  }, {
    path: "/acuvue-astigmatism",
    redirect: "/acuvue-advance-astigmatism"
  }, {
    path: "/bausch-lomb-purevision",
    redirect: "/purevision-contact-lenses"
  }, {
    path: "/daily-contacts",
    redirect: "/daily-disposables"
  }, {
    path: "/disposable-lenses",
    redirect: "/daily-disposables"
  }, {
    path: "/grey-contacts",
    redirect: "/grey-contact-lenses"
  }, {
    path: "/pure-vision",
    redirect: "/purevision-contact-lenses"
  }, {
    path: "/acuvue-lenses-online",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/brown-contacts",
    redirect: "/brown-contact-lenses"
  }, {
    path: "/buy-contact-lenses-online",
    redirect: "//"
  }, {
    path: "/cheap-coloured-contacts",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/contact-lenses-on-line",
    redirect: "//"
  }, {
    path: "/daily-wear-contact-lenses",
    redirect: "/daily-disposables"
  }, {
    path: "/eye-contact-lenses",
    redirect: "//"
  }, {
    path: "/frequency-contacts",
    redirect: "/frequency"
  }, {
    path: "/johnson-johnson-contact-lenses",
    redirect: "/johnson-johnson"
  }, {
    path: "/monthly-contacts",
    redirect: "/monthly-disposables"
  }, {
    path: "/order-contacts",
    redirect: "//"
  }, {
    path: "/toric-daily-contact-lenses",
    redirect: "/daily-toric-contact-lenses"
  }, {
    path: "/your-lenses",
    redirect: "//"
  }, {
    path: "/astigmatism-contacts",
    redirect: "/toric-lenses"
  }, {
    path: "/buy-contact-lens",
    redirect: "//"
  }, {
    path: "/buy-contacts-online",
    redirect: "//"
  }, {
    path: "/contact-lenses-online",
    redirect: "//"
  }, {
    path: "/continuous-wear-contacts",
    redirect: "/continuous-wear-contact-lenses"
  }, {
    path: "/daily-contact-lens",
    redirect: "/daily-disposables"
  }, {
    path: "/disposable-contact-lens",
    redirect: "/daily-disposables"
  }, {
    path: "/eye-contact-lens",
    redirect: "//"
  }, {
    path: "/lenses",
    redirect: "//"
  }, {
    path: "/soflens-natural-colors-contact-lenses",
    redirect: "/coloured-contact-lenses/soflens-natural-colors_p97"
  }, {
    path: "/contact-lens-cases/barrel-lens-case_p208",
    component: _1b085a39,
    name: "en-GB/product/208"
  }, {
    path: "/contact-lens-cases/barrel-lens-case_208",
    redirect: "/contact-lens-cases/barrel-lens-case_p208"
  }, {
    path: "/eye-drops/systane-ultra-eye-drops_p209",
    component: _1b085a39,
    name: "en-GB/product/209"
  }, {
    path: "/eye-drops/polaroid-eye-drops_p210",
    component: _1b085a39,
    name: "en-GB/product/210"
  }, {
    path: "/cooper-vision/toric-lenses/avaira-toric_p211",
    component: _1b085a39,
    name: "en-GB/product/211"
  }, {
    path: "/eye-drops/blink-revitalising-preservative-free-vials_p70",
    redirect: "/eye-drops/blink-refreshing-preservative-free-vials_p70"
  }, {
    path: "/how-is-it-possible",
    component: _974a4b7e,
    name: "en-GB/document/83"
  }, {
    path: "/ndl-acuvue-for-astigmatism",
    component: _387cb151,
    name: "en-GB/brand/294"
  }, {
    path: "/ndl-complete-solution",
    component: _387cb151,
    name: "en-GB/brand/282"
  }, {
    path: "/ndl-contact-for-lenses",
    component: _387cb151,
    name: "en-GB/brand/283"
  }, {
    path: "/ndl-contact-lens-shop",
    component: _387cb151,
    name: "en-GB/brand/285"
  }, {
    path: "/ndl-contact-lenses-for-sale",
    component: _387cb151,
    name: "en-GB/brand/284"
  }, {
    path: "/ndl-coopervision-options",
    component: _387cb151,
    name: "en-GB/brand/291"
  }, {
    path: "/ndl-express-delivery",
    component: _387cb151,
    name: "en-GB/brand/286"
  }, {
    path: "/ndl-fast-delivery-on-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/287"
  }, {
    path: "/ndl-lenses",
    component: _387cb151,
    name: "en-GB/brand/288"
  }, {
    path: "/ndl-multi-purpose-contact-lens-solutions",
    component: _387cb151,
    name: "en-GB/brand/295"
  }, {
    path: "/ndl-multifocal-toric-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/289"
  }, {
    path: "/ndl-opti-free-solution",
    component: _387cb151,
    name: "en-GB/brand/290"
  }, {
    path: "/ndl-total-care-solution-by-amo",
    component: _387cb151,
    name: "en-GB/brand/293"
  }, {
    path: "/find-your-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/298"
  }, {
    path: "/air-optix-contact-lenses",
    redirect: "/air-optix-aqua-contact-lenses"
  }, {
    path: "/bauschampersandlomb",
    redirect: "/bausch-and-lomb"
  }, {
    path: "/lens-case-",
    component: _1b085a39,
    name: "en-GB/product/189"
  }, {
    path: "/1-day-acuvue-moist-2",
    component: _974a4b7e,
    name: "en-GB/document/85"
  }, {
    path: "/1day-acuvue-moist",
    redirect: "/1-day-acuvue-moist-2"
  }, {
    path: "/acuvue-contact-lenses-2",
    component: _974a4b7e,
    name: "en-GB/document/86"
  }, {
    path: "/bausch-and-lomb-contact-lenses-2",
    component: _974a4b7e,
    name: "en-GB/document/87"
  }, {
    path: "/focus-dailies-contact-lenses-2",
    component: _974a4b7e,
    name: "en-GB/document/88"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-aqua_p213",
    component: _1b085a39,
    name: "en-GB/product/213"
  }, {
    path: "/ciba-vision/extended-wear-lenses/air-optix-night-day-aqua_p214",
    component: _1b085a39,
    name: "en-GB/product/214"
  }, {
    path: "/home2",
    redirect: "//"
  }, {
    path: "/white-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/black-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/red-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/movie-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/other-halloween-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/cat-eye-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/uv-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/all-halloween-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/twilight-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/novelty-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/crazy-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/fancy-dress-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/yellow-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/vampire-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/zombie-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/freaky-prescription-contact-lenses",
    redirect: "/prescription-halloween-lenses"
  }, {
    path: "/edit",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/autoreorder",
    component: _974a4b7e,
    name: "en-GB/document/89"
  }, {
    path: "/coloured-contact-lenses/soflens",
    redirect: "/coloured-contact-lenses/soflens-natural-colors_p97"
  }, {
    path: "/cheapest-contact-lenses",
    redirect: "//"
  }, {
    path: "/discount-contact-lenses-uk",
    redirect: "//"
  }, {
    path: "/cheap-contacts",
    redirect: "//"
  }, {
    path: "/cheap-contact-lenses-uk",
    redirect: "//"
  }, {
    path: "/cheap-contact-lens",
    redirect: "//"
  }, {
    path: "/discount-contacts",
    redirect: "//"
  }, {
    path: "/discount-contact-lenses",
    redirect: "//"
  }, {
    path: "/buy-online/contact-lenses-online",
    redirect: "//"
  }, {
    path: "/buy-online/contact-lenses",
    redirect: "//"
  }, {
    path: "/cheap-lenses",
    redirect: "//"
  }, {
    path: "/vision-express-contact-lenses",
    redirect: "/opticians-own-brand-conversion"
  }, {
    path: "/blog/auto-re-order",
    redirect: "/blog/never-run-out-of-contact-lenses-with-auto-re-order"
  }, {
    path: "/ndl-cheap-contact-lenses-online",
    component: _387cb151,
    name: "en-GB/brand/300"
  }, {
    path: "/multi-purpose-solutions/biotrue-flat-pack_p215",
    component: _1b085a39,
    name: "en-GB/product/215"
  }, {
    path: "/biotrue-flat-pack-240ml",
    redirect: "/multi-purpose-solutions/biotrue-flat-pack_p215"
  }, {
    path: "/sauflon",
    component: _387cb151,
    name: "en-GB/brand/301"
  }, {
    path: "/sauflon/monthly-disposables",
    component: _25921ca4,
    name: "en-GB/category/114"
  }, {
    path: "/sauflon/toric-lenses",
    component: _25921ca4,
    name: "en-GB/category/115"
  }, {
    path: "/sauflon/multifocal-lenses",
    component: _25921ca4,
    name: "en-GB/category/116"
  }, {
    path: "/sauflon/daily-disposables/bioclear-1-day_p216",
    component: _1b085a39,
    name: "en-GB/product/216"
  }, {
    path: "/sauflon/multifocal-lenses/clariti-1-day-multifocal_p217",
    component: _1b085a39,
    name: "en-GB/product/217"
  }, {
    path: "/sauflon/daily-disposables/clariti-1-day-toric_p218",
    component: _1b085a39,
    name: "en-GB/product/218"
  }, {
    path: "/sauflon/daily-disposables/clariti-1-day_p219",
    component: _1b085a39,
    name: "en-GB/product/219"
  }, {
    path: "/sauflon/monthly-disposables/clariti-monthly_p220",
    component: _1b085a39,
    name: "en-GB/product/220"
  }, {
    path: "/sauflon/monthly-disposables/clariti-monthly-multifocal_p221",
    component: _1b085a39,
    name: "en-GB/product/221"
  }, {
    path: "/multifocal-lenses/bausch-lomb/soflens-multi-focal_p20",
    redirect: "/bausch-lomb/multifocal-lenses/soflens-multi-focal_p20"
  }, {
    path: "/sauflon/toric-lenses/clariti-monthly-toric_p222",
    component: _1b085a39,
    name: "en-GB/product/222"
  }, {
    path: "/sauflon/monthly-disposables/sauflon-55-uv_p223",
    component: _1b085a39,
    name: "en-GB/product/223"
  }, {
    path: "/clariti-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/302"
  }, {
    path: "/sauflon/monthly-disposables/select_p224",
    component: _1b085a39,
    name: "en-GB/product/224"
  }, {
    path: "/clariti-multifocal-lenses",
    component: _387cb151,
    name: "en-GB/brand/303"
  }, {
    path: "/clariti-monthly-lenses",
    component: _387cb151,
    name: "en-GB/brand/305"
  }, {
    path: "/clariti-toric-lenses",
    component: _387cb151,
    name: "en-GB/brand/306"
  }, {
    path: "/clariti-daily-disposable-lenses",
    component: _387cb151,
    name: "en-GB/brand/304"
  }, {
    path: "/ciba",
    redirect: "/ciba-vision"
  }, {
    path: "/coloured-contact-lenses/freshlook",
    redirect: "/freshlook-contact-lenses"
  }, {
    path: "/sauflon/daily-disposables",
    component: _25921ca4,
    name: "en-GB/category/113"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/total-care-disinfecting-storing-and-wetting-solution_p129",
    redirect: "/hard-rgp-solutions/total-care-disinfecting-solution_p129"
  }, {
    path: "/ciba-vision/daily-disposables/dailies-total-1_p225",
    component: _1b085a39,
    name: "en-GB/product/225"
  }, {
    path: "/hard-/-rgp-lens-solutions/solutions-accessories/total-care-disinfecting-solution_p129",
    redirect: "/hard-rgp-solutions/total-care-disinfecting-solution_p129"
  }, {
    path: "/bausch-lomb/daily-disposables/biotrue-oneday_p226",
    component: _1b085a39,
    name: "en-GB/product/226"
  }, {
    path: "/bausch-lomb/disposable-lenses/biotrue-oneday_p226",
    redirect: "/bausch-lomb/daily-disposables/biotrue-oneday_p226"
  }, {
    path: "/bausch-lomb/daily-disposables/biotrue-oneday-90-pack_p227",
    component: _1b085a39,
    name: "en-GB/product/227"
  }, {
    path: "/bausch-lomb/multifocal-lenses/purevision-2-hd-for-presbyopia_p228",
    component: _1b085a39,
    name: "en-GB/product/228"
  }, {
    path: "/bausch-lomb/multifocal-lenses/purevision-2-hd-for-presbyopia_p229",
    component: _1b085a39,
    name: "en-GB/product/229"
  }, {
    path: "/bifocal-lenses",
    redirect: "/multifocal-lenses"
  }, {
    path: "/bifocal-contacts",
    redirect: "/multifocal-lenses"
  }, {
    path: "/disposable-coloured-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/coloured-contacts-for-dark-eyes",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/dry-eye-drops",
    redirect: "/eye-drops"
  }, {
    path: "/hazel-contacts",
    redirect: "/brown-contact-lenses"
  }, {
    path: "/cooper-vision/toric-lenses/biomedics-1-day-extra-toric_p57",
    redirect: "/cooper-vision/toric-lenses/biomedics-1-day-toric_p57"
  }, {
    path: "/acuvue-oasys-with-hydraclear-plus",
    redirect: "/acuvue/extended-wear-lenses/acuvue-oasys_p38"
  }, {
    path: "/air-o2-optix",
    redirect: "/air-optix-aqua-contact-lenses"
  }, {
    path: "/aqua-contact-lenses",
    redirect: "/green-contact-lenses"
  }, {
    path: "/bausch-and-lomb-purevision",
    redirect: "/purevision-contact-lenses"
  }, {
    path: "/bifocal-contact-lenses",
    redirect: "/multifocal-lenses"
  }, {
    path: "/ciba-vision-focus-dailies-toric",
    redirect: "/ciba-vision/toric-lenses/focus-dailies-toric_p10"
  }, {
    path: "/ciba-vision-night-and-day",
    redirect: "/ciba-vision/extended-wear-lenses/air-optix-night-day-aqua_p22"
  }, {
    path: "/ciba-vision-uk",
    redirect: "/ciba-vision"
  }, {
    path: "/coloured-contact-lenses-for-dark-eyes",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/contact-lenses-for-astigmatism",
    redirect: "/toric-lenses"
  }, {
    path: "/contact-lens-eye-drops",
    redirect: "/eye-drops"
  }, {
    path: "/cosmetic-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/dailies-contact-lenses",
    redirect: "/focus-dailies"
  }, {
    path: "/dailies-contact-lenses-uk",
    redirect: "/focus-dailies"
  }, {
    path: "/daily-contact-lenses-uk",
    redirect: "/daily-disposables"
  }, {
    path: "/daily-soft-lenses",
    redirect: "/daily-disposables"
  }, {
    path: "/eye-drops-for-dry-eyes",
    redirect: "/eye-drops"
  }, {
    path: "/focus-dailies-aquacomfort-plus",
    redirect: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p5"
  }, {
    path: "/focus-daily",
    redirect: "/focus-dailies"
  }, {
    path: "/freshlook-colours",
    redirect: "/coloured-contact-lenses/freshlook-colors_p92"
  }, {
    path: "/freshlook-lenses-for-dark-eyes",
    redirect: "/freshlook-contact-lenses"
  }, {
    path: "/freshlook-lenses-for-light-eyes",
    redirect: "/freshlook-contact-lenses"
  }, {
    path: "/hazel-contact-lenses",
    redirect: "/brown-contact-lenses"
  }, {
    path: "/honey-contact-lenses",
    redirect: "/brown-contact-lenses"
  }, {
    path: "/i-caps-eye-vitamins",
    redirect: "/eye-vitamins"
  }, {
    path: "/monthly-contact-lenses",
    redirect: "/monthly-disposables"
  }, {
    path: "/natural-coloured-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/oasys-contact-lenses",
    redirect: "/acuvue-oasys-contacts"
  }, {
    path: "/purple-contact-lenses",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/soflens-66-toric",
    redirect: "/bausch-lomb/toric-lenses/soflens-for-astigmatism_p19"
  }, {
    path: "/soft-lenses",
    redirect: "/daysoft-contact-lenses"
  }, {
    path: "/toric-daily-disposable-contact-lenses",
    redirect: "/daily-toric-contact-lenses"
  }, {
    path: "/turquoise-contact-lenses",
    redirect: "/blue-contact-lenses"
  }, {
    path: "/violet-contact-lenses",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/acuvue-online",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/bausch-and-lomb-renu-solution",
    redirect: "/multi-purpose-solutions/renu-multi-purpose-solution_p42"
  }, {
    path: "/buy-contact-lenses",
    redirect: "//"
  }, {
    path: "/daily-coloured-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/preservative-free-eye-drops",
    redirect: "/eye-drops"
  }, {
    path: "/soft-contact-lenses",
    redirect: "/daysoft-contact-lenses"
  }, {
    path: "/cooper-vision/daily-disposables/my-day_p230",
    component: _1b085a39,
    name: "en-GB/product/230"
  }, {
    path: "/cooper-vision/extended-wear-lenses/biofinity-xr_p231",
    component: _1b085a39,
    name: "en-GB/product/231"
  }, {
    path: "/partner-opticians",
    redirect: "/contact-us"
  }, {
    path: "/buy-online/daily-contact-lenses",
    redirect: "/daily-disposables"
  }, {
    path: "/buy-online/daily-contact-lenses",
    redirect: "/toric-lenses"
  }, {
    path: "/buy-online/toric-lenses",
    redirect: "/toric-lenses"
  }, {
    path: "/buy-online/contact-lens-solution",
    redirect: "/solutions-accessories"
  }, {
    path: "/buy-online/bifocal-lenses",
    redirect: "/multifocal-lenses"
  }, {
    path: "/buy-online/focus-dailies",
    redirect: "/focus-dailies"
  }, {
    path: "/free-biotrue",
    redirect: "/biotrue-solution"
  }, {
    path: "/free-solution",
    redirect: "/biotrue-solution"
  }, {
    path: "/free-solution-added",
    redirect: "/biotrue-solution"
  }, {
    path: "/campaign-ended",
    redirect: "/solutions-accessories"
  }, {
    path: "/e-commerce-web-developer-php",
    redirect: "/careers"
  }, {
    path: "/ecommerce-developer",
    redirect: "/careers"
  }, {
    path: "/alt-home",
    redirect: "//"
  }, {
    path: "/introductory-discount",
    redirect: "/tell-a-friend"
  }, {
    path: "/is-it-safe-to-buy-from-lenstore",
    redirect: "/faqs"
  }, {
    path: "/buying-contact-lenses-from-lenstore",
    redirect: "/terms-conditions"
  }, {
    path: "/a-welcome-gift",
    redirect: "/tell-a-friend"
  }, {
    path: "/give-a-gift",
    redirect: "/tell-a-friend"
  }, {
    path: "/thank-you-for-sharing",
    redirect: "/tell-a-friend"
  }, {
    path: "/buy-online/acuvue-contact-lenses",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/front-end-web-developer",
    redirect: "/careers"
  }, {
    path: "/yes-to-free-solution",
    redirect: "/biotrue-solution"
  }, {
    path: "/no-to-free-solution",
    redirect: "/biotrue-solution"
  }, {
    path: "/atv",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8"
  }, {
    path: "/atv-trueye",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8"
  }, {
    path: "/perfect-homepage",
    redirect: "//"
  }, {
    path: "/buy-online/toric-contact-lenses",
    redirect: "/toric-lenses"
  }, {
    path: "/multi-purpose-solutions/solutions-accessories/options-multi-p146",
    redirect: "/multi-purpose-solutions/options-multi_p146"
  }, {
    path: "/a-site-for-eyesores",
    component: _974a4b7e,
    name: "en-GB/document/92"
  }, {
    path: "/cooper-vision/toric-lenses/proclear-compatibles",
    redirect: "/cooper-vision/toric-lenses/proclear-toric_p35"
  }, {
    path: "/eye-drops/optrex-tired-eye-spray_p205",
    redirect: "/eye-drops/optrex-actimist-tired-eye-spray_p205"
  }, {
    path: "/biotrue",
    component: _387cb151,
    name: "en-GB/brand/309"
  }, {
    path: "/myday",
    component: _387cb151,
    name: "en-GB/brand/310"
  }, {
    path: "/vision-direct",
    redirect: "//"
  }, {
    path: "/coloured-contact-lenses/air-optix-colors_p232",
    component: _1b085a39,
    name: "en-GB/product/232"
  }, {
    path: "/ciba-vision/toric-lenses/dailies-aquacomfort-plus-toric_p233",
    component: _1b085a39,
    name: "en-GB/product/233"
  }, {
    path: "/ciba-vision/multifocal-lenses/dailies-aquacomfort-plus-multifocal_p234",
    component: _1b085a39,
    name: "en-GB/product/234"
  }, {
    path: "/request-your-appointment",
    component: _12d211f4,
    name: "en-GB/store-locator"
  }, {
    path: "/free-eye-test",
    redirect: "/request-your-appointment"
  }, {
    path: "/steph-mclean",
    component: _974a4b7e,
    name: "en-GB/document/93"
  }, {
    path: "/contact-lenses-news",
    redirect: "/blog"
  }, {
    path: "/all-coloured-contacts",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/lensbase-contact-lenses",
    redirect: "//"
  }, {
    path: "/bausch-lomb/multifocal-lenses/biotrue-oneday-for-presbyopia_p235",
    component: _1b085a39,
    name: "en-GB/product/235"
  }, {
    path: "/biotrue-multifocal-lenses",
    component: _387cb151,
    name: "en-GB/brand/312"
  }, {
    path: "/feel-good-contact-lenses",
    redirect: "/feel-good-about-contacts"
  }, {
    path: "/eyetest",
    redirect: "/request-your-appointment?utm_source=lenstore&utm_medium=package-insert&utm_campaign=free-eye-test"
  }, {
    path: "/eye-drops/biotrue-rewetting-drops-vials_p236",
    component: _1b085a39,
    name: "en-GB/product/236"
  }, {
    path: "/other-accessories/therapearl-eye-mask_p237",
    component: _1b085a39,
    name: "en-GB/product/237"
  }, {
    path: "/eye-drops/biotrue-rewetting-drops_p238",
    component: _1b085a39,
    name: "en-GB/product/238"
  }, {
    path: "/eye-drops/biotrue-rewetting-drops_p237",
    redirect: "/eye-drops/biotrue-rewetting-drops_p238"
  }, {
    path: "/other-accessories/therapearl-eye-mask_p238",
    redirect: "/other-accessories/therapearl-eye-mask_p237"
  }, {
    path: "/feel-good-contacts",
    redirect: "/feel-good-about-contacts"
  }, {
    path: "/coloured-contact-lenses/1-day-acuvue-define-natural-shimmer_p239",
    component: _1b085a39,
    name: "en-GB/product/239"
  }, {
    path: "/coloured-contact-lenses/1-day-acuvue-define-natural-sparkle_p240",
    component: _1b085a39,
    name: "en-GB/product/240"
  }, {
    path: "/1-day-acuvue-define",
    component: _387cb151,
    name: "en-GB/brand/313"
  }, {
    path: "/checkout",
    component: _3747f11c
  }, {
    path: "/account/order-history",
    component: _829d8442,
    name: "en-GB/orders"
  }, {
    path: "/eye-vitamins/ocuvite-complete_p148",
    redirect: "/ocular-vitamins/ocuvite-complete_p148"
  }, {
    path: "/macushield",
    component: _387cb151,
    name: "en-GB/brand/314"
  }, {
    path: "/ocular-vitamins/macushield_p241",
    component: _1b085a39,
    name: "en-GB/product/241"
  }, {
    path: "/ocular-vitamins/macushield_p242",
    component: _1b085a39,
    name: "en-GB/product/242"
  }, {
    path: "/ocular-vitamins/macushield-vegetarian_p243",
    component: _1b085a39,
    name: "en-GB/product/243"
  }, {
    path: "/ocular-vitamins/macushield-gold_p244",
    component: _1b085a39,
    name: "en-GB/product/244"
  }, {
    path: "/our-services",
    component: _974a4b7e,
    name: "en-GB/document/1"
  }, {
    path: "/other-accessories/blephasol_p245",
    component: _1b085a39,
    name: "en-GB/product/245"
  }, {
    path: "/other-accessories/blephaclean_p246",
    component: _1b085a39,
    name: "en-GB/product/246"
  }, {
    path: "/other-accessories/blephagel_p247",
    component: _1b085a39,
    name: "en-GB/product/247"
  }, {
    path: "/daily-disposables/dailies-total-1_p225",
    redirect: "/ciba-vision/daily-disposables/dailies-total-1_p225"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue_p11",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-moist_p13"
  }, {
    path: "/acuvue/multifocal-lenses/acuvue-bifocal_p37",
    redirect: "/acuvue/multifocal-lenses/acuvue-oasys-for-presbyopia_p152"
  }, {
    path: "/acuvue/toric-lenses/1-day-acuvue-for-astigmatism_p50",
    redirect: "/acuvue/toric-lenses/1-day-acuvue-moist-for-astigmatism_p106"
  }, {
    path: "/ciba-vision/toric-lenses/focus-toric-visitint_p52",
    redirect: "/ciba-vision/toric-lenses/air-optix-for-astigmatism_p48"
  }, {
    path: "/ciba-vision/monthly-disposables/precision-uv_p53",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p77"
  }, {
    path: "/cooper-vision/daily-disposables/biomedics-1-day_p55",
    redirect: "/cooper-vision/daily-disposables/biomedics-1-day-extra_p200"
  }, {
    path: "/cooper-vision/daily-disposables/biomedics-1-day_p56",
    redirect: "/cooper-vision/daily-disposables/biomedics-1-day-extra_p200"
  }, {
    path: "/cooper-vision/monthly-disposables/biomedics-38_p58",
    redirect: "/biomedics"
  }, {
    path: "/cooper-vision/multifocal-lenses/biomedics-73-multifocal_p59",
    redirect: "/biomedics"
  }, {
    path: "/acuvue/monthly-disposables/surevue_p78",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-individual_p81",
    redirect: "/air-optix-aqua-contact-lenses"
  }, {
    path: "/coloured-contact-lenses/focus-monthly-soft-colors_p91",
    redirect: "/freshlook-contact-lenses"
  }, {
    path: "/coloured-contact-lenses/expressions-accents_p98",
    redirect: "/coopervision-expressions"
  }, {
    path: "/coloured-contact-lenses/expressions-accents_p99",
    redirect: "/coopervision-expressions"
  }, {
    path: "/coloured-contact-lenses/images_p102",
    redirect: "/coopervision-expressions"
  }, {
    path: "/ciba-vision/extended-wear-lenses/air-optix-night-day_p105",
    redirect: "/ciba-vision/extended-wear-lenses/air-optix-night-day-aqua_p22"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue_p12",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-moist_p13"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix_p21",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p77"
  }, {
    path: "/ciba-vision/multifocal-lenses/focus-progressives_p23",
    redirect: "/ciba-vision/multifocal-lenses/air-optix-aqua-multifocal_p90"
  }, {
    path: "/ciba-vision/toric-lenses/focus-toric-visitint_p24",
    redirect: "/ciba-vision/toric-lenses/air-optix-for-astigmatism_p48"
  }, {
    path: "/ciba-vision/monthly-disposables/precision-uv_p26",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p77"
  }, {
    path: "/cooper-vision/monthly-disposables/actifresh-400_p27",
    redirect: "/cooper-vision"
  }, {
    path: "/cooper-vision/monthly-disposables/frequency-38_p28",
    redirect: "/frequency"
  }, {
    path: "/cooper-vision/monthly-disposables/frequency-58-uv_p31",
    redirect: "/frequency"
  }, {
    path: "/fashion-crazy-lenses/crazy-lenses_p107",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/fashion-crazy-lenses/wild-eyes_p108",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/aqua-3-tone_p153",
    redirect: "/coloured-contact-lenses/soflens-natural-colors_p97"
  }, {
    path: "/halloween-lenses/aqua-cat-eye_p154",
    redirect: "/coloured-contact-lenses/soflens-natural-colors_p97"
  }, {
    path: "/halloween-lenses/avatar_p155",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/blue-3-tone_p156",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/blue-manson_p157",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/glimmer-blue_p158",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/glimmer-green_p159",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/glimmer-silver_p160",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/green-3-tone_p161",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/grey-3-tone_p162",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/hazel-3-tone_p163",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/green-uv_p164",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/pink-uv_p165",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/violet-uv_p166",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/yellow-uv_p167",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/luna-eclipse_p168",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/mystic-aqua_p169",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/mystic-blue_p170",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/mystic-green_p171",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/twilight-volturi_p172",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/violet-3-tone_p173",
    redirect: "/coloured-contact-lenses/freshlook-colorblends_p93"
  }, {
    path: "/halloween-lenses/white-cat-eye_p174",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/white-mesh_p175",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/yellow-cat-eye_p176",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/white-out_p177",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/black-out_p178",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/red-out_p179",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/manson_p180",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/white-zombie_p181",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/hell-raiser_p182",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/twilight_p183",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/white-uv_p184",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/blue-uv_p185",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/red-cat-eye_p186",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/yellow_p187",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/red-screen_p188",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/halloween-lenses/limited-edition-union-jack_p199",
    redirect: "/alternative-coloured-contact-lenses"
  }, {
    path: "/coloured-contact-lenses/soflens-natural-colors-limited-stock_p212",
    redirect: "/coloured-contact-lenses/soflens-natural-colors_p97"
  }, {
    path: "/ciba-vision/daily-disposables/focus-dailies-basic_p62",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8"
  }, {
    path: "/surevue",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/acuvue-bifocal",
    redirect: "/acuvue/multifocal-lenses/acuvue-oasys-for-presbyopia_p152"
  }, {
    path: "/precision-uv",
    redirect: "/air-optix-aqua-contact-lenses"
  }, {
    path: "/monthly-disposables/acuvue",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/acuvue/monthly-disposables",
    redirect: "/acuvue-contact-lenses"
  }, {
    path: "/acuvue-advance-with-hydraclear",
    redirect: "/acuvue/two-weekly-disposables/acuvue-advance_p49"
  }, {
    path: "/acuvue-brand-lenses-for-astigmatism",
    redirect: "/acuvue/toric-lenses/acuvue-advance-for-astigmatism_p36"
  }, {
    path: "/biomedics-multifocal-lenses",
    redirect: "/biomedics"
  }, {
    path: "/birmingham-contact-lenses",
    redirect: "//"
  }, {
    path: "/cheap-coloured-contact-lenses",
    redirect: "/coloured-contact-lenses"
  }, {
    path: "/cheap-toric-contact-lenses",
    redirect: "/toric-contact-lenses"
  }, {
    path: "/ciba-vision-dailies-aquacomfort-plus",
    redirect: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p5"
  }, {
    path: "/continuous-wear-contact-lenses",
    redirect: "/extended-wear-lenses"
  }, {
    path: "/coopervision-proclear",
    redirect: "/proclear-contact-lenses"
  }, {
    path: "/daily-contact-lenses-for-astigmatism",
    redirect: "/daily-toric-contact-lenses"
  }, {
    path: "/true-eye-contact-lenses",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-trueye_p76"
  }, {
    path: "/varifocal-contact-lenses",
    redirect: "/multifocal-lenses"
  }, {
    path: "/bausch-and-lomb",
    redirect: "/bausch-lomb"
  }, {
    path: "/contact-lenses-uk",
    redirect: "//"
  }, {
    path: "/focus-dailies-contact-lenses",
    redirect: "/focus-dailies"
  }, {
    path: "/manchester-contact-lenses",
    redirect: "//"
  }, {
    path: "/edinburgh-contact-lenses",
    redirect: "//"
  }, {
    path: "/dollond-and-aitchison-contact-lenses",
    redirect: "/opticians-own-brand-conversion"
  }, {
    path: "/prescription-contact-lenses",
    redirect: "//"
  }, {
    path: "/optical-express-contact-lenses",
    redirect: "/opticians-own-brand-conversion"
  }, {
    path: "/sainsburys-contact-lenses",
    redirect: "/opticians-own-brand-conversion"
  }, {
    path: "/lensway",
    redirect: "//"
  }, {
    path: "/acuvue-true-eye-contact-lenses",
    redirect: "/acuvue/daily-disposables/1-day-acuvue-trueye_p76"
  }, {
    path: "/biomedics-toric-contact-lenses",
    redirect: "/cooper-vision/toric-lenses/biomedics-toric_p54"
  }, {
    path: "/frequency-xcel-toric-and-xr",
    redirect: "/cooper-vision/toric-lenses/frequency-xcel-toric_p33"
  }, {
    path: "/freshlook-lenses",
    redirect: "/freshlook-contact-lenses"
  }, {
    path: "/proclear-multifocal-contact-lenses",
    redirect: "/cooper-vision/multifocal-lenses/proclear-multifocal_p61"
  }, {
    path: "/proclear-toric-for-astigmatism",
    redirect: "/cooper-vision/toric-lenses/proclear-toric_p35"
  }, {
    path: "/boots-contact-lenses",
    component: _974a4b7e,
    name: "en-GB/document/110"
  }, {
    path: "/contact-lenses-without-a-prescription-usa",
    redirect: "/contact-lenses-without-prescription"
  }, {
    path: "/contact-lenses-direct",
    redirect: "//"
  }, {
    path: "/feel-good-about-contacts",
    redirect: "//"
  }, {
    path: "/contact-lenses-by-post",
    redirect: "//"
  }, {
    path: "/eye-drops/clinitas-soothe-vials_p248",
    component: _1b085a39,
    name: "en-GB/product/248"
  }, {
    path: "/how-your-eyes-work",
    component: _974a4b7e,
    name: "en-GB/document/95"
  }, {
    path: "/other-accessories/blink-lid-clean-wipes_p249",
    component: _1b085a39,
    name: "en-GB/product/249"
  }, {
    path: "/acuvue/multifocal-lenses/1-day-acuvue-moist-multifocal_p250",
    component: _1b085a39,
    name: "en-GB/product/250"
  }, {
    path: "/acuvue/multifocal/1-day-acuvue-moist-multifocal_p250",
    redirect: "/acuvue/multifocal-lenses/1-day-acuvue-moist-multifocal_p250"
  }, {
    path: "/renu-solutions",
    component: _387cb151,
    name: "en-GB/brand/315"
  }, {
    path: "/opti-free-solutions",
    component: _387cb151,
    name: "en-GB/brand/316"
  }, {
    path: "/search-home",
    component: _974a4b7e,
    name: "en-GB/document/96"
  }, {
    path: "/biotrue-offer",
    redirect: "/biotrue"
  }, {
    path: "/ciba-vision/daily-disposables/dailies-total-1_p251",
    component: _1b085a39,
    name: "en-GB/product/251"
  }, {
    path: "/daily-disposables/dailies-total-1_p251",
    redirect: "/ciba-vision/daily-disposables/dailies-total-1_p251"
  }, {
    path: "/battersea",
    component: _974a4b7e,
    name: "en-GB/document/97"
  }, {
    path: "/love-your-pets-eyes",
    component: _974a4b7e,
    name: "en-GB/document/98"
  }, {
    path: "/cooper-vision/daily-disposables/my-day_p252",
    component: _1b085a39,
    name: "en-GB/product/252"
  }, {
    path: "/love-your-pets-eyes-3",
    component: _974a4b7e,
    name: "en-GB/document/99"
  }, {
    path: "/love-your-pets-eyes-2",
    component: _974a4b7e,
    name: "en-GB/document/100"
  }, {
    path: "/eye-drops/biotrue-rewetting-drops_p236",
    redirect: "/eye-drops/biotrue-rewetting-drops_p238"
  }, {
    path: "/love-your-pets-eyes-4",
    component: _974a4b7e,
    name: "en-GB/document/101"
  }, {
    path: "/love-your-pets-eyes-5",
    component: _974a4b7e,
    name: "en-GB/document/102"
  }, {
    path: "/multi-purpose-solutions/synergi_p253",
    component: _1b085a39,
    name: "en-GB/product/253"
  }, {
    path: "/multi-purpose-solutions/synergi-one-month-pack_p253",
    redirect: "/multi-purpose-solutions/synergi_p253"
  }, {
    path: "/multi-purpose-solutions/synergi-250ml_p253",
    redirect: "/multi-purpose-solutions/synergi_p253"
  }, {
    path: "/multi-purpose-solutions/synergi-triple-pack_p254",
    component: _1b085a39,
    name: "en-GB/product/254"
  }, {
    path: "/contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/317"
  }, {
    path: "/other-accessories/biotrue-daily-eyelid-wipes_p255",
    component: _1b085a39,
    name: "en-GB/product/255"
  }, {
    path: "/1-day-acuvue-for-astigmatism-30-lenses",
    component: _1b085a39,
    name: "en-GB/product/50"
  }, {
    path: "/eye-drops/optrex-intensive-eye-drops_p256",
    component: _1b085a39,
    name: "en-GB/product/256"
  }, {
    path: "/ocular-vitamins/energeyes_p257",
    component: _1b085a39,
    name: "en-GB/product/257"
  }, {
    path: "/hydrogen-peroxide-solutions/aosept-plus-hydraglyde_p258",
    component: _1b085a39,
    name: "en-GB/product/258"
  }, {
    path: "/hydrogen-peroxide-solutions/aoseptplus_hydraglyde_p258",
    redirect: "/hydrogen-peroxide-solutions/aosept-plus-hydraglyde_p258"
  }, {
    path: "/hydrogen-peroxide-solutions/aosept-plus-hydraglyde-twin-pack_p259",
    component: _1b085a39,
    name: "en-GB/product/259"
  }, {
    path: "/eye-drops/systane-ultra-eye-drops-vials_p260",
    component: _1b085a39,
    name: "en-GB/product/260"
  }, {
    path: "/eye-drops/systane-balance-lubricant-eye-drops_p261",
    component: _1b085a39,
    name: "en-GB/product/261"
  }, {
    path: "/eye-drops/systane-gel-drops_p262",
    component: _1b085a39,
    name: "en-GB/product/262"
  }, {
    path: "/hydrogen-peroxide-solutions/easysept-hydro_p263",
    component: _1b085a39,
    name: "en-GB/product/263"
  }, {
    path: "/eye-drops/blink-refreshing-mist_p264",
    component: _1b085a39,
    name: "en-GB/product/264"
  }, {
    path: "/search-buy-accessories",
    component: _974a4b7e,
    name: "en-GB/document/103"
  }, {
    path: "/search-buy-sunglasses",
    component: _974a4b7e,
    name: "en-GB/document/104"
  }, {
    path: "/about-sunglasses",
    component: _974a4b7e,
    name: "en-GB/document/105"
  }, {
    path: "/sunglasses/michael-kors-chelsea-mk5004-rose-gold-taupe-l_p265",
    component: _1b085a39,
    name: "en-GB/product/265"
  }, {
    path: "/sunglasses/michael-kors-chelsea-mk5004-gold-dark-chocolate-brown-l_p266",
    component: _1b085a39,
    name: "en-GB/product/266"
  }, {
    path: "/sunglasses/michael-kors-willa-i-mk2015b-red-gold-l_p267",
    component: _1b085a39,
    name: "en-GB/product/267"
  }, {
    path: "/sunglasses/michael-kors-mk6029-pink-tortoiseshell-rose-gold-l_p268",
    component: _1b085a39,
    name: "en-GB/product/268"
  }, {
    path: "/sunglasses/michael-kors-mk6029-dark-tortoiseshell-gold-l_p269",
    component: _1b085a39,
    name: "en-GB/product/269"
  }, {
    path: "/sunglasses/michael-kors-playa-norte-mk5006-tortoiseshell--gold-l_p270",
    component: _1b085a39,
    name: "en-GB/product/270"
  }, {
    path: "/sunglasses/michael-kors-sadie-i-mk1005-black-gold-leopard-black-l_p271",
    component: _1b085a39,
    name: "en-GB/product/271"
  }, {
    path: "/sunglasses/michael-kors-marrakesh-mk6006-black-l_p272",
    component: _1b085a39,
    name: "en-GB/product/272"
  }, {
    path: "/sunglasses/michael-kors-marrakesh-mk6006-dark-tortoiseshell-l_p273",
    component: _1b085a39,
    name: "en-GB/product/273"
  }, {
    path: "/sunglasses/michael-kors-fiji-mk1003-gold-l_p274",
    component: _1b085a39,
    name: "en-GB/product/274"
  }, {
    path: "/sunglasses/michael-kors-playa-norte-mk5006-black-silver-l_p275",
    component: _1b085a39,
    name: "en-GB/product/275"
  }, {
    path: "/sunglasses/michael-kors-isle-of-skye-mk6017-black-off-white-l_p276",
    component: _1b085a39,
    name: "en-GB/product/276"
  }, {
    path: "/sunglasses/michael-kors-isle-of-skye-mk6017-tortoiseshell-smokey-transparent-l_p277",
    component: _1b085a39,
    name: "en-GB/product/277"
  }, {
    path: "/sunglasses/michael-kors-chelsea-mk5004-rose-gold-l_p278",
    component: _1b085a39,
    name: "en-GB/product/278"
  }, {
    path: "/sunglasses/michael-kors-hvar-mk5007-silver-black-l_p279",
    component: _1b085a39,
    name: "en-GB/product/279"
  }, {
    path: "/sunglasses/michael-kors-gstaad-mk2009-dark-tortoiseshell-l_p280",
    component: _1b085a39,
    name: "en-GB/product/280"
  }, {
    path: "/sunglasses/oakley-frogskin-oo9013-crystal-m_p281",
    component: _1b085a39,
    name: "en-GB/product/281"
  }, {
    path: "/sunglasses/oakley-sliver-oo9262-matte-black-grey-l_p282",
    component: _1b085a39,
    name: "en-GB/product/282"
  }, {
    path: "/sunglasses/oakley-sliver-oo9262-matte-black-violet-iridium-polarised-l_p283",
    component: _1b085a39,
    name: "en-GB/product/283"
  }, {
    path: "/sunglasses/oakley-catalyst-oo9272-black-ink-positive-red-iridium-m_p284",
    component: _1b085a39,
    name: "en-GB/product/284"
  }, {
    path: "/sunglasses/oakley-holbrook-oo9102-matte-black-grey-m_p285",
    component: _1b085a39,
    name: "en-GB/product/285"
  }, {
    path: "/sunglasses/oakley-holbrook-oo9102-black-grey-m_p286",
    component: _1b085a39,
    name: "en-GB/product/286"
  }, {
    path: "/sunglasses/oakley-jupiter-squared-oo9135-black-l_p287",
    component: _1b085a39,
    name: "en-GB/product/287"
  }, {
    path: "/sunglasses/oakley-frogskin-oo9013-black-violet-iridium-m_p288",
    component: _1b085a39,
    name: "en-GB/product/288"
  }, {
    path: "/sunglasses/oakley-frogskin-oo9013-black-fire-iridium-m_p289",
    component: _1b085a39,
    name: "en-GB/product/289"
  }, {
    path: "/sunglasses/oakley-two-face-oo9189-black-violet-iridium-l_p290",
    component: _1b085a39,
    name: "en-GB/product/290"
  }, {
    path: "/sunglasses/oakley-five-squared-oo9238-black-m_p291",
    component: _1b085a39,
    name: "en-GB/product/291"
  }, {
    path: "/sunglasses/oakley-five-squared-oo9238-grey-m_p292",
    component: _1b085a39,
    name: "en-GB/product/292"
  }, {
    path: "/sunglasses/oakley-holbrook-oo9102-black-blue-m_p293",
    component: _1b085a39,
    name: "en-GB/product/293"
  }, {
    path: "/sunglasses/oakley-square-wire-oo4075-black-l_p294",
    component: _1b085a39,
    name: "en-GB/product/294"
  }, {
    path: "/sunglasses/oakley-valve-oo9236-black-l_p295",
    component: _1b085a39,
    name: "en-GB/product/295"
  }, {
    path: "/sunglasses/oakley-conductor-oo4107-matte-black-l_p296",
    component: _1b085a39,
    name: "en-GB/product/296"
  }, {
    path: "/sunglasses/ralph-ra5201-teal-tortoiseshell-gold-m_p297",
    component: _1b085a39,
    name: "en-GB/product/297"
  }, {
    path: "/sunglasses/ralph-ra5196-brown-m_p298",
    component: _1b085a39,
    name: "en-GB/product/298"
  }, {
    path: "/sunglasses/ralph-ra5150-havana-l_p299",
    component: _1b085a39,
    name: "en-GB/product/299"
  }, {
    path: "/sunglasses/ralph-ra5160-black-l_p300",
    component: _1b085a39,
    name: "en-GB/product/300"
  }, {
    path: "/sunglasses/ralph-ra5168-tortoiseshell-l_p301",
    component: _1b085a39,
    name: "en-GB/product/301"
  }, {
    path: "/sunglasses/ralph-ra5165-tortoiseshell-l_p302",
    component: _1b085a39,
    name: "en-GB/product/302"
  }, {
    path: "/sunglasses/ralph-ra5130-tortoiseshell-l_p303",
    component: _1b085a39,
    name: "en-GB/product/303"
  }, {
    path: "/sunglasses/ralph-ra5176-black-l_p304",
    component: _1b085a39,
    name: "en-GB/product/304"
  }, {
    path: "/sunglasses/ralph-ra4115-black-gold-l_p305",
    component: _1b085a39,
    name: "en-GB/product/305"
  }, {
    path: "/sunglasses/ralph-ra5185-brown-horn-l_p306",
    component: _1b085a39,
    name: "en-GB/product/306"
  }, {
    path: "/sunglasses/ralph-ra5202-tortoiseshell-gold-m_p307",
    component: _1b085a39,
    name: "en-GB/product/307"
  }, {
    path: "/sunglasses/ralph-ra5196-black-black-bandana-m_p308",
    component: _1b085a39,
    name: "en-GB/product/308"
  }, {
    path: "/sunglasses/ralph-ra5203-black-nude-m_p309",
    component: _1b085a39,
    name: "en-GB/product/309"
  }, {
    path: "/sunglasses/ralph-ra5204-tortoiseshell-m_p310",
    component: _1b085a39,
    name: "en-GB/product/310"
  }, {
    path: "/sunglasses/ralph-ra5201-black-gold-m_p311",
    component: _1b085a39,
    name: "en-GB/product/311"
  }, {
    path: "/sunglasses/ralph-ra5202-blue-tortoiseshell-gold-m_p312",
    component: _1b085a39,
    name: "en-GB/product/312"
  }, {
    path: "/sunglasses/ralph-ra5203-pink-tortoiseshell-m_p313",
    component: _1b085a39,
    name: "en-GB/product/313"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-blue-m_p314",
    component: _1b085a39,
    name: "en-GB/product/314"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-green-m_p315",
    component: _1b085a39,
    name: "en-GB/product/315"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-orange-m_p316",
    component: _1b085a39,
    name: "en-GB/product/316"
  }, {
    path: "/sunglasses/ray-ban-erika-rb4171-black-blue-m_p317",
    component: _1b085a39,
    name: "en-GB/product/317"
  }, {
    path: "/sunglasses/ray-ban-rb4231-matte-black-l_p318",
    component: _1b085a39,
    name: "en-GB/product/318"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-gold-grey-l_p319",
    component: _1b085a39,
    name: "en-GB/product/319"
  }, {
    path: "/sunglasses/ray-ban-rb4226-havana-l_p320",
    component: _1b085a39,
    name: "en-GB/product/320"
  }, {
    path: "/sunglasses/ray-ban-rb4228-black-l_p321",
    component: _1b085a39,
    name: "en-GB/product/321"
  }, {
    path: "/sunglasses/ray-ban-justin-rb4165-black-rubber-m_p322",
    component: _1b085a39,
    name: "en-GB/product/322"
  }, {
    path: "/sunglasses/ray-ban-erika-rb4171-black-green-m_p323",
    component: _1b085a39,
    name: "en-GB/product/323"
  }, {
    path: "/sunglasses/ray-ban-rb4181-havana-l_p324",
    component: _1b085a39,
    name: "en-GB/product/324"
  }, {
    path: "/sunglasses/ray-ban-rb3498-black-l_p325",
    component: _1b085a39,
    name: "en-GB/product/325"
  }, {
    path: "/sunglasses/ray-ban-rb4184-havana-m_p326",
    component: _1b085a39,
    name: "en-GB/product/326"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-grey-s_p327",
    component: _1b085a39,
    name: "en-GB/product/327"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-silver-l_p328",
    component: _1b085a39,
    name: "en-GB/product/328"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-gloss-black-green-m_p329",
    component: _1b085a39,
    name: "en-GB/product/329"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-green-s_p330",
    component: _1b085a39,
    name: "en-GB/product/330"
  }, {
    path: "/sunglasses/ray-ban-rb4068-havana-polarised-l_p331",
    component: _1b085a39,
    name: "en-GB/product/331"
  }, {
    path: "/sunglasses/ray-ban-wayfarer-rb2140-black-s_p332",
    component: _1b085a39,
    name: "en-GB/product/332"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-havana-s_p333",
    component: _1b085a39,
    name: "en-GB/product/333"
  }, {
    path: "/sunglasses/ray-ban-rb4068-havana-l_p334",
    component: _1b085a39,
    name: "en-GB/product/334"
  }, {
    path: "/sunglasses/ray-ban-cats-5000-rb4125-havana-l_p335",
    component: _1b085a39,
    name: "en-GB/product/335"
  }, {
    path: "/sunglasses/ray-ban-clubmaster-rb3016-tortoiseshell-s_p336",
    component: _1b085a39,
    name: "en-GB/product/336"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-gold-m_p337",
    component: _1b085a39,
    name: "en-GB/product/337"
  }, {
    path: "/sunglasses/ray-ban-rb4147-havana-l_p338",
    component: _1b085a39,
    name: "en-GB/product/338"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-matte-black-green-m_p339",
    component: _1b085a39,
    name: "en-GB/product/339"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-gun-l_p340",
    component: _1b085a39,
    name: "en-GB/product/340"
  }, {
    path: "/sunglasses/ray-ban-justin-rb4165-black-m_p341",
    component: _1b085a39,
    name: "en-GB/product/341"
  }, {
    path: "/sunglasses/ray-ban-justin-rb4165-havana-m_p342",
    component: _1b085a39,
    name: "en-GB/product/342"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-gold-green-l_p343",
    component: _1b085a39,
    name: "en-GB/product/343"
  }, {
    path: "/sunglasses/ray-ban-clubmaster-rb3016-black-s_p344",
    component: _1b085a39,
    name: "en-GB/product/344"
  }, {
    path: "/sunglasses/ray-ban-erika-rb4171-havana-m_p345",
    component: _1b085a39,
    name: "en-GB/product/345"
  }, {
    path: "/sunglasses/ray-ban-rb3502-gun-l_p346",
    component: _1b085a39,
    name: "en-GB/product/346"
  }, {
    path: "/sunglasses/ray-ban-rb4198-black-m_p347",
    component: _1b085a39,
    name: "en-GB/product/347"
  }, {
    path: "/sunglasses/ray-ban-rb4198-havana-m_p348",
    component: _1b085a39,
    name: "en-GB/product/348"
  }, {
    path: "/sunglasses/carrera-100-s-black-ruth-l_p349",
    component: _1b085a39,
    name: "en-GB/product/349"
  }, {
    path: "/sunglasses/carrera-8015-s-ruth-l_p350",
    component: _1b085a39,
    name: "en-GB/product/350"
  }, {
    path: "/sunglasses/carrera-6012-s-havana-m_p351",
    component: _1b085a39,
    name: "en-GB/product/351"
  }, {
    path: "/sunglasses/carrera-6000-black-s_p352",
    component: _1b085a39,
    name: "en-GB/product/352"
  }, {
    path: "/sunglasses/carrera-97-s-havana-l_p353",
    component: _1b085a39,
    name: "en-GB/product/353"
  }, {
    path: "/sunglasses/carrera-new-safari-black-l_p354",
    component: _1b085a39,
    name: "en-GB/product/354"
  }, {
    path: "/sunglasses/carrera-6016-s-black-l_p355",
    component: _1b085a39,
    name: "en-GB/product/355"
  }, {
    path: "/sunglasses/carrera-new-champion-black-l_p356",
    component: _1b085a39,
    name: "en-GB/product/356"
  }, {
    path: "/sunglasses/boss-0561-s-blue-l_p357",
    component: _1b085a39,
    name: "en-GB/product/357"
  }, {
    path: "/sunglasses/boss-0561-s-black-l_p358",
    component: _1b085a39,
    name: "en-GB/product/358"
  }, {
    path: "/sunglasses/boss-0592-s-black-crystal-l_p359",
    component: _1b085a39,
    name: "en-GB/product/359"
  }, {
    path: "/sunglasses/boss-0732-s-black-l_p360",
    component: _1b085a39,
    name: "en-GB/product/360"
  }, {
    path: "/sunglasses/boss-0475-s-ruth-black-l_p361",
    component: _1b085a39,
    name: "en-GB/product/361"
  }, {
    path: "/sunglasses/boss-0509-s-black-m_p362",
    component: _1b085a39,
    name: "en-GB/product/362"
  }, {
    path: "/sunglasses/boss-0727-s-black-l_p363",
    component: _1b085a39,
    name: "en-GB/product/363"
  }, {
    path: "/sunglasses/boss-0471-s-black-s_p364",
    component: _1b085a39,
    name: "en-GB/product/364"
  }, {
    path: "/sunglasses/boss-0727-s-grey-l_p365",
    component: _1b085a39,
    name: "en-GB/product/365"
  }, {
    path: "/sunglasses/boss-0667-s-horn-s_p366",
    component: _1b085a39,
    name: "en-GB/product/366"
  }, {
    path: "/sunglasses/boss-0732-s-brown-l_p367",
    component: _1b085a39,
    name: "en-GB/product/367"
  }, {
    path: "/sunglasses/boss-0471-s-havana-s_p368",
    component: _1b085a39,
    name: "en-GB/product/368"
  }, {
    path: "/sunglasses/boss-0735-s-olive-m_p369",
    component: _1b085a39,
    name: "en-GB/product/369"
  }, {
    path: "/sunglasses/boss-0440-s-black-l_p370",
    component: _1b085a39,
    name: "en-GB/product/370"
  }, {
    path: "/sunglasses/boss-0539-s-black-l_p371",
    component: _1b085a39,
    name: "en-GB/product/371"
  }, {
    path: "/sunglasses/boss-0665-s-black-l_p372",
    component: _1b085a39,
    name: "en-GB/product/372"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-096-n-s-havana-l_p373",
    component: _1b085a39,
    name: "en-GB/product/373"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-096-n-s-black-l_p374",
    component: _1b085a39,
    name: "en-GB/product/374"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-458-s-havana-s_p375",
    component: _1b085a39,
    name: "en-GB/product/375"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-287-s-black-l_p376",
    component: _1b085a39,
    name: "en-GB/product/376"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-411-s-brown-l_p377",
    component: _1b085a39,
    name: "en-GB/product/377"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-461-s-havana-blue-s_p378",
    component: _1b085a39,
    name: "en-GB/product/378"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-464-s-havana-crystal-l_p379",
    component: _1b085a39,
    name: "en-GB/product/379"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-464-s-black-crystal-l_p380",
    component: _1b085a39,
    name: "en-GB/product/380"
  }, {
    path: "/sunglasses/heritage-hsam08-tortoiseshell-m_p381",
    component: _1b085a39,
    name: "en-GB/product/381"
  }, {
    path: "/sunglasses/heritage-hsbf03-pink-m_p382",
    component: _1b085a39,
    name: "en-GB/product/382"
  }, {
    path: "/sunglasses/heritage-hsaf17-black-s_p383",
    component: _1b085a39,
    name: "en-GB/product/383"
  }, {
    path: "/sunglasses/heritage-hsaf17-tortoiseshell-s_p384",
    component: _1b085a39,
    name: "en-GB/product/384"
  }, {
    path: "/sunglasses/heritage-hsbf15-black-l_p385",
    component: _1b085a39,
    name: "en-GB/product/385"
  }, {
    path: "/sunglasses/heritage-hsbf15-red-tortoiseshell-l_p386",
    component: _1b085a39,
    name: "en-GB/product/386"
  }, {
    path: "/sunglasses/heritage-hsbm03-grey-black-m_p387",
    component: _1b085a39,
    name: "en-GB/product/387"
  }, {
    path: "/sunglasses/heritage-hsbm07-silver-black-l_p388",
    component: _1b085a39,
    name: "en-GB/product/388"
  }, {
    path: "/sunglasses/heritage-hsam05-black-s_p389",
    component: _1b085a39,
    name: "en-GB/product/389"
  }, {
    path: "/sunglasses/heritage-hsam07-black-s_p390",
    component: _1b085a39,
    name: "en-GB/product/390"
  }, {
    path: "/sunglasses/joules-js7030-tortoiseshell-blue-m_p391",
    component: _1b085a39,
    name: "en-GB/product/391"
  }, {
    path: "/sunglasses/joules-js7032-black-m_p392",
    component: _1b085a39,
    name: "en-GB/product/392"
  }, {
    path: "/sunglasses/joules-js7032-tortoiseshell-floral-m_p393",
    component: _1b085a39,
    name: "en-GB/product/393"
  }, {
    path: "/sunglasses/joules-js7014-brown-cream-m_p394",
    component: _1b085a39,
    name: "en-GB/product/394"
  }, {
    path: "/sunglasses/joules-js7025-blue-white-l_p395",
    component: _1b085a39,
    name: "en-GB/product/395"
  }, {
    path: "/sunglasses/joules-js7025-purple-floral-l_p396",
    component: _1b085a39,
    name: "en-GB/product/396"
  }, {
    path: "/sunglasses/joules-js7011-blue-white-m_p397",
    component: _1b085a39,
    name: "en-GB/product/397"
  }, {
    path: "/sunglasses/joules-js7011-blue-floral-m_p398",
    component: _1b085a39,
    name: "en-GB/product/398"
  }, {
    path: "/sunglasses/joules-js7013-tortoiseshell-l_p399",
    component: _1b085a39,
    name: "en-GB/product/399"
  }, {
    path: "/sunglasses/joules-js7016-tortoiseshell-m_p400",
    component: _1b085a39,
    name: "en-GB/product/400"
  }, {
    path: "/sunglasses/joules-js7024-brown-l_p401",
    component: _1b085a39,
    name: "en-GB/product/401"
  }, {
    path: "/sunglasses/joules-js7014-black-red-m_p402",
    component: _1b085a39,
    name: "en-GB/product/402"
  }, {
    path: "/sunglasses/superdry-rockstar-black-m_p403",
    component: _1b085a39,
    name: "en-GB/product/403"
  }, {
    path: "/sunglasses/superdry-kobe-matte-black-m_p404",
    component: _1b085a39,
    name: "en-GB/product/404"
  }, {
    path: "/sunglasses/superdry-kobe-brown-m_p405",
    component: _1b085a39,
    name: "en-GB/product/405"
  }, {
    path: "/sunglasses/superdry-walker-gloss-black-fluro-orange-l_p406",
    component: _1b085a39,
    name: "en-GB/product/406"
  }, {
    path: "/sunglasses/superdry-huntsman-matte-silver-blue-l_p407",
    component: _1b085a39,
    name: "en-GB/product/407"
  }, {
    path: "/sunglasses/superdry-supergami-matte-black-l_p408",
    component: _1b085a39,
    name: "en-GB/product/408"
  }, {
    path: "/sunglasses/superdry-san-matte-dark-navy-s_p409",
    component: _1b085a39,
    name: "en-GB/product/409"
  }, {
    path: "/sunglasses/superdry-walker-matte-gold-camo-l_p410",
    component: _1b085a39,
    name: "en-GB/product/410"
  }, {
    path: "/sunglasses/superdry-astro-matte-black-l_p411",
    component: _1b085a39,
    name: "en-GB/product/411"
  }, {
    path: "/sunglasses/superdry-huntsman-shiny-gun-purple-l_p412",
    component: _1b085a39,
    name: "en-GB/product/412"
  }, {
    path: "/sunglasses/superdry-ni-gloss-tortoiseshell-m_p413",
    component: _1b085a39,
    name: "en-GB/product/413"
  }, {
    path: "/sunglasses/superdry-supergami-matte-tortoiseshell-fluro-blue-l_p414",
    component: _1b085a39,
    name: "en-GB/product/414"
  }, {
    path: "/sunglasses/ted-baker-tb1395-tortoiseshell-l_p415",
    component: _1b085a39,
    name: "en-GB/product/415"
  }, {
    path: "/sunglasses/ted-baker-tb1395-black-l_p416",
    component: _1b085a39,
    name: "en-GB/product/416"
  }, {
    path: "/sunglasses/ted-baker-tb1416-orange-marmalade-m_p417",
    component: _1b085a39,
    name: "en-GB/product/417"
  }, {
    path: "/sunglasses/ted-baker-tb1424-tortoiseshell-pink-l_p418",
    component: _1b085a39,
    name: "en-GB/product/418"
  }, {
    path: "/sunglasses/ted-baker-tb1424-tortoiseshell-mint-l_p419",
    component: _1b085a39,
    name: "en-GB/product/419"
  }, {
    path: "/sunglasses/ted-baker-tb1358-brown-m_p420",
    component: _1b085a39,
    name: "en-GB/product/420"
  }, {
    path: "/sunglasses/ted-baker-tb1394-black-l_p421",
    component: _1b085a39,
    name: "en-GB/product/421"
  }, {
    path: "/sunglasses/ted-baker-tb1415-tortoiseshell-m_p422",
    component: _1b085a39,
    name: "en-GB/product/422"
  }, {
    path: "/sunglasses/ted-baker-tb1183-tortoiseshell-l_p423",
    component: _1b085a39,
    name: "en-GB/product/423"
  }, {
    path: "/sunglasses/ted-baker-tb1183-black-l_p424",
    component: _1b085a39,
    name: "en-GB/product/424"
  }, {
    path: "/sunglasses/ted-baker-tb1297-tortoiseshell-l_p425",
    component: _1b085a39,
    name: "en-GB/product/425"
  }, {
    path: "/sunglasses/ted-baker-tb1358-tortoiseshell-m_p426",
    component: _1b085a39,
    name: "en-GB/product/426"
  }, {
    path: "/sunglasses/ted-baker-tb1393-black-l_p427",
    component: _1b085a39,
    name: "en-GB/product/427"
  }, {
    path: "/sunglasses/ted-baker-tb1394-tortoiseshell-l_p428",
    component: _1b085a39,
    name: "en-GB/product/428"
  }, {
    path: "/sunglasses/ted-baker-tb1415-blue-m_p429",
    component: _1b085a39,
    name: "en-GB/product/429"
  }, {
    path: "/sunglasses/ted-baker-tb1416-black-m_p430",
    component: _1b085a39,
    name: "en-GB/product/430"
  }, {
    path: "/sunglasses/ted-baker-tb1405-grey-blue-m_p431",
    component: _1b085a39,
    name: "en-GB/product/431"
  }, {
    path: "/sunglasses/ted-baker-tb1406-black-l_p432",
    component: _1b085a39,
    name: "en-GB/product/432"
  }, {
    path: "/sunglasses/ted-baker-tb1408-grey-l_p433",
    component: _1b085a39,
    name: "en-GB/product/433"
  }, {
    path: "/sunglasses/ted-baker-tb1409-black-l_p434",
    component: _1b085a39,
    name: "en-GB/product/434"
  }, {
    path: "/sunglasses/ted-baker-tb1409-tortoiseshell-l_p435",
    component: _1b085a39,
    name: "en-GB/product/435"
  }, {
    path: "/sunglasses/ted-baker-tb1166-grey-l_p436",
    component: _1b085a39,
    name: "en-GB/product/436"
  }, {
    path: "/sunglasses/ted-baker-tb1166-gold-l_p437",
    component: _1b085a39,
    name: "en-GB/product/437"
  }, {
    path: "/sunglasses/ted-baker-tb1271-grey-l_p438",
    component: _1b085a39,
    name: "en-GB/product/438"
  }, {
    path: "/sunglasses/unofficial-un-36-tortoiseshell-m_p439",
    component: _1b085a39,
    name: "en-GB/product/439"
  }, {
    path: "/sunglasses/unofficial-un-37-black-m_p440",
    component: _1b085a39,
    name: "en-GB/product/440"
  }, {
    path: "/sunglasses/unofficial-un-37-tortoiseshell-m_p441",
    component: _1b085a39,
    name: "en-GB/product/441"
  }, {
    path: "/sunglasses/unofficial-un-21-black-m_p442",
    component: _1b085a39,
    name: "en-GB/product/442"
  }, {
    path: "/sunglasses/unofficial-unbf29-navy-blue-s_p443",
    component: _1b085a39,
    name: "en-GB/product/443"
  }, {
    path: "/sunglasses/unofficial-un-47-brown-s_p444",
    component: _1b085a39,
    name: "en-GB/product/444"
  }, {
    path: "/sunglasses/unofficial-unbm00-black-s_p445",
    component: _1b085a39,
    name: "en-GB/product/445"
  }, {
    path: "/sunglasses/unofficial-unbm14-black-navy-blue-l_p446",
    component: _1b085a39,
    name: "en-GB/product/446"
  }, {
    path: "/sunglasses/unofficial-un-36-blue-m_p447",
    component: _1b085a39,
    name: "en-GB/product/447"
  }, {
    path: "/sunglasses/unofficial-un-47-black-s_p448",
    component: _1b085a39,
    name: "en-GB/product/448"
  }, {
    path: "/sunglasses/unofficial-unam02-black-s_p449",
    component: _1b085a39,
    name: "en-GB/product/449"
  }, {
    path: "/sunglasses",
    component: _25921ca4,
    name: "en-GB/category/117"
  }, {
    path: "/sunglasses/ray-ban",
    component: _25921ca4,
    name: "en-GB/category/118"
  }, {
    path: "/sunglasses/oakley",
    component: _25921ca4,
    name: "en-GB/category/119"
  }, {
    path: "/sunglasses/michael-kors",
    component: _25921ca4,
    name: "en-GB/category/124"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs",
    component: _25921ca4,
    name: "en-GB/category/123"
  }, {
    path: "/sunglasses/boss",
    component: _25921ca4,
    name: "en-GB/category/126"
  }, {
    path: "/sunglasses/ralph",
    component: _25921ca4,
    name: "en-GB/category/125"
  }, {
    path: "/sunglasses/ted-baker",
    component: _25921ca4,
    name: "en-GB/category/122"
  }, {
    path: "/sunglasses/carrera",
    component: _25921ca4,
    name: "en-GB/category/142"
  }, {
    path: "/sunglasses/superdry",
    component: _25921ca4,
    name: "en-GB/category/129"
  }, {
    path: "/sunglasses/joules",
    component: _25921ca4,
    name: "en-GB/category/128"
  }, {
    path: "/sunglasses/heritage",
    component: _25921ca4,
    name: "en-GB/category/127"
  }, {
    path: "/sunglasses/unofficial",
    component: _25921ca4,
    name: "en-GB/category/130"
  }, {
    path: "/sunglasses/designer-sunglasses",
    component: _25921ca4,
    name: "en-GB/category/134"
  }, {
    path: "/sunglasses/iconic-sunglasses",
    component: _25921ca4,
    name: "en-GB/category/136"
  }, {
    path: "/sunglasses/sports-sunglasses",
    component: _25921ca4,
    name: "en-GB/category/137"
  }, {
    path: "/sunglasses/high-tech",
    component: _25921ca4,
    name: "en-GB/category/135"
  }, {
    path: "/sunglasses/winter-sun",
    component: _25921ca4,
    name: "en-GB/category/138"
  }, {
    path: "/unidays-student-discount",
    component: _974a4b7e,
    name: "en-GB/document/106"
  }, {
    path: "/sauflon/monthly-disposables/clariti-elite_p450",
    component: _1b085a39,
    name: "en-GB/product/450"
  }, {
    path: "/focus-dailies-toric-discontinuing-faq",
    component: _974a4b7e,
    name: "en-GB/document/107"
  }, {
    path: "/cooper-vision/monthly-disposables/proclear_p451",
    component: _1b085a39,
    name: "en-GB/product/451"
  }, {
    path: "/cooper-vision/extended-wear-lenses/biofinity_p452",
    component: _1b085a39,
    name: "en-GB/product/452"
  }, {
    path: "/cooper-vision/toric-lenses/biofinity-toric_p453",
    component: _1b085a39,
    name: "en-GB/product/453"
  }, {
    path: "/cooper-vision/multifocal-lenses/biofinity-multifocal_p454",
    component: _1b085a39,
    name: "en-GB/product/454"
  }, {
    path: "/define",
    redirect: "/1-day-acuvue-define?d=define&utm_source=lenstore&utm_medium=package-insert&utm_campaign=define-trial"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-aqua-limited-stock_p213",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p213"
  }, {
    path: "/ciba-vision/toric-lenses/air-optix-for-astigmatism_p455",
    component: _1b085a39,
    name: "en-GB/product/455"
  }, {
    path: "/ciba-vision/multifocal-lenses/air-optix-aqua-multifocal_p456",
    component: _1b085a39,
    name: "en-GB/product/456"
  }, {
    path: "/ciba-vision/extended-wear-lenses/air-optix-night-day-aqua-limited-stock_p214",
    redirect: "/ciba-vision/extended-wear-lenses/air-optix-night-day-aqua_p214"
  }, {
    path: "/ciba-vision/multifocal-lenses/dailies-total-1-multifocal_p457",
    component: _1b085a39,
    name: "en-GB/product/457"
  }, {
    path: "/other-accessories/bioture-daily-eyelid-wipes_p255",
    redirect: "/other-accessories/biotrue-daily-eyelid-wipes_p255"
  }, {
    path: "/acuvue/daily-disposables/acuvue-oasys-1-day_p458",
    component: _1b085a39,
    name: "en-GB/product/458"
  }, {
    path: "/acuvue/daily-disposables/acuvue-oasys-1-day_p459",
    component: _1b085a39,
    name: "en-GB/product/459"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-plus-hydraglyde_p460",
    component: _1b085a39,
    name: "en-GB/product/460"
  }, {
    path: "/water-and-contact-lenses",
    redirect: "/eyecare/water-and-contact-lenses"
  }, {
    path: "/ciba-vision/toric-lenses/iwear-air-astigmatism_p461",
    redirect: "/ciba-vision/toric-lenses/air-optix-for-astigmatism_p48"
  }, {
    path: "/ciba-vision/multifocal-lenses/iwear-air-multifocal_p462",
    redirect: "/ciba-vision/multifocal-lenses/air-optix-aqua-multifocal_p90"
  }, {
    path: "/ciba-vision/monthly-disposables/iwear-air_p463",
    redirect: "/ciba-vision/monthly-disposables/air-optix-aqua_p77"
  }, {
    path: "/ciba-vision/daily-disposables/iwear-free_p464",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8"
  }, {
    path: "/ciba-vision/daily-disposables/iwear-extra_p465",
    redirect: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p5"
  }, {
    path: "/ciba-vision/multifocal-lenses/focus-dailies-progressives_p9",
    redirect: "/ciba-vision/multifocal-lenses/dailies-aquacomfort-plus-multifocal_p234"
  }, {
    path: "/specsavers-contact-lenses",
    component: _974a4b7e,
    name: "en-GB/document/111"
  }, {
    path: "/ocular-vitamins",
    redirect: "/eye-vitamins"
  }, {
    path: "/ciba-vision/daily-disposables/dailiesaqua-comfort-plus_p6",
    redirect: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p6"
  }, {
    path: "/bausch-lomb/toric-lenses/soflens-for-astigatism_p19",
    redirect: "/bausch-lomb/toric-lenses/soflens-for-astigmatism_p19"
  }, {
    path: "/cooper-vision/monthly-disposables/avaira-vitality_p466",
    component: _1b085a39,
    name: "en-GB/product/466"
  }, {
    path: "/cooper-vision/monthly-disposables/avaira_vitality_p466",
    redirect: "/cooper-vision/monthly-disposables/avaira-vitality_p466"
  }, {
    path: "/ciba-vision/daily-disposables/dailies-aqua-comfort-plus_p5",
    redirect: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p5"
  }, {
    path: "/ciba-vision/daily-disposables/dailies-aqua-comfort-plus_p6",
    redirect: "/ciba-vision/daily-disposables/dailies-aquacomfort-plus_p6"
  }, {
    path: "/ciba-vision/multifocal-lenses/dailies-aqua-comfort-plus-multifocal_p234",
    redirect: "/ciba-vision/multifocal-lenses/dailies-aquacomfort-plus-multifocal_p234"
  }, {
    path: "/ciba-vision/toric-lenses/dailies-aqua-comfort-plus-toric_p233",
    redirect: "/ciba-vision/toric-lenses/dailies-aquacomfort-plus-toric_p233"
  }, {
    path: "/bausch-lomb/multifocal-lenses/soflens-multifocal_p20",
    redirect: "/bausch-lomb/multifocal-lenses/soflens-multi-focal_p20"
  }, {
    path: "/bausch-lomb/multifocal-lenses/soflens-multifocal_p45",
    redirect: "/bausch-lomb/multifocal-lenses/soflens-multi-focal_p45"
  }, {
    path: "/cooper-vision/toric-lenses/proclear-compatibles-toric_p35",
    redirect: "/cooper-vision/toric-lenses/proclear-toric_p35"
  }, {
    path: "/bausch-lomb/toric-lenses/soflens-toric_p44",
    redirect: "/bausch-lomb/toric-lenses/soflens-for-astigmatism_p44"
  }, {
    path: "/bausch-lomb/toric-lenses/soflens-toric_p19",
    redirect: "/bausch-lomb/toric-lenses/soflens-for-astigmatism_p19"
  }, {
    path: "/ciba-vision/daily-disposables/focus-dailies_p7",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p7"
  }, {
    path: "/ciba-vision/daily-disposables/focus-dailies_p8",
    redirect: "/ciba-vision/daily-disposables/focus-dailies-all-day-comfort_p8"
  }, {
    path: "/cooper-vision/extended-wear-lenses/biofinity-energys_p467",
    component: _1b085a39,
    name: "en-GB/product/467"
  }, {
    path: "/cooper-vision/extended-wear-lenses/biofinity_energys_467",
    redirect: "/cooper-vision/extended-wear-lenses/biofinity-energys_p467"
  }, {
    path: "/cooper-vision/extended-wear-lenses/biofinity_energys_p467",
    redirect: "/cooper-vision/extended-wear-lenses/biofinity-energys_p467"
  }, {
    path: "/acuvue/daily-disposables/acuvue-oasys-1-day-for-astigmatism_p468",
    component: _1b085a39,
    name: "en-GB/product/468"
  }, {
    path: "/cooper-vision/monthly-disposables/avaira-vitality-6_p469",
    component: _1b085a39,
    name: "en-GB/product/469"
  }, {
    path: "/cooper-vision/daily-disposables/my-day-toric_p470",
    component: _1b085a39,
    name: "en-GB/product/470"
  }, {
    path: "/myday-toric_p470",
    redirect: "/cooper-vision/daily-disposables/my-day-toric_p470"
  }, {
    path: "/my-day-toric_p470",
    redirect: "/cooper-vision/daily-disposables/my-day-toric_p470"
  }, {
    path: "/air-optix-3-lenses",
    component: _1b085a39,
    name: "en-GB/product/21"
  }, {
    path: "/cooper-vision/toric-lenses/biofinity-xr-toric_p471",
    component: _1b085a39,
    name: "en-GB/product/471"
  }, {
    path: "/multi-purpose-solutions/hy-care-one-month-pack_p472",
    component: _1b085a39,
    name: "en-GB/product/472"
  }, {
    path: "/multi-purpose-solutions/hy-care-three-month-pack_p473",
    component: _1b085a39,
    name: "en-GB/product/473"
  }, {
    path: "/acuvue-brand-presbyopia",
    component: _974a4b7e,
    name: "en-GB/document/113"
  }, {
    path: "/acuvue-brand-daily-disposables",
    component: _974a4b7e,
    name: "en-GB/document/114"
  }, {
    path: "/uk-efs-tax-policy",
    component: _974a4b7e,
    name: "en-GB/document/115"
  }, {
    path: "/acuvue/monthly-disposables/acuvue-vita_p474",
    component: _1b085a39,
    name: "en-GB/product/474"
  }, {
    path: "/acuvue/daily-disposables/acuvue-vita_p474",
    redirect: "/acuvue/monthly-disposables/acuvue-vita_p474"
  }, {
    path: "/lenstore-net-lenstore-de",
    component: _974a4b7e,
    name: "en-GB/document/116"
  }, {
    path: "/which-acuvue-lens-is-for-you",
    component: _974a4b7e,
    name: "en-GB/document/117"
  }, {
    path: "/bausch-lomb/monthly-disposables/ultra-6_p475",
    component: _1b085a39,
    name: "en-GB/product/475"
  }, {
    path: "/bausch-lomb/monthly-disposables/ultra-3_p476",
    component: _1b085a39,
    name: "en-GB/product/476"
  }, {
    path: "/bausch-lomb/monthly-disposables/ultra-3_p477",
    redirect: "/bausch-lomb/monthly-disposables/ultra-3_p476"
  }, {
    path: "/bausch-lomb/multifocal-lenses/ultra-presbyopia-3_p477",
    component: _1b085a39,
    name: "en-GB/product/477"
  }, {
    path: "/bausch-lomb/multifocal-lenses/ultra-presbyopia-6_p478",
    component: _1b085a39,
    name: "en-GB/product/478"
  }, {
    path: "/acuvue-lens-selector",
    redirect: "/which-acuvue-lens-is-for-you"
  }, {
    path: "/ecommerce-front-end-developer",
    component: _974a4b7e,
    name: "en-GB/document/118"
  }, {
    path: "/ecommerce-full-stack-developer",
    component: _974a4b7e,
    name: "en-GB/document/119"
  }, {
    path: "/ecommerce-senior-php-developer",
    component: _974a4b7e,
    name: "en-GB/document/120"
  }, {
    path: "/content-and-communications-manager",
    component: _974a4b7e,
    name: "en-GB/document/121"
  }, {
    path: "/digital-marketing-manager",
    component: _974a4b7e,
    name: "en-GB/document/122"
  }, {
    path: "/privacy",
    component: _974a4b7e,
    name: "en-GB/document/9"
  }, {
    path: "/cookies",
    component: _974a4b7e,
    name: "en-GB/document/124"
  }, {
    path: "/cooper-vision/toric-lenses/avaira-vitality-toric_p479",
    component: _1b085a39,
    name: "en-GB/product/479"
  }, {
    path: "/bausch-lomb/disposable-lenses/biotrue-oneday-for-astigmatism_p480",
    component: _1b085a39,
    name: "en-GB/product/480"
  }, {
    path: "/cooper-vision/extended-wear-lenses/biofinity-energys_p481",
    component: _1b085a39,
    name: "en-GB/product/481"
  }, {
    path: "/happy",
    redirect: "/acuvue-brand-home?utm_source=lenstore&utm_medium=package-insert&utm_campaign=1DAM"
  }, {
    path: "/happyeyes",
    redirect: "/acuvue-brand-home?utm_source=lenstore&utm_medium=package-insert&utm_campaign=1DAMA"
  }, {
    path: "/upclose",
    redirect: "/acuvue-brand-home?utm_source=lenstore&utm_medium=package-insert&utm_campaign=1DAMM&utm_content=1"
  }, {
    path: "/focus",
    redirect: "/acuvue-brand-home?utm_source=lenstore&utm_medium=package-insert&utm_campaign=1DAMM&utm_content=2"
  }, {
    path: "/focus-progressives-6-lenses",
    component: _1b085a39,
    name: "en-GB/product/23"
  }, {
    path: "/biomedics-1-day-30-lenses",
    component: _1b085a39,
    name: "en-GB/product/55"
  }, {
    path: "/biomedics-1-day-90-lenses",
    component: _1b085a39,
    name: "en-GB/product/56"
  }, {
    path: "/company",
    redirect: "/about-us"
  }, {
    path: "/login",
    component: _00ef9433,
    name: "en-GB/login"
  }, {
    path: "/pay-less-for-lenses",
    component: _974a4b7e,
    name: "en-GB/document/126"
  }, {
    path: "/mentionme",
    redirect: "/pay-less-for-lenses"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-plus-hydraglyde-for-astigmatism_p482",
    component: _1b085a39,
    name: "en-GB/product/482"
  }, {
    path: "/b330/lenti-multifocali-giornaliere",
    component: _387cb151,
    name: "en-GB/brand/330"
  }, {
    path: "/how-to-find-your-prescription",
    component: _974a4b7e,
    name: "en-GB/document/127"
  }, {
    path: "/unsubscribe",
    component: _3747f11c
  }, {
    path: "/price-comparison-lentiamo",
    component: _387cb151,
    name: "en-GB/brand/332"
  }, {
    path: "/youth-discount",
    component: _974a4b7e,
    name: "en-GB/document/131"
  }, {
    path: "/b333/the-best-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/333"
  }, {
    path: "/quanto-costano-le-lenti",
    component: _387cb151,
    name: "en-GB/brand/337"
  }, {
    path: "/price-comparison-adrialenti",
    component: _387cb151,
    name: "en-GB/brand/340"
  }, {
    path: "/cooper-vision/daily-disposables/live-1-day_p483",
    component: _1b085a39,
    name: "en-GB/product/483"
  }, {
    path: "/payment-methods",
    component: _974a4b7e,
    name: "en-GB/document/132"
  }, {
    path: "/free-delivery-over-69-euros",
    component: _387cb151,
    name: "en-GB/brand/343"
  }, {
    path: "/daily-coloured-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/344"
  }, {
    path: "/ciba-vision/multifocal-lenses/air-optix-plus-hydraglyde-multifocal_p484",
    component: _1b085a39,
    name: "en-GB/product/484"
  }, {
    path: "/coloured-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/345"
  }, {
    path: "/bausch-lomb-ultra",
    component: _387cb151,
    name: "en-GB/brand/346"
  }, {
    path: "/1-day-acuvue-moist",
    component: _387cb151,
    name: "en-GB/brand/347"
  }, {
    path: "/free-eye-care",
    redirect: "/request-your-appointment"
  }, {
    path: "/best-price-guarantee",
    component: _974a4b7e,
    name: "en-GB/document/133"
  }, {
    path: "/how-to-insert-/-remove-contact-lenses",
    component: _974a4b7e,
    name: "en-GB/document/134"
  }, {
    path: "/how-to-get-cheap-lenses-",
    component: _974a4b7e,
    name: "en-GB/document/135"
  }, {
    path: "/at-what-age-can-we-wear-lenses-",
    component: _974a4b7e,
    name: "en-GB/document/136"
  }, {
    path: "/cheap-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/348"
  }, {
    path: "/contact-lenses-and-winter",
    component: _974a4b7e,
    name: "en-GB/document/137"
  }, {
    path: "/astigmatism",
    component: _974a4b7e,
    name: "en-GB/document/138"
  }, {
    path: "/how-to-clean-your-contact-lenses",
    component: _974a4b7e,
    name: "en-GB/document/139"
  }, {
    path: "/privacy-notice",
    redirect: "/privacy"
  }, {
    path: "/research-terms-conditions",
    component: _974a4b7e,
    name: "en-GB/document/141"
  }, {
    path: "/best-contact-lens-brands",
    component: _387cb151,
    name: "en-GB/brand/349"
  }, {
    path: "/datenschutzerklaerung",
    redirect: "/datenschutzerklarung"
  }, {
    path: "/recensioni-trustpilot",
    component: _387cb151,
    name: "en-GB/brand/350"
  }, {
    path: "/comment-vous-faire-rembourser-vos-lentilles-achetees-en-ligne-",
    component: _974a4b7e,
    name: "en-GB/document/143"
  }, {
    path: "/corona-advises",
    component: _974a4b7e,
    name: "en-GB/document/144"
  }, {
    path: "/ordering-lenses-online",
    component: _974a4b7e,
    name: "en-GB/document/145"
  }, {
    path: "/6-truth",
    component: _974a4b7e,
    name: "en-GB/document/146"
  }, {
    path: "/advises-eyecare-home",
    component: _974a4b7e,
    name: "en-GB/document/147"
  }, {
    path: "/covid-19-service-updates",
    component: _974a4b7e,
    name: "en-GB/document/148"
  }, {
    path: "/daysoft-alternative",
    component: _974a4b7e,
    name: "en-GB/document/149"
  }, {
    path: "/healthy-habits",
    component: _974a4b7e,
    name: "en-GB/document/150"
  }, {
    path: "/biomedics-tageslinsen",
    component: _387cb151,
    name: "en-GB/brand/351"
  }, {
    path: "/isic-discounts",
    component: _974a4b7e,
    name: "en-GB/document/151"
  }, {
    path: "/customer-feedback-terms",
    component: _974a4b7e,
    name: "en-GB/document/152"
  }, {
    path: "/customer-feedback-privacy-notice",
    redirect: "/privacy"
  }, {
    path: "/kundenumfrage_datenschutzerklarung",
    redirect: "/datenschutzerklarung"
  }, {
    path: "/acuvue-tageslinsen",
    component: _387cb151,
    name: "en-GB/brand/352"
  }, {
    path: "/acuvue-wochenlinsen",
    component: _387cb151,
    name: "en-GB/brand/353"
  }, {
    path: "/acuvue-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/354"
  }, {
    path: "/acuvue-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/355"
  }, {
    path: "/acuvue-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/356"
  }, {
    path: "/air-optix-aqua-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/357"
  }, {
    path: "/air-optix-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/358"
  }, {
    path: "/air-optix-aqua-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/359"
  }, {
    path: "/avaira-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/360"
  }, {
    path: "/avaira-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/361"
  }, {
    path: "/biofinity-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/362"
  }, {
    path: "/biofinity-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/363"
  }, {
    path: "/biofinity-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/364"
  }, {
    path: "/biomedics-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/365"
  }, {
    path: "/biomedics-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/366"
  }, {
    path: "/biotrue-tageslinsen",
    component: _387cb151,
    name: "en-GB/brand/367"
  }, {
    path: "/biotrue-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/368"
  }, {
    path: "/biotrue-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/369"
  }, {
    path: "/biof",
    redirect: "/biotrue-multifokale-kontaktlinsen"
  }, {
    path: "/clariti-tageslinsen",
    component: _387cb151,
    name: "en-GB/brand/370"
  }, {
    path: "/clariti-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/371"
  }, {
    path: "/clariti-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/372"
  }, {
    path: "/clariti-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/373"
  }, {
    path: "/dailies-tageslinsen",
    component: _387cb151,
    name: "en-GB/brand/374"
  }, {
    path: "/dailies-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/375"
  }, {
    path: "/dailies-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/376"
  }, {
    path: "/frequency-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/377"
  }, {
    path: "/frequency-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/378"
  }, {
    path: "/myday-tageslinsen",
    component: _387cb151,
    name: "en-GB/brand/379"
  }, {
    path: "/myday-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/380"
  }, {
    path: "/proclear-tageslinsen",
    component: _387cb151,
    name: "en-GB/brand/381"
  }, {
    path: "/proclear-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/382"
  }, {
    path: "/proclear-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/383"
  }, {
    path: "/proclear-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/384"
  }, {
    path: "/purevision-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/385"
  }, {
    path: "/purevision-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/386"
  }, {
    path: "/purevision-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/387"
  }, {
    path: "/softlens-tageslinsen",
    component: _387cb151,
    name: "en-GB/brand/388"
  }, {
    path: "/softlens-monatslinsen",
    component: _387cb151,
    name: "en-GB/brand/389"
  }, {
    path: "/softlens-torische-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/390"
  }, {
    path: "/softlens-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "en-GB/brand/391"
  }, {
    path: "/white-label-afflelou",
    component: _387cb151,
    name: "en-GB/brand/392"
  }, {
    path: "/white-label-krys",
    component: _387cb151,
    name: "en-GB/brand/393"
  }, {
    path: "/customer-feedback-tandc",
    component: _974a4b7e,
    name: "en-GB/document/154"
  }, {
    path: "/customer-feedback-privacy-notice-0720",
    redirect: "/privacy"
  }, {
    path: "/multi-purpose-solutions/revitalens_p485",
    component: _1b085a39,
    name: "en-GB/product/485"
  }, {
    path: "/multi-purpose-solutions/revitalens_twin_pack_p486",
    component: _1b085a39,
    name: "en-GB/product/486"
  }, {
    path: "/eye-drops/blink-intensive-triple-action_p487",
    component: _1b085a39,
    name: "en-GB/product/487"
  }, {
    path: "/datenschutzerkla-rung-umfrage",
    component: _974a4b7e,
    name: "en-GB/document/156"
  }, {
    path: "/datenschutzerkla-rung_umfrage",
    redirect: "/datenschutzerklarung"
  }, {
    path: "/kundenumfrage-lenstore-teilnahmebedingungen",
    component: _974a4b7e,
    name: "en-GB/document/157"
  }, {
    path: "/politique-de-confidentialita",
    redirect: "/gestion-des-donna-es-personnelles"
  }, {
    path: "/documento-de-privacidad-",
    redirect: "/politica-de-privacidad"
  }, {
    path: "/informativa-sulla-privacy-",
    redirect: "/informativa-sulla-privacy"
  }, {
    path: "/trova-prezzi-lenti-a-contatto",
    component: _387cb151,
    name: "en-GB/brand/395"
  }, {
    path: "/menicon/daily-disposables/miru-1day-flat_p488",
    component: _1b085a39,
    name: "en-GB/product/488"
  }, {
    path: "/menicon/monthly-disposables/miru-1month_p489",
    component: _1b085a39,
    name: "en-GB/product/489"
  }, {
    path: "/menicon/monthly-disposables/miru-1month_toric_p490",
    component: _1b085a39,
    name: "en-GB/product/490"
  }, {
    path: "/menicon/monthly-disposables/miru-1month_multifocal_p491",
    component: _1b085a39,
    name: "en-GB/product/491"
  }, {
    path: "/miru-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/396"
  }, {
    path: "/menicon-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/394"
  }, {
    path: "/menicon-2",
    redirect: "/menicon-contact-lenses"
  }, {
    path: "/menicon",
    redirect: "/menicon-contact-lenses"
  }, {
    path: "/specsavers-easyvision-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/401"
  }, {
    path: "/acuvue-trueye-stop-switch-go",
    redirect: "/eyecare/trueye-stop-switch-go"
  }, {
    path: "/eye-care",
    component: _387cb151,
    name: "en-GB/brand/402"
  }, {
    path: "/ciba-vision/daily-disposables/precision-1_p492",
    component: _1b085a39,
    name: "en-GB/product/492"
  }, {
    path: "/ciba-vision/daily-disposables/precision-1_p493",
    component: _1b085a39,
    name: "en-GB/product/493"
  }, {
    path: "/ciba-vision/toric-lenses/precision-1-for-astigmatism_p494",
    component: _1b085a39,
    name: "en-GB/product/494"
  }, {
    path: "/ciba-vision/toric-lenses/precision-1-for-astigmatism_p495",
    component: _1b085a39,
    name: "en-GB/product/495"
  }, {
    path: "/coloured-contact-lenses/angelic-white-clearcolor-1-day-phantom_p496",
    component: _1b085a39,
    name: "en-GB/product/496"
  }, {
    path: "/coloured-contact-lenses/black-out-clearcolor-1-day-phantom_p497",
    component: _1b085a39,
    name: "en-GB/product/497"
  }, {
    path: "/coloured-contact-lenses/red-vampire-clearcolor-1-day-phantom_p498",
    component: _1b085a39,
    name: "en-GB/product/498"
  }, {
    path: "/coloured-contact-lenses/white-out-clearcolor-1-day-phantom_p499",
    component: _1b085a39,
    name: "en-GB/product/499"
  }, {
    path: "/coloured-contact-lenses/zombie-yellow-clearcolor-1-day-phantom_p500",
    component: _1b085a39,
    name: "en-GB/product/500"
  }, {
    path: "/coloured-contact-lenses/blue-walker-clearcolor-1-day-phantom_p501",
    component: _1b085a39,
    name: "en-GB/product/501"
  }, {
    path: "/acuvue/extended-wear-lenses/acuvue-oasys_p502",
    component: _1b085a39,
    name: "en-GB/product/502"
  }, {
    path: "/acuvue/monthly-disposables/acuvue-vita_p503",
    component: _1b085a39,
    name: "en-GB/product/503"
  }, {
    path: "/coloured-contact-lenses/angelic-red-clearcolor-phantom_p504",
    component: _1b085a39,
    name: "en-GB/product/504"
  }, {
    path: "/coloured-contact-lenses/banshee-clearcolor-phantom_p505",
    component: _1b085a39,
    name: "en-GB/product/505"
  }, {
    path: "/coloured-contact-lenses/black-wolf-clearcolor-phantom_p506",
    component: _1b085a39,
    name: "en-GB/product/506"
  }, {
    path: "/coloured-contact-lenses/lestat-clearcolor-phantom_p507",
    component: _1b085a39,
    name: "en-GB/product/507"
  }, {
    path: "/coloured-contact-lenses/manson-clearcolor-phantom_p508",
    component: _1b085a39,
    name: "en-GB/product/508"
  }, {
    path: "/coloured-contact-lenses/red-cat-clearcolor-phantom_p509",
    component: _1b085a39,
    name: "en-GB/product/509"
  }, {
    path: "/coloured-contact-lenses/red-vampire-clearcolor-phantom_p510",
    component: _1b085a39,
    name: "en-GB/product/510"
  }, {
    path: "/coloured-contact-lenses/smiley-clearcolor-phantom_p511",
    component: _1b085a39,
    name: "en-GB/product/511"
  }, {
    path: "/coloured-contact-lenses/white-out-clearcolor-phantom_p512",
    component: _1b085a39,
    name: "en-GB/product/512"
  }, {
    path: "/revitalens",
    component: _387cb151,
    name: "en-GB/brand/403"
  }, {
    path: "/precision-1-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/404"
  }, {
    path: "/multi-purpose-solutions/all-in-one-light-travel-pack-100ml_p513",
    component: _1b085a39,
    name: "en-GB/product/513"
  }, {
    path: "/multi-purpose-solutions/all-in-one-light-250ml_p514",
    component: _1b085a39,
    name: "en-GB/product/514"
  }, {
    path: "/multi-purpose-solutions/all-in-one-light-360ml_p515",
    component: _1b085a39,
    name: "en-GB/product/515"
  }, {
    path: "/multi-purpose-solutions/all-in-one-light-3x250ml_p516",
    component: _1b085a39,
    name: "en-GB/product/516"
  }, {
    path: "/multi-purpose-solutions/hy-care-travel-pack-100ml_p517",
    component: _1b085a39,
    name: "en-GB/product/517"
  }, {
    path: "/hydrogen-peroxide-solutions/refine-one-step-travel-pack-100ml_p518",
    component: _1b085a39,
    name: "en-GB/product/518"
  }, {
    path: "/hydrogen-peroxide-solutions/refine-one-step-250ml_p519",
    component: _1b085a39,
    name: "en-GB/product/519"
  }, {
    path: "/hydrogen-peroxide-solutions/refine-one-step-360ml_p520",
    component: _1b085a39,
    name: "en-GB/product/520"
  }, {
    path: "/hydrogen-peroxide-solutions/refine-one-step-3x250ml_p521",
    component: _1b085a39,
    name: "en-GB/product/521"
  }, {
    path: "/saline-solutions/saline-solution-travel-pack-100ml_p522",
    component: _1b085a39,
    name: "en-GB/product/522"
  }, {
    path: "/saline-solutions/saline-solution-360ml_p523",
    component: _1b085a39,
    name: "en-GB/product/523"
  }, {
    path: "/hard-rgp-solutions/delta-cleaner-20ml_p524",
    component: _1b085a39,
    name: "en-GB/product/524"
  }, {
    path: "/hard-rgp-solutions/delta-plus-travel-pack-100ml_p525",
    component: _1b085a39,
    name: "en-GB/product/525"
  }, {
    path: "/hard-rgp-solutions/delta-multi-pack_p526",
    component: _1b085a39,
    name: "en-GB/product/526"
  }, {
    path: "/eye-drops/comfort-drops-20ml_p527",
    component: _1b085a39,
    name: "en-GB/product/527"
  }, {
    path: "/all-in-one-light-travel-pack-100ml_p513",
    redirect: "/multi-purpose-solutions/all-in-one-light-travel-pack-100ml_p513"
  }, {
    path: "/multi-purpose-solutions-hy-care-travel-pack-100ml_p517",
    redirect: "/multi-purpose-solutions/hy-care-travel-pack-100ml_p517"
  }, {
    path: "/refine-one-step-travel-pack-100ml_p518",
    redirect: "/hydrogen-peroxide-solutions/refine-one-step-travel-pack-100ml_p518"
  }, {
    path: "/preservative-free-saline-travel-pack-100ml_p522",
    redirect: "/saline-solutions/saline-solution-travel-pack-100ml_p522"
  }, {
    path: "/delta-plus-travel-pack-100ml_p525",
    redirect: "/hard-rgp-solutions/delta-plus-travel-pack-100ml_p525"
  }, {
    path: "/comf-drops-20ml_p527",
    redirect: "/eye-drops/comfort-drops-20ml_p527"
  }, {
    path: "/all-in-one-light-250ml_p514",
    redirect: "/multi-purpose-solutions/all-in-one-light-250ml_p514"
  }, {
    path: "/all-in-one-light-360ml_p515",
    redirect: "/multi-purpose-solutions/all-in-one-light-360ml_p515"
  }, {
    path: "/all-in-one-light-2x250ml_p516",
    redirect: "/multi-purpose-solutions/all-in-one-light-3x250ml_p516"
  }, {
    path: "/refine-one-step-250ml_p519",
    redirect: "/hydrogen-peroxide-solutions/refine-one-step-250ml_p519"
  }, {
    path: "/refine-one-step-360ml_p520",
    redirect: "/hydrogen-peroxide-solutions/refine-one-step-360ml_p520"
  }, {
    path: "/refine-one-step-3x250ml_p521",
    redirect: "/hydrogen-peroxide-solutions/refine-one-step-3x250ml_p521"
  }, {
    path: "/preservative-free-saline-360ml_p523",
    redirect: "/saline-solutions/saline-solution-360ml_p523"
  }, {
    path: "/delta-cleaner-20ml_p524",
    redirect: "/hard-rgp-solutions/delta-cleaner-20ml_p524"
  }, {
    path: "/delta-multi-pack_p526",
    redirect: "/hard-rgp-solutions/delta-multi-pack_p526"
  }, {
    path: "/saline-solutions/preservative-free-saline-360ml_p523",
    redirect: "/saline-solutions/saline-solution-360ml_p523"
  }, {
    path: "/saline-solutions/preservative-free-saline-travel-pack-100ml_p522",
    redirect: "/saline-solutions/saline-solution-travel-pack-100ml_p522"
  }, {
    path: "/halloween-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/405"
  }, {
    path: "/clearlab",
    component: _387cb151,
    name: "en-GB/brand/406"
  }, {
    path: "/clearcolor-phantom",
    component: _387cb151,
    name: "en-GB/brand/407"
  }, {
    path: "/clearcolor-phantom-1-day",
    component: _387cb151,
    name: "en-GB/brand/408"
  }, {
    path: "/multi-purpose-solutions/all-in-one-light-2x250ml_p516",
    redirect: "/multi-purpose-solutions/all-in-one-light-3x250ml_p516"
  }, {
    path: "/coloured-contact-lenses/angelic-white-clearcolor-1day-phantom_p496",
    redirect: "/coloured-contact-lenses/angelic-white-clearcolor-1-day-phantom_p496"
  }, {
    path: "/coloured-contact-lenses/black-out-clearcolor-1day-phantom_p497",
    redirect: "/coloured-contact-lenses/black-out-clearcolor-1-day-phantom_p497"
  }, {
    path: "/coloured-contact-lenses/red-vampire-clearcolor-1day-phantom_p498",
    redirect: "/coloured-contact-lenses/red-vampire-clearcolor-1-day-phantom_p498"
  }, {
    path: "/coloured-contact-lenses/white-out-clearcolor-1day-phantom_p499",
    redirect: "/coloured-contact-lenses/white-out-clearcolor-1-day-phantom_p499"
  }, {
    path: "/coloured-contact-lenses/zombie-yellow-clearcolor-1day-phantom_p500",
    redirect: "/coloured-contact-lenses/zombie-yellow-clearcolor-1-day-phantom_p500"
  }, {
    path: "/coloured-contact-lenses/blue-walker-clearcolor-1day-phantom_p501",
    redirect: "/coloured-contact-lenses/blue-walker-clearcolor-1-day-phantom_p501"
  }, {
    path: "/fashion-contact-lenses",
    component: _387cb151,
    name: "en-GB/brand/409"
  }, {
    path: "/halloween-coloured-contact-lenses",
    redirect: "/halloween-contact-lenses"
  }, {
    path: "/fashion-coloured-contact-lenses",
    redirect: "/fashion-contact-lenses"
  }, {
    path: "/coopervision-solutions",
    component: _387cb151,
    name: "en-GB/brand/410"
  }, {
    path: "/featured-solutions",
    component: _25921ca4,
    name: "en-GB/category/143"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-plus-hydraglyde_p528",
    component: _1b085a39,
    name: "en-GB/product/528"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-plus-hydraglyde-for-astigmatism_p529",
    component: _1b085a39,
    name: "en-GB/product/529"
  }, {
    path: "/ciba-vision/multifocal-lenses/air-optix-plus-hydraglyde-multifocal_p530",
    component: _1b085a39,
    name: "en-GB/product/530"
  }, {
    path: "/coloured-contact-lenses/light-blue-clearcolor-1-day_p531",
    component: _1b085a39,
    name: "en-GB/product/531"
  }, {
    path: "/coloured-contact-lenses/grey-clearcolor-1-day_p532",
    component: _1b085a39,
    name: "en-GB/product/532"
  }, {
    path: "/coloured-contact-lenses/hazel-clearcolor-1-day_p533",
    component: _1b085a39,
    name: "en-GB/product/533"
  }, {
    path: "/coloured-contact-lenses/blue-clearcolor-1-day_p534",
    component: _1b085a39,
    name: "en-GB/product/534"
  }, {
    path: "/coloured-contact-lenses/green-clearcolor-1-day_p535",
    component: _1b085a39,
    name: "en-GB/product/535"
  }, {
    path: "/coloured-contact-lenses/light-bluecoloured-contact-lenses/light-blue-clearcolo-clearcolor-1-day_p531",
    redirect: "/coloured-contact-lenses/light-blue-clearcolor-1-day_p531"
  }, {
    path: "/coloured-contact-lenses/light-blue-clearcolor-1-tone_p536",
    component: _1b085a39,
    name: "en-GB/product/536"
  }, {
    path: "/coloured-contact-lenses/brown-clearcolor-1-tone_p537",
    component: _1b085a39,
    name: "en-GB/product/537"
  }, {
    path: "/coloured-contact-lenses/green-clearcolor-1-tone_p538",
    component: _1b085a39,
    name: "en-GB/product/538"
  }, {
    path: "/coloured-contact-lenses/violet-clearcolor-1-tone_p539",
    component: _1b085a39,
    name: "en-GB/product/539"
  }, {
    path: "/coloured-contact-lenses/aqua-blue-clearcolor-1-tone_p540",
    component: _1b085a39,
    name: "en-GB/product/540"
  }, {
    path: "/coloured-contact-lenses/grey-clearcolor-1-tone_p541",
    component: _1b085a39,
    name: "en-GB/product/541"
  }, {
    path: "/menicon/daily-disposables/miru-1day-upside_p542",
    component: _1b085a39,
    name: "en-GB/product/542"
  }, {
    path: "/acuvue/multifocal-lenses/acuvue-oasys-multifocal_p543",
    component: _1b085a39,
    name: "en-GB/product/543"
  }, {
    path: "/bausch-lomb/toric-lenses/ultra-astigmatism-3_p544",
    component: _1b085a39,
    name: "en-GB/product/544"
  }, {
    path: "/bausch-lomb/toric-lenses/ultra-astigmatism-6_p545",
    component: _1b085a39,
    name: "en-GB/product/545"
  }, {
    path: "/bausch-lomb/monthly-disposables/ultra-multifocal-for-astigmatism-6_p546",
    component: _1b085a39,
    name: "en-GB/product/546"
  }, {
    path: "/alcon/monthly-disposables/total30_p547",
    component: _1b085a39,
    name: "en-GB/product/547"
  }, {
    path: "/alcon/monthly-disposables/total30-6_p548",
    component: _1b085a39,
    name: "en-GB/product/548"
  }, {
    path: "/ciba-vision/monthly-disposables/total30-6_p548",
    redirect: "/alcon/monthly-disposables/total30-6_p548"
  }, {
    path: "/ciba-vision/monthly-disposables/total30_p547",
    redirect: "/alcon/monthly-disposables/total30_p547"
  }, {
    path: "/reset-password",
    component: _3d98be02,
    name: "en-GB/reset-password"
  }, {
    path: "/chnage-pass",
    redirect: "/reset-password"
  }, {
    path: "/change-pass",
    redirect: "/reset-password"
  }, {
    path: "/menicon/multifocal-lenses/miru-1day-flat-multifocal_p549",
    component: _1b085a39,
    name: "en-GB/product/549"
  }, {
    path: "/menicon/toric-lenses/miru-1day-flat-toric_p550",
    component: _1b085a39,
    name: "en-GB/product/550"
  }, {
    path: "/order-confirmation",
    component: _4f90837a,
    name: "en-GB/order-confirmation"
  }, {
    path: "/basket",
    component: _99c1be28,
    name: "en-GB/basket"
  }, {
    path: "/order-history",
    redirect: "/account/order-history"
  }, {
    path: "/account",
    component: _b0dd6f12,
    name: "en-GB/account"
  }, {
    path: "/account/address-book",
    component: _89a7e070,
    name: "en-GB/address-book"
  }, {
    path: "/account/communication-preferences",
    component: _08e7896a,
    name: "en-GB/communication-preferences"
  }, {
    path: "/menicon/multifocal-lenses/miru-1day-upside-multifocal_p551",
    component: _1b085a39,
    name: "en-GB/product/551"
  }, {
    path: "/bausch-lomb/daily-disposables/ultra-one-day_p552",
    component: _1b085a39,
    name: "en-GB/product/552"
  }, {
    path: "/iwear-extra-30-lenses",
    component: _1b085a39,
    name: "en-GB/product/465"
  }, {
    path: "/eye-drops/systane-complete-preservative-free-eye-drops_p553",
    component: _1b085a39,
    name: "en-GB/product/553"
  }, {
    path: "/eye-drops/systane-hydration-preservative-free-eye-drops_p554",
    component: _1b085a39,
    name: "en-GB/product/554"
  }, {
    path: "/eye-drops/systane-ultra-preservative-free-eye-drops_p555",
    component: _1b085a39,
    name: "en-GB/product/555"
  }, {
    path: "/eye-drops/systane-complete-preservative-free-eye-drops-10ml_p553",
    redirect: "/eye-drops/systane-complete-preservative-free-eye-drops_p553"
  }, {
    path: "/menicon/daily-disposables/miru-1day-upside_p543",
    redirect: "/acuvue/multifocal-lenses/acuvue-oasys-multifocal_p543"
  }, {
    path: "/account/auto-reorders",
    component: _0b6e7d80,
    name: "en-GB/auto-reorders"
  }, {
    path: "/cooper-vision/multifocal-lenses/myday-multifocal_p556",
    component: _1b085a39,
    name: "en-GB/product/556"
  }, {
    path: "/register",
    component: _b33f986e,
    name: "en-GB/register"
  }, {
    path: "/payment",
    component: _18205bd0,
    name: "en-GB/payment"
  }, {
    path: "/account/change-password",
    component: _ccd8623c,
    name: "en-GB/change-password"
  }, {
    path: "/delivery",
    component: _34d0109a,
    name: "en-GB/delivery"
  }, {
    path: "/forgot-password",
    component: _c4961b62,
    name: "en-GB/forgot-password"
  }, {
    path: "/account/free-eye-test",
    component: _1fe318b9,
    name: "en-GB/free-eye-test"
  }, {
    path: "/ciba-vision/toric-lenses/dailies-total-1-for-astigmatism_p557",
    component: _1b085a39,
    name: "en-GB/product/557"
  }, {
    path: "/ciba-vision/toric-lenses/dailies-total-1-for-astigmatism_p558",
    component: _1b085a39,
    name: "en-GB/product/558"
  }, {
    path: "/instructions-for-use",
    component: _974a4b7e,
    name: "en-GB/document/159"
  }, {
    path: "/manage-payments",
    component: _6171e290,
    name: "en-GB/payment-management"
  }, {
    path: "/sameday-/-express-delivery",
    redirect: "/delivery-postage"
  }, {
    path: "/request-your-free-eye-test",
    redirect: "/request-your-appointment"
  }, {
    path: "/sameday-/-express-delivery",
    component: _974a4b7e,
    name: "en-GB/document/73"
  }, {
    path: "/ciba-vision/toric-lenses/dailies-total-1-for-astigmatism_557",
    redirect: "/ciba-vision/toric-lenses/dailies-total-1-for-astigmatism_p557"
  }, {
    path: "/ciba-vision/toric-lenses/dailies-total-1-for-astigmatism_558",
    redirect: "/ciba-vision/toric-lenses/dailies-total-1-for-astigmatism_p558"
  }, {
    path: "/acuvue/daily-disposables/acuvue-oasys-max-1-day_p559",
    component: _1b085a39,
    name: "en-GB/product/559"
  }, {
    path: "/acuvue/multifocal-lenses/acuvue-oasys-max-1-day-multifocal_p560",
    component: _1b085a39,
    name: "en-GB/product/560"
  }, {
    path: "/key-worker-discount",
    component: _974a4b7e,
    name: "en-GB/document/160"
  }, {
    path: "/acuvue-oasys-max",
    component: _387cb151,
    name: "en-GB/brand/411"
  }, {
    path: "/acuvue-brand",
    component: _3747f11c
  }, {
    path: "/acuvue-brand-home",
    redirect: "/acuvue-brand"
  }, {
    path: "/acuvue-brand-home-page",
    component: _974a4b7e,
    name: "en-GB/document/158"
  }, {
    path: "/manage-payments/auto-reorder/:id?",
    component: _2030f21f,
    name: "en-GB/payment-management-child"
  }, {
    path: "/acuvue/two-weekly-disposables/acuvue-oasys-with-transitions_p561",
    component: _1b085a39,
    name: "en-GB/product/561"
  }, {
    path: "/account/auto-reorders/orders/:id?",
    component: _74a9990f,
    name: "en-GB/auto-reorders-child"
  }, {
    path: "/buy-cheap-lenses",
    redirect: "/cheap-contact-lenses"
  }, {
    path: "/account/autoreorder",
    redirect: "/account/auto-reorders"
  }, {
    path: "/data-subject-access-request",
    component: _974a4b7e,
    name: "en-GB/document/161"
  }, {
    path: "/bausch-lomb/multifocal-lenses/biotrue-oneday-for-presbyopia_p562",
    component: _1b085a39,
    name: "en-GB/product/562"
  }, {
    path: "/bausch-lomb/disposable-lenses/biotrue-oneday-for-astigmatism_p562",
    redirect: "/bausch-lomb/multifocal-lenses/biotrue-oneday-for-presbyopia_p562"
  }, {
    path: "/multi-purpose-solutions/biotrue-flight-pack-100ml_p563",
    component: _1b085a39,
    name: "en-GB/product/563"
  }, {
    path: "/store-locator",
    redirect: "/request-your-appointment"
  }, {
    path: "/thank-you-landing",
    component: _974a4b7e,
    name: "en-GB/document/162"
  }, {
    path: "/acuvue/daily-disposables/acuvue-oasys-max-1-day_p564",
    component: _1b085a39,
    name: "en-GB/product/564"
  }, {
    path: "/acuvue/multifocal-lenses/acuvue-oasys-max-1-day-multifocal_p565",
    component: _1b085a39,
    name: "en-GB/product/565"
  }, {
    path: "/contact-lenses/",
    redirect: "/contact-lenses"
  }, {
    path: "/alcon/monthly-disposables/total30-for-astigmatism_p566",
    component: _1b085a39,
    name: "en-GB/product/566"
  }, {
    path: "/alcon/monthly-disposables/total30-multifocal_p567",
    component: _1b085a39,
    name: "en-GB/product/567"
  }, {
    path: "/alcon/monthly-disposables/total30-for-astigmatism_p568",
    component: _1b085a39,
    name: "en-GB/product/568"
  }, {
    path: "/alcon/monthly-disposables/total30-multifocal_p569",
    component: _1b085a39,
    name: "en-GB/product/569"
  }, {
    path: "/visco/daily-disposables/everclear-elite_p570",
    component: _1b085a39,
    name: "en-GB/product/570"
  }, {
    path: "/everclear",
    component: _387cb151,
    name: "en-GB/brand/412"
  }, {
    path: "/ciba-vision/daily-disposables/focus-dailies-progressives_p9",
    component: _1b085a39,
    name: "en-GB/product/9"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue_p11",
    component: _1b085a39,
    name: "en-GB/product/11"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue_p12",
    component: _1b085a39,
    name: "en-GB/product/12"
  }, {
    path: "/focus-toric-visitint-6-lenses",
    component: _1b085a39,
    name: "en-GB/product/24"
  }, {
    path: "/ciba-vision/monthly-disposables/precision-uv_p26",
    component: _1b085a39,
    name: "en-GB/product/26"
  }, {
    path: "/actifresh-400_p27",
    component: _1b085a39,
    name: "en-GB/product/27"
  }, {
    path: "/cooper-vision/monthly-disposables/frequency-38_p28",
    component: _1b085a39,
    name: "en-GB/product/28"
  }, {
    path: "/ciba-vision/monthly/precision-uv_p26",
    redirect: "/ciba-vision/monthly-disposables/precision-uv_p26"
  }, {
    path: "/cooper-vision/monthly-disposables/frequency-58-uv_p31",
    component: _1b085a39,
    name: "en-GB/product/31"
  }, {
    path: "/ciba-vision/toric-lenses/iwear-air-astigmatism_p461",
    component: _1b085a39,
    name: "en-GB/product/461"
  }, {
    path: "/johnson-johnson/acuvue-bifocal",
    component: _1b085a39,
    name: "en-GB/product/37"
  }, {
    path: "/ciba-vision/multifocal-lenses/iwear-air-multifocal_p462",
    component: _1b085a39,
    name: "en-GB/product/462"
  }, {
    path: "/ciba-vision/monthly-disposables/iwear-air_p463",
    component: _1b085a39,
    name: "en-GB/product/463"
  }, {
    path: "/ciba-vision/daily-disposables/iwear-free_p464",
    component: _1b085a39,
    name: "en-GB/product/464"
  }, {
    path: "/acuvue/multifocal-lenses/acuvue-bifocal_p37",
    redirect: "/johnson-johnson/acuvue-bifocal"
  }, {
    path: "/coloured-contact-lenses/soflens-natural-colors-limited-stock_p212",
    component: _1b085a39,
    name: "en-GB/product/212"
  }, {
    path: "/focus-toric-visitint-3-lenses",
    component: _1b085a39,
    name: "en-GB/product/52"
  }, {
    path: "/ciba-vision/toric-lenses/focus-toric-visitint_p52",
    redirect: "/focus-toric-visitint-3-lenses"
  }, {
    path: "/halloween-lenses/limited-edition-union-jack_p199",
    component: _1b085a39,
    name: "en-GB/product/199"
  }, {
    path: "/ciba-vision/monthly-disposables/precision-uv_p53",
    component: _1b085a39,
    name: "en-GB/product/53"
  }, {
    path: "/halloween-lenses/aqua-3-tone_p153",
    component: _1b085a39,
    name: "en-GB/product/153"
  }, {
    path: "/halloween-lenses/aqua-cat-eye_p154",
    component: _1b085a39,
    name: "en-GB/product/154"
  }, {
    path: "/halloween-lenses/avatar_p155",
    component: _1b085a39,
    name: "en-GB/product/155"
  }, {
    path: "/biomedics-38-6-lenses",
    component: _1b085a39,
    name: "en-GB/product/58"
  }, {
    path: "/halloween-lenses/blue-3-tone_p156",
    component: _1b085a39,
    name: "en-GB/product/156"
  }, {
    path: "/halloween-lenses/blue-manson_p157",
    component: _1b085a39,
    name: "en-GB/product/157"
  }, {
    path: "/halloween-lenses/glimmer-blue_p158",
    component: _1b085a39,
    name: "en-GB/product/158"
  }, {
    path: "/halloween-lenses/glimmer-green_p159",
    component: _1b085a39,
    name: "en-GB/product/159"
  }, {
    path: "/halloween-lenses/glimmer-silver_p160",
    component: _1b085a39,
    name: "en-GB/product/160"
  }, {
    path: "/cooper-vision/multifocal-lenses/biomedics-73-multifocal_p59",
    component: _1b085a39,
    name: "en-GB/product/59"
  }, {
    path: "/halloween-lenses/green-3-tone_p161",
    component: _1b085a39,
    name: "en-GB/product/161"
  }, {
    path: "/halloween-lenses/grey-3-tone_p162",
    component: _1b085a39,
    name: "en-GB/product/162"
  }, {
    path: "/halloween-lenses/hazel-3-tone_p163",
    component: _1b085a39,
    name: "en-GB/product/163"
  }, {
    path: "/halloween-lenses/green-uv_p164",
    component: _1b085a39,
    name: "en-GB/product/164"
  }, {
    path: "/halloween-lenses/pink-uv_p165",
    component: _1b085a39,
    name: "en-GB/product/165"
  }, {
    path: "/halloween-lenses/violet-uv_p166",
    component: _1b085a39,
    name: "en-GB/product/166"
  }, {
    path: "/ciba-vision/daily-disposables/focus-dailies-basic_p62",
    component: _1b085a39,
    name: "en-GB/product/62"
  }, {
    path: "/halloween-lenses/yellow-uv_p167",
    component: _1b085a39,
    name: "en-GB/product/167"
  }, {
    path: "/halloween-lenses/luna-eclipse_p168",
    component: _1b085a39,
    name: "en-GB/product/168"
  }, {
    path: "/halloween-lenses/mystic-aqua_p169",
    component: _1b085a39,
    name: "en-GB/product/169"
  }, {
    path: "/halloween-lenses/mystic-blue_p170",
    component: _1b085a39,
    name: "en-GB/product/170"
  }, {
    path: "/halloween-lenses/mystic-green_p171",
    component: _1b085a39,
    name: "en-GB/product/171"
  }, {
    path: "/halloween-lenses/twilight-volturi_p172",
    component: _1b085a39,
    name: "en-GB/product/172"
  }, {
    path: "/halloween-lenses/violet-3-tone_p173",
    component: _1b085a39,
    name: "en-GB/product/173"
  }, {
    path: "/halloween-lenses/white-cat-eye_p174",
    component: _1b085a39,
    name: "en-GB/product/174"
  }, {
    path: "/halloween-lenses/white-mesh_p175",
    component: _1b085a39,
    name: "en-GB/product/175"
  }, {
    path: "/halloween-lenses/yellow-cat-eye_p176",
    component: _1b085a39,
    name: "en-GB/product/176"
  }, {
    path: "/halloween-lenses/white-out_p177",
    component: _1b085a39,
    name: "en-GB/product/177"
  }, {
    path: "/halloween-lenses/black-out_p178",
    component: _1b085a39,
    name: "en-GB/product/178"
  }, {
    path: "/johnson-johnson/surevue",
    component: _1b085a39,
    name: "en-GB/product/78"
  }, {
    path: "/halloween-lenses/red-out_p179",
    component: _1b085a39,
    name: "en-GB/product/179"
  }, {
    path: "/halloween-lenses/manson_p180",
    component: _1b085a39,
    name: "en-GB/product/180"
  }, {
    path: "/ciba-vision/monthly-disposables/air-optix-individual_p81",
    component: _1b085a39,
    name: "en-GB/product/81"
  }, {
    path: "/halloween-lenses/white-zombie_p181",
    component: _1b085a39,
    name: "en-GB/product/181"
  }, {
    path: "/halloween-lenses/hell-raiser_p182",
    component: _1b085a39,
    name: "en-GB/product/182"
  }, {
    path: "/halloween-lenses/twilight_p183",
    component: _1b085a39,
    name: "en-GB/product/183"
  }, {
    path: "/halloween-lenses/white-uv_p184",
    component: _1b085a39,
    name: "en-GB/product/184"
  }, {
    path: "/halloween-lenses/blue-uv_p185",
    component: _1b085a39,
    name: "en-GB/product/185"
  }, {
    path: "/focus-monthly-soft-colors",
    component: _1b085a39,
    name: "en-GB/product/91"
  }, {
    path: "/halloween-lenses/red-cat-eye_p186",
    component: _1b085a39,
    name: "en-GB/product/186"
  }, {
    path: "/halloween-lenses/yellow_p187",
    component: _1b085a39,
    name: "en-GB/product/187"
  }, {
    path: "/halloween-lenses/red-screen_p188",
    component: _1b085a39,
    name: "en-GB/product/188"
  }, {
    path: "/coloured-contact-lenses/expressions-accents_p98",
    component: _1b085a39,
    name: "en-GB/product/98"
  }, {
    path: "/coloured-contact-lenses/expressions-accents-_p98",
    redirect: "/coloured-contact-lenses/expressions-accents_p98"
  }, {
    path: "/coloured-contact-lenses/expressions-accents_p99",
    component: _1b085a39,
    name: "en-GB/product/99"
  }, {
    path: "/coloured-contact-lenses/expressions-accents-_p99",
    redirect: "/coloured-contact-lenses/expressions-accents_p99"
  }, {
    path: "/fashion-crazy-lenses/wild-eyes_p108",
    component: _1b085a39,
    name: "en-GB/product/108"
  }, {
    path: "/fashion-crazy-lenses/crazy-lenses_p107",
    component: _1b085a39,
    name: "en-GB/product/107"
  }, {
    path: "/coloured-contact-lenses/images_p102",
    component: _1b085a39,
    name: "en-GB/product/102"
  }, {
    path: "/ciba-vision/extended-wear-lenses/air-optix-night-day_p105",
    component: _1b085a39,
    name: "en-GB/product/105"
  }, {
    path: "/everclear-elite-trial-pack-",
    component: _1b085a39,
    name: "en-GB/product/1588008"
  }, {
    path: "/visco/daily-disposables/everclear-elite-trial_p571",
    component: _1b085a39,
    name: "en-GB/product/571"
  }, {
    path: "/everclear/daily-disposables/everclear-elite_p571",
    redirect: "/visco/daily-disposables/everclear-elite-trial_p571"
  }, {
    path: "/-lsprod035_p-0-75_bc8-8_d14-4_cy-1-75_ax60",
    redirect: "/cooper-vision/toric-lenses/proclear-toric_p35"
  }, {
    path: "/black-friday-lenses",
    component: _974a4b7e,
    name: "en-GB/document/163"
  }, {
    path: "/b/kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/317"
  }, {
    path: "/b/biotrue",
    component: _387cb151,
    name: "de-DE/brand/309"
  }, {
    path: "/b/clariti",
    component: _387cb151,
    name: "de-DE/brand/302"
  }, {
    path: "/c/tageslinsen",
    component: _25921ca4,
    name: "de-DE/category/1"
  }, {
    path: "/c/wochenlinsen",
    component: _25921ca4,
    name: "de-DE/category/2"
  }, {
    path: "/c/monatslinsen",
    component: _25921ca4,
    name: "de-DE/category/3"
  }, {
    path: "/c/torische-kontaktlinsen",
    component: _25921ca4,
    name: "de-DE/category/5"
  }, {
    path: "/c/multifokale-kontaktlinsen",
    component: _25921ca4,
    name: "de-DE/category/6"
  }, {
    path: "/c/farbige-linsen",
    redirect: "/farbige-kontaktlinsen"
  }, {
    path: "/b/freshlook",
    component: _387cb151,
    name: "de-DE/brand/92"
  }, {
    path: "/b/expressions",
    component: _387cb151,
    name: "de-DE/brand/149"
  }, {
    path: "/b/myday",
    component: _387cb151,
    name: "de-DE/brand/310"
  }, {
    path: "/b/1-day-acuvue",
    component: _387cb151,
    name: "de-DE/brand/6"
  }, {
    path: "/b/acuvue-2",
    component: _387cb151,
    name: "de-DE/brand/7"
  }, {
    path: "/b/acuvue-advance",
    component: _387cb151,
    name: "de-DE/brand/83"
  }, {
    path: "/b/acuvue-oasys",
    component: _387cb151,
    name: "de-DE/brand/16"
  }, {
    path: "/b/air-optix-aqua",
    component: _387cb151,
    name: "de-DE/brand/19"
  }, {
    path: "/b/avaira",
    component: _387cb151,
    name: "de-DE/brand/90"
  }, {
    path: "/b/biofinity",
    component: _387cb151,
    name: "de-DE/brand/27"
  }, {
    path: "/b/biomedics",
    component: _387cb151,
    name: "de-DE/brand/84"
  }, {
    path: "/b/dailies",
    component: _387cb151,
    name: "de-DE/brand/62"
  }, {
    path: "/b/frequency",
    component: _387cb151,
    name: "de-DE/brand/86"
  }, {
    path: "/b/proclear",
    component: _387cb151,
    name: "de-DE/brand/75"
  }, {
    path: "/b/purevision",
    component: _387cb151,
    name: "de-DE/brand/76"
  }, {
    path: "/b/soflens",
    component: _387cb151,
    name: "de-DE/brand/85"
  }, {
    path: "/b/acuvue",
    component: _387cb151,
    name: "de-DE/brand/1"
  }, {
    path: "/b/bausch-lomb",
    component: _387cb151,
    name: "de-DE/brand/2"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-kleine-flasche",
    component: _1b085a39,
    name: "de-DE/product/110"
  }, {
    path: "/b/coopervision",
    component: _387cb151,
    name: "de-DE/brand/4"
  }, {
    path: "/b/ciba-vision",
    component: _387cb151,
    name: "de-DE/brand/3"
  }, {
    path: "/c/pflegemittel-zubehor",
    component: _25921ca4,
    name: "de-DE/category/7"
  }, {
    path: "/c/kochsalzlosung",
    component: _25921ca4,
    name: "de-DE/category/112"
  }, {
    path: "/c/kontaktlinsenbehalter",
    component: _25921ca4,
    name: "de-DE/category/103"
  }, {
    path: "/c/augentropfen",
    component: _25921ca4,
    name: "de-DE/category/104"
  }, {
    path: "/kontaktlinsen/p/expressions-colour",
    component: _1b085a39,
    name: "de-DE/product/101"
  }, {
    path: "/c/tag-und-nachtlinsen",
    component: _25921ca4,
    name: "de-DE/category/4"
  }, {
    path: "/c/all-in-one-losung",
    component: _25921ca4,
    name: "de-DE/category/105"
  }, {
    path: "/kontaktlinsen/p/soflens-daily-disposable-30",
    component: _1b085a39,
    name: "de-DE/product/1"
  }, {
    path: "/kontaktlinsen/p/soflens-daily-disposable-90",
    component: _1b085a39,
    name: "de-DE/product/2"
  }, {
    path: "/kontaktlinsen/p/dailies-aquacomfort-plus-30",
    component: _1b085a39,
    name: "de-DE/product/5"
  }, {
    path: "/kontaktlinsen/p/dailies-aquacomfort-plus-90",
    component: _1b085a39,
    name: "de-DE/product/6"
  }, {
    path: "/kontaktlinsen/p/focus-dailies-all-day-comfort-90",
    component: _1b085a39,
    name: "de-DE/product/7"
  }, {
    path: "/kontaktlinsen/p/focus-dailies-all-day-comfort-30",
    component: _1b085a39,
    name: "de-DE/product/8"
  }, {
    path: "/kontaktlinsen/p/focus-dailies-toric-30",
    component: _1b085a39,
    name: "de-DE/product/10"
  }, {
    path: "/kontaktlinsen/p/1-day-acuvue-moist-30",
    component: _1b085a39,
    name: "de-DE/product/13"
  }, {
    path: "/kontaktlinsen/p/1-day-acuvue-moist-90",
    component: _1b085a39,
    name: "de-DE/product/14"
  }, {
    path: "/kontaktlinsen/p/purevision-multi-focal-6",
    component: _1b085a39,
    name: "de-DE/product/16"
  }, {
    path: "/kontaktlinsen/p/soflens-38",
    component: _1b085a39,
    name: "de-DE/product/17"
  }, {
    path: "/kontaktlinsen/p/soflens-59",
    component: _1b085a39,
    name: "de-DE/product/18"
  }, {
    path: "/kontaktlinsen/p/soflens-for-astigmatism-6",
    component: _1b085a39,
    name: "de-DE/product/19"
  }, {
    path: "/kontaktlinsen/p/soflens-multi-focal-6",
    component: _1b085a39,
    name: "de-DE/product/20"
  }, {
    path: "/pflegemittel-und-zubehor/p/flacher-kontaktlinsenbehalter",
    component: _1b085a39,
    name: "de-DE/product/25"
  }, {
    path: "/pflegemittel-und-zubehor/p/total-care-losung",
    component: _1b085a39,
    name: "de-DE/product/129"
  }, {
    path: "/pflegemittel-und-zubehor/p/hoher-kontaktlinsenbehalter",
    component: _1b085a39,
    name: "de-DE/product/208"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-lid-clean-reinigungstucher",
    component: _1b085a39,
    name: "de-DE/product/249"
  }, {
    path: "/pflegemittel-und-zubehor/p/blephaclean-reinigungstucher",
    component: _1b085a39,
    name: "de-DE/product/246"
  }, {
    path: "/pflegemittel-und-zubehor/p/sensitive-eyes-kochsalzlosung",
    component: _1b085a39,
    name: "de-DE/product/71"
  }, {
    path: "/kontaktlinsen/p/proclear",
    component: _1b085a39,
    name: "de-DE/product/34"
  }, {
    path: "/kontaktlinsen/p/proclear-toric",
    component: _1b085a39,
    name: "de-DE/product/35"
  }, {
    path: "/kontaktlinsen/p/acuvue-advance",
    component: _1b085a39,
    name: "de-DE/product/49"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys",
    component: _1b085a39,
    name: "de-DE/product/38"
  }, {
    path: "/kontaktlinsen/p/biomedics-55-evolution",
    component: _1b085a39,
    name: "de-DE/product/39"
  }, {
    path: "/kontaktlinsen/p/purevision-multi-focal-3",
    component: _1b085a39,
    name: "de-DE/product/40"
  }, {
    path: "/pflegemittel-und-zubehor/p/renu-mps-240ml",
    component: _1b085a39,
    name: "de-DE/product/42"
  }, {
    path: "/kontaktlinsen/p/acuvue-advance-for-astigmatism",
    component: _1b085a39,
    name: "de-DE/product/36"
  }, {
    path: "/kontaktlinsen/p/soflens-multi-focal-3",
    component: _1b085a39,
    name: "de-DE/product/45"
  }, {
    path: "/pflegemittel-und-zubehor/p/renu-mps-120ml",
    component: _1b085a39,
    name: "de-DE/product/46"
  }, {
    path: "/kontaktlinsen/p/focus-dailies-toric-90",
    component: _1b085a39,
    name: "de-DE/product/47"
  }, {
    path: "/kontaktlinsen/p/air-optix-for-astigmatism",
    component: _1b085a39,
    name: "de-DE/product/48"
  }, {
    path: "/kontaktlinsen/p/soflens-for-astigmatism-3",
    component: _1b085a39,
    name: "de-DE/product/44"
  }, {
    path: "/kontaktlinsen/p/acuvue-2",
    component: _1b085a39,
    name: "de-DE/product/51"
  }, {
    path: "/kontaktlinsen/p/biomedics-toric",
    component: _1b085a39,
    name: "de-DE/product/54"
  }, {
    path: "/kontaktlinsen/p/biomedics-1-day-extra-toric",
    component: _1b085a39,
    name: "de-DE/product/57"
  }, {
    path: "/kontaktlinsen/p/proclear-1-day-30",
    component: _1b085a39,
    name: "de-DE/product/60"
  }, {
    path: "/kontaktlinsen/p/proclear-multifocal-3",
    component: _1b085a39,
    name: "de-DE/product/61"
  }, {
    path: "/kontaktlinsen/p/biofinity",
    component: _1b085a39,
    name: "de-DE/product/63"
  }, {
    path: "/pflegemittel-und-zubehor/p/opti-free-replenish",
    component: _1b085a39,
    name: "de-DE/product/64"
  }, {
    path: "/pflegemittel-und-zubehor/p/opti-free-replenish-doppelpack",
    component: _1b085a39,
    name: "de-DE/product/66"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-contacts-flasche",
    component: _1b085a39,
    name: "de-DE/product/67"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-contacts-ampullen",
    component: _1b085a39,
    name: "de-DE/product/68"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-refreshing-flasche",
    component: _1b085a39,
    name: "de-DE/product/69"
  }, {
    path: "/pflegemittel-und-zubehor/p/renu-mps-flight-pack",
    component: _1b085a39,
    name: "de-DE/product/72"
  }, {
    path: "/kontaktlinsen/p/proclear-multifocal-toric",
    component: _1b085a39,
    name: "de-DE/product/74"
  }, {
    path: "/kontaktlinsen/p/1-day-acuvue-trueye-30",
    component: _1b085a39,
    name: "de-DE/product/76"
  }, {
    path: "/kontaktlinsen/p/air-optix-aqua",
    component: _1b085a39,
    name: "de-DE/product/77"
  }, {
    path: "/kontaktlinsen/p/proclear-multifocal-xr",
    component: _1b085a39,
    name: "de-DE/product/79"
  }, {
    path: "/kontaktlinsen/p/proclear-toric-xr",
    component: _1b085a39,
    name: "de-DE/product/80"
  }, {
    path: "/pflegemittel-und-zubehor/p/renu-mps-kleine-flasche-60ml",
    component: _1b085a39,
    name: "de-DE/product/82"
  }, {
    path: "/kontaktlinsen/p/proclear-multifocal-6",
    component: _1b085a39,
    name: "de-DE/product/83"
  }, {
    path: "/kontaktlinsen/p/proclear-1-day-90",
    component: _1b085a39,
    name: "de-DE/product/84"
  }, {
    path: "/kontaktlinsen/p/proclear-tailor-made-toric",
    component: _1b085a39,
    name: "de-DE/product/85"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-for-astigmatism",
    component: _1b085a39,
    name: "de-DE/product/86"
  }, {
    path: "/kontaktlinsen/p/biofinity-toric",
    component: _1b085a39,
    name: "de-DE/product/87"
  }, {
    path: "/kontaktlinsen/p/soflens-daily-disposable-toric",
    component: _1b085a39,
    name: "de-DE/product/88"
  }, {
    path: "/kontaktlinsen/p/avaira",
    component: _1b085a39,
    name: "de-DE/product/89"
  }, {
    path: "/kontaktlinsen/p/air-optix-aqua-multifocal",
    component: _1b085a39,
    name: "de-DE/product/90"
  }, {
    path: "/kontaktlinsen/p/freshlook-colors",
    component: _1b085a39,
    name: "de-DE/product/92"
  }, {
    path: "/kontaktlinsen/p/freshlook-colorblends",
    component: _1b085a39,
    name: "de-DE/product/93"
  }, {
    path: "/kontaktlinsen/p/freshlook-dimensions-2",
    component: _1b085a39,
    name: "de-DE/product/94"
  }, {
    path: "/kontaktlinsen/p/freshlook-dimensions-6",
    component: _1b085a39,
    name: "de-DE/product/95"
  }, {
    path: "/kontaktlinsen/p/freshlook-one-day",
    component: _1b085a39,
    name: "de-DE/product/96"
  }, {
    path: "/kontaktlinsen/p/soflens-natural-colors",
    component: _1b085a39,
    name: "de-DE/product/97"
  }, {
    path: "/kontaktlinsen/p/1-day-acuvue-trueye-90",
    component: _1b085a39,
    name: "de-DE/product/104"
  }, {
    path: "/kontaktlinsen/p/1-day-acuvue-moist-for-astigmatism",
    component: _1b085a39,
    name: "de-DE/product/106"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-300ml",
    component: _1b085a39,
    name: "de-DE/product/109"
  }, {
    path: "/pflegemittel-und-zubehor/p/opti-free-express",
    component: _1b085a39,
    name: "de-DE/product/113"
  }, {
    path: "/pflegemittel-und-zubehor/p/opti-free-express-doppelpack",
    component: _1b085a39,
    name: "de-DE/product/114"
  }, {
    path: "/pflegemittel-und-zubehor/p/opti-free-replenish-flight-pack",
    component: _1b085a39,
    name: "de-DE/product/115"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-flasche",
    component: _1b085a39,
    name: "de-DE/product/117"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-ampullen",
    component: _1b085a39,
    name: "de-DE/product/118"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-n-clean-flasche",
    component: _1b085a39,
    name: "de-DE/product/119"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-intensive-tears-flasche",
    component: _1b085a39,
    name: "de-DE/product/120"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-intensive-tears-ampullen",
    component: _1b085a39,
    name: "de-DE/product/121"
  }, {
    path: "/pflegemittel-und-zubehor/p/complete-revitalens",
    component: _1b085a39,
    name: "de-DE/product/125"
  }, {
    path: "/pflegemittel-und-zubehor/p/complete-revitalens-doppelpack",
    component: _1b085a39,
    name: "de-DE/product/126"
  }, {
    path: "/pflegemittel-und-zubehor/p/lens-plus-ocupure",
    component: _1b085a39,
    name: "de-DE/product/127"
  }, {
    path: "/pflegemittel-und-zubehor/p/oxysept",
    component: _1b085a39,
    name: "de-DE/product/128"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-total-care-reiniger",
    component: _1b085a39,
    name: "de-DE/product/130"
  }, {
    path: "/pflegemittel-und-zubehor/p/ultrazyme-universal-proteintabletten",
    component: _1b085a39,
    name: "de-DE/product/131"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-doppelpack",
    component: _1b085a39,
    name: "de-DE/product/132"
  }, {
    path: "/pflegemittel-und-zubehor/p/boston-advance-reiniger",
    component: _1b085a39,
    name: "de-DE/product/133"
  }, {
    path: "/pflegemittel-und-zubehor/p/boston-advance",
    component: _1b085a39,
    name: "de-DE/product/134"
  }, {
    path: "/pflegemittel-und-zubehor/p/boston-simplus",
    component: _1b085a39,
    name: "de-DE/product/135"
  }, {
    path: "/pflegemittel-und-zubehor/p/boston-simplus-flight-pack",
    component: _1b085a39,
    name: "de-DE/product/136"
  }, {
    path: "/pflegemittel-und-zubehor/p/daily-cleaner",
    component: _1b085a39,
    name: "de-DE/product/137"
  }, {
    path: "/pflegemittel-und-zubehor/p/renu-multiplus-kleine-flasche-60ml",
    component: _1b085a39,
    name: "de-DE/product/139"
  }, {
    path: "/pflegemittel-und-zubehor/p/renu-multiplus",
    component: _1b085a39,
    name: "de-DE/product/140"
  }, {
    path: "/pflegemittel-und-zubehor/p/renu-multiplus-vorratspack",
    component: _1b085a39,
    name: "de-DE/product/141"
  }, {
    path: "/pflegemittel-und-zubehor/p/renu-mps-vorratspack",
    component: _1b085a39,
    name: "de-DE/product/142"
  }, {
    path: "/pflegemittel-und-zubehor/p/solocare-aqua",
    component: _1b085a39,
    name: "de-DE/product/144"
  }, {
    path: "/pflegemittel-und-zubehor/p/solocare-aqua-doppelpack",
    component: _1b085a39,
    name: "de-DE/product/145"
  }, {
    path: "/pflegemittel-und-zubehor/p/options-multi",
    component: _1b085a39,
    name: "de-DE/product/146"
  }, {
    path: "/pflegemittel-und-zubehor/p/ocuvite-complete",
    component: _1b085a39,
    name: "de-DE/product/148"
  }, {
    path: "/kontaktlinsen/p/purevision-2-hd-3",
    component: _1b085a39,
    name: "de-DE/product/149"
  }, {
    path: "/kontaktlinsen/p/purevision-2-hd-6",
    component: _1b085a39,
    name: "de-DE/product/150"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-for-presbyopia",
    component: _1b085a39,
    name: "de-DE/product/152"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-vorattspack",
    component: _1b085a39,
    name: "de-DE/product/190"
  }, {
    path: "/kontaktlinsen/p/biofinity-multifocal",
    component: _1b085a39,
    name: "de-DE/product/191"
  }, {
    path: "/kontaktlinsen/p/purevision-2-hd-for-astigmatism-6",
    component: _1b085a39,
    name: "de-DE/product/192"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-flight-pack",
    component: _1b085a39,
    name: "de-DE/product/193"
  }, {
    path: "/kontaktlinsen/p/opti-free-puremoist",
    component: _1b085a39,
    name: "de-DE/product/194"
  }, {
    path: "/kontaktlinsen/p/opti-free-puremoist-doppelpack",
    component: _1b085a39,
    name: "de-DE/product/195"
  }, {
    path: "/kontaktlinsen/p/freshlook-illuminate",
    component: _1b085a39,
    name: "de-DE/product/196"
  }, {
    path: "/kontaktlinsen/p/purevision-2-hd-for-astigmatism-3",
    component: _1b085a39,
    name: "de-DE/product/197"
  }, {
    path: "/kontaktlinsen/p/proclear-1-day-multifocal",
    component: _1b085a39,
    name: "de-DE/product/198"
  }, {
    path: "/kontaktlinsen/p/biomedics-1-day-extra-30",
    component: _1b085a39,
    name: "de-DE/product/200"
  }, {
    path: "/kontaktlinsen/p/biomedics-1-day-extra-90",
    component: _1b085a39,
    name: "de-DE/product/201"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-intensive-tears-plus-flasche",
    component: _1b085a39,
    name: "de-DE/product/202"
  }, {
    path: "/pflegemittel-und-zubehor/p/optrex-actimist-2-in-1-dry-eye-augenspray",
    component: _1b085a39,
    name: "de-DE/product/204"
  }, {
    path: "/pflegemittel-und-zubehor/p/optrex-actimist-2-in-1-tired-eye-augenspray",
    component: _1b085a39,
    name: "de-DE/product/205"
  }, {
    path: "/pflegemittel-und-zubehor/p/hycosan-flasche",
    component: _1b085a39,
    name: "de-DE/product/206"
  }, {
    path: "/pflegemittel-und-zubehor/p/hycosan-extra-flasche",
    component: _1b085a39,
    name: "de-DE/product/207"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-ultra-flasche",
    component: _1b085a39,
    name: "de-DE/product/209"
  }, {
    path: "/kontaktlinsen/p/avaira-toric",
    component: _1b085a39,
    name: "de-DE/product/211"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-flachflasche",
    component: _1b085a39,
    name: "de-DE/product/215"
  }, {
    path: "/kontaktlinsen/p/bioclear-1-day",
    component: _1b085a39,
    name: "de-DE/product/216"
  }, {
    path: "/kontaktlinsen/p/clariti-1-day-multifocal",
    component: _1b085a39,
    name: "de-DE/product/217"
  }, {
    path: "/kontaktlinsen/p/clariti-1-day-toric",
    component: _1b085a39,
    name: "de-DE/product/218"
  }, {
    path: "/kontaktlinsen/p/clariti-1-day",
    component: _1b085a39,
    name: "de-DE/product/219"
  }, {
    path: "/allgemeine-geschaftsbedingungen",
    component: _974a4b7e,
    name: "de-DE/document/8"
  }, {
    path: "/kontaktlinsen/p/clariti-monthly-toric",
    component: _1b085a39,
    name: "de-DE/product/222"
  }, {
    path: "/kontaktlinsen/p/select",
    component: _1b085a39,
    name: "de-DE/product/224"
  }, {
    path: "/kontaktlinsen/p/biotrue-oneday-30",
    component: _1b085a39,
    name: "de-DE/product/226"
  }, {
    path: "/kontaktlinsen/p/purevision-2-hd-for-presbyopia-3",
    component: _1b085a39,
    name: "de-DE/product/228"
  }, {
    path: "/kontaktlinsen/p/biofinity-xr",
    component: _1b085a39,
    name: "de-DE/product/231"
  }, {
    path: "/datenschutzerklarung-cookies",
    redirect: "/datenschutzerklarung"
  }, {
    path: "/kontaktlinsen/p/dailies-aquacomfort-plus-toric",
    component: _1b085a39,
    name: "de-DE/product/233"
  }, {
    path: "/pflegemittel-und-zubehor/p/macushield-30-kapseln",
    component: _1b085a39,
    name: "de-DE/product/241"
  }, {
    path: "/kontaktlinsen/p/clariti-monthly-multifocal",
    component: _1b085a39,
    name: "de-DE/product/221"
  }, {
    path: "/kontaktlinsen/p/dailies-total-1-30",
    component: _1b085a39,
    name: "de-DE/product/225"
  }, {
    path: "/pflegemittel-und-zubehor/p/aosept-plus-mit-hydraglyde",
    component: _1b085a39,
    name: "de-DE/product/258"
  }, {
    path: "/pflegemittel-und-zubehor/p/aosept-plus-mit-hydraglyde-doppelpack",
    component: _1b085a39,
    name: "de-DE/product/259"
  }, {
    path: "/kontaktlinsen/p/biotrue-oneday-90",
    component: _1b085a39,
    name: "de-DE/product/227"
  }, {
    path: "/kontaktlinsen/p/purevision-2-hd-for-presbyopia-6",
    component: _1b085a39,
    name: "de-DE/product/229"
  }, {
    path: "/kontaktlinsen/p/myday-30",
    component: _1b085a39,
    name: "de-DE/product/230"
  }, {
    path: "/kontaktlinsen/p/air-optix-colors",
    component: _1b085a39,
    name: "de-DE/product/232"
  }, {
    path: "/kontaktlinsen/p/dailies-aquacomfort-plus-multifocal",
    component: _1b085a39,
    name: "de-DE/product/234"
  }, {
    path: "/kontaktlinsen/p/biotrue-oneday-for-presbyopia",
    component: _1b085a39,
    name: "de-DE/product/235"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-augentropfen-ampullen",
    component: _1b085a39,
    name: "de-DE/product/236"
  }, {
    path: "/pflegemittel-und-zubehor/p/thera-pearl-augenmaske",
    component: _1b085a39,
    name: "de-DE/product/237"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-augentropfen-flasche",
    component: _1b085a39,
    name: "de-DE/product/238"
  }, {
    path: "/kontaktlinsen/p/1-day-acuvue-define-natural-shimmer",
    component: _1b085a39,
    name: "de-DE/product/239"
  }, {
    path: "/kontaktlinsen/p/1-day-acuvue-define-natural-sparkle",
    component: _1b085a39,
    name: "de-DE/product/240"
  }, {
    path: "/pflegemittel-und-zubehor/p/macushield-90-kapseln",
    component: _1b085a39,
    name: "de-DE/product/242"
  }, {
    path: "/pflegemittel-und-zubehor/p243/macushield-vegetarian",
    component: _1b085a39,
    name: "de-DE/product/243"
  }, {
    path: "/pflegemittel-und-zubehor/p/macushield-gold",
    component: _1b085a39,
    name: "de-DE/product/244"
  }, {
    path: "/pflegemittel-und-zubehor/p/blephasol",
    component: _1b085a39,
    name: "de-DE/product/245"
  }, {
    path: "/pflegemittel-und-zubehor/p/blephagel",
    component: _1b085a39,
    name: "de-DE/product/247"
  }, {
    path: "/pflegemittel-und-zubehor/p/clinitas-soothe-ampullen",
    component: _1b085a39,
    name: "de-DE/product/248"
  }, {
    path: "/kontaktlinsen/p/1-day-acuvue-moist-multifocal",
    component: _1b085a39,
    name: "de-DE/product/250"
  }, {
    path: "/kontaktlinsen/p/dailies-total-1-90",
    component: _1b085a39,
    name: "de-DE/product/251"
  }, {
    path: "/kontaktlinsen/p/myday-90",
    component: _1b085a39,
    name: "de-DE/product/252"
  }, {
    path: "/pflegemittel-und-zubehor/p/synergi",
    component: _1b085a39,
    name: "de-DE/product/253"
  }, {
    path: "/pflegemittel-und-zubehor/p/synergi-vorratspack",
    component: _1b085a39,
    name: "de-DE/product/254"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-daily-eyelid-wipes",
    component: _1b085a39,
    name: "de-DE/product/255"
  }, {
    path: "/pflegemittel-und-zubehor/p/optrex-intensive-augentropfen",
    component: _1b085a39,
    name: "de-DE/product/256"
  }, {
    path: "/pflegemittel-und-zubehor/p/energeyes",
    component: _1b085a39,
    name: "de-DE/product/257"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-ultra-ampullen",
    component: _1b085a39,
    name: "de-DE/product/260"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-balance-flasche",
    component: _1b085a39,
    name: "de-DE/product/261"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-gel-drops",
    component: _1b085a39,
    name: "de-DE/product/262"
  }, {
    path: "/pflegemittel-und-zubehor/p/easysept-hydro-plus",
    component: _1b085a39,
    name: "de-DE/product/263"
  }, {
    path: "/pflegemittel-und-zubehor/p/blink-refreshing-eye-mist",
    component: _1b085a39,
    name: "de-DE/product/264"
  }, {
    path: "/b/weitere-kontaklinsenfarben",
    component: _387cb151,
    name: "de-DE/brand/151"
  }, {
    path: "/sunglasses",
    redirect: "//"
  }, {
    path: "/c/peroxidlosung",
    component: _25921ca4,
    name: "de-DE/category/106"
  }, {
    path: "/c/pflegemittel-harte-kontaktlinsen",
    component: _25921ca4,
    name: "de-DE/category/109"
  }, {
    path: "/c/augenvitamine",
    component: _25921ca4,
    name: "de-DE/category/108"
  }, {
    path: "/reduzierte-artikel",
    component: _25921ca4,
    name: "de-DE/category/9999"
  }, {
    path: "/kontaktlinsen/p/frequency-xcel-toric-xr",
    component: _1b085a39,
    name: "de-DE/product/32"
  }, {
    path: "/kasse",
    component: _99c1be28,
    name: "de-DE/basket"
  }, {
    path: "/konto/kundendaten",
    component: _1502f9db,
    name: "de-DE/profile"
  }, {
    path: "/c/augenspray-weiteres-zubehor",
    component: _25921ca4,
    name: "de-DE/category/107"
  }, {
    path: "/b/biotrue-losung",
    component: _387cb151,
    name: "de-DE/brand/173"
  }, {
    path: "/how-to-order",
    redirect: "//"
  }, {
    path: "/our-services",
    redirect: "//"
  }, {
    path: "/konto",
    component: _b0dd6f12,
    name: "de-DE/account"
  }, {
    path: "/b/macushield",
    component: _387cb151,
    name: "de-DE/brand/314"
  }, {
    path: "/tell-a-friend",
    redirect: "//"
  }, {
    path: "/konto/bestellungen",
    component: _829d8442,
    name: "de-DE/orders"
  }, {
    path: "/konto/adressbuch",
    component: _89a7e070,
    name: "de-DE/address-book"
  }, {
    path: "/b/blink-augentropfen",
    component: _387cb151,
    name: "de-DE/brand/134"
  }, {
    path: "/b/blaue-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/129"
  }, {
    path: "/b/braune-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/141"
  }, {
    path: "/b/grune-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/130"
  }, {
    path: "/b/graue-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/143"
  }, {
    path: "/b/hycosan-augentropfen",
    component: _387cb151,
    name: "de-DE/brand/280"
  }, {
    path: "/b/opti-free",
    component: _387cb151,
    name: "de-DE/brand/316"
  }, {
    path: "/b/optrex-augenpflege",
    component: _387cb151,
    name: "de-DE/brand/279"
  }, {
    path: "/b/renu",
    component: _387cb151,
    name: "de-DE/brand/315"
  }, {
    path: "/b/solo-care-aqua",
    component: _387cb151,
    name: "de-DE/brand/185"
  }, {
    path: "/b/systane",
    component: _387cb151,
    name: "de-DE/brand/170"
  }, {
    path: "/farbige-kontaktlinsen-dokument-archivieren",
    redirect: "/farbige-kontaktlinsen"
  }, {
    path: "/faq",
    component: _974a4b7e,
    name: "de-DE/document/6"
  }, {
    path: "/kontaktlinsen/p/frequency-xcel-toric",
    component: _1b085a39,
    name: "de-DE/product/33"
  }, {
    path: "/kontaktlinsen/p/frequency-55",
    component: _1b085a39,
    name: "de-DE/product/29"
  }, {
    path: "/kontaktlinsen/p/frequency-55-aspheric",
    component: _1b085a39,
    name: "de-DE/product/30"
  }, {
    path: "/kontaktlinsen/p/air-optix-night-day-aqua",
    component: _1b085a39,
    name: "de-DE/product/22"
  }, {
    path: "/konto/kontaktoptionen",
    component: _08e7896a,
    name: "de-DE/communication-preferences"
  }, {
    path: "/b/sauflon",
    component: _387cb151,
    name: "de-DE/brand/301"
  }, {
    path: "/opticians-own-brand-conversion",
    redirect: "//"
  }, {
    path: "/b/1-day-acuvue-define",
    component: _387cb151,
    name: "de-DE/brand/313"
  }, {
    path: "/kontaktlinsen/p/clariti-elite",
    component: _1b085a39,
    name: "de-DE/product/450"
  }, {
    path: "/kontakt",
    component: _974a4b7e,
    name: "de-DE/document/2"
  }, {
    path: "/lieferung-versandkosten",
    component: _974a4b7e,
    name: "de-DE/document/10"
  }, {
    path: "/widerrufsrecht",
    component: _974a4b7e,
    name: "de-DE/document/11"
  }, {
    path: "/finden-sie-ihre-marke",
    component: _974a4b7e,
    name: "de-DE/document/64"
  }, {
    path: "/finden-sie-ihre-kontaktlinsen",
    component: _974a4b7e,
    name: "de-DE/document/50"
  }, {
    path: "/finden-sie-ihre-pflegemittel",
    component: _974a4b7e,
    name: "de-DE/document/103"
  }, {
    path: "/kontaktlinsen/p/proclear-3",
    component: _1b085a39,
    name: "de-DE/product/451"
  }, {
    path: "/kontaktlinsen/p/biofinity-6",
    component: _1b085a39,
    name: "de-DE/product/452"
  }, {
    path: "/kontaktlinsen/p/biofinity-toric-6",
    component: _1b085a39,
    name: "de-DE/product/453"
  }, {
    path: "/kontaktlinsen/p/biofinity-multifocal-6",
    component: _1b085a39,
    name: "de-DE/product/454"
  }, {
    path: "/kontaktlinsen/p/air-optix-aqua-6",
    component: _1b085a39,
    name: "de-DE/product/213"
  }, {
    path: "/kontaktlinsen/p/air-optix-for-astigmatism-6",
    component: _1b085a39,
    name: "de-DE/product/455"
  }, {
    path: "/kontaktlinsen/p/air-optix-aqua-multifocal-6",
    component: _1b085a39,
    name: "de-DE/product/456"
  }, {
    path: "/kontaktlinsen/p/air-optix-night-day-aqua-6",
    component: _1b085a39,
    name: "de-DE/product/214"
  }, {
    path: "/kontaktlinsen/p/dailies-total-1-multifocal-30",
    component: _1b085a39,
    name: "de-DE/product/457"
  }, {
    path: "/eigenmarken-und-originalprodukt",
    component: _974a4b7e,
    name: "de-DE/document/14"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-1-day-30",
    component: _1b085a39,
    name: "de-DE/product/458"
  }, {
    path: "/kontaktlinsen/acuvue-oasys-1-day",
    redirect: "/kontaktlinsen/p/acuvue-oasys-1-day-30"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-1-day-90",
    component: _1b085a39,
    name: "de-DE/product/459"
  }, {
    path: "/acuvue/tageslinsen/acuvue-oasys-1-day",
    redirect: "/kontaktlinsen/p/acuvue-oasys-1-day-30"
  }, {
    path: "/acuvue/tageslinsen/acuvue-oasys-1-day-90",
    redirect: "/kontaktlinsen/p/acuvue-oasys-1-day-90"
  }, {
    path: "/kontaktlinsen/p/air-optix-plus-hydraglyde",
    component: _1b085a39,
    name: "de-DE/product/460"
  }, {
    path: "/kontaktlinsen/p/avaira-vitality",
    component: _1b085a39,
    name: "de-DE/product/466"
  }, {
    path: "/kontaktlinsen/p/avaira_vitality",
    redirect: "/kontaktlinsen/p/avaira-vitality"
  }, {
    path: "/kontaktlinsen/p/soflens-toric-6",
    redirect: "/kontaktlinsen/p/soflens-for-astigmatism-6"
  }, {
    path: "/kontaktlinsen/p/dailies-aqua-comfort-plus-multifocal",
    redirect: "/kontaktlinsen/p/dailies-aquacomfort-plus-multifocal"
  }, {
    path: "/kontaktlinsen/p/dailies-aqua-comfort-plus-toric",
    redirect: "/kontaktlinsen/p/dailies-aquacomfort-plus-toric"
  }, {
    path: "/kontaktlinsen/p/focus-dailies-30",
    redirect: "/kontaktlinsen/p/focus-dailies-all-day-comfort-30"
  }, {
    path: "/kontaktlinsen/p/focus-dailies-90",
    redirect: "/kontaktlinsen/p/focus-dailies-all-day-comfort-90"
  }, {
    path: "/kontaktlinsen/p/soflens-toric-3",
    redirect: "/kontaktlinsen/p/soflens-for-astigmatism-3"
  }, {
    path: "/kontaktlinsen/p/biofinity-energys",
    component: _1b085a39,
    name: "de-DE/product/467"
  }, {
    path: "//kontaktlinsen/p/biofinity-energys",
    redirect: "/kontaktlinsen/p/biofinity-energys"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-1-day-for-astigmatism-30",
    component: _1b085a39,
    name: "de-DE/product/468"
  }, {
    path: "/https-//www-lenstore-net/kontaktlinsen/p/acuvue-oasys-1-day-for-astigmatism-30",
    redirect: "/kontaktlinsen/p/acuvue-oasys-1-day-for-astigmatism-30"
  }, {
    path: "/kontaktlinsen/p/avaira-vitality-6",
    component: _1b085a39,
    name: "de-DE/product/469"
  }, {
    path: "/kontaktlinsen/p/avaira_vitality_6",
    redirect: "/kontaktlinsen/p/avaira-vitality-6"
  }, {
    path: "/alcon",
    component: _387cb151,
    name: "de-DE/brand/158"
  }, {
    path: "/kontaktlinsen/p/myday-toric-30",
    component: _1b085a39,
    name: "de-DE/product/470"
  }, {
    path: "/myday-toric",
    redirect: "/kontaktlinsen/p/myday-toric-30"
  }, {
    path: "/kontaktlinsen/p/myday-toric",
    redirect: "/kontaktlinsen/p/myday-toric-30"
  }, {
    path: "/kontaktlinsen/p/biofinity-xr-toric-3",
    component: _1b085a39,
    name: "de-DE/product/471"
  }, {
    path: "/kontaktlinsen/p/biofinity-xr-toric",
    redirect: "/kontaktlinsen/p/biofinity-xr-toric-3"
  }, {
    path: "/pflegemittel-und-zubehor/p/hy-care-vorratspack",
    component: _1b085a39,
    name: "de-DE/product/473"
  }, {
    path: "/pflegemittel-und-zubehor/p/hy-care",
    component: _1b085a39,
    name: "de-DE/product/472"
  }, {
    path: "/kontaktlinsen/p/acuvue-vita-3",
    component: _1b085a39,
    name: "de-DE/product/474"
  }, {
    path: "/kontaktlinsen/p/ultra-6",
    component: _1b085a39,
    name: "de-DE/product/475"
  }, {
    path: "/kontaktlinsen/p/ultra-3",
    component: _1b085a39,
    name: "de-DE/product/476"
  }, {
    path: "/kontaktlinsen/p/ultra-presbyopia-3",
    component: _1b085a39,
    name: "de-DE/product/477"
  }, {
    path: "/kontaktlinsen/p/ultra-presbyopia-6",
    component: _1b085a39,
    name: "de-DE/product/478"
  }, {
    path: "/unser-service",
    component: _974a4b7e,
    name: "de-DE/document/1"
  }, {
    path: "/datenschutzerklarung",
    component: _974a4b7e,
    name: "de-DE/document/9"
  }, {
    path: "/cookies",
    component: _974a4b7e,
    name: "de-DE/document/124"
  }, {
    path: "/kontaktlinsen/p/avaira-vitality-toric",
    component: _1b085a39,
    name: "de-DE/product/479"
  }, {
    path: "/kontaktlinsen/p/biotrue-oneday-for-astigmatism-30",
    component: _1b085a39,
    name: "de-DE/product/480"
  }, {
    path: "/affiliate-partner-werden",
    component: _974a4b7e,
    name: "de-DE/document/42"
  }, {
    path: "/kontaktlinsen/p/biofinity-energys-6",
    component: _1b085a39,
    name: "de-DE/product/481"
  }, {
    path: "/studentenrabatt",
    component: _974a4b7e,
    name: "de-DE/document/125"
  }, {
    path: "/uber-uns",
    component: _974a4b7e,
    name: "de-DE/document/77"
  }, {
    path: "/kontaktlinsen/p/air-optix-plus-hydraglyde-for-astigmatism",
    component: _1b085a39,
    name: "de-DE/product/482"
  }, {
    path: "/lenstore-rabattcodes",
    component: _387cb151,
    name: "de-DE/brand/214"
  }, {
    path: "/abmeldung",
    component: _3747f11c
  }, {
    path: "/gunstig-linsen-bestellen",
    component: _974a4b7e,
    name: "de-DE/document/126"
  }, {
    path: "/weiterempfehlung-rabatt",
    redirect: "/gunstig-linsen-bestellen"
  }, {
    path: "/ga-nstig-linsen-bestellen",
    redirect: "/gunstig-linsen-bestellen"
  }, {
    path: "/kontaktlinsen/p/live-1-day-30",
    component: _1b085a39,
    name: "de-DE/product/483"
  }, {
    path: "/linsen-abo",
    component: _974a4b7e,
    name: "de-DE/document/89"
  }, {
    path: "/kontaktlinsen/p/air-optix-plus-hydraglyde-multifocal",
    component: _1b085a39,
    name: "de-DE/product/484"
  }, {
    path: "/farbige-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/345"
  }, {
    path: "/best-preis-garantie",
    redirect: "/b/kontaktlinsen"
  }, {
    path: "/marktforschung-agb",
    component: _974a4b7e,
    name: "de-DE/document/141"
  }, {
    path: "/login",
    component: _00ef9433,
    name: "de-DE/login"
  }, {
    path: "/kontaktlinsen-online-bestellen",
    component: _974a4b7e,
    name: "de-DE/document/145"
  }, {
    path: "/covid-19-service-update",
    component: _974a4b7e,
    name: "de-DE/document/148"
  }, {
    path: "/biomedics-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/351"
  }, {
    path: "/kundenumfrage-bedingungen",
    component: _974a4b7e,
    name: "de-DE/document/152"
  }, {
    path: "/acuvue-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/352"
  }, {
    path: "/acuvue-wochenlinsen",
    component: _387cb151,
    name: "de-DE/brand/353"
  }, {
    path: "/acuvue-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/354"
  }, {
    path: "/acuvue-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/355"
  }, {
    path: "/acuvue-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/356"
  }, {
    path: "/air-optix-aqua-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/357"
  }, {
    path: "/air-optix-aqua-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/358"
  }, {
    path: "/air-optix-aqua-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/359"
  }, {
    path: "/avaira-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/360"
  }, {
    path: "/avaira-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/361"
  }, {
    path: "/biofinity-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/362"
  }, {
    path: "/biofinity-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/363"
  }, {
    path: "/biofinity-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/364"
  }, {
    path: "/biomedics-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/365"
  }, {
    path: "/biomedics-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/366"
  }, {
    path: "/biotrue-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/367"
  }, {
    path: "/biotrue-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/368"
  }, {
    path: "/biotrue-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/369"
  }, {
    path: "/clariti-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/370"
  }, {
    path: "/clariti-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/371"
  }, {
    path: "/clariti-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/372"
  }, {
    path: "/clariti-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/373"
  }, {
    path: "/dailies-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/374"
  }, {
    path: "/dailies-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/375"
  }, {
    path: "/dailies-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/376"
  }, {
    path: "/frequency-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/377"
  }, {
    path: "/myday-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/379"
  }, {
    path: "/myday-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/380"
  }, {
    path: "/proclear-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/381"
  }, {
    path: "/proclear-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/382"
  }, {
    path: "/proclear-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/383"
  }, {
    path: "/proclear-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/384"
  }, {
    path: "/purevision-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/385"
  }, {
    path: "/purevision-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/386"
  }, {
    path: "/purevision-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/387"
  }, {
    path: "/softlens-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/388"
  }, {
    path: "/softlens-monatslinsen",
    component: _387cb151,
    name: "de-DE/brand/389"
  }, {
    path: "/softlens-torische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/390"
  }, {
    path: "/softlens-multifokale-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/391"
  }, {
    path: "/kundenumfrage-lenstore-teilnahmebedingungen",
    component: _974a4b7e,
    name: "de-DE/document/157"
  }, {
    path: "/kontaktlinsen/p/miru-1day-flat",
    component: _1b085a39,
    name: "de-DE/product/488"
  }, {
    path: "/kontaktlinsen/p/miru-1month",
    component: _1b085a39,
    name: "de-DE/product/489"
  }, {
    path: "/kontaktlinsen/p/miru-1month_toric",
    component: _1b085a39,
    name: "de-DE/product/490"
  }, {
    path: "/kontaktlinsen/p/miru-1month_multifocal",
    component: _1b085a39,
    name: "de-DE/product/491"
  }, {
    path: "/b/menicon",
    component: _387cb151,
    name: "de-DE/brand/394"
  }, {
    path: "/b/miru-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/396"
  }, {
    path: "/uk-efs-ltd-steuerpolitik",
    component: _974a4b7e,
    name: "de-DE/document/115"
  }, {
    path: "/pflegemittel-und-zubehor/p/acuvue-revitalens-300ml",
    component: _1b085a39,
    name: "de-DE/product/485"
  }, {
    path: "/pflegemittel-und-zubehor/p/acuvue-revitalens-doppelpack",
    component: _1b085a39,
    name: "de-DE/product/486"
  }, {
    path: "/kontaktlinsen/p/precision-1-30",
    component: _1b085a39,
    name: "de-DE/product/492"
  }, {
    path: "/kontaktlinsen/p/precision-1-for-astigmatism-30",
    component: _1b085a39,
    name: "de-DE/product/494"
  }, {
    path: "/kontaktlinsen/p/precision-1-90",
    component: _1b085a39,
    name: "de-DE/product/493"
  }, {
    path: "/kontaktlinsen/p493/precision-1-90",
    redirect: "/kontaktlinsen/p/precision-1-90"
  }, {
    path: "/kontaktlinsen/p/precision-1-for-astigmatism-90",
    component: _1b085a39,
    name: "de-DE/product/495"
  }, {
    path: "/kontaktlinsen/p/angelic-white-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "de-DE/product/496"
  }, {
    path: "/kontaktlinsen/p//p/angelic-white-clearcolor-1-day-phantom-",
    redirect: "/kontaktlinsen/p/angelic-white-clearcolor-1-day-phantom"
  }, {
    path: "/kontaktlinsen/p/red-vampire-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "de-DE/product/498"
  }, {
    path: "/kontaktlinsen/p/black-out-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "de-DE/product/497"
  }, {
    path: "/kontaktlinsen/p/white-out-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "de-DE/product/499"
  }, {
    path: "/kontaktlinsen/p/zombie-yellow-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "de-DE/product/500"
  }, {
    path: "/kontaktlinsen/p/blue-walker-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "de-DE/product/501"
  }, {
    path: "//kontaktlinsen/p/white-out-clearcolor-1-day-phantom-",
    redirect: "/kontaktlinsen/p/white-out-clearcolor-1-day-phantom"
  }, {
    path: "/kontaktlinsen/p/angelic-red-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/504"
  }, {
    path: "/kontaktlinsen/p/banshee-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/505"
  }, {
    path: "/kontaktlinsen/p/black-wolf-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/506"
  }, {
    path: "/kontaktlinsen/p/lestat-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/507"
  }, {
    path: "/kontaktlinsen/p/manson-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/508"
  }, {
    path: "/kontaktlinsen/p/red-cat-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/509"
  }, {
    path: "/kontaktlinsen/p/red-vampire-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/510"
  }, {
    path: "/kontaktlinsen/p//p/angelic-white-clearcolor-1-day-phantom",
    redirect: "/kontaktlinsen/p/angelic-white-clearcolor-1-day-phantom"
  }, {
    path: "/kontaktlinsen/p/smiley-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/511"
  }, {
    path: "/kontaktlinsen/p/white-out-clearcolor-phantom",
    component: _1b085a39,
    name: "de-DE/product/512"
  }, {
    path: "//kontaktlinsen/p/white-out-clearcolor-1-day-phantom",
    redirect: "/kontaktlinsen/p/white-out-clearcolor-1-day-phantom"
  }, {
    path: "//kontaktlinsen/p/blue-walker-clearcolor-1-day-phantom",
    redirect: "/kontaktlinsen/p/blue-walker-clearcolor-1-day-phantom"
  }, {
    path: "/b/revitalens",
    component: _387cb151,
    name: "de-DE/brand/403"
  }, {
    path: "/b/precision-1",
    component: _387cb151,
    name: "de-DE/brand/404"
  }, {
    path: "/pflegemittel-und-zubehor/p/all-in-one-light-reiseset-100ml",
    component: _1b085a39,
    name: "de-DE/product/513"
  }, {
    path: "/pflegemittel-und-zubehor/p/all-in-one-light-250ml",
    component: _1b085a39,
    name: "de-DE/product/514"
  }, {
    path: "/pflegemittel-und-zubehor/p/all-in-one-light-360ml",
    component: _1b085a39,
    name: "de-DE/product/515"
  }, {
    path: "/pflegemittel-und-zubehor/p/all-in-one-light-3x250ml",
    component: _1b085a39,
    name: "de-DE/product/516"
  }, {
    path: "/pflegemittel-und-zubehor/p/hy-care-reiseset-100ml",
    component: _1b085a39,
    name: "de-DE/product/517"
  }, {
    path: "/pflegemittel-und-zubehor/p/refine-one-step-travel-pack-100ml",
    component: _1b085a39,
    name: "de-DE/product/518"
  }, {
    path: "/pflegemittel-und-zubehor/p/refine-one-step-250ml",
    component: _1b085a39,
    name: "de-DE/product/519"
  }, {
    path: "/pflegemittel-und-zubehor/p/refine-one-step-360ml",
    component: _1b085a39,
    name: "de-DE/product/520"
  }, {
    path: "/pflegemittel-und-zubehor/p/refine-one-step-3x250ml",
    component: _1b085a39,
    name: "de-DE/product/521"
  }, {
    path: "/pflegemittel-und-zubehor/p/saline-reiseset-100ml",
    component: _1b085a39,
    name: "de-DE/product/522"
  }, {
    path: "/pflegemittel-und-zubehor/p/saline-360ml",
    component: _1b085a39,
    name: "de-DE/product/523"
  }, {
    path: "/pflegemittel-und-zubehor/p/delta-cleaner-20ml",
    component: _1b085a39,
    name: "de-DE/product/524"
  }, {
    path: "/pflegemittel-und-zubehor/p/delta-plus-reiseset-100ml",
    component: _1b085a39,
    name: "de-DE/product/525"
  }, {
    path: "/pflegemittel-und-zubehor/p/delta-multi-pack",
    component: _1b085a39,
    name: "de-DE/product/526"
  }, {
    path: "/pflegemittel-und-zubehor/p/comfort-drops-20ml",
    component: _1b085a39,
    name: "de-DE/product/527"
  }, {
    path: "/pflegemittel-und-zubehor/p/all-in-one-light-travel-pack-100ml",
    redirect: "/pflegemittel-und-zubehor/p/all-in-one-light-reiseset-100ml"
  }, {
    path: "/pflegemittel-und-zubehor/p/hy-care-travel-pack-100ml",
    redirect: "/pflegemittel-und-zubehor/p/hy-care-reiseset-100ml"
  }, {
    path: "/pflegemittel-und-zubehor/p/preservative-free-saline-travel-pack-100ml",
    redirect: "/pflegemittel-und-zubehor/p/saline-reiseset-100ml"
  }, {
    path: "/pflegemittel-und-zubehor/p/delta-plus-travel-pack-100ml",
    redirect: "/pflegemittel-und-zubehor/p/delta-plus-reiseset-100ml"
  }, {
    path: "/pflegemittel-und-zubehor/p/comf-drops-20ml",
    redirect: "/pflegemittel-und-zubehor/p/comfort-drops-20ml"
  }, {
    path: "/all-in-one-pflegemittel/p/all-in-one-light-reiseset-100ml",
    redirect: "/pflegemittel-und-zubehor/p/all-in-one-light-reiseset-100ml"
  }, {
    path: "/all-in-one-pflegemittel/p/hy-care-reiseset-100ml",
    redirect: "/pflegemittel-und-zubehor/p/hy-care-reiseset-100ml"
  }, {
    path: "/peroxid-systeme/p/refine-one-step-travel-pack-100ml",
    redirect: "/pflegemittel-und-zubehor/p/refine-one-step-travel-pack-100ml"
  }, {
    path: "/kochsalzloesung/p/preservative-free-saline-reiseset-100ml",
    redirect: "/pflegemittel-und-zubehor/p/saline-reiseset-100ml"
  }, {
    path: "/pflegemittel-harte-kontaktlinsen/p/delta-plus-reiseset-100ml",
    redirect: "/pflegemittel-und-zubehor/p/delta-plus-reiseset-100ml"
  }, {
    path: "/augentropfen/p/comfort-drops-20ml",
    redirect: "/pflegemittel-und-zubehor/p/comfort-drops-20ml"
  }, {
    path: "/pflegemittel-und-zubehor/p/preservative-free-saline-360ml",
    redirect: "/pflegemittel-und-zubehor/p/saline-360ml"
  }, {
    path: "/pflegemittel-und-zubehor/p/preservative-free-saline-reiseset-100ml",
    redirect: "/pflegemittel-und-zubehor/p/saline-reiseset-100ml"
  }, {
    path: "/pflegemittel-und-zubehor/p/saline-solution-360ml",
    redirect: "/pflegemittel-und-zubehor/p/saline-360ml"
  }, {
    path: "/b/clearlab",
    component: _387cb151,
    name: "de-DE/brand/406"
  }, {
    path: "/b/clearcolor-phantom",
    component: _387cb151,
    name: "de-DE/brand/407"
  }, {
    path: "/b/clearcolor-phantom-1-day",
    component: _387cb151,
    name: "de-DE/brand/408"
  }, {
    path: "/b/farbige-kontaktlinsen-mit-sehstarke",
    component: _387cb151,
    name: "de-DE/brand/96"
  }, {
    path: "/b/halloween-kontaktlinsen-mit-sehstarke",
    component: _387cb151,
    name: "de-DE/brand/211"
  }, {
    path: "/b/halloween-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/405"
  }, {
    path: "/b/modische-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/409"
  }, {
    path: "/pflegemittel-und-zubehor/p/all-in-one-light-2x250ml",
    redirect: "/pflegemittel-und-zubehor/p/all-in-one-light-3x250ml"
  }, {
    path: "/kontaktlinsen/p/air-optix-plus-hydraglyde-6",
    component: _1b085a39,
    name: "de-DE/product/528"
  }, {
    path: "/kontaktlinsen/p/air-optix-plus-hydraglyde-for-astigmatism-6",
    component: _1b085a39,
    name: "de-DE/product/529"
  }, {
    path: "/kontaktlinsen/p/air-optix-plus-hydraglyde-multifocal-6",
    component: _1b085a39,
    name: "de-DE/product/530"
  }, {
    path: "/kontaktlinsen/p/light-blue-clearcolor-1-day",
    component: _1b085a39,
    name: "de-DE/product/531"
  }, {
    path: "/kontaktlinsen/p/grey-clearcolor-1-day",
    component: _1b085a39,
    name: "de-DE/product/532"
  }, {
    path: "/kontaktlinsen/p/hazel-clearcolor-1-day",
    component: _1b085a39,
    name: "de-DE/product/533"
  }, {
    path: "/kontaktlinsen/p/blue-clearcolor-1-day",
    component: _1b085a39,
    name: "de-DE/product/534"
  }, {
    path: "/kontaktlinsen/p/green-clearcolor-1-day",
    component: _1b085a39,
    name: "de-DE/product/535"
  }, {
    path: "/coloured-contact-lenses/light-blue-clearcolor-1-day_p531",
    redirect: "/kontaktlinsen/p/light-blue-clearcolor-1-day"
  }, {
    path: "/coloured-contact-lenses/grey-clearcolor-1-day_p532",
    redirect: "/kontaktlinsen/p/grey-clearcolor-1-day"
  }, {
    path: "/coloured-contact-lenses/hazel-clearcolor-1-day_p533",
    redirect: "/kontaktlinsen/p/hazel-clearcolor-1-day"
  }, {
    path: "/coloured-contact-lenses/blue-clearcolor-1-day_p534",
    redirect: "/kontaktlinsen/p/blue-clearcolor-1-day"
  }, {
    path: "/coloured-contact-lenses/green-clearcolor-1-day_p535",
    redirect: "/kontaktlinsen/p/green-clearcolor-1-day"
  }, {
    path: "/b/coopervision-losung",
    component: _387cb151,
    name: "de-DE/brand/410"
  }, {
    path: "/b/silikon-hydrogel-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/110"
  }, {
    path: "/kontaktlinsen/p/miru-1day-upside",
    component: _1b085a39,
    name: "de-DE/product/542"
  }, {
    path: "/kontaktlinsen/p/total30",
    component: _1b085a39,
    name: "de-DE/product/547"
  }, {
    path: "/kontaktlinsen/p/total30-6",
    component: _1b085a39,
    name: "de-DE/product/548"
  }, {
    path: "/kontaktlinsen/p/bausch-and-lomb-ultra-for-astigmatism-3",
    component: _1b085a39,
    name: "de-DE/product/544"
  }, {
    path: "/kontaktlinsen/p/bausch-and-lomb-ultra-for-astigmatism-6",
    component: _1b085a39,
    name: "de-DE/product/545"
  }, {
    path: "/kontaktlinsen/p/bausch-and-lomb-ultra-multifocal-for-astigmatism-6",
    component: _1b085a39,
    name: "de-DE/product/546"
  }, {
    path: "/kontaktlinsen/p549/miru-1day-flat-multifocal",
    component: _1b085a39,
    name: "de-DE/product/549"
  }, {
    path: "/kontaktlinsen/p550/miru-1day-flat-toric",
    component: _1b085a39,
    name: "de-DE/product/550"
  }, {
    path: "/kontaktlinsen/p551/miru-1day-upside-multifocal",
    component: _1b085a39,
    name: "de-DE/product/551"
  }, {
    path: "/kontaktlinsen/p/ultra-one-day-30",
    component: _1b085a39,
    name: "de-DE/product/552"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-multifocal",
    component: _1b085a39,
    name: "de-DE/product/543"
  }, {
    path: "/kontaktlinsen/p556/myday-multifocal",
    component: _1b085a39,
    name: "de-DE/product/556"
  }, {
    path: "/kontaktlinsen/p6/acuvue-oasys-multifocal",
    redirect: "/kontaktlinsen/p/acuvue-oasys-multifocal"
  }, {
    path: "/mein-konto",
    redirect: "/konto"
  }, {
    path: "/mein-bestellungen",
    redirect: "/konto/bestellungen"
  }, {
    path: "/konto/linsen-abos",
    component: _0b6e7d80,
    name: "de-DE/auto-reorders"
  }, {
    path: "/kundendaten",
    redirect: "/konto/kundendaten"
  }, {
    path: "/kontaktoptionen",
    redirect: "/konto/kontaktoptionen"
  }, {
    path: "/adressbuch",
    redirect: "/konto/adressbuch"
  }, {
    path: "/pflegemittel-und-zubehor/p/macushield-vegetarian",
    redirect: "/pflegemittel-und-zubehor/p243/macushield-vegetarian"
  }, {
    path: "/anmeldung",
    component: _b33f986e,
    name: "de-DE/register"
  }, {
    path: "/zahlung",
    component: _18205bd0,
    name: "de-DE/payment"
  }, {
    path: "/konto/passwort-andern",
    component: _ccd8623c,
    name: "de-DE/change-password"
  }, {
    path: "/passwort-andern",
    component: _3d98be02,
    name: "de-DE/reset-password"
  }, {
    path: "/passwort-vergessen",
    component: _c4961b62,
    name: "de-DE/forgot-password"
  }, {
    path: "/versand",
    component: _34d0109a,
    name: "de-DE/delivery"
  }, {
    path: "/bestellbestaetigung",
    component: _4f90837a,
    name: "de-DE/order-confirmation"
  }, {
    path: "/impressum",
    component: _974a4b7e,
    name: "de-DE/document/108"
  }, {
    path: "/anmelden",
    redirect: "/login"
  }, {
    path: "/torishce-tageslinsen",
    component: _387cb151,
    name: "de-DE/brand/123"
  }, {
    path: "/kontaktlinsen/p557/dailies-total1-for-astigmatism",
    component: _1b085a39,
    name: "de-DE/product/557"
  }, {
    path: "/kontaktlinsen/p558/dailies-total1-for-astigmatism",
    component: _1b085a39,
    name: "de-DE/product/558"
  }, {
    path: "/meine-zahlungsmethoden",
    component: _6171e290,
    name: "de-DE/payment-management"
  }, {
    path: "/gebrauchsanweisung",
    component: _974a4b7e,
    name: "de-DE/document/159"
  }, {
    path: "/niederlassung-finden",
    component: _12d211f4,
    name: "de-DE/store-locator"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-max-1-day",
    component: _1b085a39,
    name: "de-DE/product/559"
  }, {
    path: "/kontaktlinsen/p560/acuvue-oasys-max-1-day-multifocal",
    component: _1b085a39,
    name: "de-DE/product/560"
  }, {
    path: "/b347/1-day-acuvue-moist",
    component: _387cb151,
    name: "de-DE/brand/347"
  }, {
    path: "/b/acuvue-oasys-max",
    component: _387cb151,
    name: "de-DE/brand/411"
  }, {
    path: "/bausch-lomb-ultra",
    component: _387cb151,
    name: "de-DE/brand/346"
  }, {
    path: "/b/acuvue-oasys-max-1-day",
    redirect: "/b/acuvue-oasys-max"
  }, {
    path: "/meine-zahlungsmethoden/linsen-abo/:id?",
    component: _2030f21f,
    name: "de-DE/payment-management-child"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-with-transitions",
    component: _1b085a39,
    name: "de-DE/product/561"
  }, {
    path: "/b/gunstige-kontaktlinsen",
    component: _387cb151,
    name: "de-DE/brand/348"
  }, {
    path: "/b348/gunstige-kontaktlinsen",
    redirect: "/b/gunstige-kontaktlinsen"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-complete-augentropfen-ohne-konservierungsstoffe",
    component: _1b085a39,
    name: "de-DE/product/553"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-hydratation-augentropfen-ohne-konservierungsstoffe",
    component: _1b085a39,
    name: "de-DE/product/554"
  }, {
    path: "/systane-hydratation-augentropfen-ohne-konservierungsstoffe",
    redirect: "/pflegemittel-und-zubehor/p/systane-hydratation-augentropfen-ohne-konservierungsstoffe"
  }, {
    path: "/pflegemittel-und-zubehor/p/systane-ultra-augentropfen-ohne-konservierungsstoffe",
    component: _1b085a39,
    name: "de-DE/product/555"
  }, {
    path: "/systane-complete-augentropfen-ohne-konservierungsstoffe",
    redirect: "/pflegemittel-und-zubehor/p/systane-complete-augentropfen-ohne-konservierungsstoffe"
  }, {
    path: "/systane-ultra-augentropfen-ohne-konservierungsstoffe",
    redirect: "/pflegemittel-und-zubehor/p/systane-ultra-augentropfen-ohne-konservierungsstoffe"
  }, {
    path: "/konto/linsen-abos/bestellungen/:id?",
    component: _74a9990f,
    name: "de-DE/auto-reorders-child"
  }, {
    path: "/sitemap",
    component: _974a4b7e,
    name: "de-DE/document/60"
  }, {
    path: "/kontaktlinsen/p/biotrue-oneday-for-presbyopia-90",
    component: _1b085a39,
    name: "de-DE/product/562"
  }, {
    path: "/dsar",
    component: _974a4b7e,
    name: "de-DE/document/161"
  }, {
    path: "/kontaktlinsen/p/biotrue-oneday-for-astigmatism-90",
    redirect: "/kontaktlinsen/p/biotrue-oneday-for-presbyopia-90"
  }, {
    path: "/pflegemittel-und-zubehor/p/biotrue-flight-pack-100ml",
    component: _1b085a39,
    name: "de-DE/product/563"
  }, {
    path: "/kontaktlinsen/p/acuvue-oasys-max-1-day-90",
    component: _1b085a39,
    name: "de-DE/product/564"
  }, {
    path: "/kontaktlinsen/p565/acuvue-oasys-max-1-day-multifocal-90",
    component: _1b085a39,
    name: "de-DE/product/565"
  }, {
    path: "/best-preis-garantie",
    component: _974a4b7e,
    name: "de-DE/document/133"
  }, {
    path: "/vielen-dank",
    component: _974a4b7e,
    name: "de-DE/document/162"
  }, {
    path: "/student-beans",
    component: _974a4b7e,
    name: "de-DE/document/106"
  }, {
    path: "/kontaktlinsen/p/total30-for-astigmatism",
    component: _1b085a39,
    name: "de-DE/product/566"
  }, {
    path: "/kontaktlinsen/p/total30-multifocal",
    component: _1b085a39,
    name: "de-DE/product/567"
  }, {
    path: "/kontaktlinsen/p/total30-for-astigmatism-6",
    component: _1b085a39,
    name: "de-DE/product/568"
  }, {
    path: "/kontaktlinsen/p/total30-multifocal-6",
    component: _1b085a39,
    name: "de-DE/product/569"
  }, {
    path: "/kontaktlinsen/p/everclear-elite",
    component: _1b085a39,
    name: "de-DE/product/570"
  }, {
    path: "/kontaktlinsen/p/everclear-elite-probepackung",
    component: _1b085a39,
    name: "de-DE/product/571"
  }, {
    path: "//kontaktlinsen/p/everclear-elite",
    redirect: "/kontaktlinsen/p/everclear-elite"
  }, {
    path: "/b6/1-day-acuvue",
    component: _387cb151,
    name: "es-ES/brand/6"
  }, {
    path: "/b1/acuvue",
    component: _387cb151,
    name: "es-ES/brand/1"
  }, {
    path: "/b7/acuvue-2",
    component: _387cb151,
    name: "es-ES/brand/7"
  }, {
    path: "/b16/acuvue-oasys",
    component: _387cb151,
    name: "es-ES/brand/16"
  }, {
    path: "/b3/alcon",
    component: _387cb151,
    name: "es-ES/brand/3"
  }, {
    path: "/b90/avaira",
    component: _387cb151,
    name: "es-ES/brand/90"
  }, {
    path: "/b2/bausch-lomb",
    component: _387cb151,
    name: "es-ES/brand/2"
  }, {
    path: "/b27/biofinity",
    component: _387cb151,
    name: "es-ES/brand/27"
  }, {
    path: "/b84/biomedics",
    component: _387cb151,
    name: "es-ES/brand/84"
  }, {
    path: "/b134/blink",
    component: _387cb151,
    name: "es-ES/brand/134"
  }, {
    path: "/b302/clariti",
    component: _387cb151,
    name: "es-ES/brand/302"
  }, {
    path: "/b4/coopervision",
    component: _387cb151,
    name: "es-ES/brand/4"
  }, {
    path: "/b149/coopervision-expressions",
    component: _387cb151,
    name: "es-ES/brand/149"
  }, {
    path: "/b62/dailies",
    component: _387cb151,
    name: "es-ES/brand/62"
  }, {
    path: "/b86/frequency",
    component: _387cb151,
    name: "es-ES/brand/86"
  }, {
    path: "/b92/freshlook",
    component: _387cb151,
    name: "es-ES/brand/92"
  }, {
    path: "/b280/hycosan",
    component: _387cb151,
    name: "es-ES/brand/280"
  }, {
    path: "/b279/optrex",
    component: _387cb151,
    name: "es-ES/brand/279"
  }, {
    path: "/b75/proclear",
    component: _387cb151,
    name: "es-ES/brand/75"
  }, {
    path: "/b76/purevision",
    component: _387cb151,
    name: "es-ES/brand/76"
  }, {
    path: "/b301/sauflon",
    component: _387cb151,
    name: "es-ES/brand/301"
  }, {
    path: "/b85/soflens",
    component: _387cb151,
    name: "es-ES/brand/85"
  }, {
    path: "/b110/lentillas-de-hidrogel-de-silicona",
    component: _387cb151,
    name: "es-ES/brand/110"
  }, {
    path: "/b129/lentes-de-contacto-azules",
    component: _387cb151,
    name: "es-ES/brand/129"
  }, {
    path: "/b130/lentillas-verdes",
    component: _387cb151,
    name: "es-ES/brand/130"
  }, {
    path: "/b141/lentes-de-contacto-marrones",
    component: _387cb151,
    name: "es-ES/brand/141"
  }, {
    path: "/b143/lentes-de-contacto-grises",
    component: _387cb151,
    name: "es-ES/brand/143"
  }, {
    path: "/b151/otros-colores",
    component: _387cb151,
    name: "es-ES/brand/151"
  }, {
    path: "/b158/alcon-cuidado-de-ojos",
    component: _387cb151,
    name: "es-ES/brand/158"
  }, {
    path: "/b170/systane",
    component: _387cb151,
    name: "es-ES/brand/170"
  }, {
    path: "/b185/solo-care-aqua",
    component: _387cb151,
    name: "es-ES/brand/185"
  }, {
    path: "/b232/lentillas-de-comfilcon",
    component: _387cb151,
    name: "es-ES/brand/232"
  }, {
    path: "/b317/lentes-de-contacto",
    component: _387cb151,
    name: "es-ES/brand/317"
  }, {
    path: "/c1/lentillas-diarias",
    component: _25921ca4,
    name: "es-ES/category/1"
  }, {
    path: "/c103/estuche-para-lentillas",
    component: _25921ca4,
    name: "es-ES/category/103"
  }, {
    path: "/c104/gotas-oftalmicas",
    component: _25921ca4,
    name: "es-ES/category/104"
  }, {
    path: "/c105/multifunciones-para-lentillas-blandas",
    component: _25921ca4,
    name: "es-ES/category/105"
  }, {
    path: "/c106/soluciones-de-peroxido-de-hidra-geno",
    component: _25921ca4,
    name: "es-ES/category/106"
  }, {
    path: "/c107/spray-para-los-ojos",
    component: _25921ca4,
    name: "es-ES/category/107"
  }, {
    path: "/c108/vitaminas-para-los-ojos",
    component: _25921ca4,
    name: "es-ES/category/108"
  }, {
    path: "/c109/liquidos-para-lentillas-rigidas",
    component: _25921ca4,
    name: "es-ES/category/109"
  }, {
    path: "/c112/liquidos-salinas-para-lentillas",
    component: _25921ca4,
    name: "es-ES/category/112"
  }, {
    path: "/c2/lentillas-quincenales",
    component: _25921ca4,
    name: "es-ES/category/2"
  }, {
    path: "/c3/lentillas-mensuales",
    component: _25921ca4,
    name: "es-ES/category/3"
  }, {
    path: "/c4/lentillas-uso-prolongado",
    component: _25921ca4,
    name: "es-ES/category/4"
  }, {
    path: "/c5/lentillas-toricas-para-astigmatismo",
    component: _25921ca4,
    name: "es-ES/category/5"
  }, {
    path: "/c6/lentillas-multifocales",
    component: _25921ca4,
    name: "es-ES/category/6"
  }, {
    path: "/c7/accesorios",
    component: _25921ca4,
    name: "es-ES/category/7"
  }, {
    path: "/b19/air-optix",
    component: _387cb151,
    name: "es-ES/brand/19"
  }, {
    path: "/b308/biotrue",
    component: _387cb151,
    name: "es-ES/brand/308"
  }, {
    path: "/b309/biotrue",
    component: _387cb151,
    name: "es-ES/brand/309"
  }, {
    path: "/esta-pagina-no-esta-disponible",
    component: _974a4b7e,
    name: "es-ES/document/44"
  }, {
    path: "/contactanos",
    component: _974a4b7e,
    name: "es-ES/document/2"
  }, {
    path: "/nuestros-servicios",
    component: _974a4b7e,
    name: "es-ES/document/1"
  }, {
    path: "/devoluciones-y-reembolsos",
    component: _974a4b7e,
    name: "es-ES/document/11"
  }, {
    path: "/preguntas-mas-frecuentes",
    component: _974a4b7e,
    name: "es-ES/document/6"
  }, {
    path: "/lentes-de-contacto/p1/soflens-daily-disposable",
    component: _1b085a39,
    name: "es-ES/product/1"
  }, {
    path: "/lentes-de-contacto/p2/soflens-daily-disposable-90",
    component: _1b085a39,
    name: "es-ES/product/2"
  }, {
    path: "/lentes-de-contacto/p5/dailies-aquacomfort-plus",
    component: _1b085a39,
    name: "es-ES/product/5"
  }, {
    path: "/lentes-de-contacto/p6/dailies-aquacomfort-plus-90",
    component: _1b085a39,
    name: "es-ES/product/6"
  }, {
    path: "/lentes-de-contacto/p7/focus-dailies-all-day-comfort-90",
    component: _1b085a39,
    name: "es-ES/product/7"
  }, {
    path: "/lentes-de-contacto/p8/focus-dailies-all-day-comfort",
    component: _1b085a39,
    name: "es-ES/product/8"
  }, {
    path: "/lentes-de-contacto/p13/1-day-acuvue-moist",
    component: _1b085a39,
    name: "es-ES/product/13"
  }, {
    path: "/lentes-de-contacto/p14/1-day-acuvue-moist-90",
    component: _1b085a39,
    name: "es-ES/product/14"
  }, {
    path: "/lentes-de-contacto/p16/purevision-multi-focal",
    component: _1b085a39,
    name: "es-ES/product/16"
  }, {
    path: "/lentes-de-contacto/p17/soflens-38",
    component: _1b085a39,
    name: "es-ES/product/17"
  }, {
    path: "/lentes-de-contacto/p18/soflens-59",
    component: _1b085a39,
    name: "es-ES/product/18"
  }, {
    path: "/lentes-de-contacto/p19/soflens-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/19"
  }, {
    path: "/lentes-de-contacto/p20/soflens-multi-focal",
    component: _1b085a39,
    name: "es-ES/product/20"
  }, {
    path: "/lentes-de-contacto/p22/air-optix-night-day-aqua",
    component: _1b085a39,
    name: "es-ES/product/22"
  }, {
    path: "/estuche-de-lentillas/p25/portalentillas",
    component: _1b085a39,
    name: "es-ES/product/25"
  }, {
    path: "/lentes-de-contacto/p30/frequency-55-aspheric",
    component: _1b085a39,
    name: "es-ES/product/30"
  }, {
    path: "/lentes-de-contacto/p32/frequency-xcel-toric-xr",
    component: _1b085a39,
    name: "es-ES/product/32"
  }, {
    path: "/lentes-de-contacto/p33/frequency-xcel-toric",
    component: _1b085a39,
    name: "es-ES/product/33"
  }, {
    path: "/lentes-de-contacto/p34/proclear",
    component: _1b085a39,
    name: "es-ES/product/34"
  }, {
    path: "/lentes-de-contacto/p35/proclear-toric",
    component: _1b085a39,
    name: "es-ES/product/35"
  }, {
    path: "/lentes-de-contacto/p38/acuvue-oasys",
    component: _1b085a39,
    name: "es-ES/product/38"
  }, {
    path: "/lentes-de-contacto/p39/biomedics-55-evolution",
    component: _1b085a39,
    name: "es-ES/product/39"
  }, {
    path: "/soluciones-multiuso/p42/renu-mps-liquido-multiusos",
    component: _1b085a39,
    name: "es-ES/product/42"
  }, {
    path: "/lentes-de-contacto/p44/soflens-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/44"
  }, {
    path: "/lentes-de-contacto/p45/soflens-multi-focal",
    component: _1b085a39,
    name: "es-ES/product/45"
  }, {
    path: "/soluciones-multiuso/p46/renu-mps-envase-practico",
    component: _1b085a39,
    name: "es-ES/product/46"
  }, {
    path: "/lentes-de-contacto/p48/air-optix-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/48"
  }, {
    path: "/lentes-de-contacto/p51/acuvue-2",
    component: _1b085a39,
    name: "es-ES/product/51"
  }, {
    path: "/lentes-de-contacto/p54/biomedics-toric",
    component: _1b085a39,
    name: "es-ES/product/54"
  }, {
    path: "/lentes-de-contacto/p57/biomedics-1-day-extra-toric",
    component: _1b085a39,
    name: "es-ES/product/57"
  }, {
    path: "/lentes-de-contacto/p60/proclear-1-day",
    component: _1b085a39,
    name: "es-ES/product/60"
  }, {
    path: "/lentes-de-contacto/p61/proclear-multifocal",
    component: _1b085a39,
    name: "es-ES/product/61"
  }, {
    path: "/lentes-de-contacto/p63/biofinity",
    component: _1b085a39,
    name: "es-ES/product/63"
  }, {
    path: "/soluciones-multiuso/p64/opti-free-replenish",
    component: _1b085a39,
    name: "es-ES/product/64"
  }, {
    path: "/soluciones-multiuso/p66/opti-free-replenish-duo-pack",
    component: _1b085a39,
    name: "es-ES/product/66"
  }, {
    path: "/gotas-para-los-ojos/p67/blink-contacts",
    component: _1b085a39,
    name: "es-ES/product/67"
  }, {
    path: "/p264/blink-refreshing-spray",
    component: _1b085a39,
    name: "es-ES/product/264"
  }, {
    path: "/gotas-para-los-ojos/p69/blink-refreshing",
    component: _1b085a39,
    name: "es-ES/product/69"
  }, {
    path: "/liquidos-salinas/p71/sensitive-eyes-plus-salina",
    component: _1b085a39,
    name: "es-ES/product/71"
  }, {
    path: "/soluciones-multiuso/p72/renu-mps-pack-de-viaje",
    component: _1b085a39,
    name: "es-ES/product/72"
  }, {
    path: "/lentes-de-contacto/p74/proclear-multifocal-toric",
    component: _1b085a39,
    name: "es-ES/product/74"
  }, {
    path: "/lentes-de-contacto/p76/1-day-acuvue-trueye",
    component: _1b085a39,
    name: "es-ES/product/76"
  }, {
    path: "/lentes-de-contacto/p77/air-optix-aqua",
    component: _1b085a39,
    name: "es-ES/product/77"
  }, {
    path: "/lentes-de-contacto/p79/proclear-multifocal-xr",
    component: _1b085a39,
    name: "es-ES/product/79"
  }, {
    path: "/lentes-de-contacto/p80/proclear-toric-xr",
    component: _1b085a39,
    name: "es-ES/product/80"
  }, {
    path: "/soluciones-multiuso/p82/renu-mps-mini",
    component: _1b085a39,
    name: "es-ES/product/82"
  }, {
    path: "/lentes-de-contacto/p83/proclear-multifocal",
    component: _1b085a39,
    name: "es-ES/product/83"
  }, {
    path: "/lentes-de-contacto/p84/proclear-1-day",
    component: _1b085a39,
    name: "es-ES/product/84"
  }, {
    path: "/lentes-de-contacto/p85/proclear-tailor-made-toric",
    component: _1b085a39,
    name: "es-ES/product/85"
  }, {
    path: "/lentes-de-contacto/p86/acuvue-oasys-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/86"
  }, {
    path: "/lentes-de-contacto/p87/biofinity-toric",
    component: _1b085a39,
    name: "es-ES/product/87"
  }, {
    path: "/lentes-de-contacto/p88/soflens-daily-disposable-toric",
    component: _1b085a39,
    name: "es-ES/product/88"
  }, {
    path: "/lentes-de-contacto/p90/air-optix-aqua-multifocal",
    component: _1b085a39,
    name: "es-ES/product/90"
  }, {
    path: "/lentes-de-contacto/p92/freshlook-colors",
    component: _1b085a39,
    name: "es-ES/product/92"
  }, {
    path: "/lentes-de-contacto/p93/freshlook-colorblends",
    component: _1b085a39,
    name: "es-ES/product/93"
  }, {
    path: "/lentes-de-contacto/p94/freshlook-dimensions",
    component: _1b085a39,
    name: "es-ES/product/94"
  }, {
    path: "/lentes-de-contacto/p95/freshlook-dimensions-6",
    component: _1b085a39,
    name: "es-ES/product/95"
  }, {
    path: "/lentes-de-contacto/p96/freshlook-one-day",
    component: _1b085a39,
    name: "es-ES/product/96"
  }, {
    path: "/lentes-de-contacto/p97/soflens-natural-colors",
    component: _1b085a39,
    name: "es-ES/product/97"
  }, {
    path: "/lentes-de-contacto/p101/expressions-colour",
    component: _1b085a39,
    name: "es-ES/product/101"
  }, {
    path: "/lentes-de-contacto/p104/1-day-acuvue-trueye",
    component: _1b085a39,
    name: "es-ES/product/104"
  }, {
    path: "/lentes-de-contacto/p106/1-day-acuvue-moist-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/106"
  }, {
    path: "/soluciones-multiuso/p109/biotrue",
    component: _1b085a39,
    name: "es-ES/product/109"
  }, {
    path: "/soluciones-multiuso/p113/opti-free-express",
    component: _1b085a39,
    name: "es-ES/product/113"
  }, {
    path: "/soluciones-multiuso/p114/opti-free-express-duo-pack",
    component: _1b085a39,
    name: "es-ES/product/114"
  }, {
    path: "/soluciones-multiuso/p115/opti-free-replenish-pack-de-viaje",
    component: _1b085a39,
    name: "es-ES/product/115"
  }, {
    path: "/gotas-para-los-ojos/p117/systane",
    component: _1b085a39,
    name: "es-ES/product/117"
  }, {
    path: "/gotas-para-los-ojos/p118/systane-monodosis",
    component: _1b085a39,
    name: "es-ES/product/118"
  }, {
    path: "/gotas-para-los-ojos/p119/blink-n-clean",
    component: _1b085a39,
    name: "es-ES/product/119"
  }, {
    path: "/gotas-para-los-ojos/p120/blink-intensive-tears",
    component: _1b085a39,
    name: "es-ES/product/120"
  }, {
    path: "/gotas-para-los-ojos/p121/blink-intensive-tears-monodosis",
    component: _1b085a39,
    name: "es-ES/product/121"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p125/complete-revitalens",
    component: _1b085a39,
    name: "es-ES/product/125"
  }, {
    path: "/p256/optrex-intensive",
    component: _1b085a39,
    name: "es-ES/product/256"
  }, {
    path: "/liquidos-salinas/p127/lens-plus-ocupure",
    component: _1b085a39,
    name: "es-ES/product/127"
  }, {
    path: "/soluciones-de-peroxido/p128/oxysept",
    component: _1b085a39,
    name: "es-ES/product/128"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p129/total-care-wetting",
    component: _1b085a39,
    name: "es-ES/product/129"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p130/total-care-cleaner",
    component: _1b085a39,
    name: "es-ES/product/130"
  }, {
    path: "/otros-accesorios/p131/ultrazyme-universal",
    component: _1b085a39,
    name: "es-ES/product/131"
  }, {
    path: "/soluciones-multiuso/p132/biotrue-duo-pack",
    component: _1b085a39,
    name: "es-ES/product/132"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p133/boston-cleaner",
    component: _1b085a39,
    name: "es-ES/product/133"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p134/boston-conditioning-solution",
    component: _1b085a39,
    name: "es-ES/product/134"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p135/boston-simplus-multi-action-solution",
    component: _1b085a39,
    name: "es-ES/product/135"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p136/boston-simplus-pack-de-viaje",
    component: _1b085a39,
    name: "es-ES/product/136"
  }, {
    path: "/soluciones-multiuso/p139/renu-multi-plus-semanal",
    component: _1b085a39,
    name: "es-ES/product/139"
  }, {
    path: "/soluciones-multiuso/p140/renu-multi-plus",
    component: _1b085a39,
    name: "es-ES/product/140"
  }, {
    path: "/soluciones-multiuso/p141/renu-multi-plus-pack-triple",
    component: _1b085a39,
    name: "es-ES/product/141"
  }, {
    path: "/soluciones-multiuso/p142/renu-multi-purpose-solution-pack-triple",
    component: _1b085a39,
    name: "es-ES/product/142"
  }, {
    path: "/soluciones-multiuso/p144/solocare-aqua",
    component: _1b085a39,
    name: "es-ES/product/144"
  }, {
    path: "/soluciones-multiuso/p145/solocare-aqua-twin-pack",
    component: _1b085a39,
    name: "es-ES/product/145"
  }, {
    path: "/soluciones-multiuso/p146/options-multi",
    component: _1b085a39,
    name: "es-ES/product/146"
  }, {
    path: "/vitaminas-para-los-ojos/p148/ocuvite-complete",
    component: _1b085a39,
    name: "es-ES/product/148"
  }, {
    path: "/lentes-de-contacto/p149/purevision-2-hd",
    component: _1b085a39,
    name: "es-ES/product/149"
  }, {
    path: "/lentes-de-contacto/p150/purevision-2-hd-6",
    component: _1b085a39,
    name: "es-ES/product/150"
  }, {
    path: "/lentes-de-contacto/p152/acuvue-oasys-for-presbyopia",
    component: _1b085a39,
    name: "es-ES/product/152"
  }, {
    path: "/soluciones-multiuso/p190/biotrue-pack-triple",
    component: _1b085a39,
    name: "es-ES/product/190"
  }, {
    path: "/lentes-de-contacto/p191/biofinity-multifocal",
    component: _1b085a39,
    name: "es-ES/product/191"
  }, {
    path: "/lentes-de-contacto/p192/purevision-2-hd-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/192"
  }, {
    path: "/soluciones-multiuso/p193/biotrue-pack-de-viaje",
    component: _1b085a39,
    name: "es-ES/product/193"
  }, {
    path: "/soluciones-multiuso/p194/opti-free-puremoist",
    component: _1b085a39,
    name: "es-ES/product/194"
  }, {
    path: "/soluciones-multiuso/p195/opti-free-puremoist-duo-pack",
    component: _1b085a39,
    name: "es-ES/product/195"
  }, {
    path: "/lentes-de-contacto/p196/freshlook-illuminate",
    component: _1b085a39,
    name: "es-ES/product/196"
  }, {
    path: "/lentes-de-contacto/p197/purevision-2-hd-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/197"
  }, {
    path: "/lentes-de-contacto/p198/proclear-1-day-multifocal",
    component: _1b085a39,
    name: "es-ES/product/198"
  }, {
    path: "/lentes-de-contacto/p200/biomedics-1-day-extra",
    component: _1b085a39,
    name: "es-ES/product/200"
  }, {
    path: "/lentes-de-contacto/p201/biomedics-1-day-extra-90",
    component: _1b085a39,
    name: "es-ES/product/201"
  }, {
    path: "/gotas-para-los-ojos/p202/blink-intensive-tears-plus",
    component: _1b085a39,
    name: "es-ES/product/202"
  }, {
    path: "/otros-accesorios/p204/spray-optrex-actimist-doble-accion-para-ojos-secos-y-cansados",
    component: _1b085a39,
    name: "es-ES/product/204"
  }, {
    path: "/p248/clinitas-soothe-monodosis",
    component: _1b085a39,
    name: "es-ES/product/248"
  }, {
    path: "/p249/blink-eye-lid-toallitas",
    component: _1b085a39,
    name: "es-ES/product/249"
  }, {
    path: "/gotas-para-los-ojos/p206/hycosan",
    component: _1b085a39,
    name: "es-ES/product/206"
  }, {
    path: "/gotas-para-los-ojos/p207/hycosan-extra",
    component: _1b085a39,
    name: "es-ES/product/207"
  }, {
    path: "/estuche-de-lentillas/p208/portalentillas-barrel",
    component: _1b085a39,
    name: "es-ES/product/208"
  }, {
    path: "/gotas-para-los-ojos/p209/systane-ultra",
    component: _1b085a39,
    name: "es-ES/product/209"
  }, {
    path: "/lentes-de-contacto/p213/air-optix-aqua",
    component: _1b085a39,
    name: "es-ES/product/213"
  }, {
    path: "/lentes-de-contacto/p214/air-optix-night-day-aqua",
    component: _1b085a39,
    name: "es-ES/product/214"
  }, {
    path: "/soluciones-multiuso/p215/biotrue-solucion-unica",
    component: _1b085a39,
    name: "es-ES/product/215"
  }, {
    path: "/lentes-de-contacto/p217/clariti-1-day-multifocal",
    component: _1b085a39,
    name: "es-ES/product/217"
  }, {
    path: "/lentes-de-contacto/p218/clariti-1-day-toric",
    component: _1b085a39,
    name: "es-ES/product/218"
  }, {
    path: "/lentes-de-contacto/p219/clariti-1-day",
    component: _1b085a39,
    name: "es-ES/product/219"
  }, {
    path: "/lentes-de-contacto/p221/clariti-monthly-multifocal",
    component: _1b085a39,
    name: "es-ES/product/221"
  }, {
    path: "/lentes-de-contacto/p222/clariti-monthly-toric",
    component: _1b085a39,
    name: "es-ES/product/222"
  }, {
    path: "/lentes-de-contacto/p225/dailies-total-1",
    component: _1b085a39,
    name: "es-ES/product/225"
  }, {
    path: "/lentes-de-contacto/p226/biotrue-oneday",
    component: _1b085a39,
    name: "es-ES/product/226"
  }, {
    path: "/lentes-de-contacto/p227/biotrue-oneday-90",
    component: _1b085a39,
    name: "es-ES/product/227"
  }, {
    path: "/lentes-de-contacto/p228/purevision-2-hd-for-presbyopia",
    component: _1b085a39,
    name: "es-ES/product/228"
  }, {
    path: "/lentes-de-contacto/p229/purevision-2-hd-for-presbyopia-6",
    component: _1b085a39,
    name: "es-ES/product/229"
  }, {
    path: "/lentes-de-contacto/p230/myday",
    component: _1b085a39,
    name: "es-ES/product/230"
  }, {
    path: "/lentes-de-contacto/p231/biofinity-xr",
    component: _1b085a39,
    name: "es-ES/product/231"
  }, {
    path: "/lentes-de-contacto/p232/air-optix-colors",
    component: _1b085a39,
    name: "es-ES/product/232"
  }, {
    path: "/lentes-de-contacto/p233/dailies-aquacomfort-plus-toric",
    component: _1b085a39,
    name: "es-ES/product/233"
  }, {
    path: "/lentes-de-contacto/p234/dailies-aquacomfort-plus-multifocal",
    component: _1b085a39,
    name: "es-ES/product/234"
  }, {
    path: "/lentes-de-contacto/p235/biotrue-oneday-for-presbyopia",
    component: _1b085a39,
    name: "es-ES/product/235"
  }, {
    path: "/otros-accesorios/p237/thera-pearl",
    component: _1b085a39,
    name: "es-ES/product/237"
  }, {
    path: "/vitaminas-para-los-ojos/p241/macushield",
    component: _1b085a39,
    name: "es-ES/product/241"
  }, {
    path: "/vitaminas-para-los-ojos/p242/macushield-90",
    component: _1b085a39,
    name: "es-ES/product/242"
  }, {
    path: "/vitaminas-para-los-ojos/p243/macushield-vegetarian",
    component: _1b085a39,
    name: "es-ES/product/243"
  }, {
    path: "/vitaminas-para-los-ojos/p244/macushield-gold",
    component: _1b085a39,
    name: "es-ES/product/244"
  }, {
    path: "/otros-accesorios/p245/blephasol",
    component: _1b085a39,
    name: "es-ES/product/245"
  }, {
    path: "/otros-accesorios/p246/blephaclean",
    component: _1b085a39,
    name: "es-ES/product/246"
  }, {
    path: "/otros-accesorios/p247/blephagel",
    component: _1b085a39,
    name: "es-ES/product/247"
  }, {
    path: "/lentes-de-contacto/p250/1-day-acuvue-moist-multifocal",
    component: _1b085a39,
    name: "es-ES/product/250"
  }, {
    path: "/lentes-de-contacto/p251/dailies-total-1",
    component: _1b085a39,
    name: "es-ES/product/251"
  }, {
    path: "/lentes-de-contacto/p252/myday",
    component: _1b085a39,
    name: "es-ES/product/252"
  }, {
    path: "/otros-accesorios/p255/biotrue-daily-eyelid-toallitas",
    component: _1b085a39,
    name: "es-ES/product/255"
  }, {
    path: "/soluciones-de-peroxido/p258/aosept-plus-hydraglyde",
    component: _1b085a39,
    name: "es-ES/product/258"
  }, {
    path: "/soluciones-de-peroxido/p259/aosept-plus-hydraglyde-duo-pack",
    component: _1b085a39,
    name: "es-ES/product/259"
  }, {
    path: "/gotas-para-los-ojos/p260/systane-ultra-monodosis",
    component: _1b085a39,
    name: "es-ES/product/260"
  }, {
    path: "/gotas-para-los-ojos/p261/systane-balance",
    component: _1b085a39,
    name: "es-ES/product/261"
  }, {
    path: "/gotas-para-los-ojos/p262/systane-gel",
    component: _1b085a39,
    name: "es-ES/product/262"
  }, {
    path: "/lentes-de-contacto/p450/clariti-elite",
    component: _1b085a39,
    name: "es-ES/product/450"
  }, {
    path: "/lentes-de-contacto/p451/proclear",
    component: _1b085a39,
    name: "es-ES/product/451"
  }, {
    path: "/lentes-de-contacto/p452/biofinity",
    component: _1b085a39,
    name: "es-ES/product/452"
  }, {
    path: "/lentes-de-contacto/p453/biofinity-toric",
    component: _1b085a39,
    name: "es-ES/product/453"
  }, {
    path: "/lentes-de-contacto/p454/biofinity-multifocal",
    component: _1b085a39,
    name: "es-ES/product/454"
  }, {
    path: "/lentes-de-contacto/p455/air-optix-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/455"
  }, {
    path: "/lentes-de-contacto/p456/air-optix-aqua-multifocal",
    component: _1b085a39,
    name: "es-ES/product/456"
  }, {
    path: "/lentes-de-contacto/p457/dailies-total-1-multifocal",
    component: _1b085a39,
    name: "es-ES/product/457"
  }, {
    path: "/lentes-de-contacto/p458/acuvue-oasys-1-day",
    component: _1b085a39,
    name: "es-ES/product/458"
  }, {
    path: "/lentes-de-contacto/p459/acuvue-oasys-1-day-90",
    component: _1b085a39,
    name: "es-ES/product/459"
  }, {
    path: "/lentes-de-contacto/p460/air-optix-plus-hydraglyde",
    component: _1b085a39,
    name: "es-ES/product/460"
  }, {
    path: "/lentes-de-contacto/p466/avaira-vitality",
    component: _1b085a39,
    name: "es-ES/product/466"
  }, {
    path: "/lentes-de-contacto/p467/biofinity-energys",
    component: _1b085a39,
    name: "es-ES/product/467"
  }, {
    path: "/lentes-de-contacto/p468/acuvue-oasys-1-day-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/468"
  }, {
    path: "/lentes-de-contacto/p469/avaira-vitality-6",
    component: _1b085a39,
    name: "es-ES/product/469"
  }, {
    path: "/lentes-de-contacto/p470/myday-toric",
    component: _1b085a39,
    name: "es-ES/product/470"
  }, {
    path: "/lentes-de-contacto/p471/biofinity-xr-toric",
    component: _1b085a39,
    name: "es-ES/product/471"
  }, {
    path: "/soluciones-multiuso/p472/hy-care",
    component: _1b085a39,
    name: "es-ES/product/472"
  }, {
    path: "/soluciones-multiuso/p473/hy-care-pack-triple",
    component: _1b085a39,
    name: "es-ES/product/473"
  }, {
    path: "/lentes-de-contacto/p474/acuvue-vita",
    component: _1b085a39,
    name: "es-ES/product/474"
  }, {
    path: "/lentes-de-contacto/p475/bausch-lomb-ultra-6",
    component: _1b085a39,
    name: "es-ES/product/475"
  }, {
    path: "/lentes-de-contacto/p476/bausch-lomb-ultra",
    component: _1b085a39,
    name: "es-ES/product/476"
  }, {
    path: "/lentes-de-contacto/p477/bausch-lomb-ultra-presbyopia",
    component: _1b085a39,
    name: "es-ES/product/477"
  }, {
    path: "/lentes-de-contacto/p478/bausch-lomb-ultra-presbyopia-6",
    component: _1b085a39,
    name: "es-ES/product/478"
  }, {
    path: "/lentes-de-contacto/p479/avaira-vitality-toric",
    component: _1b085a39,
    name: "es-ES/product/479"
  }, {
    path: "/lentes-de-contacto/p480/biotrue-oneday-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/480"
  }, {
    path: "/lentes-de-contacto/p481/biofinity-energys",
    component: _1b085a39,
    name: "es-ES/product/481"
  }, {
    path: "/lentes-de-contacto/p482/air-optix-plus-hydraglyde-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/482"
  }, {
    path: "/lentes-de-contacto/p483/live-1-day",
    component: _1b085a39,
    name: "es-ES/product/483"
  }, {
    path: "/lentes-de-contacto/p484/air-optix-plus-hydraglyde-multifocal",
    component: _1b085a39,
    name: "es-ES/product/484"
  }, {
    path: "/politica-de-privacidad",
    component: _974a4b7e,
    name: "es-ES/document/9"
  }, {
    path: "/c7/liquidos-para-lentillas",
    redirect: "/c7/accesorios"
  }, {
    path: "/c8/lentillas-colores",
    redirect: "/b345/lentillas-de-colores"
  }, {
    path: "/terminos-y-condiciones",
    component: _974a4b7e,
    name: "es-ES/document/8"
  }, {
    path: "/informacion-de-envio",
    component: _974a4b7e,
    name: "es-ES/document/10"
  }, {
    path: "/soluciones-multiuso/p215/biotrue-pack-de-viaje",
    redirect: "/soluciones-multiuso/p215/biotrue-solucion-unica"
  }, {
    path: "/utiles-recordatorios",
    component: _974a4b7e,
    name: "es-ES/document/15"
  }, {
    path: "/uso-de-cookies",
    component: _974a4b7e,
    name: "es-ES/document/124"
  }, {
    path: "/cuenta/direcciones",
    component: _89a7e070,
    name: "es-ES/address-book"
  }, {
    path: "/cambiar-contrasena",
    component: _3d98be02,
    name: "es-ES/reset-password"
  }, {
    path: "/cuenta/preferencias-de-comunicacion",
    component: _08e7896a,
    name: "es-ES/communication-preferences"
  }, {
    path: "/cuenta",
    component: _b0dd6f12,
    name: "es-ES/account"
  }, {
    path: "/cuenta/pedidos",
    component: _829d8442,
    name: "es-ES/orders"
  }, {
    path: "/cuenta/tus-datos",
    component: _1502f9db,
    name: "es-ES/profile"
  }, {
    path: "/cambiar-contrasen",
    redirect: "/cambiar-contrasena"
  }, {
    path: "/b309/myday",
    redirect: "/b309/biotrue"
  }, {
    path: "/b310/myday",
    component: _387cb151,
    name: "es-ES/brand/310"
  }, {
    path: "/b314/macushield",
    component: _387cb151,
    name: "es-ES/brand/314"
  }, {
    path: "/b348/lentillas-baratas",
    component: _387cb151,
    name: "es-ES/brand/348"
  }, {
    path: "/comprar-lentillas-online",
    component: _974a4b7e,
    name: "es-ES/document/145"
  }, {
    path: "/covid-19-informacion-para-los-clientes",
    component: _974a4b7e,
    name: "es-ES/document/148"
  }, {
    path: "/covid-19-informacia-n-para-los-clientes",
    redirect: "/covid-19-informacion-para-los-clientes"
  }, {
    path: "/b123/lentillas-toricas-diarias",
    component: _387cb151,
    name: "es-ES/brand/123"
  }, {
    path: "/b330/lentillas-progresivas-diarias",
    component: _387cb151,
    name: "es-ES/brand/330"
  }, {
    path: "/b96/lentillas-colores-graduadas",
    component: _387cb151,
    name: "es-ES/brand/96"
  }, {
    path: "/como-leer-y-entender-receta-lentillas",
    component: _974a4b7e,
    name: "es-ES/document/127"
  }, {
    path: "/como_leer_y_entender_prescripcia-n_lentillas",
    redirect: "/como-leer-y-entender-receta-lentillas"
  }, {
    path: "/terminos-y-condiciones-de-la-encuesta-de-frecuencia-de-uso-durante-el-covid-19",
    component: _974a4b7e,
    name: "es-ES/document/157"
  }, {
    path: "/b343/envio-gratuito",
    component: _387cb151,
    name: "es-ES/brand/343"
  }, {
    path: "/b345/lentillas-de-colores",
    component: _387cb151,
    name: "es-ES/brand/345"
  }, {
    path: "/como_leer_y_entender_receta_lentillas",
    redirect: "/como-leer-y-entender-receta-lentillas"
  }, {
    path: "/lentillas-de-colores",
    redirect: "/b345/lentillas-de-colores"
  }, {
    path: "/345/lentillas-de-colores",
    redirect: "/b345/lentillas-de-colores"
  }, {
    path: "/b337/cual-es-el-precio-de-las-lentes",
    component: _387cb151,
    name: "es-ES/brand/337"
  }, {
    path: "/modalidades-de-pago",
    component: _974a4b7e,
    name: "es-ES/document/132"
  }, {
    path: "/b337/cua_l-es-el-precio-de-las-lentes",
    redirect: "/b337/cual-es-el-precio-de-las-lentes"
  }, {
    path: "/b333/mejores-lentes-de-contacto",
    component: _387cb151,
    name: "es-ES/brand/333"
  }, {
    path: "/ta-rminos-y-condiciones-de-la-encuesta-de-frecuencia-de-uso-durante-el-covid-19",
    redirect: "/terminos-y-condiciones-de-la-encuesta-de-frecuencia-de-uso-durante-el-covid-19"
  }, {
    path: "/como-ponerse-y-quitarse-las-lentillas",
    component: _974a4b7e,
    name: "es-ES/document/134"
  }, {
    path: "/b173/soluciones-multiusos-biotrue",
    component: _387cb151,
    name: "es-ES/brand/173"
  }, {
    path: "/soluciones-multiusos-biotrue",
    redirect: "/b173/soluciones-multiusos-biotrue"
  }, {
    path: "/b342/envio-gratuito",
    redirect: "/b343/envio-gratuito"
  }, {
    path: "/lentes-de-contacto/p488/miru-1day-flat",
    component: _1b085a39,
    name: "es-ES/product/488"
  }, {
    path: "/lentes-de-contacto/p489/miru-1month",
    component: _1b085a39,
    name: "es-ES/product/489"
  }, {
    path: "/lentes-de-contacto/p490/miru-1month-toric",
    component: _1b085a39,
    name: "es-ES/product/490"
  }, {
    path: "/lentes-de-contacto/p491/miru-1month-multifocal",
    component: _1b085a39,
    name: "es-ES/product/491"
  }, {
    path: "/b354/miru",
    component: _387cb151,
    name: "es-ES/brand/396"
  }, {
    path: "/b359/menicon",
    component: _387cb151,
    name: "es-ES/brand/394"
  }, {
    path: "/lentes-de-contacto/p491/miru-1month_multifocal",
    redirect: "/lentes-de-contacto/p491/miru-1month-multifocal"
  }, {
    path: "/lentes-de-contacto/p490/miru-1month_toric",
    redirect: "/lentes-de-contacto/p490/miru-1month-toric"
  }, {
    path: "/uk-efs-ltd-politica-fiscal",
    component: _974a4b7e,
    name: "es-ES/document/115"
  }, {
    path: "/uk-efs-ltd-pola-tica-fiscal",
    redirect: "/uk-efs-ltd-politica-fiscal"
  }, {
    path: "/b315/renu",
    component: _387cb151,
    name: "es-ES/brand/315"
  }, {
    path: "/b316/opti-free",
    component: _387cb151,
    name: "es-ES/brand/316"
  }, {
    path: "/como-limpiar-las-lentes-de-contacto",
    component: _974a4b7e,
    name: "es-ES/document/139"
  }, {
    path: "/a-que-edad-puedo-usar-lentes-de-contacto",
    component: _974a4b7e,
    name: "es-ES/document/136"
  }, {
    path: "/lentes-de-contacto/p492/precision-1",
    component: _1b085a39,
    name: "es-ES/product/492"
  }, {
    path: "/lentes-de-contacto/p494/precision-1-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/494"
  }, {
    path: "/lentes-de-contacto/p493/precision-1",
    component: _1b085a39,
    name: "es-ES/product/493"
  }, {
    path: "/lentes-de-contacto/p495/precision-1-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/495"
  }, {
    path: "/lentilles-de-colores/p496/angelic-white-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "es-ES/product/496"
  }, {
    path: "/lentillas-de-colores/p498/red-vampire-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "es-ES/product/498"
  }, {
    path: "/lentillas-de-colores/p497/black-out-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "es-ES/product/497"
  }, {
    path: "/lentillas-de-colores/p499/white-out-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "es-ES/product/499"
  }, {
    path: "/lentillas-de-colores/p500/zombie-yellow-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "es-ES/product/500"
  }, {
    path: "/lentillas-de-colores/p501/blue-walker-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "es-ES/product/501"
  }, {
    path: "/lentillas-de-colores/p504/angelic-red-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/504"
  }, {
    path: "/lentillas-de-colores/p505/banshee-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/505"
  }, {
    path: "/lentillas-de-colores/p506/black-wolf-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/506"
  }, {
    path: "/lentillas-de-colores/p507/lestat-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/507"
  }, {
    path: "/lentillas-de-colores/p508/manson-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/508"
  }, {
    path: "/lentillas-de-colores/p509/red-cat-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/509"
  }, {
    path: "/lentillas-de-colores/p510/red-vampire-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/510"
  }, {
    path: "/lentillas-de-colores/p511/smiley-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/511"
  }, {
    path: "/lentillas-de-colores/p512/white-out-clearcolor-phantom",
    component: _1b085a39,
    name: "es-ES/product/512"
  }, {
    path: "/lentillas-de-colores-/p504/angelic-red-clearcolor-phantom",
    redirect: "/lentillas-de-colores/p504/angelic-red-clearcolor-phantom"
  }, {
    path: "/lentillas-de-colores-/p505/banshee-clearcolor-phantom",
    redirect: "/lentillas-de-colores/p505/banshee-clearcolor-phantom"
  }, {
    path: "/b363/precision-1",
    component: _387cb151,
    name: "es-ES/brand/404"
  }, {
    path: "/soluciones-multiuso/p513/all-in-one-light-pack-de-viaje-100ml",
    component: _1b085a39,
    name: "es-ES/product/513"
  }, {
    path: "/soluciones-multiuso/p514/all-in-one-light-250ml",
    component: _1b085a39,
    name: "es-ES/product/514"
  }, {
    path: "/soluciones-multiuso/p515/all-in-one-light-360ml",
    component: _1b085a39,
    name: "es-ES/product/515"
  }, {
    path: "/soluciones-multiuso/p516/all-in-one-light-3x250ml",
    component: _1b085a39,
    name: "es-ES/product/516"
  }, {
    path: "/soluciones-multiuso/p517/hy-care-pack-de-viaje-100ml",
    component: _1b085a39,
    name: "es-ES/product/517"
  }, {
    path: "/soluciones-de-peroxido/p518/refine-one-step-pack-de-viaje-100ml",
    component: _1b085a39,
    name: "es-ES/product/518"
  }, {
    path: "/soluciones-de-peroxido/p519/refine-one-step-250ml",
    component: _1b085a39,
    name: "es-ES/product/519"
  }, {
    path: "/soluciones-de-peroxido/p520/refine-one-step-360ml",
    component: _1b085a39,
    name: "es-ES/product/520"
  }, {
    path: "/soluciones-de-peroxido/p521/refine-one-step-3x250ml",
    component: _1b085a39,
    name: "es-ES/product/521"
  }, {
    path: "/liquidos-salinas/p522/saline-pack-de-viaje-100ml",
    component: _1b085a39,
    name: "es-ES/product/522"
  }, {
    path: "/liquidos-salinas/p523/saline-360ml",
    component: _1b085a39,
    name: "es-ES/product/523"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p524/delta-cleaner-20ml",
    component: _1b085a39,
    name: "es-ES/product/524"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p525/delta-plus-pack-de-viaje-100ml",
    component: _1b085a39,
    name: "es-ES/product/525"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p526/delta-multi-pack",
    component: _1b085a39,
    name: "es-ES/product/526"
  }, {
    path: "/gotas-para-los-ojos/p527/comfort-drops-20ml",
    component: _1b085a39,
    name: "es-ES/product/527"
  }, {
    path: "/soluciones-multiuso/p513/all-in-one-light-travel-pack-100ml",
    redirect: "/soluciones-multiuso/p513/all-in-one-light-pack-de-viaje-100ml"
  }, {
    path: "/soluciones-multiuso/p517/hy-care-travel-pack-100ml",
    redirect: "/soluciones-multiuso/p517/hy-care-pack-de-viaje-100ml"
  }, {
    path: "/soluciones-de-peroxido/p518/refine-one-step-travel-pack-100ml",
    redirect: "/soluciones-de-peroxido/p518/refine-one-step-pack-de-viaje-100ml"
  }, {
    path: "/liquidos-salinas/p522/preservative-free-saline-travel-pack-100ml",
    redirect: "/liquidos-salinas/p522/saline-pack-de-viaje-100ml"
  }, {
    path: "/soluciones-para-lentillas-rigidas/p525/delta-plus-travel-pack-100ml",
    redirect: "/soluciones-para-lentillas-rigidas/p525/delta-plus-pack-de-viaje-100ml"
  }, {
    path: "/gotas-para-los-ojos/p527/comf-drops-20ml",
    redirect: "/gotas-para-los-ojos/p527/comfort-drops-20ml"
  }, {
    path: "/liquidos-salinas/p523/preservative-free-saline-360ml",
    redirect: "/liquidos-salinas/p523/saline-360ml"
  }, {
    path: "/liquidos-salinas/p522/preservative-free-saline-pack-de-viaje-100ml",
    redirect: "/liquidos-salinas/p522/saline-pack-de-viaje-100ml"
  }, {
    path: "/liquidos-salinas/p523/saline-solution-360ml",
    redirect: "/liquidos-salinas/p523/saline-360ml"
  }, {
    path: "/b406/clearlab",
    component: _387cb151,
    name: "es-ES/brand/406"
  }, {
    path: "/b407/clearcolor-phantom",
    component: _387cb151,
    name: "es-ES/brand/407"
  }, {
    path: "/b408/clearcolor-phantom-1-day",
    component: _387cb151,
    name: "es-ES/brand/408"
  }, {
    path: "/soluciones-multiuso/p516/all-in-one-light-2x250ml",
    redirect: "/soluciones-multiuso/p516/all-in-one-light-3x250ml"
  }, {
    path: "/lentilles-de-colores/p496/angelic-white-clearcolor-1day-phantom",
    redirect: "/lentilles-de-colores/p496/angelic-white-clearcolor-1-day-phantom"
  }, {
    path: "/lentillas-de-colores/p497/black-out-clearcolor-1day-phantom",
    redirect: "/lentillas-de-colores/p497/black-out-clearcolor-1-day-phantom"
  }, {
    path: "//lentillas-de-colores/p498/red-vampire-clearcolor-1day-phantom",
    redirect: "/lentillas-de-colores/p498/red-vampire-clearcolor-1-day-phantom"
  }, {
    path: "/lentillas-de-colores/p499/white-out-clearcolor-1day-phantom",
    redirect: "/lentillas-de-colores/p499/white-out-clearcolor-1-day-phantom"
  }, {
    path: "/lentillas-de-colores/p500/zombie-yellow-clearcolor-1day-phantom",
    redirect: "/lentillas-de-colores/p500/zombie-yellow-clearcolor-1-day-phantom"
  }, {
    path: "/lentillas-de-colores/p501/blue-walker-clearcolor-1day-phantom",
    redirect: "/lentillas-de-colores/p501/blue-walker-clearcolor-1-day-phantom"
  }, {
    path: "//lentillas-de-colores/p498/red-vampire-clearcolor-1-day-phantom",
    redirect: "/lentillas-de-colores/p498/red-vampire-clearcolor-1-day-phantom"
  }, {
    path: "/b211/lentillas-graduadas-de-halloween",
    component: _387cb151,
    name: "es-ES/brand/211"
  }, {
    path: "/b405/lentillas-halloween",
    component: _387cb151,
    name: "es-ES/brand/405"
  }, {
    path: "/b409/lentillas-de-color-a-la-moda",
    component: _387cb151,
    name: "es-ES/brand/409"
  }, {
    path: "/lentes-de-contacto/p528/air-optix-plus-hdraglyde-6",
    component: _1b085a39,
    name: "es-ES/product/528"
  }, {
    path: "/lentes-de-contacto/p529/air-optix-plus-hydraglyde-for-astigmatism-6",
    component: _1b085a39,
    name: "es-ES/product/529"
  }, {
    path: "/lentes-de-contacto/p530/air-optix-plus-hydraglyde-multifocal-6",
    component: _1b085a39,
    name: "es-ES/product/530"
  }, {
    path: "/soluciones-multiuso/p485/acuvue-revitalens-300ml",
    component: _1b085a39,
    name: "es-ES/product/485"
  }, {
    path: "/soluciones-multiuso/p486/acuvue-revitalens-duo-pack",
    component: _1b085a39,
    name: "es-ES/product/486"
  }, {
    path: "/b62/lentillas-diarias",
    redirect: "/b62/dailies"
  }, {
    path: "/lentillas-de-colores/p531/light-blue-clearcolor-1-day",
    component: _1b085a39,
    name: "es-ES/product/531"
  }, {
    path: "/lentillas-de-colores/p532/grey-clearcolor-1-day",
    component: _1b085a39,
    name: "es-ES/product/532"
  }, {
    path: "/lentillas-de-colores/p533/hazel-clearcolor-1-day",
    component: _1b085a39,
    name: "es-ES/product/533"
  }, {
    path: "/lentillas-de-colores/p534/blue-clearcolor-1-day",
    component: _1b085a39,
    name: "es-ES/product/534"
  }, {
    path: "/lentillas-de-colores/p535/green-clearcolor-1-day",
    component: _1b085a39,
    name: "es-ES/product/535"
  }, {
    path: "/b410/soluciones-coopervision",
    component: _387cb151,
    name: "es-ES/brand/410"
  }, {
    path: "/lentillas-de-colores/p532/gray-clearcolor-1-day",
    redirect: "/lentillas-de-colores/p532/grey-clearcolor-1-day"
  }, {
    path: "/lentes-de-contacto/p542/miru-1day-upside",
    component: _1b085a39,
    name: "es-ES/product/542"
  }, {
    path: "/lentes-de-contacto/p547/total30",
    component: _1b085a39,
    name: "es-ES/product/547"
  }, {
    path: "/lentes-de-contacto/p548/total30-6",
    component: _1b085a39,
    name: "es-ES/product/548"
  }, {
    path: "/lentes-de-contacto/p544/bausch-lomb-ultra-for-astigmatism-3",
    component: _1b085a39,
    name: "es-ES/product/544"
  }, {
    path: "/lentes-de-contacto/p545/bausch-lomb-ultra-for-astigmatism-6",
    component: _1b085a39,
    name: "es-ES/product/545"
  }, {
    path: "/lentes-de-contacto/p546/bausch-lomb-ultra-multifocal-for-astigmatism-6",
    component: _1b085a39,
    name: "es-ES/product/546"
  }, {
    path: "/lentes-de-contacto/p549/miru-1day-flat-multifocal",
    component: _1b085a39,
    name: "es-ES/product/549"
  }, {
    path: "/lentes-de-contacto/p550/miru-1day-flat-toric",
    component: _1b085a39,
    name: "es-ES/product/550"
  }, {
    path: "/lentilles-de-contact/p551/miru-1day-upside-multifocal",
    component: _1b085a39,
    name: "es-ES/product/551"
  }, {
    path: "/lentes-de-contacto/p552/bausch-lomb-ultra-one-day",
    component: _1b085a39,
    name: "es-ES/product/552"
  }, {
    path: "/lentes-de-contacto/p543/acuvue-oasys-multifocal",
    component: _1b085a39,
    name: "es-ES/product/543"
  }, {
    path: "/lentes-de-contacto/p556/myday-multifocal",
    component: _1b085a39,
    name: "es-ES/product/556"
  }, {
    path: "/lentes-de-contacto/p6/acuvue-oasys-multifocal",
    redirect: "/lentes-de-contacto/p543/acuvue-oasys-multifocal"
  }, {
    path: "/registrarse",
    component: _b33f986e,
    name: "es-ES/register"
  }, {
    path: "/pago",
    component: _18205bd0,
    name: "es-ES/payment"
  }, {
    path: "/cuenta/cambiar-contrasena",
    component: _ccd8623c,
    name: "es-ES/change-password"
  }, {
    path: "/login",
    component: _00ef9433,
    name: "es-ES/login"
  }, {
    path: "/pedidos",
    redirect: "/cuenta/pedidos"
  }, {
    path: "/tus-datos",
    redirect: "/cuenta/tus-datos"
  }, {
    path: "/preferencias-de-comunicacion",
    redirect: "/cuenta/preferencias-de-comunicacion"
  }, {
    path: "/direcciones",
    redirect: "/cuenta/direcciones"
  }, {
    path: "/basket",
    component: _99c1be28,
    name: "es-ES/basket"
  }, {
    path: "/recuperar-contrasena",
    component: _c4961b62,
    name: "es-ES/forgot-password"
  }, {
    path: "/lentes-de-contacto/p556/mydaymultifocal",
    redirect: "/lentes-de-contacto/p556/myday-multifocal"
  }, {
    path: "/envio",
    component: _34d0109a,
    name: "es-ES/delivery"
  }, {
    path: "/confirmacion-del-pedido",
    component: _4f90837a,
    name: "es-ES/order-confirmation"
  }, {
    path: "/iniciar-sesion",
    redirect: "/login"
  }, {
    path: "/lentes-de-contacto/p557/dailies-total1-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/557"
  }, {
    path: "/lentes-de-contacto/p558/dailies-total1-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/558"
  }, {
    path: "/lentes-de-contacto/p14/1-day-acuvue-moist-90-",
    redirect: "/lentes-de-contacto/p14/1-day-acuvue-moist-90"
  }, {
    path: "/lentes-de-contacto/p6/dailies-aquacomfort-plus-90-",
    redirect: "/lentes-de-contacto/p6/dailies-aquacomfort-plus-90"
  }, {
    path: "/lentes-de-contacto/p2/soflens-daily-disposable-90-",
    redirect: "/lentes-de-contacto/p2/soflens-daily-disposable-90"
  }, {
    path: "/lentes-de-contacto/p150/purevision-2-hd-6-",
    redirect: "/lentes-de-contacto/p150/purevision-2-hd-6"
  }, {
    path: "/lentes-de-contacto/p201/biomedics-1-day-extra-90-",
    redirect: "/lentes-de-contacto/p201/biomedics-1-day-extra-90"
  }, {
    path: "/lentes-de-contacto/p227/biotrue-oneday-90-",
    redirect: "/lentes-de-contacto/p227/biotrue-oneday-90"
  }, {
    path: "/lentes-de-contacto/p229/purevision-2-hd-for-presbyopia-6-",
    redirect: "/lentes-de-contacto/p229/purevision-2-hd-for-presbyopia-6"
  }, {
    path: "/lentes-de-contacto/p459/acuvue-oasys-1-day-90-",
    redirect: "/lentes-de-contacto/p459/acuvue-oasys-1-day-90"
  }, {
    path: "/lentes-de-contacto/p478/bausch-lomb-ultra-presbyopia-6-",
    redirect: "/lentes-de-contacto/p478/bausch-lomb-ultra-presbyopia-6"
  }, {
    path: "/lentes-de-contacto/p95/freshlook-dimensions-6-",
    redirect: "/lentes-de-contacto/p95/freshlook-dimensions-6"
  }, {
    path: "/vitaminas-para-los-ojos/p242/macushield-90-",
    redirect: "/vitaminas-para-los-ojos/p242/macushield-90"
  }, {
    path: "/gestion-de-pagos",
    component: _6171e290,
    name: "es-ES/payment-management"
  }, {
    path: "/instrucciones-de-uso",
    component: _974a4b7e,
    name: "es-ES/document/159"
  }, {
    path: "/localizador-de-tienda",
    component: _12d211f4,
    name: "es-ES/store-locator"
  }, {
    path: "/b214/codigo-descuento",
    component: _387cb151,
    name: "es-ES/brand/214"
  }, {
    path: "/lentes-de-contacto/p559/acuvue-oasys-max-1-day",
    component: _1b085a39,
    name: "es-ES/product/559"
  }, {
    path: "/lentes-de-contacto/p560/acuvue-oasys-max-1-day-multifocal",
    component: _1b085a39,
    name: "es-ES/product/560"
  }, {
    path: "/b347/1-day-acuvue-moist",
    component: _387cb151,
    name: "es-ES/brand/347"
  }, {
    path: "/b411/acuvue-oasys-max-1-day",
    component: _387cb151,
    name: "es-ES/brand/411"
  }, {
    path: "/gestion-de-pagos/pedido-automatico/:id?",
    component: _2030f21f,
    name: "es-ES/payment-management-child"
  }, {
    path: "/lentes-de-contacto/p561/acuvue-oasys-with-transitions",
    component: _1b085a39,
    name: "es-ES/product/561"
  }, {
    path: "/cuenta/pedido-automatico",
    component: _0b6e7d80,
    name: "es-ES/auto-reorders"
  }, {
    path: "/programa-de-afiliaciacion",
    component: _974a4b7e,
    name: "es-ES/document/42"
  }, {
    path: "/lenstore-programa-de-afiliacia-n",
    redirect: "/programa-de-afiliaciacion"
  }, {
    path: "/programa-de-afiliacia-n",
    redirect: "/programa-de-afiliaciacion"
  }, {
    path: "/gotas-para-los-ojos/p555/systane-ultra-sin-conservantes",
    component: _1b085a39,
    name: "es-ES/product/555"
  }, {
    path: "/gotas-para-los-ojos/p553/systane-complete-sin-conservantes",
    component: _1b085a39,
    name: "es-ES/product/553"
  }, {
    path: "/gotas-para-los-ojos/p554/systane-hidratation-sin-conservantes",
    component: _1b085a39,
    name: "es-ES/product/554"
  }, {
    path: "/cuenta/pedido-automatico/pedidos/:id?",
    component: _74a9990f,
    name: "es-ES/auto-reorders-child"
  }, {
    path: "/sitemap",
    component: _974a4b7e,
    name: "es-ES/document/60"
  }, {
    path: "/lentes-de-contacto/p562/biotrue-oneday-for-presbyopia-90",
    component: _1b085a39,
    name: "es-ES/product/562"
  }, {
    path: "/dsar",
    component: _974a4b7e,
    name: "es-ES/document/161"
  }, {
    path: "/lentes-de-contacto/p562/biotrue-oneday-for-astigmatism-90",
    redirect: "/lentes-de-contacto/p562/biotrue-oneday-for-presbyopia-90"
  }, {
    path: "/soluzioni-multiuso/p563/biotrue-flight-pack-100ml",
    component: _1b085a39,
    name: "es-ES/product/563"
  }, {
    path: "/soluzioni-multiuso/p193/biotrue-flight-pack-100ml",
    redirect: "/soluzioni-multiuso/p563/biotrue-flight-pack-100ml"
  }, {
    path: "/lentes-de-contacto/p564/acuvue-oasys-max-1-day-90",
    component: _1b085a39,
    name: "es-ES/product/564"
  }, {
    path: "/lentes-de-contacto/p565/acuvue-oasys-max-1-day-multifocal-90",
    component: _1b085a39,
    name: "es-ES/product/565"
  }, {
    path: "/gracias",
    component: _974a4b7e,
    name: "es-ES/document/162"
  }, {
    path: "/lentes-de-contacto/p566/total30-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/566"
  }, {
    path: "/lentes-de-contacto/p567/total30-multifocal",
    component: _1b085a39,
    name: "es-ES/product/567"
  }, {
    path: "/lentes-de-contacto/p568/total30-for-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/568"
  }, {
    path: "/lentes-de-contacto/p569/total30-multifocal",
    component: _1b085a39,
    name: "es-ES/product/569"
  }, {
    path: "/pagina-descuento-estudiantes",
    component: _974a4b7e,
    name: "es-ES/document/106"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue_p12",
    component: _1b085a39,
    name: "es-ES/product/12"
  }, {
    path: "/acuvue/daily-disposables/1-day-acuvue_p11",
    component: _1b085a39,
    name: "es-ES/product/11"
  }, {
    path: "/ciba-vision/daily-disposables/focus-dailies-progressives_p9",
    component: _1b085a39,
    name: "es-ES/product/9"
  }, {
    path: "/air-optix-3-lenses",
    component: _1b085a39,
    name: "es-ES/product/21"
  }, {
    path: "/focus-progressives-6-lenses",
    component: _1b085a39,
    name: "es-ES/product/23"
  }, {
    path: "/focus-toric-visitint-6-lenses",
    component: _1b085a39,
    name: "es-ES/product/24"
  }, {
    path: "/ciba-vision/monthly-disposables/precision-uv_p26",
    component: _1b085a39,
    name: "es-ES/product/26"
  }, {
    path: "/actifresh-400_p27",
    component: _1b085a39,
    name: "es-ES/product/27"
  }, {
    path: "/cooper-vision/monthly-disposables/frequency-38_p28",
    component: _1b085a39,
    name: "es-ES/product/28"
  }, {
    path: "/ciba-vision/monthly/precision-uv_p26",
    redirect: "/ciba-vision/monthly-disposables/precision-uv_p26"
  }, {
    path: "/cooper-vision/monthly-disposables/frequency-58-uv_p31",
    component: _1b085a39,
    name: "es-ES/product/31"
  }, {
    path: "/johnson-johnson/acuvue-bifocal",
    component: _1b085a39,
    name: "es-ES/product/37"
  }, {
    path: "/acuvue/multifocal-lenses/acuvue-bifocal_p37",
    redirect: "/johnson-johnson/acuvue-bifocal"
  }, {
    path: "/sunglasses/michael-kors-chelsea-mk5004-rose-gold-taupe-l_p265",
    component: _1b085a39,
    name: "es-ES/product/265"
  }, {
    path: "/sunglasses/michael-kors-chelsea-mk5004-gold-dark-chocolate-brown-l_p266",
    component: _1b085a39,
    name: "es-ES/product/266"
  }, {
    path: "/sunglasses/michael-kors-willa-i-mk2015b-red-gold-l_p267",
    component: _1b085a39,
    name: "es-ES/product/267"
  }, {
    path: "/sunglasses/michael-kors-mk6029-pink-tortoiseshell-rose-gold-l_p268",
    component: _1b085a39,
    name: "es-ES/product/268"
  }, {
    path: "/sunglasses/michael-kors-mk6029-dark-tortoiseshell-gold-l_p269",
    component: _1b085a39,
    name: "es-ES/product/269"
  }, {
    path: "/sunglasses/michael-kors-playa-norte-mk5006-tortoiseshell--gold-l_p270",
    component: _1b085a39,
    name: "es-ES/product/270"
  }, {
    path: "/sunglasses/michael-kors-sadie-i-mk1005-black-gold-leopard-black-l_p271",
    component: _1b085a39,
    name: "es-ES/product/271"
  }, {
    path: "/sunglasses/michael-kors-marrakesh-mk6006-black-l_p272",
    component: _1b085a39,
    name: "es-ES/product/272"
  }, {
    path: "/sunglasses/michael-kors-marrakesh-mk6006-dark-tortoiseshell-l_p273",
    component: _1b085a39,
    name: "es-ES/product/273"
  }, {
    path: "/sunglasses/michael-kors-fiji-mk1003-gold-l_p274",
    component: _1b085a39,
    name: "es-ES/product/274"
  }, {
    path: "/sunglasses/michael-kors-playa-norte-mk5006-black-silver-l_p275",
    component: _1b085a39,
    name: "es-ES/product/275"
  }, {
    path: "/sunglasses/michael-kors-isle-of-skye-mk6017-black-off-white-l_p276",
    component: _1b085a39,
    name: "es-ES/product/276"
  }, {
    path: "/sunglasses/michael-kors-isle-of-skye-mk6017-tortoiseshell-smokey-transparent-l_p277",
    component: _1b085a39,
    name: "es-ES/product/277"
  }, {
    path: "/sunglasses/michael-kors-chelsea-mk5004-rose-gold-l_p278",
    component: _1b085a39,
    name: "es-ES/product/278"
  }, {
    path: "/sunglasses/michael-kors-hvar-mk5007-silver-black-l_p279",
    component: _1b085a39,
    name: "es-ES/product/279"
  }, {
    path: "/sunglasses/michael-kors-gstaad-mk2009-dark-tortoiseshell-l_p280",
    component: _1b085a39,
    name: "es-ES/product/280"
  }, {
    path: "/sunglasses/oakley-frogskin-oo9013-crystal-m_p281",
    component: _1b085a39,
    name: "es-ES/product/281"
  }, {
    path: "/sunglasses/oakley-sliver-oo9262-matte-black-grey-l_p282",
    component: _1b085a39,
    name: "es-ES/product/282"
  }, {
    path: "/sunglasses/oakley-sliver-oo9262-matte-black-violet-iridium-polarised-l_p283",
    component: _1b085a39,
    name: "es-ES/product/283"
  }, {
    path: "/sunglasses/oakley-catalyst-oo9272-black-ink-positive-red-iridium-m_p284",
    component: _1b085a39,
    name: "es-ES/product/284"
  }, {
    path: "/sunglasses/oakley-holbrook-oo9102-matte-black-grey-m_p285",
    component: _1b085a39,
    name: "es-ES/product/285"
  }, {
    path: "/sunglasses/oakley-holbrook-oo9102-black-grey-m_p286",
    component: _1b085a39,
    name: "es-ES/product/286"
  }, {
    path: "/sunglasses/oakley-jupiter-squared-oo9135-black-l_p287",
    component: _1b085a39,
    name: "es-ES/product/287"
  }, {
    path: "/sunglasses/oakley-frogskin-oo9013-black-violet-iridium-m_p288",
    component: _1b085a39,
    name: "es-ES/product/288"
  }, {
    path: "/sunglasses/oakley-frogskin-oo9013-black-fire-iridium-m_p289",
    component: _1b085a39,
    name: "es-ES/product/289"
  }, {
    path: "/sunglasses/oakley-two-face-oo9189-black-violet-iridium-l_p290",
    component: _1b085a39,
    name: "es-ES/product/290"
  }, {
    path: "/sunglasses/oakley-five-squared-oo9238-black-m_p291",
    component: _1b085a39,
    name: "es-ES/product/291"
  }, {
    path: "/sunglasses/oakley-five-squared-oo9238-grey-m_p292",
    component: _1b085a39,
    name: "es-ES/product/292"
  }, {
    path: "/sunglasses/oakley-holbrook-oo9102-black-blue-m_p293",
    component: _1b085a39,
    name: "es-ES/product/293"
  }, {
    path: "/sunglasses/oakley-square-wire-oo4075-black-l_p294",
    component: _1b085a39,
    name: "es-ES/product/294"
  }, {
    path: "/sunglasses/oakley-valve-oo9236-black-l_p295",
    component: _1b085a39,
    name: "es-ES/product/295"
  }, {
    path: "/sunglasses/oakley-conductor-oo4107-matte-black-l_p296",
    component: _1b085a39,
    name: "es-ES/product/296"
  }, {
    path: "/sunglasses/ralph-ra5201-teal-tortoiseshell-gold-m_p297",
    component: _1b085a39,
    name: "es-ES/product/297"
  }, {
    path: "/sunglasses/ralph-ra5196-brown-m_p298",
    component: _1b085a39,
    name: "es-ES/product/298"
  }, {
    path: "/sunglasses/ralph-ra5150-havana-l_p299",
    component: _1b085a39,
    name: "es-ES/product/299"
  }, {
    path: "/sunglasses/ralph-ra5160-black-l_p300",
    component: _1b085a39,
    name: "es-ES/product/300"
  }, {
    path: "/sunglasses/ralph-ra5168-tortoiseshell-l_p301",
    component: _1b085a39,
    name: "es-ES/product/301"
  }, {
    path: "/sunglasses/ralph-ra5165-tortoiseshell-l_p302",
    component: _1b085a39,
    name: "es-ES/product/302"
  }, {
    path: "/sunglasses/ralph-ra5130-tortoiseshell-l_p303",
    component: _1b085a39,
    name: "es-ES/product/303"
  }, {
    path: "/sunglasses/ralph-ra5176-black-l_p304",
    component: _1b085a39,
    name: "es-ES/product/304"
  }, {
    path: "/sunglasses/ralph-ra4115-black-gold-l_p305",
    component: _1b085a39,
    name: "es-ES/product/305"
  }, {
    path: "/sunglasses/ralph-ra5185-brown-horn-l_p306",
    component: _1b085a39,
    name: "es-ES/product/306"
  }, {
    path: "/sunglasses/ralph-ra5202-tortoiseshell-gold-m_p307",
    component: _1b085a39,
    name: "es-ES/product/307"
  }, {
    path: "/sunglasses/ralph-ra5196-black-black-bandana-m_p308",
    component: _1b085a39,
    name: "es-ES/product/308"
  }, {
    path: "/sunglasses/ralph-ra5203-black-nude-m_p309",
    component: _1b085a39,
    name: "es-ES/product/309"
  }, {
    path: "/sunglasses/ralph-ra5204-tortoiseshell-m_p310",
    component: _1b085a39,
    name: "es-ES/product/310"
  }, {
    path: "/sunglasses/ralph-ra5201-black-gold-m_p311",
    component: _1b085a39,
    name: "es-ES/product/311"
  }, {
    path: "/sunglasses/ralph-ra5202-blue-tortoiseshell-gold-m_p312",
    component: _1b085a39,
    name: "es-ES/product/312"
  }, {
    path: "/sunglasses/ralph-ra5203-pink-tortoiseshell-m_p313",
    component: _1b085a39,
    name: "es-ES/product/313"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-blue-m_p314",
    component: _1b085a39,
    name: "es-ES/product/314"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-green-m_p315",
    component: _1b085a39,
    name: "es-ES/product/315"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-orange-m_p316",
    component: _1b085a39,
    name: "es-ES/product/316"
  }, {
    path: "/sunglasses/ray-ban-erika-rb4171-black-blue-m_p317",
    component: _1b085a39,
    name: "es-ES/product/317"
  }, {
    path: "/sunglasses/ray-ban-rb4231-matte-black-l_p318",
    component: _1b085a39,
    name: "es-ES/product/318"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-gold-grey-l_p319",
    component: _1b085a39,
    name: "es-ES/product/319"
  }, {
    path: "/sunglasses/ray-ban-rb4226-havana-l_p320",
    component: _1b085a39,
    name: "es-ES/product/320"
  }, {
    path: "/sunglasses/ray-ban-rb4228-black-l_p321",
    component: _1b085a39,
    name: "es-ES/product/321"
  }, {
    path: "/sunglasses/ray-ban-justin-rb4165-black-rubber-m_p322",
    component: _1b085a39,
    name: "es-ES/product/322"
  }, {
    path: "/sunglasses/ray-ban-erika-rb4171-black-green-m_p323",
    component: _1b085a39,
    name: "es-ES/product/323"
  }, {
    path: "/sunglasses/ray-ban-rb4181-havana-l_p324",
    component: _1b085a39,
    name: "es-ES/product/324"
  }, {
    path: "/sunglasses/ray-ban-rb3498-black-l_p325",
    component: _1b085a39,
    name: "es-ES/product/325"
  }, {
    path: "/sunglasses/ray-ban-rb4184-havana-m_p326",
    component: _1b085a39,
    name: "es-ES/product/326"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-grey-s_p327",
    component: _1b085a39,
    name: "es-ES/product/327"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-silver-l_p328",
    component: _1b085a39,
    name: "es-ES/product/328"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-gloss-black-green-m_p329",
    component: _1b085a39,
    name: "es-ES/product/329"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-black-green-s_p330",
    component: _1b085a39,
    name: "es-ES/product/330"
  }, {
    path: "/sunglasses/ray-ban-rb4068-havana-polarised-l_p331",
    component: _1b085a39,
    name: "es-ES/product/331"
  }, {
    path: "/sunglasses/ray-ban-wayfarer-rb2140-black-s_p332",
    component: _1b085a39,
    name: "es-ES/product/332"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-havana-s_p333",
    component: _1b085a39,
    name: "es-ES/product/333"
  }, {
    path: "/sunglasses/ray-ban-rb4068-havana-l_p334",
    component: _1b085a39,
    name: "es-ES/product/334"
  }, {
    path: "/sunglasses/ray-ban-cats-5000-rb4125-havana-l_p335",
    component: _1b085a39,
    name: "es-ES/product/335"
  }, {
    path: "/sunglasses/ray-ban-clubmaster-rb3016-tortoiseshell-s_p336",
    component: _1b085a39,
    name: "es-ES/product/336"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-gold-m_p337",
    component: _1b085a39,
    name: "es-ES/product/337"
  }, {
    path: "/sunglasses/ray-ban-rb4147-havana-l_p338",
    component: _1b085a39,
    name: "es-ES/product/338"
  }, {
    path: "/sunglasses/ray-ban-new-wayfarer-rb2132-matte-black-green-m_p339",
    component: _1b085a39,
    name: "es-ES/product/339"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-gun-l_p340",
    component: _1b085a39,
    name: "es-ES/product/340"
  }, {
    path: "/sunglasses/ray-ban-justin-rb4165-black-m_p341",
    component: _1b085a39,
    name: "es-ES/product/341"
  }, {
    path: "/sunglasses/ray-ban-justin-rb4165-havana-m_p342",
    component: _1b085a39,
    name: "es-ES/product/342"
  }, {
    path: "/sunglasses/ray-ban-aviator-rb3025-gold-green-l_p343",
    component: _1b085a39,
    name: "es-ES/product/343"
  }, {
    path: "/sunglasses/ray-ban-clubmaster-rb3016-black-s_p344",
    component: _1b085a39,
    name: "es-ES/product/344"
  }, {
    path: "/sunglasses/ray-ban-erika-rb4171-havana-m_p345",
    component: _1b085a39,
    name: "es-ES/product/345"
  }, {
    path: "/sunglasses/ray-ban-rb3502-gun-l_p346",
    component: _1b085a39,
    name: "es-ES/product/346"
  }, {
    path: "/sunglasses/ray-ban-rb4198-black-m_p347",
    component: _1b085a39,
    name: "es-ES/product/347"
  }, {
    path: "/sunglasses/ray-ban-rb4198-havana-m_p348",
    component: _1b085a39,
    name: "es-ES/product/348"
  }, {
    path: "/sunglasses/carrera-100-s-black-ruth-l_p349",
    component: _1b085a39,
    name: "es-ES/product/349"
  }, {
    path: "/sunglasses/carrera-8015-s-ruth-l_p350",
    component: _1b085a39,
    name: "es-ES/product/350"
  }, {
    path: "/sunglasses/carrera-6012-s-havana-m_p351",
    component: _1b085a39,
    name: "es-ES/product/351"
  }, {
    path: "/sunglasses/carrera-6000-black-s_p352",
    component: _1b085a39,
    name: "es-ES/product/352"
  }, {
    path: "/sunglasses/carrera-97-s-havana-l_p353",
    component: _1b085a39,
    name: "es-ES/product/353"
  }, {
    path: "/sunglasses/carrera-new-safari-black-l_p354",
    component: _1b085a39,
    name: "es-ES/product/354"
  }, {
    path: "/sunglasses/carrera-6016-s-black-l_p355",
    component: _1b085a39,
    name: "es-ES/product/355"
  }, {
    path: "/sunglasses/carrera-new-champion-black-l_p356",
    component: _1b085a39,
    name: "es-ES/product/356"
  }, {
    path: "/sunglasses/boss-0561-s-blue-l_p357",
    component: _1b085a39,
    name: "es-ES/product/357"
  }, {
    path: "/sunglasses/boss-0561-s-black-l_p358",
    component: _1b085a39,
    name: "es-ES/product/358"
  }, {
    path: "/sunglasses/boss-0592-s-black-crystal-l_p359",
    component: _1b085a39,
    name: "es-ES/product/359"
  }, {
    path: "/sunglasses/boss-0732-s-black-l_p360",
    component: _1b085a39,
    name: "es-ES/product/360"
  }, {
    path: "/sunglasses/boss-0475-s-ruth-black-l_p361",
    component: _1b085a39,
    name: "es-ES/product/361"
  }, {
    path: "/sunglasses/boss-0509-s-black-m_p362",
    component: _1b085a39,
    name: "es-ES/product/362"
  }, {
    path: "/sunglasses/boss-0727-s-black-l_p363",
    component: _1b085a39,
    name: "es-ES/product/363"
  }, {
    path: "/sunglasses/boss-0471-s-black-s_p364",
    component: _1b085a39,
    name: "es-ES/product/364"
  }, {
    path: "/sunglasses/boss-0727-s-grey-l_p365",
    component: _1b085a39,
    name: "es-ES/product/365"
  }, {
    path: "/sunglasses/boss-0667-s-horn-s_p366",
    component: _1b085a39,
    name: "es-ES/product/366"
  }, {
    path: "/sunglasses/boss-0732-s-brown-l_p367",
    component: _1b085a39,
    name: "es-ES/product/367"
  }, {
    path: "/sunglasses/boss-0471-s-havana-s_p368",
    component: _1b085a39,
    name: "es-ES/product/368"
  }, {
    path: "/sunglasses/boss-0735-s-olive-m_p369",
    component: _1b085a39,
    name: "es-ES/product/369"
  }, {
    path: "/sunglasses/boss-0440-s-black-l_p370",
    component: _1b085a39,
    name: "es-ES/product/370"
  }, {
    path: "/sunglasses/boss-0539-s-black-l_p371",
    component: _1b085a39,
    name: "es-ES/product/371"
  }, {
    path: "/sunglasses/boss-0665-s-black-l_p372",
    component: _1b085a39,
    name: "es-ES/product/372"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-096-n-s-havana-l_p373",
    component: _1b085a39,
    name: "es-ES/product/373"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-096-n-s-black-l_p374",
    component: _1b085a39,
    name: "es-ES/product/374"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-458-s-havana-s_p375",
    component: _1b085a39,
    name: "es-ES/product/375"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-287-s-black-l_p376",
    component: _1b085a39,
    name: "es-ES/product/376"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-411-s-brown-l_p377",
    component: _1b085a39,
    name: "es-ES/product/377"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-461-s-havana-blue-s_p378",
    component: _1b085a39,
    name: "es-ES/product/378"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-464-s-havana-crystal-l_p379",
    component: _1b085a39,
    name: "es-ES/product/379"
  }, {
    path: "/sunglasses/marc-by-marc-jacobs-464-s-black-crystal-l_p380",
    component: _1b085a39,
    name: "es-ES/product/380"
  }, {
    path: "/sunglasses/heritage-hsam08-tortoiseshell-m_p381",
    component: _1b085a39,
    name: "es-ES/product/381"
  }, {
    path: "/sunglasses/heritage-hsbf03-pink-m_p382",
    component: _1b085a39,
    name: "es-ES/product/382"
  }, {
    path: "/sunglasses/heritage-hsaf17-black-s_p383",
    component: _1b085a39,
    name: "es-ES/product/383"
  }, {
    path: "/sunglasses/heritage-hsaf17-tortoiseshell-s_p384",
    component: _1b085a39,
    name: "es-ES/product/384"
  }, {
    path: "/sunglasses/heritage-hsbf15-black-l_p385",
    component: _1b085a39,
    name: "es-ES/product/385"
  }, {
    path: "/sunglasses/heritage-hsbf15-red-tortoiseshell-l_p386",
    component: _1b085a39,
    name: "es-ES/product/386"
  }, {
    path: "/sunglasses/heritage-hsbm03-grey-black-m_p387",
    component: _1b085a39,
    name: "es-ES/product/387"
  }, {
    path: "/sunglasses/heritage-hsbm07-silver-black-l_p388",
    component: _1b085a39,
    name: "es-ES/product/388"
  }, {
    path: "/sunglasses/heritage-hsam05-black-s_p389",
    component: _1b085a39,
    name: "es-ES/product/389"
  }, {
    path: "/sunglasses/heritage-hsam07-black-s_p390",
    component: _1b085a39,
    name: "es-ES/product/390"
  }, {
    path: "/sunglasses/joules-js7030-tortoiseshell-blue-m_p391",
    component: _1b085a39,
    name: "es-ES/product/391"
  }, {
    path: "/sunglasses/joules-js7032-black-m_p392",
    component: _1b085a39,
    name: "es-ES/product/392"
  }, {
    path: "/sunglasses/joules-js7032-tortoiseshell-floral-m_p393",
    component: _1b085a39,
    name: "es-ES/product/393"
  }, {
    path: "/sunglasses/joules-js7014-brown-cream-m_p394",
    component: _1b085a39,
    name: "es-ES/product/394"
  }, {
    path: "/sunglasses/joules-js7025-blue-white-l_p395",
    component: _1b085a39,
    name: "es-ES/product/395"
  }, {
    path: "/sunglasses/joules-js7025-purple-floral-l_p396",
    component: _1b085a39,
    name: "es-ES/product/396"
  }, {
    path: "/sunglasses/joules-js7011-blue-white-m_p397",
    component: _1b085a39,
    name: "es-ES/product/397"
  }, {
    path: "/sunglasses/joules-js7011-blue-floral-m_p398",
    component: _1b085a39,
    name: "es-ES/product/398"
  }, {
    path: "/sunglasses/joules-js7013-tortoiseshell-l_p399",
    component: _1b085a39,
    name: "es-ES/product/399"
  }, {
    path: "/sunglasses/joules-js7016-tortoiseshell-m_p400",
    component: _1b085a39,
    name: "es-ES/product/400"
  }, {
    path: "/sunglasses/joules-js7024-brown-l_p401",
    component: _1b085a39,
    name: "es-ES/product/401"
  }, {
    path: "/sunglasses/joules-js7014-black-red-m_p402",
    component: _1b085a39,
    name: "es-ES/product/402"
  }, {
    path: "/sunglasses/superdry-rockstar-black-m_p403",
    component: _1b085a39,
    name: "es-ES/product/403"
  }, {
    path: "/sunglasses/superdry-kobe-matte-black-m_p404",
    component: _1b085a39,
    name: "es-ES/product/404"
  }, {
    path: "/sunglasses/superdry-kobe-brown-m_p405",
    component: _1b085a39,
    name: "es-ES/product/405"
  }, {
    path: "/sunglasses/superdry-walker-gloss-black-fluro-orange-l_p406",
    component: _1b085a39,
    name: "es-ES/product/406"
  }, {
    path: "/sunglasses/superdry-huntsman-matte-silver-blue-l_p407",
    component: _1b085a39,
    name: "es-ES/product/407"
  }, {
    path: "/sunglasses/superdry-supergami-matte-black-l_p408",
    component: _1b085a39,
    name: "es-ES/product/408"
  }, {
    path: "/sunglasses/superdry-san-matte-dark-navy-s_p409",
    component: _1b085a39,
    name: "es-ES/product/409"
  }, {
    path: "/sunglasses/superdry-walker-matte-gold-camo-l_p410",
    component: _1b085a39,
    name: "es-ES/product/410"
  }, {
    path: "/sunglasses/superdry-astro-matte-black-l_p411",
    component: _1b085a39,
    name: "es-ES/product/411"
  }, {
    path: "/sunglasses/superdry-huntsman-shiny-gun-purple-l_p412",
    component: _1b085a39,
    name: "es-ES/product/412"
  }, {
    path: "/sunglasses/superdry-ni-gloss-tortoiseshell-m_p413",
    component: _1b085a39,
    name: "es-ES/product/413"
  }, {
    path: "/sunglasses/superdry-supergami-matte-tortoiseshell-fluro-blue-l_p414",
    component: _1b085a39,
    name: "es-ES/product/414"
  }, {
    path: "/sunglasses/ted-baker-tb1395-tortoiseshell-l_p415",
    component: _1b085a39,
    name: "es-ES/product/415"
  }, {
    path: "/sunglasses/ted-baker-tb1395-black-l_p416",
    component: _1b085a39,
    name: "es-ES/product/416"
  }, {
    path: "/sunglasses/ted-baker-tb1416-orange-marmalade-m_p417",
    component: _1b085a39,
    name: "es-ES/product/417"
  }, {
    path: "/sunglasses/ted-baker-tb1424-tortoiseshell-pink-l_p418",
    component: _1b085a39,
    name: "es-ES/product/418"
  }, {
    path: "/sunglasses/ted-baker-tb1424-tortoiseshell-mint-l_p419",
    component: _1b085a39,
    name: "es-ES/product/419"
  }, {
    path: "/sunglasses/ted-baker-tb1358-brown-m_p420",
    component: _1b085a39,
    name: "es-ES/product/420"
  }, {
    path: "/sunglasses/ted-baker-tb1394-black-l_p421",
    component: _1b085a39,
    name: "es-ES/product/421"
  }, {
    path: "/sunglasses/ted-baker-tb1415-tortoiseshell-m_p422",
    component: _1b085a39,
    name: "es-ES/product/422"
  }, {
    path: "/sunglasses/ted-baker-tb1183-tortoiseshell-l_p423",
    component: _1b085a39,
    name: "es-ES/product/423"
  }, {
    path: "/sunglasses/ted-baker-tb1183-black-l_p424",
    component: _1b085a39,
    name: "es-ES/product/424"
  }, {
    path: "/sunglasses/ted-baker-tb1297-tortoiseshell-l_p425",
    component: _1b085a39,
    name: "es-ES/product/425"
  }, {
    path: "/sunglasses/ted-baker-tb1358-tortoiseshell-m_p426",
    component: _1b085a39,
    name: "es-ES/product/426"
  }, {
    path: "/sunglasses/ted-baker-tb1393-black-l_p427",
    component: _1b085a39,
    name: "es-ES/product/427"
  }, {
    path: "/sunglasses/ted-baker-tb1394-tortoiseshell-l_p428",
    component: _1b085a39,
    name: "es-ES/product/428"
  }, {
    path: "/sunglasses/ted-baker-tb1415-blue-m_p429",
    component: _1b085a39,
    name: "es-ES/product/429"
  }, {
    path: "/sunglasses/ted-baker-tb1416-black-m_p430",
    component: _1b085a39,
    name: "es-ES/product/430"
  }, {
    path: "/sunglasses/ted-baker-tb1405-grey-blue-m_p431",
    component: _1b085a39,
    name: "es-ES/product/431"
  }, {
    path: "/sunglasses/ted-baker-tb1406-black-l_p432",
    component: _1b085a39,
    name: "es-ES/product/432"
  }, {
    path: "/sunglasses/ted-baker-tb1408-grey-l_p433",
    component: _1b085a39,
    name: "es-ES/product/433"
  }, {
    path: "/sunglasses/ted-baker-tb1409-black-l_p434",
    component: _1b085a39,
    name: "es-ES/product/434"
  }, {
    path: "/sunglasses/ted-baker-tb1409-tortoiseshell-l_p435",
    component: _1b085a39,
    name: "es-ES/product/435"
  }, {
    path: "/sunglasses/ted-baker-tb1166-grey-l_p436",
    component: _1b085a39,
    name: "es-ES/product/436"
  }, {
    path: "/sunglasses/ted-baker-tb1166-gold-l_p437",
    component: _1b085a39,
    name: "es-ES/product/437"
  }, {
    path: "/sunglasses/ted-baker-tb1271-grey-l_p438",
    component: _1b085a39,
    name: "es-ES/product/438"
  }, {
    path: "/sunglasses/unofficial-un-36-tortoiseshell-m_p439",
    component: _1b085a39,
    name: "es-ES/product/439"
  }, {
    path: "/sunglasses/unofficial-un-37-black-m_p440",
    component: _1b085a39,
    name: "es-ES/product/440"
  }, {
    path: "/sunglasses/unofficial-un-37-tortoiseshell-m_p441",
    component: _1b085a39,
    name: "es-ES/product/441"
  }, {
    path: "/sunglasses/unofficial-un-21-black-m_p442",
    component: _1b085a39,
    name: "es-ES/product/442"
  }, {
    path: "/sunglasses/unofficial-unbf29-navy-blue-s_p443",
    component: _1b085a39,
    name: "es-ES/product/443"
  }, {
    path: "/sunglasses/unofficial-un-47-brown-s_p444",
    component: _1b085a39,
    name: "es-ES/product/444"
  }, {
    path: "/sunglasses/unofficial-unbm00-black-s_p445",
    component: _1b085a39,
    name: "es-ES/product/445"
  }, {
    path: "/sunglasses/unofficial-unbm14-black-navy-blue-l_p446",
    component: _1b085a39,
    name: "es-ES/product/446"
  }, {
    path: "/sunglasses/unofficial-un-36-blue-m_p447",
    component: _1b085a39,
    name: "es-ES/product/447"
  }, {
    path: "/sunglasses/unofficial-un-47-black-s_p448",
    component: _1b085a39,
    name: "es-ES/product/448"
  }, {
    path: "/sunglasses/unofficial-unam02-black-s_p449",
    component: _1b085a39,
    name: "es-ES/product/449"
  }, {
    path: "/ocular-vitamins/energeyes_p257",
    component: _1b085a39,
    name: "es-ES/product/257"
  }, {
    path: "/eye-drops/biotrue-rewetting-drops-vials_p236",
    component: _1b085a39,
    name: "es-ES/product/236"
  }, {
    path: "/lentes-de-contacto/p50/1-day-acuvue-moist-astigmatism",
    component: _1b085a39,
    name: "es-ES/product/50"
  }, {
    path: "/coloured-contact-lenses/soflens-natural-colors-limited-stock_p212",
    component: _1b085a39,
    name: "es-ES/product/212"
  }, {
    path: "/focus-toric-visitint-3-lenses",
    component: _1b085a39,
    name: "es-ES/product/52"
  }, {
    path: "/halloween-lenses/limited-edition-union-jack_p199",
    component: _1b085a39,
    name: "es-ES/product/199"
  }, {
    path: "/lens-case-",
    component: _1b085a39,
    name: "es-ES/product/189"
  }, {
    path: "/ciba-vision/monthly-disposables/precision-uv_p53",
    component: _1b085a39,
    name: "es-ES/product/53"
  }, {
    path: "/biomedics-1-day-30-lenses",
    component: _1b085a39,
    name: "es-ES/product/55"
  }, {
    path: "/biomedics-1-day-90-lenses",
    component: _1b085a39,
    name: "es-ES/product/56"
  }, {
    path: "/biomedics-38-6-lenses",
    component: _1b085a39,
    name: "es-ES/product/58"
  }, {
    path: "/lentes-de-contacto/p59/biomedics-73-multifocal",
    component: _1b085a39,
    name: "es-ES/product/59"
  }, {
    path: "/lentes-de-contacto/p62/focus-dailies-basic",
    component: _1b085a39,
    name: "es-ES/product/62"
  }, {
    path: "/soluciones-multiuso/p65/opti-free-replenish-travel-pack",
    component: _1b085a39,
    name: "es-ES/product/65"
  }, {
    path: "/johnson-johnson/surevue",
    component: _1b085a39,
    name: "es-ES/product/78"
  }, {
    path: "/lentes-de-contacto/p81/air-optix-individual",
    component: _1b085a39,
    name: "es-ES/product/81"
  }, {
    path: "/focus-monthly-soft-colors",
    component: _1b085a39,
    name: "es-ES/product/91"
  }, {
    path: "/halloween-lenses/aqua-3-tone_p153",
    component: _1b085a39,
    name: "es-ES/product/153"
  }, {
    path: "/halloween-lenses/aqua-cat-eye_p154",
    component: _1b085a39,
    name: "es-ES/product/154"
  }, {
    path: "/halloween-lenses/avatar_p155",
    component: _1b085a39,
    name: "es-ES/product/155"
  }, {
    path: "/halloween-lenses/blue-3-tone_p156",
    component: _1b085a39,
    name: "es-ES/product/156"
  }, {
    path: "/halloween-lenses/blue-manson_p157",
    component: _1b085a39,
    name: "es-ES/product/157"
  }, {
    path: "/halloween-lenses/glimmer-blue_p158",
    component: _1b085a39,
    name: "es-ES/product/158"
  }, {
    path: "/halloween-lenses/glimmer-green_p159",
    component: _1b085a39,
    name: "es-ES/product/159"
  }, {
    path: "/halloween-lenses/glimmer-silver_p160",
    component: _1b085a39,
    name: "es-ES/product/160"
  }, {
    path: "/halloween-lenses/green-3-tone_p161",
    component: _1b085a39,
    name: "es-ES/product/161"
  }, {
    path: "/halloween-lenses/grey-3-tone_p162",
    component: _1b085a39,
    name: "es-ES/product/162"
  }, {
    path: "/halloween-lenses/hazel-3-tone_p163",
    component: _1b085a39,
    name: "es-ES/product/163"
  }, {
    path: "/halloween-lenses/green-uv_p164",
    component: _1b085a39,
    name: "es-ES/product/164"
  }, {
    path: "/halloween-lenses/pink-uv_p165",
    component: _1b085a39,
    name: "es-ES/product/165"
  }, {
    path: "/halloween-lenses/violet-uv_p166",
    component: _1b085a39,
    name: "es-ES/product/166"
  }, {
    path: "/halloween-lenses/yellow-uv_p167",
    component: _1b085a39,
    name: "es-ES/product/167"
  }, {
    path: "/halloween-lenses/luna-eclipse_p168",
    component: _1b085a39,
    name: "es-ES/product/168"
  }, {
    path: "/halloween-lenses/mystic-aqua_p169",
    component: _1b085a39,
    name: "es-ES/product/169"
  }, {
    path: "/halloween-lenses/mystic-blue_p170",
    component: _1b085a39,
    name: "es-ES/product/170"
  }, {
    path: "/halloween-lenses/mystic-green_p171",
    component: _1b085a39,
    name: "es-ES/product/171"
  }, {
    path: "/halloween-lenses/twilight-volturi_p172",
    component: _1b085a39,
    name: "es-ES/product/172"
  }, {
    path: "/halloween-lenses/violet-3-tone_p173",
    component: _1b085a39,
    name: "es-ES/product/173"
  }, {
    path: "/halloween-lenses/white-cat-eye_p174",
    component: _1b085a39,
    name: "es-ES/product/174"
  }, {
    path: "/halloween-lenses/white-mesh_p175",
    component: _1b085a39,
    name: "es-ES/product/175"
  }, {
    path: "/halloween-lenses/yellow-cat-eye_p176",
    component: _1b085a39,
    name: "es-ES/product/176"
  }, {
    path: "/halloween-lenses/white-out_p177",
    component: _1b085a39,
    name: "es-ES/product/177"
  }, {
    path: "/halloween-lenses/black-out_p178",
    component: _1b085a39,
    name: "es-ES/product/178"
  }, {
    path: "/halloween-lenses/red-out_p179",
    component: _1b085a39,
    name: "es-ES/product/179"
  }, {
    path: "/halloween-lenses/manson_p180",
    component: _1b085a39,
    name: "es-ES/product/180"
  }, {
    path: "/halloween-lenses/white-zombie_p181",
    component: _1b085a39,
    name: "es-ES/product/181"
  }, {
    path: "/halloween-lenses/hell-raiser_p182",
    component: _1b085a39,
    name: "es-ES/product/182"
  }, {
    path: "/halloween-lenses/twilight_p183",
    component: _1b085a39,
    name: "es-ES/product/183"
  }, {
    path: "/halloween-lenses/white-uv_p184",
    component: _1b085a39,
    name: "es-ES/product/184"
  }, {
    path: "/halloween-lenses/blue-uv_p185",
    component: _1b085a39,
    name: "es-ES/product/185"
  }, {
    path: "/halloween-lenses/red-cat-eye_p186",
    component: _1b085a39,
    name: "es-ES/product/186"
  }, {
    path: "/halloween-lenses/yellow_p187",
    component: _1b085a39,
    name: "es-ES/product/187"
  }, {
    path: "/halloween-lenses/red-screen_p188",
    component: _1b085a39,
    name: "es-ES/product/188"
  }, {
    path: "/lentes-de-contacto/p98/expressions-accents",
    component: _1b085a39,
    name: "es-ES/product/98"
  }, {
    path: "/lentes-de-contacto/p99/expressions-accents",
    component: _1b085a39,
    name: "es-ES/product/99"
  }, {
    path: "/eye-vitamins/i-caps_p112",
    component: _1b085a39,
    name: "es-ES/product/112"
  }, {
    path: "/lentes-de-contacto/p98/expressions-accents-",
    redirect: "/lentes-de-contacto/p98/expressions-accents"
  }, {
    path: "/fashion-crazy-lenses/wild-eyes_p108",
    component: _1b085a39,
    name: "es-ES/product/108"
  }, {
    path: "/fashion-crazy-lenses/crazy-lenses_p107",
    component: _1b085a39,
    name: "es-ES/product/107"
  }, {
    path: "/ciba-vision/extended-wear-lenses/air-optix-night-day_p105",
    component: _1b085a39,
    name: "es-ES/product/105"
  }, {
    path: "/lentes-de-contacto/p102/images",
    component: _1b085a39,
    name: "es-ES/product/102"
  }, {
    path: "/lentes-de-contacto/p570/everclear-elite",
    component: _1b085a39,
    name: "es-ES/product/570"
  }, {
    path: "/lentes-de-contacto/p571/everclear-elite-pack-de-prueba",
    component: _1b085a39,
    name: "es-ES/product/571"
  }, {
    path: "/lentilles-de-contact/p476/bausch-and-lomb-ultra",
    component: _1b085a39,
    name: "fr-FR/product/476"
  }, {
    path: "/lentilles-de-contact/p475/bausch-and-lomb-ultra-6",
    component: _1b085a39,
    name: "fr-FR/product/475"
  }, {
    path: "/lentilles-de-contact/p474/acuvue-vita",
    component: _1b085a39,
    name: "fr-FR/product/474"
  }, {
    path: "/solutions-multifonctions/p473/hy-care-solution-multifonctions-3x250-ml",
    component: _1b085a39,
    name: "fr-FR/product/473"
  }, {
    path: "/solutions-multifonctions/p472/hy-care-solution-multifonctions",
    component: _1b085a39,
    name: "fr-FR/product/472"
  }, {
    path: "/lentilles-de-contact/p471/biofinity-xr-toric",
    component: _1b085a39,
    name: "fr-FR/product/471"
  }, {
    path: "/lentilles-de-contact/p470/myday-toric",
    component: _1b085a39,
    name: "fr-FR/product/470"
  }, {
    path: "/lentilles-de-contact/p469/avaira-vitality-6",
    component: _1b085a39,
    name: "fr-FR/product/469"
  }, {
    path: "/lentilles-de-contact/p468/acuvue-oasys-1-day-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/468"
  }, {
    path: "/lentilles-de-contact/p467/biofinity-energys",
    component: _1b085a39,
    name: "fr-FR/product/467"
  }, {
    path: "/lentilles-de-contact/p466/avaira-vitality",
    component: _1b085a39,
    name: "fr-FR/product/466"
  }, {
    path: "/lentilles-de-contact/p460/air-optix-plus-hydraglyde",
    component: _1b085a39,
    name: "fr-FR/product/460"
  }, {
    path: "/lentilles-de-contact/p459/acuvue-oasys-1-day-90",
    component: _1b085a39,
    name: "fr-FR/product/459"
  }, {
    path: "/lentilles-de-contact/p457/dailies-total-1-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/457"
  }, {
    path: "/lentilles-de-contact/p458/acuvue-oasys-1-day",
    component: _1b085a39,
    name: "fr-FR/product/458"
  }, {
    path: "/lentilles-de-contact/p456/air-optix-aqua-multifocal-6",
    component: _1b085a39,
    name: "fr-FR/product/456"
  }, {
    path: "/lentilles-de-contact/p455/air-optix-for-astigmatism-6",
    component: _1b085a39,
    name: "fr-FR/product/455"
  }, {
    path: "/lentilles-de-contact/p454/biofinity-multifocal-6",
    component: _1b085a39,
    name: "fr-FR/product/454"
  }, {
    path: "/lentilles-de-contact/p453/biofinity-toric-6",
    component: _1b085a39,
    name: "fr-FR/product/453"
  }, {
    path: "/lentilles-de-contact/p452/biofinity-6",
    component: _1b085a39,
    name: "fr-FR/product/452"
  }, {
    path: "/lentilles-de-contact/p451/proclear",
    component: _1b085a39,
    name: "fr-FR/product/451"
  }, {
    path: "/autres-accessoires/p264/spray-blink-refreshing",
    component: _1b085a39,
    name: "fr-FR/product/264"
  }, {
    path: "/lentilles-de-contact/p450/clariti-elite",
    component: _1b085a39,
    name: "fr-FR/product/450"
  }, {
    path: "/gouttes-pour-les-yeux/p262/systane-gel-drops",
    component: _1b085a39,
    name: "fr-FR/product/262"
  }, {
    path: "/gouttes-pour-les-yeux/p261/systane-balance",
    component: _1b085a39,
    name: "fr-FR/product/261"
  }, {
    path: "/gouttes-pour-les-yeux/p261/systane-balance-lubricant",
    redirect: "/gouttes-pour-les-yeux/p261/systane-balance"
  }, {
    path: "/gouttes-pour-les-yeux/p260/systane-ultra-30-dosettes",
    component: _1b085a39,
    name: "fr-FR/product/260"
  }, {
    path: "/solutions-au-peroxide-dhydrogene/p259/aosept-plus-hydraglyde-2x360-ml",
    component: _1b085a39,
    name: "fr-FR/product/259"
  }, {
    path: "/solutions-au-peroxide-dhydrogene/p258/aosept-plus-hydraglyde",
    component: _1b085a39,
    name: "fr-FR/product/258"
  }, {
    path: "/gouttes-pour-les-yeux/p256/optrex-intensive",
    component: _1b085a39,
    name: "fr-FR/product/256"
  }, {
    path: "/autres-accessoires/p255/biotrue-daily-eyelid-lingettes",
    component: _1b085a39,
    name: "fr-FR/product/255"
  }, {
    path: "/lentilles-de-contact/p252/myday-90",
    component: _1b085a39,
    name: "fr-FR/product/252"
  }, {
    path: "/lentilles-de-contact/p250/1-day-acuvue-moist-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/250"
  }, {
    path: "/lentilles-de-contact/p251/dailies-total-1-90",
    component: _1b085a39,
    name: "fr-FR/product/251"
  }, {
    path: "/gouttes-pour-les-yeux/p248/clinitas-soothe-20-dosettes",
    component: _1b085a39,
    name: "fr-FR/product/248"
  }, {
    path: "/autres-accessoires/p249/blink-lid-clean-lingettes",
    component: _1b085a39,
    name: "fr-FR/product/249"
  }, {
    path: "/autres-accessoires/p247/blephagel",
    component: _1b085a39,
    name: "fr-FR/product/247"
  }, {
    path: "/autres-accessoires/p246/blephaclean",
    component: _1b085a39,
    name: "fr-FR/product/246"
  }, {
    path: "/autres-accessoires/p245/blephasol",
    component: _1b085a39,
    name: "fr-FR/product/245"
  }, {
    path: "/vitamines-pour-les-yeux/p244/macushield-gold",
    component: _1b085a39,
    name: "fr-FR/product/244"
  }, {
    path: "/vitamines-pour-les-yeux/p242/macushield-90",
    component: _1b085a39,
    name: "fr-FR/product/242"
  }, {
    path: "/vitamines-pour-les-yeux/p243/macushield-vegetarian",
    component: _1b085a39,
    name: "fr-FR/product/243"
  }, {
    path: "/vitamines-pour-les-yeux/p241/macushield",
    component: _1b085a39,
    name: "fr-FR/product/241"
  }, {
    path: "/autres-accessoires/p237/thera-pearl-masque-pour-les-yeux",
    component: _1b085a39,
    name: "fr-FR/product/237"
  }, {
    path: "/lentilles-de-contact/p235/biotrue-oneday-for-presbyopia",
    component: _1b085a39,
    name: "fr-FR/product/235"
  }, {
    path: "/lentilles-de-contact/p234/dailies-aquacomfort-plus-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/234"
  }, {
    path: "/lentilles-de-contact/p233/dailies-aquacomfort-plus-toric",
    component: _1b085a39,
    name: "fr-FR/product/233"
  }, {
    path: "/lentilles-de-contact/p232/air-optix-colors",
    component: _1b085a39,
    name: "fr-FR/product/232"
  }, {
    path: "/lentilles-de-contact/p230/myday",
    component: _1b085a39,
    name: "fr-FR/product/230"
  }, {
    path: "/lentilles-de-contact/p231/biofinity-xr",
    component: _1b085a39,
    name: "fr-FR/product/231"
  }, {
    path: "/lentilles-de-contact/p229/purevision-2-hd-for-presbyopia-6",
    component: _1b085a39,
    name: "fr-FR/product/229"
  }, {
    path: "/lentilles-de-contact/p227/biotrue-oneday-90",
    component: _1b085a39,
    name: "fr-FR/product/227"
  }, {
    path: "/lentilles-de-contact/p228/purevision-2-hd-for-presbyopia",
    component: _1b085a39,
    name: "fr-FR/product/228"
  }, {
    path: "/lentilles-de-contact/p226/biotrue-oneday",
    component: _1b085a39,
    name: "fr-FR/product/226"
  }, {
    path: "/lentilles-de-contact/p222/clariti-toric",
    component: _1b085a39,
    name: "fr-FR/product/222"
  }, {
    path: "/lentilles-de-contact/p225/dailies-total-1",
    component: _1b085a39,
    name: "fr-FR/product/225"
  }, {
    path: "/lentilles-de-contact/p221/clariti-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/221"
  }, {
    path: "/lentilles-de-contact/p219/clariti-1-day",
    component: _1b085a39,
    name: "fr-FR/product/219"
  }, {
    path: "/lentilles-de-contact/p217/clariti-1-day-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/217"
  }, {
    path: "/lentilles-de-contact/p218/clariti-1-day-toric",
    component: _1b085a39,
    name: "fr-FR/product/218"
  }, {
    path: "/solutions-multifonctions/p215/biotrue-flat-pack-240-ml",
    component: _1b085a39,
    name: "fr-FR/product/215"
  }, {
    path: "/lentilles-de-contact/p214/air-optix-night-day-aqua-6",
    component: _1b085a39,
    name: "fr-FR/product/214"
  }, {
    path: "/gouttes-pour-les-yeux/p209/systane-ultra",
    component: _1b085a39,
    name: "fr-FR/product/209"
  }, {
    path: "/lentilles-de-contact/p213/air-optix-aqua-6",
    component: _1b085a39,
    name: "fr-FR/product/213"
  }, {
    path: "/etuis-a-lentilles/p208/etui-a-lentilles-haut",
    component: _1b085a39,
    name: "fr-FR/product/208"
  }, {
    path: "/gouttes-pour-les-yeux/p207/hycosan-extra",
    component: _1b085a39,
    name: "fr-FR/product/207"
  }, {
    path: "/autres-accessoires/p205/optrex-actimist-2in1-spray-yeux-fatigues",
    component: _1b085a39,
    name: "fr-FR/product/205"
  }, {
    path: "/gouttes-pour-les-yeux/p206/hycosan",
    component: _1b085a39,
    name: "fr-FR/product/206"
  }, {
    path: "/autres-accessoires/p204/optrex-actimist-2in1-spray-yeux-secs",
    component: _1b085a39,
    name: "fr-FR/product/204"
  }, {
    path: "/gouttes-pour-les-yeux/p202/blink-intensive-tears-plus",
    component: _1b085a39,
    name: "fr-FR/product/202"
  }, {
    path: "/lentilles-de-contact/p201/biomedics-1-day-extra-90",
    component: _1b085a39,
    name: "fr-FR/product/201"
  }, {
    path: "/lentilles-de-contact/p200/biomedics-1-day-extra",
    component: _1b085a39,
    name: "fr-FR/product/200"
  }, {
    path: "/lentilles-de-contact/p198/proclear-1-day-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/198"
  }, {
    path: "/lentilles-de-contact/p196/freshlook-illuminate",
    component: _1b085a39,
    name: "fr-FR/product/196"
  }, {
    path: "/lentilles-de-contact/p197/purevision-2-hd-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/197"
  }, {
    path: "/solutions-multifonctions/p194/opti-free-puremoist",
    component: _1b085a39,
    name: "fr-FR/product/194"
  }, {
    path: "/solutions-multifonctions/p195/opti-free-puremoist-2x300-ml",
    component: _1b085a39,
    name: "fr-FR/product/195"
  }, {
    path: "/solutions-multifonctions/p193/biotrue-flight-pack",
    component: _1b085a39,
    name: "fr-FR/product/193"
  }, {
    path: "/lentilles-de-contact/p192/purevision-2-hd-for-astigmatism-6",
    component: _1b085a39,
    name: "fr-FR/product/192"
  }, {
    path: "/solutions-multifonctions/p190/biotrue-3x300-ml",
    component: _1b085a39,
    name: "fr-FR/product/190"
  }, {
    path: "/lentilles-de-contact/p191/biofinity-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/191"
  }, {
    path: "/lentilles-de-contact/p152/acuvue-oasys-for-presbyopia",
    component: _1b085a39,
    name: "fr-FR/product/152"
  }, {
    path: "/lentilles-de-contact/p150/purevision-2-hd-6",
    component: _1b085a39,
    name: "fr-FR/product/150"
  }, {
    path: "/vitamines-pour-les-yeux/p148/ocuvite-complete",
    component: _1b085a39,
    name: "fr-FR/product/148"
  }, {
    path: "/lentilles-de-contact/p149/purevision-2-hd",
    component: _1b085a39,
    name: "fr-FR/product/149"
  }, {
    path: "/solutions-multifonctions/p146/options-multi",
    component: _1b085a39,
    name: "fr-FR/product/146"
  }, {
    path: "/solutions-multifonctions/p145/solocare-aqua-2x360-ml",
    component: _1b085a39,
    name: "fr-FR/product/145"
  }, {
    path: "/solutions-multifonctions/p144/solocare-aqua",
    component: _1b085a39,
    name: "fr-FR/product/144"
  }, {
    path: "/solutions-multifonctions/p142/renu-solution-multifonctions-3x240-ml",
    component: _1b085a39,
    name: "fr-FR/product/142"
  }, {
    path: "/solutions-multifonctions/p141/renu-multi-plus-3x240-ml",
    component: _1b085a39,
    name: "fr-FR/product/141"
  }, {
    path: "/solutions-multifonctions/p140/renu-multi-plus",
    component: _1b085a39,
    name: "fr-FR/product/140"
  }, {
    path: "/solutions-multifonctions/p139/renu-multi-plus-60-ml",
    component: _1b085a39,
    name: "fr-FR/product/139"
  }, {
    path: "/solutions-pour-lentilles-rigides/p136/boston-simplus-flight-pack",
    component: _1b085a39,
    name: "fr-FR/product/136"
  }, {
    path: "/solutions-pour-lentilles-rigides/p135/boston-simplus-solution-multifonctions",
    component: _1b085a39,
    name: "fr-FR/product/135"
  }, {
    path: "/solutions-pour-lentilles-rigides/p134/boston-advance-conditioning-solution",
    component: _1b085a39,
    name: "fr-FR/product/134"
  }, {
    path: "/solutions-multifonctions/p132/biotrue-2x300-ml",
    component: _1b085a39,
    name: "fr-FR/product/132"
  }, {
    path: "/solutions-pour-lentilles-rigides/p133/boston-advance-cleaner",
    component: _1b085a39,
    name: "fr-FR/product/133"
  }, {
    path: "/autres-accessoires/p131/ultrazyme-universal-deproteinisation",
    component: _1b085a39,
    name: "fr-FR/product/131"
  }, {
    path: "/solutions-pour-lentilles-rigides/p130/total-care-nettoyage-quotidien",
    component: _1b085a39,
    name: "fr-FR/product/130"
  }, {
    path: "/solutions-pour-lentilles-rigides/p129/total-care-solution",
    component: _1b085a39,
    name: "fr-FR/product/129"
  }, {
    path: "/solutions-au-peroxide-dhydrogene/p128/oxysept",
    component: _1b085a39,
    name: "fr-FR/product/128"
  }, {
    path: "/solutions-salines/p127/lens-plus-ocupure",
    component: _1b085a39,
    name: "fr-FR/product/127"
  }, {
    path: "/solutions-pour-lentilles-rigides/p126/complete-revitalens-2x360-ml",
    component: _1b085a39,
    name: "fr-FR/product/126"
  }, {
    path: "/solutions-pour-lentilles-rigides/p125/complete-revitalens",
    component: _1b085a39,
    name: "fr-FR/product/125"
  }, {
    path: "/gouttes-pour-les-yeux/p120/blink-intensive-tears",
    component: _1b085a39,
    name: "fr-FR/product/120"
  }, {
    path: "/gouttes-pour-les-yeux/p121/blink-intensive-tears-20-dosettes",
    component: _1b085a39,
    name: "fr-FR/product/121"
  }, {
    path: "/gouttes-pour-les-yeux/p119/blink-n-clean",
    component: _1b085a39,
    name: "fr-FR/product/119"
  }, {
    path: "/gouttes-pour-les-yeux/p118/systane-lubricating-28-dosettes",
    component: _1b085a39,
    name: "fr-FR/product/118"
  }, {
    path: "/solutions-multifonctions/p115/opti-free-replenish-flight-pack",
    component: _1b085a39,
    name: "fr-FR/product/115"
  }, {
    path: "/gouttes-pour-les-yeux/p117/systane-lubricating",
    component: _1b085a39,
    name: "fr-FR/product/117"
  }, {
    path: "/solutions-multifonctions/p114/opti-free-express-2x355-ml",
    component: _1b085a39,
    name: "fr-FR/product/114"
  }, {
    path: "/solutions-multifonctions/p113/opti-free-express",
    component: _1b085a39,
    name: "fr-FR/product/113"
  }, {
    path: "/solutions-multifonctions/p109/biotrue",
    component: _1b085a39,
    name: "fr-FR/product/109"
  }, {
    path: "/lentilles-de-contact/p106/1-day-acuvue-moist-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/106"
  }, {
    path: "/lentilles-de-contact/p104/1-day-acuvue-trueye-90",
    component: _1b085a39,
    name: "fr-FR/product/104"
  }, {
    path: "/lentilles-de-contact/p101/expressions-colors",
    component: _1b085a39,
    name: "fr-FR/product/101"
  }, {
    path: "/lentilles-de-contact/p97/soflens-natural-colors",
    component: _1b085a39,
    name: "fr-FR/product/97"
  }, {
    path: "/lentilles-de-contact/p96/freshlook-one-day",
    component: _1b085a39,
    name: "fr-FR/product/96"
  }, {
    path: "/lentilles-de-contact/p95/freshlook-dimensions-6",
    component: _1b085a39,
    name: "fr-FR/product/95"
  }, {
    path: "/lentilles-de-contact/p94/freshlook-dimensions",
    component: _1b085a39,
    name: "fr-FR/product/94"
  }, {
    path: "/lentilles-de-contact/p93/freshlook-colorblends",
    component: _1b085a39,
    name: "fr-FR/product/93"
  }, {
    path: "/lentilles-de-contact/p92/freshlook-colors",
    component: _1b085a39,
    name: "fr-FR/product/92"
  }, {
    path: "/lentilles-de-contact/p90/air-optix-aqua-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/90"
  }, {
    path: "/lentilles-de-contact/p88/soflens-daily-disposable-toric",
    component: _1b085a39,
    name: "fr-FR/product/88"
  }, {
    path: "/lentilles-de-contact/p87/biofinity-toric",
    component: _1b085a39,
    name: "fr-FR/product/87"
  }, {
    path: "/lentilles-de-contact/p86/acuvue-oasys-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/86"
  }, {
    path: "/lentilles-de-contact/p85/proclear-tailor-made-toric",
    component: _1b085a39,
    name: "fr-FR/product/85"
  }, {
    path: "/lentilles-de-contact/p84/proclear-1-day-90",
    component: _1b085a39,
    name: "fr-FR/product/84"
  }, {
    path: "/lentilles-de-contact/p83/proclear-multifocal-6",
    component: _1b085a39,
    name: "fr-FR/product/83"
  }, {
    path: "/solutions-multifonctions/p82/renu-solution-multifonctions-60-ml",
    component: _1b085a39,
    name: "fr-FR/product/82"
  }, {
    path: "/lentilles-de-contact/p80/proclear-toric-xr",
    component: _1b085a39,
    name: "fr-FR/product/80"
  }, {
    path: "/lentilles-de-contact/p79/proclear-multifocal-xr",
    component: _1b085a39,
    name: "fr-FR/product/79"
  }, {
    path: "/lentilles-de-contact/p77/air-optix-aqua",
    component: _1b085a39,
    name: "fr-FR/product/77"
  }, {
    path: "/lentilles-de-contact/p76/1-day-acuvue-trueye",
    component: _1b085a39,
    name: "fr-FR/product/76"
  }, {
    path: "/lentilles-de-contact/p74/proclear-multifocal-toric",
    component: _1b085a39,
    name: "fr-FR/product/74"
  }, {
    path: "/solutions-salines/p71/sensitive-eyes-plus-saline",
    component: _1b085a39,
    name: "fr-FR/product/71"
  }, {
    path: "/solutions-multifonctions/p72/renu-multifonctions-flight-pack",
    component: _1b085a39,
    name: "fr-FR/product/72"
  }, {
    path: "/gouttes-pour-les-yeux/p69/blink-refreshing",
    component: _1b085a39,
    name: "fr-FR/product/69"
  }, {
    path: "/gouttes-pour-les-yeux/p68/blink-contacts-20-dosettes",
    component: _1b085a39,
    name: "fr-FR/product/68"
  }, {
    path: "/solutions-multifonctions/p66/opti-free-replenish-2x300-ml",
    component: _1b085a39,
    name: "fr-FR/product/66"
  }, {
    path: "/gouttes-pour-les-yeux/p67/blink-contacts",
    component: _1b085a39,
    name: "fr-FR/product/67"
  }, {
    path: "/solutions-multifonctions/p64/opti-free-replenish",
    component: _1b085a39,
    name: "fr-FR/product/64"
  }, {
    path: "/lentilles-de-contact/p63/biofinity",
    component: _1b085a39,
    name: "fr-FR/product/63"
  }, {
    path: "/lentilles-de-contact/p61/proclear-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/61"
  }, {
    path: "/lentilles-de-contact/p60/proclear-1-day",
    component: _1b085a39,
    name: "fr-FR/product/60"
  }, {
    path: "/lentilles-de-contact/p57/biomedics-1-day-extra-toric",
    component: _1b085a39,
    name: "fr-FR/product/57"
  }, {
    path: "/lentilles-de-contact/p54/biomedics-toric",
    component: _1b085a39,
    name: "fr-FR/product/54"
  }, {
    path: "/lentilles-de-contact/p51/acuvue-2",
    component: _1b085a39,
    name: "fr-FR/product/51"
  }, {
    path: "/lentilles-de-contact/p48/air-optix-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/48"
  }, {
    path: "/solutions-multifonctions/p46/renu-solution-multifonctions-120-ml",
    component: _1b085a39,
    name: "fr-FR/product/46"
  }, {
    path: "/lentilles-de-contact/p45/soflens-multi-focal",
    component: _1b085a39,
    name: "fr-FR/product/45"
  }, {
    path: "/lentilles-de-contact/p44/soflens-toric",
    component: _1b085a39,
    name: "fr-FR/product/44"
  }, {
    path: "/solutions-multifonctions/p42/renu-solution-multifonctions",
    component: _1b085a39,
    name: "fr-FR/product/42"
  }, {
    path: "/lentilles-de-contact/p39/biomedics-55-evolution",
    component: _1b085a39,
    name: "fr-FR/product/39"
  }, {
    path: "/lentilles-de-contact/p38/acuvue-oasys",
    component: _1b085a39,
    name: "fr-FR/product/38"
  }, {
    path: "/lentilles-de-contact/p35/proclear-toric",
    component: _1b085a39,
    name: "fr-FR/product/35"
  }, {
    path: "/lentilles-de-contact/p34/proclear-6",
    component: _1b085a39,
    name: "fr-FR/product/34"
  }, {
    path: "/lentilles-de-contact/p33/frequency-xcel-toric",
    component: _1b085a39,
    name: "fr-FR/product/33"
  }, {
    path: "/lentilles-de-contact/p32/frequency-xcel-toric-xr",
    component: _1b085a39,
    name: "fr-FR/product/32"
  }, {
    path: "/lentilles-de-contact/p30/frequency-55-aspheric",
    component: _1b085a39,
    name: "fr-FR/product/30"
  }, {
    path: "/etuis-a-lentilles/p25/etui-a-lentilles-plat",
    component: _1b085a39,
    name: "fr-FR/product/25"
  }, {
    path: "/lentilles-de-contact/p22/air-optix-night-day-aqua",
    component: _1b085a39,
    name: "fr-FR/product/22"
  }, {
    path: "/lentilles-de-contact/p20/soflens-multi-focal-6",
    component: _1b085a39,
    name: "fr-FR/product/20"
  }, {
    path: "/lentilles-de-contact/p19/soflens-toric-6",
    component: _1b085a39,
    name: "fr-FR/product/19"
  }, {
    path: "/lentilles-de-contact/p18/soflens-59",
    component: _1b085a39,
    name: "fr-FR/product/18"
  }, {
    path: "/lentilles-de-contact/p17/soflens-38",
    component: _1b085a39,
    name: "fr-FR/product/17"
  }, {
    path: "/lentilles-de-contact/p16/purevision-multi-focal",
    component: _1b085a39,
    name: "fr-FR/product/16"
  }, {
    path: "/lentilles-de-contact/p14/1-day-acuvue-moist-90",
    component: _1b085a39,
    name: "fr-FR/product/14"
  }, {
    path: "/lentilles-de-contact/p13/1-day-acuvue-moist",
    component: _1b085a39,
    name: "fr-FR/product/13"
  }, {
    path: "/lentilles-de-contact/p8/focus-dailies-all-day-comfort",
    component: _1b085a39,
    name: "fr-FR/product/8"
  }, {
    path: "/lentilles-de-contact/p7/focus-dailies-all-day-comfort-90",
    component: _1b085a39,
    name: "fr-FR/product/7"
  }, {
    path: "/lentilles-de-contact/p6/dailies-aquacomfort-plus-90",
    component: _1b085a39,
    name: "fr-FR/product/6"
  }, {
    path: "/lentilles-de-contact/p5/dailies-aquacomfort-plus",
    component: _1b085a39,
    name: "fr-FR/product/5"
  }, {
    path: "/lentilles-de-contact/p2/soflens-daily-disposable-90",
    component: _1b085a39,
    name: "fr-FR/product/2"
  }, {
    path: "/lentilles-de-contact/p1/soflens-daily-disposable",
    component: _1b085a39,
    name: "fr-FR/product/1"
  }, {
    path: "/lentilles-de-contact/p477/bausch-and-lomb-ultra-presbyopia",
    component: _1b085a39,
    name: "fr-FR/product/477"
  }, {
    path: "/lentilles-de-contact/p478/bausch-and-lomb-ultra-presbyopia-6",
    component: _1b085a39,
    name: "fr-FR/product/478"
  }, {
    path: "/lentilles-de-contact/p479/avaira-vitality-toric",
    component: _1b085a39,
    name: "fr-FR/product/479"
  }, {
    path: "/lentilles-de-contact/p480/biotrue-oneday-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/480"
  }, {
    path: "/lentilles-de-contact/p481/biofinity-energys-6",
    component: _1b085a39,
    name: "fr-FR/product/481"
  }, {
    path: "/lentilles-de-contact/p482/air-optix-plus-hydraglyde-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/482"
  }, {
    path: "/c1/lentilles-journalieres",
    component: _25921ca4,
    name: "fr-FR/category/1"
  }, {
    path: "/c2/lentilles-bimensuelles",
    component: _25921ca4,
    name: "fr-FR/category/2"
  }, {
    path: "/c3/lentilles-mensuelles",
    component: _25921ca4,
    name: "fr-FR/category/3"
  }, {
    path: "/c4/lentilles-a-port-prolonge",
    component: _25921ca4,
    name: "fr-FR/category/4"
  }, {
    path: "/c5/lentilles-toriques-pour-l-astigmatisme",
    component: _25921ca4,
    name: "fr-FR/category/5"
  }, {
    path: "/c6/lentilles-progressives-multifocales",
    component: _25921ca4,
    name: "fr-FR/category/6"
  }, {
    path: "/c7/solutions-et-accessoires",
    component: _25921ca4,
    name: "fr-FR/category/7"
  }, {
    path: "/c8/lentilles-colorees",
    redirect: "/b345/lentilles-de-couleur"
  }, {
    path: "/b309/biotrue",
    component: _387cb151,
    name: "fr-FR/brand/309"
  }, {
    path: "/b173/biotrue-solutions",
    component: _387cb151,
    name: "fr-FR/brand/173"
  }, {
    path: "/b134/blink",
    component: _387cb151,
    name: "fr-FR/brand/134"
  }, {
    path: "/b129/lentilles-de-contact-bleues",
    component: _387cb151,
    name: "fr-FR/brand/129"
  }, {
    path: "/b141/lentilles-de-contact-marron",
    component: _387cb151,
    name: "fr-FR/brand/141"
  }, {
    path: "/b302/clariti",
    component: _387cb151,
    name: "fr-FR/brand/302"
  }, {
    path: "/b149/coopervision-expressions",
    component: _387cb151,
    name: "fr-FR/brand/149"
  }, {
    path: "/b92/freshlook",
    component: _387cb151,
    name: "fr-FR/brand/92"
  }, {
    path: "/b130/lentilles-de-contact-vertes",
    component: _387cb151,
    name: "fr-FR/brand/130"
  }, {
    path: "/b143/lentilles-de-contact-grises",
    component: _387cb151,
    name: "fr-FR/brand/143"
  }, {
    path: "/b280/hycosan",
    component: _387cb151,
    name: "fr-FR/brand/280"
  }, {
    path: "/b310/myday",
    component: _387cb151,
    name: "fr-FR/brand/310"
  }, {
    path: "/b279/optrex",
    component: _387cb151,
    name: "fr-FR/brand/279"
  }, {
    path: "/b316/opti-free",
    component: _387cb151,
    name: "fr-FR/brand/316"
  }, {
    path: "/b173/biotrue-solution",
    redirect: "/b173/biotrue-solutions"
  }, {
    path: "/b1/acuvue",
    component: _387cb151,
    name: "fr-FR/brand/1"
  }, {
    path: "/b315/renu-solutions",
    component: _387cb151,
    name: "fr-FR/brand/315"
  }, {
    path: "/b170/systane",
    component: _387cb151,
    name: "fr-FR/brand/170"
  }, {
    path: "/b2/bausch-lomb",
    component: _387cb151,
    name: "fr-FR/brand/2"
  }, {
    path: "/b3/alcon",
    component: _387cb151,
    name: "fr-FR/brand/3"
  }, {
    path: "/b4/coopervision",
    component: _387cb151,
    name: "fr-FR/brand/4"
  }, {
    path: "/b6/1-day-acuvue",
    component: _387cb151,
    name: "fr-FR/brand/6"
  }, {
    path: "/b7/acuvue-2",
    component: _387cb151,
    name: "fr-FR/brand/7"
  }, {
    path: "/b16/acuvue-oasys",
    component: _387cb151,
    name: "fr-FR/brand/16"
  }, {
    path: "/b19/air-optix",
    component: _387cb151,
    name: "fr-FR/brand/19"
  }, {
    path: "/b90/avaira",
    component: _387cb151,
    name: "fr-FR/brand/90"
  }, {
    path: "/b27/biofinity",
    component: _387cb151,
    name: "fr-FR/brand/27"
  }, {
    path: "/b84/biomedics",
    component: _387cb151,
    name: "fr-FR/brand/84"
  }, {
    path: "/b62/dailies",
    component: _387cb151,
    name: "fr-FR/brand/62"
  }, {
    path: "/b86/frequency",
    component: _387cb151,
    name: "fr-FR/brand/86"
  }, {
    path: "/b75/proclear",
    component: _387cb151,
    name: "fr-FR/brand/75"
  }, {
    path: "/b76/purevision",
    component: _387cb151,
    name: "fr-FR/brand/76"
  }, {
    path: "/b85/soflens",
    component: _387cb151,
    name: "fr-FR/brand/85"
  }, {
    path: "/b301/sauflon-coopervision",
    component: _387cb151,
    name: "fr-FR/brand/301"
  }, {
    path: "/nos-services",
    component: _974a4b7e,
    name: "fr-FR/document/1"
  }, {
    path: "/faq",
    component: _974a4b7e,
    name: "fr-FR/document/6"
  }, {
    path: "/b151/autres-lentilles-de-contact-colorees",
    component: _387cb151,
    name: "fr-FR/brand/151"
  }, {
    path: "/c103/etuis-a-lentilles",
    component: _25921ca4,
    name: "fr-FR/category/103"
  }, {
    path: "/b317/lentilles-de-contact",
    component: _387cb151,
    name: "fr-FR/brand/317"
  }, {
    path: "/c104/gouttes-pour-les-yeux",
    component: _25921ca4,
    name: "fr-FR/category/104"
  }, {
    path: "/c109/solutions-pour-lentilles-rigides",
    component: _25921ca4,
    name: "fr-FR/category/109"
  }, {
    path: "/c106/solutions-au-peroxide-d-hydrogene",
    component: _25921ca4,
    name: "fr-FR/category/106"
  }, {
    path: "/b314/macushield",
    component: _387cb151,
    name: "fr-FR/brand/314"
  }, {
    path: "/c105/solutions-multifonctions",
    component: _25921ca4,
    name: "fr-FR/category/105"
  }, {
    path: "/c108/vitamines-pour-les-yeux",
    component: _25921ca4,
    name: "fr-FR/category/108"
  }, {
    path: "/c107/sprays-pour-les-yeux-et-accessoires",
    component: _25921ca4,
    name: "fr-FR/category/107"
  }, {
    path: "/c112/solutions-salines",
    component: _25921ca4,
    name: "fr-FR/category/112"
  }, {
    path: "/b185/solo-care-aqua",
    component: _387cb151,
    name: "fr-FR/brand/185"
  }, {
    path: "/compte/commandes",
    component: _829d8442,
    name: "fr-FR/orders"
  }, {
    path: "/b3/ciba-vision-alcon",
    redirect: "/b3/alcon"
  }, {
    path: "/rappel",
    component: _974a4b7e,
    name: "fr-FR/document/15"
  }, {
    path: "/lentilles-de-couleur-document-archive",
    redirect: "/b345/lentilles-de-couleur"
  }, {
    path: "/b62/focus-dailies",
    redirect: "/b62/dailies"
  }, {
    path: "/contactez-nous",
    component: _974a4b7e,
    name: "fr-FR/document/2"
  }, {
    path: "/solutions-pour-lentilles-rigides/p134/boston-conditioning-solution",
    redirect: "/solutions-pour-lentilles-rigides/p134/boston-advance-conditioning-solution"
  }, {
    path: "/gouttes-pour-les-yeux/p262/systane-gel",
    redirect: "/gouttes-pour-les-yeux/p262/systane-gel-drops"
  }, {
    path: "/lentilles-de-contact/p80/proclear-toric",
    redirect: "/lentilles-de-contact/p80/proclear-toric-xr"
  }, {
    path: "/lentilles-de-contact/p79/proclear-multifocal",
    redirect: "/lentilles-de-contact/p79/proclear-multifocal-xr"
  }, {
    path: "/gouttes-pour-les-yeux/p69/blink-revitalising",
    redirect: "/gouttes-pour-les-yeux/p69/blink-refreshing"
  }, {
    path: "/solutions-pour-lentilles-rigides/p133/boston-cleaner",
    redirect: "/solutions-pour-lentilles-rigides/p133/boston-advance-cleaner"
  }, {
    path: "/utilisation-de-cookies",
    component: _974a4b7e,
    name: "fr-FR/document/124"
  }, {
    path: "/lentilles-colorees",
    redirect: "/b345/lentilles-de-couleur"
  }, {
    path: "/tarifs-et-options-de-livraison",
    component: _974a4b7e,
    name: "fr-FR/document/10"
  }, {
    path: "/retours-et-remboursements",
    component: _974a4b7e,
    name: "fr-FR/document/11"
  }, {
    path: "/page-introuvable",
    component: _974a4b7e,
    name: "fr-FR/document/44"
  }, {
    path: "/privacy",
    redirect: "/gestion-des-donna-es-personnelles"
  }, {
    path: "/gestion-des-donna-es-personnelles",
    component: _974a4b7e,
    name: "fr-FR/document/9"
  }, {
    path: "/conditions-generales",
    component: _974a4b7e,
    name: "fr-FR/document/8"
  }, {
    path: "/b158/alcon-produits-soin-des-yeux",
    component: _387cb151,
    name: "fr-FR/brand/158"
  }, {
    path: "/b110/lentilles-en-silicone-hydrogel",
    component: _387cb151,
    name: "fr-FR/brand/110"
  }, {
    path: "/b110/silicone-hydrogel",
    redirect: "/b110/lentilles-en-silicone-hydrogel"
  }, {
    path: "/b110/lentilles-de-contact-silicone-hydrogel",
    redirect: "/b110/lentilles-en-silicone-hydrogel"
  }, {
    path: "/lentilles-de-contact/p483/live-1-day",
    component: _1b085a39,
    name: "fr-FR/product/483"
  }, {
    path: "/comment-mettre-vos-lentilles-de-contact",
    component: _974a4b7e,
    name: "fr-FR/document/7"
  }, {
    path: "/lentilles-de-contact/p484/air-optix-plus-hydraglyde-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/484"
  }, {
    path: "/b345/lentilles-de-couleur",
    component: _387cb151,
    name: "fr-FR/brand/345"
  }, {
    path: "/comment-lire-une-ordonnance-de-lentilles-de-contact-",
    component: _974a4b7e,
    name: "fr-FR/document/127"
  }, {
    path: "/b346/bausch-lomb-ultra",
    component: _387cb151,
    name: "fr-FR/brand/346"
  }, {
    path: "/b347/1-day-acuvue-moist",
    component: _387cb151,
    name: "fr-FR/brand/347"
  }, {
    path: "/comment-lire-une-ordonnance-de-lentilles-de-contact",
    redirect: "/comment-lire-une-ordonnance-de-lentilles-de-contact-"
  }, {
    path: "/a-propos-de-lenstore",
    component: _974a4b7e,
    name: "fr-FR/document/77"
  }, {
    path: "/b123/lentilles-toriques-journalie-res",
    component: _387cb151,
    name: "fr-FR/brand/123"
  }, {
    path: "/b330/lentilles-progressives-multifocales-journalie-res",
    component: _387cb151,
    name: "fr-FR/brand/330"
  }, {
    path: "/b123/lentilles-toriques-journalia-res",
    redirect: "/b123/lentilles-toriques-journalie-res"
  }, {
    path: "/comment-acheter-des-lentilles-pas-che-res-",
    component: _974a4b7e,
    name: "fr-FR/document/135"
  }, {
    path: "/age-pour-porter-des-lentilles",
    component: _974a4b7e,
    name: "fr-FR/document/136"
  }, {
    path: "/comment-acheter-des-lentilles-pas-cha-res-",
    redirect: "/comment-acheter-des-lentilles-pas-che-res-"
  }, {
    path: "/lentilles-toriques-journalia-res",
    redirect: "/b123/lentilles-toriques-journalie-res"
  }, {
    path: "/lentilles-progressives-ou-multifocales-journalia-res",
    redirect: "/b330/lentilles-progressives-multifocales-journalie-res"
  }, {
    path: "/c5/lentilles-toriques",
    redirect: "/c5/lentilles-toriques-pour-l-astigmatisme"
  }, {
    path: "/c6/lentilles-multifocales",
    redirect: "/c6/lentilles-progressives-multifocales"
  }, {
    path: "/c5/lentilles-toriques-pour-astigmatisme",
    redirect: "/c5/lentilles-toriques-pour-l-astigmatisme"
  }, {
    path: "/lentilles-progressives-ou-multifocales-journalie-res",
    redirect: "/b330/lentilles-progressives-multifocales-journalie-res"
  }, {
    path: "/c2/lentilles-bi-mensuelles",
    redirect: "/c2/lentilles-bimensuelles"
  }, {
    path: "/garantie-prix-le-moins-cher",
    component: _974a4b7e,
    name: "fr-FR/document/133"
  }, {
    path: "/b343/livraison-gratuite",
    component: _387cb151,
    name: "fr-FR/brand/343"
  }, {
    path: "/b348/acheter-des-lentilles-pas-cheres",
    component: _387cb151,
    name: "fr-FR/brand/348"
  }, {
    path: "/acheter-des-lentilles-pas-cheres",
    redirect: "/b348/acheter-des-lentilles-pas-cheres"
  }, {
    path: "/lentilles-toriques-journalie-res",
    redirect: "/b123/lentilles-toriques-journalie-res"
  }, {
    path: "/lentilles-progressives-multifocales-journalie-res",
    redirect: "/b330/lentilles-progressives-multifocales-journalie-res"
  }, {
    path: "/lentilles-de-couleur",
    redirect: "/b345/lentilles-de-couleur"
  }, {
    path: "/b332/lenstore-vs-marque-blanche-afflelou-",
    component: _387cb151,
    name: "fr-FR/brand/332"
  }, {
    path: "/b341/lenstore-vs-krys-",
    component: _387cb151,
    name: "fr-FR/brand/341"
  }, {
    path: "/b19/air-optix-aqua",
    redirect: "/b19/air-optix"
  }, {
    path: "/lentilles-de-contact-et-hiver",
    component: _974a4b7e,
    name: "fr-FR/document/137"
  }, {
    path: "/astigmatisme-signes-et-symptomes",
    component: _974a4b7e,
    name: "fr-FR/document/138"
  }, {
    path: "/nettoyer-et-entretenir-vos-lentilles",
    component: _974a4b7e,
    name: "fr-FR/document/139"
  }, {
    path: "/entretenir-et-nettoyer-vos-lentilles",
    redirect: "/nettoyer-et-entretenir-vos-lentilles"
  }, {
    path: "/b349/meilleures-marques-de-lentilles-de-contact",
    component: _387cb151,
    name: "fr-FR/brand/349"
  }, {
    path: "/remboursement-de-vos-lentilles-achetees-en-ligne-",
    component: _974a4b7e,
    name: "fr-FR/document/143"
  }, {
    path: "/comment-vous-faire-rembourser-vos-lentilles-achetees-en-ligne-",
    redirect: "/remboursement-de-vos-lentilles-achetees-en-ligne-"
  }, {
    path: "/6-conseils-simples-et-efficaces",
    component: _974a4b7e,
    name: "fr-FR/document/144"
  }, {
    path: "/coronavirus-6-conseils-simples-et-efficaces",
    redirect: "/6-conseils-simples-et-efficaces"
  }, {
    path: "/achetez-vos-lentilles-en-ligne",
    component: _974a4b7e,
    name: "fr-FR/document/145"
  }, {
    path: "/6-verites-lentilles-coronavirus",
    component: _974a4b7e,
    name: "fr-FR/document/146"
  }, {
    path: "/6-va-rita-s-lentilles-coronavirus",
    redirect: "/6-verites-lentilles-coronavirus"
  }, {
    path: "/covid-19-informations-aux-clients",
    component: _974a4b7e,
    name: "fr-FR/document/148"
  }, {
    path: "/conseils-soins-des-yeux-teletravail",
    component: _974a4b7e,
    name: "fr-FR/document/147"
  }, {
    path: "/conseils-soins-des-yeux-domicile",
    redirect: "/conseils-soins-des-yeux-teletravail"
  }, {
    path: "/habitudes-saines-yeux-sains",
    component: _974a4b7e,
    name: "fr-FR/document/150"
  }, {
    path: "/code-promo-isic-etudiants",
    component: _974a4b7e,
    name: "fr-FR/document/151"
  }, {
    path: "/b392/lenstore-vs-marque-blanche-afflelou-",
    component: _387cb151,
    name: "fr-FR/brand/392"
  }, {
    path: "/b393/lenstore-vs-marque-blanche-krys-",
    component: _387cb151,
    name: "fr-FR/brand/393"
  }, {
    path: "/b341/lenstore-vs-marque-blanche-krys-",
    redirect: "/b341/lenstore-vs-krys-"
  }, {
    path: "/lenstore-vs-marque-blanche-krys-",
    redirect: "/b393/lenstore-vs-marque-blanche-krys-"
  }, {
    path: "/enquaate-de-satisfaction-aupra-s-des-clients-lenstore-conditions-ga-na-rales",
    component: _974a4b7e,
    name: "fr-FR/document/157"
  }, {
    path: "/lentilles-de-contact/p488/miru-1day-flat",
    component: _1b085a39,
    name: "fr-FR/product/488"
  }, {
    path: "/lentilles-de-contact/p489/miru-1month",
    component: _1b085a39,
    name: "fr-FR/product/489"
  }, {
    path: "/lentilles-de-contact/p490/miru-1month_toric",
    component: _1b085a39,
    name: "fr-FR/product/490"
  }, {
    path: "/lentilles-de-contact/p491/miru-1month_multifocal",
    component: _1b085a39,
    name: "fr-FR/product/491"
  }, {
    path: "/b354/miru",
    component: _387cb151,
    name: "fr-FR/brand/396"
  }, {
    path: "/b359/menicon",
    component: _387cb151,
    name: "fr-FR/brand/394"
  }, {
    path: "/uk-efs-ltd-politique-fiscale",
    component: _974a4b7e,
    name: "fr-FR/document/115"
  }, {
    path: "/lentilles-de-contact/p492/precision-1",
    component: _1b085a39,
    name: "fr-FR/product/492"
  }, {
    path: "/lentilles-de-contact/p494/precision-1-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/494"
  }, {
    path: "/lentilles-de-contact/p493/precision-1",
    component: _1b085a39,
    name: "fr-FR/product/493"
  }, {
    path: "/lentilles-de-contact/p495/precision-1-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/495"
  }, {
    path: "/lentilles-de-couleur/p496/angelic-white-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "fr-FR/product/496"
  }, {
    path: "/lentilles-de-couleur/p498/red-vampire-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "fr-FR/product/498"
  }, {
    path: "/lentilles-de-couleur/p497/black-out-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "fr-FR/product/497"
  }, {
    path: "/lentilles-de-couleur/p499/white-out-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "fr-FR/product/499"
  }, {
    path: "/lentilles-de-couleur/p500/zombie-yellow-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "fr-FR/product/500"
  }, {
    path: "/lentilles-de-couleur/p501/blue-walker-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "fr-FR/product/501"
  }, {
    path: "/lentilles-de-couleur/p504/angelic-red-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/504"
  }, {
    path: "/lentilles-de-couleur/p505/banshee-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/505"
  }, {
    path: "/lentilles-de-couleur/p506/black-wolf-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/506"
  }, {
    path: "/lentilles-de-couleur/p507/lestat-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/507"
  }, {
    path: "/lentilles-de-couleur/p508/manson-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/508"
  }, {
    path: "/lentilles-de-couleur/p509/red-cat-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/509"
  }, {
    path: "/lentilles-de-couleur/p510/red-vampire-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/510"
  }, {
    path: "/lentilles-de-couleur/p511/smiley-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/511"
  }, {
    path: "/lentilles-de-couleur/p512/white-out-clearcolor-phantom",
    component: _1b085a39,
    name: "fr-FR/product/512"
  }, {
    path: "/b363/precision-1",
    component: _387cb151,
    name: "fr-FR/brand/404"
  }, {
    path: "/solutions-multifonctions/p513/all-in-one-light-format-voyage-100ml",
    component: _1b085a39,
    name: "fr-FR/product/513"
  }, {
    path: "/solutions-multifonctions/p514/all-in-one-light-250ml",
    component: _1b085a39,
    name: "fr-FR/product/514"
  }, {
    path: "/solutions-multifonctions/p515/all-in-one-light-360ml",
    component: _1b085a39,
    name: "fr-FR/product/515"
  }, {
    path: "/solutions-multifonctions/p516/all-in-one-light-3x250ml",
    component: _1b085a39,
    name: "fr-FR/product/516"
  }, {
    path: "/solutions-multifonctions/p517/hy-care-format-voyage-100ml",
    component: _1b085a39,
    name: "fr-FR/product/517"
  }, {
    path: "/solutions-au-peroxide-dhydrogene/p518/refine-one-step-format-voyage-100ml",
    component: _1b085a39,
    name: "fr-FR/product/518"
  }, {
    path: "/solutions-au-peroxide-dhydrogene/p519/refine-one-step-250ml",
    component: _1b085a39,
    name: "fr-FR/product/519"
  }, {
    path: "/solutions-au-peroxide-dhydrogene/p520/refine-one-step-360ml",
    component: _1b085a39,
    name: "fr-FR/product/520"
  }, {
    path: "/solutions-au-peroxide-dhydrogene/p521/refine-one-step-3x250ml",
    component: _1b085a39,
    name: "fr-FR/product/521"
  }, {
    path: "/solutions-salines/p523/saline-360ml",
    component: _1b085a39,
    name: "fr-FR/product/523"
  }, {
    path: "/solutions-pour-lentilles-rigides/p524/delta-cleaner-20ml",
    component: _1b085a39,
    name: "fr-FR/product/524"
  }, {
    path: "/solutions-pour-lentilles-rigides/p525/delta-plus-format-voyage-100ml",
    component: _1b085a39,
    name: "fr-FR/product/525"
  }, {
    path: "/solutions-pour-lentilles-rigides/p526/delta-multi-pack",
    component: _1b085a39,
    name: "fr-FR/product/526"
  }, {
    path: "/gouttes-pour-les-yeux/p527/comfort-drops-20ml",
    component: _1b085a39,
    name: "fr-FR/product/527"
  }, {
    path: "/solutions-multifonctions/p513/all-in-one-light-travel-pack-100ml",
    redirect: "/solutions-multifonctions/p513/all-in-one-light-format-voyage-100ml"
  }, {
    path: "/solutions-multifonctions/p517/hy-care-travel-pack-100ml",
    redirect: "/solutions-multifonctions/p517/hy-care-format-voyage-100ml"
  }, {
    path: "/solutions-au-peroxide-dhydrogene/p518/refine-one-step-travel-pack-100ml",
    redirect: "/solutions-au-peroxide-dhydrogene/p518/refine-one-step-format-voyage-100ml"
  }, {
    path: "/solutions-salines/p522/saline-format-voyage-100ml",
    component: _1b085a39,
    name: "fr-FR/product/522"
  }, {
    path: "/solutions-pour-lentilles-rigides/p525/delta-plus-travel-pack-100ml",
    redirect: "/solutions-pour-lentilles-rigides/p525/delta-plus-format-voyage-100ml"
  }, {
    path: "/gouttes-pour-les-yeux/p527/comf-drops-20ml",
    redirect: "/gouttes-pour-les-yeux/p527/comfort-drops-20ml"
  }, {
    path: "/solutions-salines/p523/preservative-free-saline-360ml",
    redirect: "/solutions-salines/p523/saline-360ml"
  }, {
    path: "/solutions-salines/p522/preservative-free-saline-format-voyage-100ml",
    redirect: "/solutions-salines/p522/saline-format-voyage-100ml"
  }, {
    path: "/solutions-salines/p523/saline-solution-360ml",
    redirect: "/solutions-salines/p523/saline-360ml"
  }, {
    path: "/b406/clearlab",
    component: _387cb151,
    name: "fr-FR/brand/406"
  }, {
    path: "/b407/clearcolor-phantom",
    component: _387cb151,
    name: "fr-FR/brand/407"
  }, {
    path: "/b408/clearcolor-phantom-1-day",
    component: _387cb151,
    name: "fr-FR/brand/408"
  }, {
    path: "/solutions-multifonctions/p516/all-in-one-light-2x250ml",
    redirect: "/solutions-multifonctions/p516/all-in-one-light-3x250ml"
  }, {
    path: "/lentilles-de-couleur/p496/angelic-white-clearcolor-1day-phantom",
    redirect: "/lentilles-de-couleur/p496/angelic-white-clearcolor-1-day-phantom"
  }, {
    path: "/lentilles-de-couleur/p497/black-out-clearcolor-1day-phantom",
    redirect: "/lentilles-de-couleur/p497/black-out-clearcolor-1-day-phantom"
  }, {
    path: "//lentilles-de-couleur/p498/red-vampire-clearcolor-1day-phantom",
    redirect: "/lentilles-de-couleur/p498/red-vampire-clearcolor-1-day-phantom"
  }, {
    path: "/lentilles-de-couleur/p499/white-out-clearcolor-1day-phantom",
    redirect: "/lentilles-de-couleur/p499/white-out-clearcolor-1-day-phantom"
  }, {
    path: "/lentilles-de-couleur/p500/zombie-yellow-clearcolor-1day-phantom",
    redirect: "/lentilles-de-couleur/p500/zombie-yellow-clearcolor-1-day-phantom"
  }, {
    path: "/lentilles-de-couleur/p501/blue-walker-clearcolor-1day-phantom",
    redirect: "/lentilles-de-couleur/p501/blue-walker-clearcolor-1-day-phantom"
  }, {
    path: "//lentilles-de-couleur/p498/red-vampire-clearcolor-1-day-phantom",
    redirect: "/lentilles-de-couleur/p498/red-vampire-clearcolor-1-day-phantom"
  }, {
    path: "/b96/-lentilles-de-couleur-avec-correction",
    component: _387cb151,
    name: "fr-FR/brand/96"
  }, {
    path: "/b211/lentilles-halloween-avec-correction",
    component: _387cb151,
    name: "fr-FR/brand/211"
  }, {
    path: "/b405/lentilles-halloween",
    component: _387cb151,
    name: "fr-FR/brand/405"
  }, {
    path: "/b409/-lentilles-de-couleur-mode-et-tendances",
    component: _387cb151,
    name: "fr-FR/brand/409"
  }, {
    path: "/lentilles-de-contact/p528/air-optix-plus-hydraglyde-6",
    component: _1b085a39,
    name: "fr-FR/product/528"
  }, {
    path: "/lentilles-de-contact/p529/air-optix-plus-hydraglyde-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/529"
  }, {
    path: "/lentilles-de-contact/p530/air-optix-plus-hydraglyde-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/530"
  }, {
    path: "/solutions-multifonctions/p485/acuvue-revitalens-300ml",
    component: _1b085a39,
    name: "fr-FR/product/485"
  }, {
    path: "/solutions-multifonctions/p486/acuvue-revitalens-2x300ml",
    component: _1b085a39,
    name: "fr-FR/product/486"
  }, {
    path: "/lentilles-de-couleur/p531/light-blue-clearcolor-1-day",
    component: _1b085a39,
    name: "fr-FR/product/531"
  }, {
    path: "/lentilles-de-couleur/p532/grey-clearcolor-1-day",
    component: _1b085a39,
    name: "fr-FR/product/532"
  }, {
    path: "/lentilles-de-couleur/p533/hazel-clearcolor-1-day",
    component: _1b085a39,
    name: "fr-FR/product/533"
  }, {
    path: "/lentilles-de-couleur/p534/blue-clearcolor-1-day",
    component: _1b085a39,
    name: "fr-FR/product/534"
  }, {
    path: "/lentilles-de-couleur/p535/green-clearcolor-1-day",
    component: _1b085a39,
    name: "fr-FR/product/535"
  }, {
    path: "/lentilles-de-couleur/p532/gray-clearcolor-1-day",
    redirect: "/lentilles-de-couleur/p532/grey-clearcolor-1-day"
  }, {
    path: "/b410/coopervision-solutions",
    component: _387cb151,
    name: "fr-FR/brand/410"
  }, {
    path: "/lentilles-de-contact/p542/miru-1day-upside",
    component: _1b085a39,
    name: "fr-FR/product/542"
  }, {
    path: "/lentilles-de-contact/p547/total30",
    component: _1b085a39,
    name: "fr-FR/product/547"
  }, {
    path: "/lentilles-de-contact/p548/total30-6",
    component: _1b085a39,
    name: "fr-FR/product/548"
  }, {
    path: "/lentilles-de-contact/p544/bausch-and-lomb-ultra-for-astigmatism-3",
    component: _1b085a39,
    name: "fr-FR/product/544"
  }, {
    path: "/lentilles-de-contact/p545/bausch-and-lomb-ultra-for-astigmatism-6",
    component: _1b085a39,
    name: "fr-FR/product/545"
  }, {
    path: "/lentilles-de-contact/p546/bausch-and-lomb-ultra-multifocal-for-astigmatism-6",
    component: _1b085a39,
    name: "fr-FR/product/546"
  }, {
    path: "/lentilles-de-contact/p549/miru-1day-flat-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/549"
  }, {
    path: "/lentilles-de-contact/p550/miru-1day-flat-toric",
    component: _1b085a39,
    name: "fr-FR/product/550"
  }, {
    path: "/login",
    component: _00ef9433,
    name: "fr-FR/login"
  }, {
    path: "/lentilles-de-contact/p551/miru-1day-upside-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/551"
  }, {
    path: "/lentilles-de-contact/p552/bausch-and-lomb-ultra-one-day",
    component: _1b085a39,
    name: "fr-FR/product/552"
  }, {
    path: "/lentilles-de-contact/p543/acuvue-oasys-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/543"
  }, {
    path: "/lentilles-de-contact/p556/myday-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/556"
  }, {
    path: "/lentilles-de-contact/p6/acucue-oasys-multifocal",
    redirect: "/lentilles-de-contact/p543/acuvue-oasys-multifocal"
  }, {
    path: "/lentilles-de-contact/p6/acuvue-oasys-multifocal",
    redirect: "/lentilles-de-contact/p543/acuvue-oasys-multifocal"
  }, {
    path: "/creez-un-compte",
    component: _b33f986e,
    name: "fr-FR/register"
  }, {
    path: "/paiement",
    component: _18205bd0,
    name: "fr-FR/payment"
  }, {
    path: "/changer-mot-de-passe",
    component: _3d98be02,
    name: "fr-FR/reset-password"
  }, {
    path: "/compte/changer-mot-de-passe",
    component: _ccd8623c,
    name: "fr-FR/change-password"
  }, {
    path: "/compte",
    component: _b0dd6f12,
    name: "fr-FR/account"
  }, {
    path: "/mes-commandes",
    redirect: "/compte/commandes"
  }, {
    path: "/compte/informations-personnelles",
    component: _1502f9db,
    name: "fr-FR/profile"
  }, {
    path: "/compte/preferences-de-communication",
    component: _08e7896a,
    name: "fr-FR/communication-preferences"
  }, {
    path: "/compte/adresses",
    component: _89a7e070,
    name: "fr-FR/address-book"
  }, {
    path: "/panier",
    component: _99c1be28,
    name: "fr-FR/basket"
  }, {
    path: "/mot-de-passe-oublie",
    component: _c4961b62,
    name: "fr-FR/forgot-password"
  }, {
    path: "/livraison",
    component: _34d0109a,
    name: "fr-FR/delivery"
  }, {
    path: "/confirmation-de-la-commande",
    component: _4f90837a,
    name: "fr-FR/order-confirmation"
  }, {
    path: "/se-connecter",
    redirect: "/login"
  }, {
    path: "/lentilles-de-contact/p557/dailies-total1-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/557"
  }, {
    path: "/lentilles-de-contact/p558/dailies-total1-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/558"
  }, {
    path: "/gerer-vos-paiements",
    component: _6171e290,
    name: "fr-FR/payment-management"
  }, {
    path: "/mode-d-emploi",
    component: _974a4b7e,
    name: "fr-FR/document/159"
  }, {
    path: "/b214/codes-de-reduction",
    component: _387cb151,
    name: "fr-FR/brand/214"
  }, {
    path: "/lentilles-de-contact/p559/acuvue-oasys-max-1-day",
    component: _1b085a39,
    name: "fr-FR/product/559"
  }, {
    path: "/lentilles-de-contact/p560/acuvue-oasys-max-1-day-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/560"
  }, {
    path: "/b411/acuvue-oasys-max",
    component: _387cb151,
    name: "fr-FR/brand/411"
  }, {
    path: "/affiliation",
    component: _974a4b7e,
    name: "fr-FR/document/42"
  }, {
    path: "/b411/acuvue-oasys-max-1-day",
    redirect: "/b411/acuvue-oasys-max"
  }, {
    path: "/gerer-vos-paiements/abonnement/:id?",
    component: _2030f21f,
    name: "fr-FR/payment-management-child"
  }, {
    path: "/lentilles-de-contact/p561/acuvue-oasys-with-transitions",
    component: _1b085a39,
    name: "fr-FR/product/561"
  }, {
    path: "/compte/abonnement",
    component: _0b6e7d80,
    name: "fr-FR/auto-reorders"
  }, {
    path: "/gouttes-pour-les-yeux/p553/systane-complete-sans-conservateur",
    component: _1b085a39,
    name: "fr-FR/product/553"
  }, {
    path: "/gouttes-pour-les-yeux/p554/systane-hydratation-sans-conservateur",
    component: _1b085a39,
    name: "fr-FR/product/554"
  }, {
    path: "/gouttes-pour-les-yeux/p554/systane-hidratation-sans-conservateur",
    redirect: "/gouttes-pour-les-yeux/p554/systane-hydratation-sans-conservateur"
  }, {
    path: "/gouttes-pour-les-yeux/p555/systane-ultra-sans-conservateur",
    component: _1b085a39,
    name: "fr-FR/product/555"
  }, {
    path: "/compte/abonnement/commandes/:id?",
    component: _74a9990f,
    name: "fr-FR/auto-reorders-child"
  }, {
    path: "/sitemap",
    component: _974a4b7e,
    name: "fr-FR/document/60"
  }, {
    path: "/lentilles-de-contact/p562/biotrue-oneday-for-presbyopia-90",
    component: _1b085a39,
    name: "fr-FR/product/562"
  }, {
    path: "/dsar",
    component: _974a4b7e,
    name: "fr-FR/document/161"
  }, {
    path: "/lentilles-de-contact/p562/biotrue-oneday-for-astigmatism-90",
    redirect: "/lentilles-de-contact/p562/biotrue-oneday-for-presbyopia-90"
  }, {
    path: "/solutions-multifonctions/p563/biotrue-flight-pack-100ml",
    component: _1b085a39,
    name: "fr-FR/product/563"
  }, {
    path: "/lentilles-de-contact/p564/acuvue-oasys-max-1-day-90",
    component: _1b085a39,
    name: "fr-FR/product/564"
  }, {
    path: "/lentilles-de-contact/p565/acuvue-oasys-max-1-day-multifocal-90",
    component: _1b085a39,
    name: "fr-FR/product/565"
  }, {
    path: "/reductions-etudiantes",
    component: _974a4b7e,
    name: "fr-FR/document/106"
  }, {
    path: "/merci",
    component: _974a4b7e,
    name: "fr-FR/document/162"
  }, {
    path: "/lentilles-de-contact/p566/total30-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/566"
  }, {
    path: "/lentilles-de-contact/p567/total30-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/567"
  }, {
    path: "/lentilles-de-contact/p568/total30-for-astigmatism",
    component: _1b085a39,
    name: "fr-FR/product/568"
  }, {
    path: "/lentilles-de-contact/p569/total30-multifocal",
    component: _1b085a39,
    name: "fr-FR/product/569"
  }, {
    path: "/lentilles-de-contact/p571/everclear-elite-boite-dessai",
    component: _1b085a39,
    name: "fr-FR/product/571"
  }, {
    path: "/lentilles-de-contact/p570/everclear-elite",
    component: _1b085a39,
    name: "fr-FR/product/570"
  }, {
    path: "/domande-frequenti",
    component: _974a4b7e,
    name: "it-IT/document/6"
  }, {
    path: "/contatti",
    component: _974a4b7e,
    name: "it-IT/document/2"
  }, {
    path: "/termini-e-condizioni",
    component: _974a4b7e,
    name: "it-IT/document/8"
  }, {
    path: "/privacy",
    redirect: "/informativa-sulla-privacy"
  }, {
    path: "/spedizione-e-consegna",
    component: _974a4b7e,
    name: "it-IT/document/10"
  }, {
    path: "/politiche-di-rimborso",
    component: _974a4b7e,
    name: "it-IT/document/11"
  }, {
    path: "/pagina-non-trovata-404",
    component: _974a4b7e,
    name: "it-IT/document/44"
  }, {
    path: "/lenti-a-contatto-colorate-documento-archivio",
    redirect: "/b345/lenti-a-contatto-colorate"
  }, {
    path: "/c1/lenti-a-contatto-giornaliere",
    component: _25921ca4,
    name: "it-IT/category/1"
  }, {
    path: "/c2/lenti-a-contatto-quindicinali",
    component: _25921ca4,
    name: "it-IT/category/2"
  }, {
    path: "/c3/lenti-a-contatto-mensili",
    component: _25921ca4,
    name: "it-IT/category/3"
  }, {
    path: "/c4/lenti-a-contatto-permanenti",
    component: _25921ca4,
    name: "it-IT/category/4"
  }, {
    path: "/c5/lenti-toriche-per-astigmatismo",
    component: _25921ca4,
    name: "it-IT/category/5"
  }, {
    path: "/c6/lenti-a-contatto-multifocali",
    component: _25921ca4,
    name: "it-IT/category/6"
  }, {
    path: "/c7/soluzioni-e-accessori",
    component: _25921ca4,
    name: "it-IT/category/7"
  }, {
    path: "/c8/lenti-a-contatto-colorate",
    redirect: "/b345/lenti-a-contatto-colorate"
  }, {
    path: "/c111/soluzioni-fisiologiche",
    component: _25921ca4,
    name: "it-IT/category/111"
  }, {
    path: "/c103/portalenti",
    component: _25921ca4,
    name: "it-IT/category/103"
  }, {
    path: "/c104/colliri",
    component: _25921ca4,
    name: "it-IT/category/104"
  }, {
    path: "/c105/soluzioni-multiuso",
    component: _25921ca4,
    name: "it-IT/category/105"
  }, {
    path: "/c106/soluzioni-perossido",
    component: _25921ca4,
    name: "it-IT/category/106"
  }, {
    path: "/c108/vitamine-per-gli-occhi",
    component: _25921ca4,
    name: "it-IT/category/108"
  }, {
    path: "/c107/spray-per-occhi-e-accessori",
    component: _25921ca4,
    name: "it-IT/category/107"
  }, {
    path: "/c109/soluzioni-per-lenti-rigide",
    component: _25921ca4,
    name: "it-IT/category/109"
  }, {
    path: "/c112/soluzioni-fisiologiche",
    component: _25921ca4,
    name: "it-IT/category/112"
  }, {
    path: "/b309/biotrue",
    component: _387cb151,
    name: "it-IT/brand/309"
  }, {
    path: "/b134/blink",
    component: _387cb151,
    name: "it-IT/brand/134"
  }, {
    path: "/b129/lenti-a-contatto-azzurre",
    component: _387cb151,
    name: "it-IT/brand/129"
  }, {
    path: "/b141/lenti-a-contatto-marroni",
    component: _387cb151,
    name: "it-IT/brand/141"
  }, {
    path: "/b302/clariti",
    component: _387cb151,
    name: "it-IT/brand/302"
  }, {
    path: "/b317/lenti-a-contatto",
    component: _387cb151,
    name: "it-IT/brand/317"
  }, {
    path: "/b149/coopervision-expressions",
    component: _387cb151,
    name: "it-IT/brand/149"
  }, {
    path: "/b92/freshlook",
    component: _387cb151,
    name: "it-IT/brand/92"
  }, {
    path: "/b130/lenti-a-contatto-verdi",
    component: _387cb151,
    name: "it-IT/brand/130"
  }, {
    path: "/b143/lenti-a-contatto-grigie",
    component: _387cb151,
    name: "it-IT/brand/143"
  }, {
    path: "/b280/hycosan",
    component: _387cb151,
    name: "it-IT/brand/280"
  }, {
    path: "/b314/macushield",
    component: _387cb151,
    name: "it-IT/brand/314"
  }, {
    path: "/b310/myday",
    component: _387cb151,
    name: "it-IT/brand/310"
  }, {
    path: "/b316/opti-free",
    component: _387cb151,
    name: "it-IT/brand/316"
  }, {
    path: "/b279/optrex",
    component: _387cb151,
    name: "it-IT/brand/279"
  }, {
    path: "/b315/renu",
    component: _387cb151,
    name: "it-IT/brand/315"
  }, {
    path: "/b110/lenti-a-contatto-silicone-idrogel",
    component: _387cb151,
    name: "it-IT/brand/110"
  }, {
    path: "/b170/systane",
    component: _387cb151,
    name: "it-IT/brand/170"
  }, {
    path: "/b1/acuvue",
    component: _387cb151,
    name: "it-IT/brand/1"
  }, {
    path: "/b2/bausch-lomb",
    component: _387cb151,
    name: "it-IT/brand/2"
  }, {
    path: "/b3/alcon",
    component: _387cb151,
    name: "it-IT/brand/3"
  }, {
    path: "/b4/coopervision",
    component: _387cb151,
    name: "it-IT/brand/4"
  }, {
    path: "/b6/1-day-acuvue",
    component: _387cb151,
    name: "it-IT/brand/6"
  }, {
    path: "/b7/acuvue-2",
    component: _387cb151,
    name: "it-IT/brand/7"
  }, {
    path: "/b16/acuvue-oasys",
    component: _387cb151,
    name: "it-IT/brand/16"
  }, {
    path: "/b19/air-optix",
    component: _387cb151,
    name: "it-IT/brand/19"
  }, {
    path: "/b90/avaira",
    component: _387cb151,
    name: "it-IT/brand/90"
  }, {
    path: "/b27/biofinity",
    component: _387cb151,
    name: "it-IT/brand/27"
  }, {
    path: "/b84/biomedics",
    component: _387cb151,
    name: "it-IT/brand/84"
  }, {
    path: "/b62/dailies",
    component: _387cb151,
    name: "it-IT/brand/62"
  }, {
    path: "/b86/frequency",
    component: _387cb151,
    name: "it-IT/brand/86"
  }, {
    path: "/b75/proclear",
    component: _387cb151,
    name: "it-IT/brand/75"
  }, {
    path: "/b76/purevision",
    component: _387cb151,
    name: "it-IT/brand/76"
  }, {
    path: "/b85/soflens",
    component: _387cb151,
    name: "it-IT/brand/85"
  }, {
    path: "/lenti-a-contatto/p14/1-day-acuvue-moist-90",
    component: _1b085a39,
    name: "it-IT/product/14"
  }, {
    path: "/lenti-a-contatto/p13/1-day-acuvue-moist",
    component: _1b085a39,
    name: "it-IT/product/13"
  }, {
    path: "/lenti-a-contatto/p106/1-day-acuvue-moist-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/106"
  }, {
    path: "/lenti-a-contatto/p250/1-day-acuvue-moist-multifocal",
    component: _1b085a39,
    name: "it-IT/product/250"
  }, {
    path: "/lenti-a-contatto/p76/1-day-acuvue-trueye",
    component: _1b085a39,
    name: "it-IT/product/76"
  }, {
    path: "/lenti-a-contatto/p104/1-day-acuvue-trueye-90",
    component: _1b085a39,
    name: "it-IT/product/104"
  }, {
    path: "/lenti-a-contatto/p51/acuvue-2",
    component: _1b085a39,
    name: "it-IT/product/51"
  }, {
    path: "/lenti-a-contatto/p38/acuvue-oasys",
    component: _1b085a39,
    name: "it-IT/product/38"
  }, {
    path: "/lenti-a-contatto/p458/acuvue-oasys-1-day",
    component: _1b085a39,
    name: "it-IT/product/458"
  }, {
    path: "/lenti-a-contatto/p459/acuvue-oasys-1-day-90",
    component: _1b085a39,
    name: "it-IT/product/459"
  }, {
    path: "/lenti-a-contatto/p468/acuvue-oasys-1-day-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/468"
  }, {
    path: "/lenti-a-contatto/p86/acuvue-oasys-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/86"
  }, {
    path: "/lenti-a-contatto/p152/acuvue-oasys-for-presbyopia",
    component: _1b085a39,
    name: "it-IT/product/152"
  }, {
    path: "/lenti-a-contatto/p474/acuvue-vita",
    component: _1b085a39,
    name: "it-IT/product/474"
  }, {
    path: "/lenti-a-contatto/p77/air-optix-aqua",
    component: _1b085a39,
    name: "it-IT/product/77"
  }, {
    path: "/lenti-a-contatto/p213/air-optix-aqua-6",
    component: _1b085a39,
    name: "it-IT/product/213"
  }, {
    path: "/lenti-a-contatto/p90/air-optix-aqua-multifocal",
    component: _1b085a39,
    name: "it-IT/product/90"
  }, {
    path: "/lenti-a-contatto/p456/air-optix-aqua-multifocal-6",
    component: _1b085a39,
    name: "it-IT/product/456"
  }, {
    path: "/lenti-a-contatto/p232/air-optix-colors",
    component: _1b085a39,
    name: "it-IT/product/232"
  }, {
    path: "/lenti-a-contatto/p48/air-optix-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/48"
  }, {
    path: "/lenti-a-contatto/p455/air-optix-for-astigmatism-6",
    component: _1b085a39,
    name: "it-IT/product/455"
  }, {
    path: "/lenti-a-contatto/p22/air-optix-night-day-aqua",
    component: _1b085a39,
    name: "it-IT/product/22"
  }, {
    path: "/lenti-a-contatto/p214/air-optix-night-day-aqua-6",
    component: _1b085a39,
    name: "it-IT/product/214"
  }, {
    path: "/lenti-a-contatto/p460/air-optix-plus-hydraglyde",
    component: _1b085a39,
    name: "it-IT/product/460"
  }, {
    path: "/soluzioni-perossido/p258/aosept-plus-con-hydraglyde",
    component: _1b085a39,
    name: "it-IT/product/258"
  }, {
    path: "/soluzioni-perossido/p259/aosept-plus-con-hydraglyde-2x360ml",
    component: _1b085a39,
    name: "it-IT/product/259"
  }, {
    path: "/lenti-a-contatto/p466/avaira-vitality",
    component: _1b085a39,
    name: "it-IT/product/466"
  }, {
    path: "/lenti-a-contatto/p469/avaira-vitality-6",
    component: _1b085a39,
    name: "it-IT/product/469"
  }, {
    path: "/lenti-a-contatto/p479/avaira-vitality-toric",
    component: _1b085a39,
    name: "it-IT/product/479"
  }, {
    path: "/portalenti/p208/portalenti-cilindrico",
    component: _1b085a39,
    name: "it-IT/product/208"
  }, {
    path: "/lenti-a-contatto/p475/bausch-and-lomb-ultra-6",
    component: _1b085a39,
    name: "it-IT/product/475"
  }, {
    path: "/lenti-a-contatto/p477/bausch-and-lomb-ultra-for-presbyopia",
    component: _1b085a39,
    name: "it-IT/product/477"
  }, {
    path: "/lenti-a-contatto/p476/bausch-and-lomb-ultra",
    component: _1b085a39,
    name: "it-IT/product/476"
  }, {
    path: "/lenti-a-contatto/p478/bausch-and-lomb-ultra-presbyopia-6",
    component: _1b085a39,
    name: "it-IT/product/478"
  }, {
    path: "/lenti-a-contatto/p63/biofinity",
    component: _1b085a39,
    name: "it-IT/product/63"
  }, {
    path: "/lenti-a-contatto/p452/biofinity-6",
    component: _1b085a39,
    name: "it-IT/product/452"
  }, {
    path: "/lenti-a-contatto/p467/biofinity-energys",
    component: _1b085a39,
    name: "it-IT/product/467"
  }, {
    path: "/lenti-a-contatto/p481/biofinity-energys-6",
    component: _1b085a39,
    name: "it-IT/product/481"
  }, {
    path: "/lenti-a-contatto/p191/biofinity-multifocal",
    component: _1b085a39,
    name: "it-IT/product/191"
  }, {
    path: "/lenti-a-contatto/p454/biofinity-multifocal-6",
    component: _1b085a39,
    name: "it-IT/product/454"
  }, {
    path: "/lenti-a-contatto/p87/biofinity-toric",
    component: _1b085a39,
    name: "it-IT/product/87"
  }, {
    path: "/lenti-a-contatto/p453/biofinity-toric-6",
    component: _1b085a39,
    name: "it-IT/product/453"
  }, {
    path: "/lenti-a-contatto/p231/biofinity-xr",
    component: _1b085a39,
    name: "it-IT/product/231"
  }, {
    path: "/lenti-a-contatto/p471/biofinity-xr-toric",
    component: _1b085a39,
    name: "it-IT/product/471"
  }, {
    path: "/lenti-a-contatto/p200/biomedics-1-day-extra",
    component: _1b085a39,
    name: "it-IT/product/200"
  }, {
    path: "/lenti-a-contatto/p201/biomedics-1-day-extra-90",
    component: _1b085a39,
    name: "it-IT/product/201"
  }, {
    path: "/lenti-a-contatto/p57/biomedics-1-day-extra-toric",
    component: _1b085a39,
    name: "it-IT/product/57"
  }, {
    path: "/lenti-a-contatto/p39/biomedics-55-evolution",
    component: _1b085a39,
    name: "it-IT/product/39"
  }, {
    path: "/lenti-a-contatto/p54/biomedics-toric",
    component: _1b085a39,
    name: "it-IT/product/54"
  }, {
    path: "/soluzioni-multiuso/p109/biotrue",
    component: _1b085a39,
    name: "it-IT/product/109"
  }, {
    path: "/spray-per-occhi-e-accessori/p255/biotrue-daily-eyelid-salviette",
    component: _1b085a39,
    name: "it-IT/product/255"
  }, {
    path: "/soluzioni-multiuso/p215/biotrue-flat-pack",
    component: _1b085a39,
    name: "it-IT/product/215"
  }, {
    path: "/soluzioni-multiuso/p193/biotrue-set-da-viaggio",
    component: _1b085a39,
    name: "it-IT/product/193"
  }, {
    path: "/lenti-a-contatto/p226/biotrue-oneday",
    component: _1b085a39,
    name: "it-IT/product/226"
  }, {
    path: "/lenti-a-contatto/p227/biotrue-oneday-90",
    component: _1b085a39,
    name: "it-IT/product/227"
  }, {
    path: "/lenti-a-contatto/p480/biotrue-oneday-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/480"
  }, {
    path: "/lenti-a-contatto/p235/biotrue-oneday-for-presbyopia",
    component: _1b085a39,
    name: "it-IT/product/235"
  }, {
    path: "/soluzioni-multiuso/p190/biotrue-soluzione-3x300ml",
    component: _1b085a39,
    name: "it-IT/product/190"
  }, {
    path: "/soluzioni-multiuso/p132/biotrue-soluzione-2x300ml",
    component: _1b085a39,
    name: "it-IT/product/132"
  }, {
    path: "/spray-per-occhi-e-accessori/p246/blephaclean",
    component: _1b085a39,
    name: "it-IT/product/246"
  }, {
    path: "/spray-per-occhi-e-accessori/p247/blephagel",
    component: _1b085a39,
    name: "it-IT/product/247"
  }, {
    path: "/spray-per-occhi-e-accessori/p245/blephasol",
    component: _1b085a39,
    name: "it-IT/product/245"
  }, {
    path: "/gocce-per-occhi/p67/blink-contacts-collirio",
    component: _1b085a39,
    name: "it-IT/product/67"
  }, {
    path: "/gocce-per-occhi/p68/blink-contacts-fiale",
    component: _1b085a39,
    name: "it-IT/product/68"
  }, {
    path: "/gocce-per-occhi/p120/blink-intensive-tears-collirio",
    component: _1b085a39,
    name: "it-IT/product/120"
  }, {
    path: "/gocce-per-occhi/p121/blink-intensive-tears-fiale",
    component: _1b085a39,
    name: "it-IT/product/121"
  }, {
    path: "/gocce-per-occhi/p202/blink-intensive-tears-plus-collirio",
    component: _1b085a39,
    name: "it-IT/product/202"
  }, {
    path: "/spray-per-occhi-e-accessori/p249/blink-salviette-sterili-detergenti",
    component: _1b085a39,
    name: "it-IT/product/249"
  }, {
    path: "/spray-per-occhi-e-accessori/p264/blink-refreshing-spray",
    component: _1b085a39,
    name: "it-IT/product/264"
  }, {
    path: "/gocce-per-occhi/p69/blink-refreshing-collirio",
    component: _1b085a39,
    name: "it-IT/product/69"
  }, {
    path: "/gocce-per-occhi/p119/blink-n-clean-collirio",
    component: _1b085a39,
    name: "it-IT/product/119"
  }, {
    path: "/soluzioni-per-lenti-rigide/p133/boston-advance-cleaner",
    component: _1b085a39,
    name: "it-IT/product/133"
  }, {
    path: "/soluzioni-per-lenti-rigide/p134/boston-advance-formula-conditioning",
    component: _1b085a39,
    name: "it-IT/product/134"
  }, {
    path: "/soluzioni-per-lenti-rigide/p135/boston-simplus",
    component: _1b085a39,
    name: "it-IT/product/135"
  }, {
    path: "/soluzioni-per-lenti-rigide/p136/boston-simplus-set-da-viaggio",
    component: _1b085a39,
    name: "it-IT/product/136"
  }, {
    path: "/lenti-a-contatto/p219/clariti-1-day",
    component: _1b085a39,
    name: "it-IT/product/219"
  }, {
    path: "/lenti-a-contatto/p217/clariti-1-day-multifocal",
    component: _1b085a39,
    name: "it-IT/product/217"
  }, {
    path: "/lenti-a-contatto/p218/clariti-1-day-toric",
    component: _1b085a39,
    name: "it-IT/product/218"
  }, {
    path: "/lenti-a-contatto/p450/clariti-elite",
    component: _1b085a39,
    name: "it-IT/product/450"
  }, {
    path: "/lenti-a-contatto/p221/clariti-multifocal",
    component: _1b085a39,
    name: "it-IT/product/221"
  }, {
    path: "/lenti-a-contatto/p222/clariti-toric",
    component: _1b085a39,
    name: "it-IT/product/222"
  }, {
    path: "/gocce-per-occhi/p248/clinitas-soothe-collirio-fiale",
    component: _1b085a39,
    name: "it-IT/product/248"
  }, {
    path: "/soluzioni-per-lenti-rigide/p125/complete-revitalens",
    component: _1b085a39,
    name: "it-IT/product/125"
  }, {
    path: "/soluzioni-per-lenti-rigide/p126/complete-revitalens-2x360ml",
    component: _1b085a39,
    name: "it-IT/product/126"
  }, {
    path: "/lenti-a-contatto/p5/dailies-aqua-comfort-plus",
    component: _1b085a39,
    name: "it-IT/product/5"
  }, {
    path: "/lenti-a-contatto/p6/dailies-aqua-comfort-plus-90",
    component: _1b085a39,
    name: "it-IT/product/6"
  }, {
    path: "/lenti-a-contatto/p234/dailies-aqua-comfort-plus-multifocal",
    component: _1b085a39,
    name: "it-IT/product/234"
  }, {
    path: "/lenti-a-contatto/p233/dailies-aqua-comfort-plus-toric",
    component: _1b085a39,
    name: "it-IT/product/233"
  }, {
    path: "/lenti-a-contatto/p225/dailies-total-1",
    component: _1b085a39,
    name: "it-IT/product/225"
  }, {
    path: "/lenti-a-contatto/p251/dailies-total-1-90",
    component: _1b085a39,
    name: "it-IT/product/251"
  }, {
    path: "/lenti-a-contatto/p457/dailies-total-1-multifocal",
    component: _1b085a39,
    name: "it-IT/product/457"
  }, {
    path: "/lenti-a-contatto/p101/expressions-colors",
    component: _1b085a39,
    name: "it-IT/product/101"
  }, {
    path: "/lenti-a-contatto/p7/focus-dailies-all-day-comfort-90",
    component: _1b085a39,
    name: "it-IT/product/7"
  }, {
    path: "/lenti-a-contatto/p8/focus-dailies-all-day-comfort",
    component: _1b085a39,
    name: "it-IT/product/8"
  }, {
    path: "/lenti-a-contatto/p30/frequency-55-aspheric",
    component: _1b085a39,
    name: "it-IT/product/30"
  }, {
    path: "/lenti-a-contatto/p33/frequency-xcel-toric",
    component: _1b085a39,
    name: "it-IT/product/33"
  }, {
    path: "/lenti-a-contatto/p32/frequency-xcel-toric-xr",
    component: _1b085a39,
    name: "it-IT/product/32"
  }, {
    path: "/lenti-a-contatto/p93/freshlook-colorblends",
    component: _1b085a39,
    name: "it-IT/product/93"
  }, {
    path: "/lenti-a-contatto/p92/freshlook-colors",
    component: _1b085a39,
    name: "it-IT/product/92"
  }, {
    path: "/lenti-a-contatto/p94/freshlook-dimensions",
    component: _1b085a39,
    name: "it-IT/product/94"
  }, {
    path: "/lenti-a-contatto/p95/freshlook-dimensions-6",
    component: _1b085a39,
    name: "it-IT/product/95"
  }, {
    path: "/lenti-a-contatto/p196/freshlook-illuminate",
    component: _1b085a39,
    name: "it-IT/product/196"
  }, {
    path: "/lenti-a-contatto/p96/freshlook-one-day",
    component: _1b085a39,
    name: "it-IT/product/96"
  }, {
    path: "/soluzioni-multiuso/p472/hy-care-soluzione",
    component: _1b085a39,
    name: "it-IT/product/472"
  }, {
    path: "/soluzioni-multiuso/p473/hy-care-soluzione-3x250ml",
    component: _1b085a39,
    name: "it-IT/product/473"
  }, {
    path: "/gocce-per-occhi/p207/hycosan-extra-collirio",
    component: _1b085a39,
    name: "it-IT/product/207"
  }, {
    path: "/gocce-per-occhi/p206/hycosan-collirio",
    component: _1b085a39,
    name: "it-IT/product/206"
  }, {
    path: "/portalenti/p25/portalenti",
    component: _1b085a39,
    name: "it-IT/product/25"
  }, {
    path: "/soluzioni-fisiologiche/p127/lens-plus-ocupure-soluzione-fisiologica",
    component: _1b085a39,
    name: "it-IT/product/127"
  }, {
    path: "/vitamine-per-gli-occhi/p241/macushield",
    component: _1b085a39,
    name: "it-IT/product/241"
  }, {
    path: "/vitamine-per-gli-occhi/p242/macushield-90",
    component: _1b085a39,
    name: "it-IT/product/242"
  }, {
    path: "/vitamine-per-gli-occhi/p244/macushield-gold",
    component: _1b085a39,
    name: "it-IT/product/244"
  }, {
    path: "/vitamine-per-gli-occhi/p243/macushield-vegetarian",
    component: _1b085a39,
    name: "it-IT/product/243"
  }, {
    path: "/lenti-a-contatto/p230/myday",
    component: _1b085a39,
    name: "it-IT/product/230"
  }, {
    path: "/lenti-a-contatto/p252/myday-90",
    component: _1b085a39,
    name: "it-IT/product/252"
  }, {
    path: "/lenti-a-contatto/p470/myday-toric",
    component: _1b085a39,
    name: "it-IT/product/470"
  }, {
    path: "/vitamine-per-gli-occhi/p148/ocuvite-complete",
    component: _1b085a39,
    name: "it-IT/product/148"
  }, {
    path: "/soluzioni-multiuso/p113/opti-free-express",
    component: _1b085a39,
    name: "it-IT/product/113"
  }, {
    path: "/soluzioni-multiuso/p114/opti-free-express-2x355ml",
    component: _1b085a39,
    name: "it-IT/product/114"
  }, {
    path: "/soluzioni-multiuso/p194/opti-free-puremoist",
    component: _1b085a39,
    name: "it-IT/product/194"
  }, {
    path: "/soluzioni-multiuso/p195/opti-free-puremoist-2x300ml",
    component: _1b085a39,
    name: "it-IT/product/195"
  }, {
    path: "/soluzioni-multiuso/p64/opti-free-replenish",
    component: _1b085a39,
    name: "it-IT/product/64"
  }, {
    path: "/soluzioni-multiuso/p115/opti-free-replenish-set-da-viaggio",
    component: _1b085a39,
    name: "it-IT/product/115"
  }, {
    path: "/soluzioni-multiuso/p66/opti-free-replenish-2x300ml",
    component: _1b085a39,
    name: "it-IT/product/66"
  }, {
    path: "/soluzioni-multiuso/p146/options-multi-3x250ml",
    component: _1b085a39,
    name: "it-IT/product/146"
  }, {
    path: "/spray-per-occhi-e-accessori/p204/optrex-actimist-spray-2in1-per-occhi-secchi-e-irritati",
    component: _1b085a39,
    name: "it-IT/product/204"
  }, {
    path: "/spray-per-occhi-e-accessori/p205/optrex-actimist-spray-2in1-per-occhi-stanchi-e-arrossati",
    component: _1b085a39,
    name: "it-IT/product/205"
  }, {
    path: "/gocce-per-occhi/p256/optrex-intensive-collirio",
    component: _1b085a39,
    name: "it-IT/product/256"
  }, {
    path: "/soluzioni-perossido/p128/oxysept",
    component: _1b085a39,
    name: "it-IT/product/128"
  }, {
    path: "/lenti-a-contatto/p34/proclear-6",
    component: _1b085a39,
    name: "it-IT/product/34"
  }, {
    path: "/lenti-a-contatto/p451/proclear",
    component: _1b085a39,
    name: "it-IT/product/451"
  }, {
    path: "/lenti-a-contatto/p60/proclear-1-day",
    component: _1b085a39,
    name: "it-IT/product/60"
  }, {
    path: "/lenti-a-contatto/p84/proclear-1-day-90",
    component: _1b085a39,
    name: "it-IT/product/84"
  }, {
    path: "/lenti-a-contatto/p198/proclear-1-day-multifocal",
    component: _1b085a39,
    name: "it-IT/product/198"
  }, {
    path: "/lenti-a-contatto/p35/proclear-toric",
    component: _1b085a39,
    name: "it-IT/product/35"
  }, {
    path: "/lenti-a-contatto/p61/proclear-multifocal",
    component: _1b085a39,
    name: "it-IT/product/61"
  }, {
    path: "/lenti-a-contatto/p83/proclear-multifocal-6",
    component: _1b085a39,
    name: "it-IT/product/83"
  }, {
    path: "/lenti-a-contatto/p74/proclear-multifocal-toric",
    component: _1b085a39,
    name: "it-IT/product/74"
  }, {
    path: "/lenti-a-contatto/p79/proclear-multifocal-xr",
    component: _1b085a39,
    name: "it-IT/product/79"
  }, {
    path: "/lenti-a-contatto/p85/proclear-tailor-made-toric",
    component: _1b085a39,
    name: "it-IT/product/85"
  }, {
    path: "/lenti-a-contatto/p80/proclear-toric-xr",
    component: _1b085a39,
    name: "it-IT/product/80"
  }, {
    path: "/lenti-a-contatto/p149/purevision-2-hd",
    component: _1b085a39,
    name: "it-IT/product/149"
  }, {
    path: "/lenti-a-contatto/p150/purevision-2-hd-6",
    component: _1b085a39,
    name: "it-IT/product/150"
  }, {
    path: "/lenti-a-contatto/p192/purevision-2-hd-for-astigmatism-6",
    component: _1b085a39,
    name: "it-IT/product/192"
  }, {
    path: "/lenti-a-contatto/p197/purevision-2-hd-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/197"
  }, {
    path: "/lenti-a-contatto/p228/purevision-2-hd-for-presbyopia",
    component: _1b085a39,
    name: "it-IT/product/228"
  }, {
    path: "/lenti-a-contatto/p229/purevision-2-hd-for-presbyopia-6",
    component: _1b085a39,
    name: "it-IT/product/229"
  }, {
    path: "/lenti-a-contatto/p16/purevision-multi-focal",
    component: _1b085a39,
    name: "it-IT/product/16"
  }, {
    path: "/soluzioni-multiuso/p140/renu-multiplus-240ml",
    component: _1b085a39,
    name: "it-IT/product/140"
  }, {
    path: "/soluzioni-multiuso/p139/renu-multiplus-60ml",
    component: _1b085a39,
    name: "it-IT/product/139"
  }, {
    path: "/soluzioni-multiuso/p141/renu-multiplus-3x240ml",
    component: _1b085a39,
    name: "it-IT/product/141"
  }, {
    path: "/soluzioni-multiuso/p72/renu-multi-purpose-set-da-viaggio",
    component: _1b085a39,
    name: "it-IT/product/72"
  }, {
    path: "/soluzioni-multiuso/p42/renu-multi-purpose-soluzione-240ml",
    component: _1b085a39,
    name: "it-IT/product/42"
  }, {
    path: "/soluzioni-multiuso/p46/renu-multi-purpose-soluzione-120ml",
    component: _1b085a39,
    name: "it-IT/product/46"
  }, {
    path: "/soluzioni-multiuso/p82/renu-multi-purpose-soluzione-60ml",
    component: _1b085a39,
    name: "it-IT/product/82"
  }, {
    path: "/soluzioni-multiuso/p142/renu-multi-purpose-soluzione-3x240ml",
    component: _1b085a39,
    name: "it-IT/product/142"
  }, {
    path: "/soluzioni-fisiologiche/p71/sensitive-eyes-plus-soluzione-salina",
    component: _1b085a39,
    name: "it-IT/product/71"
  }, {
    path: "/lenti-a-contatto/p17/soflens-38",
    component: _1b085a39,
    name: "it-IT/product/17"
  }, {
    path: "/lenti-a-contatto/p18/soflens-59",
    component: _1b085a39,
    name: "it-IT/product/18"
  }, {
    path: "/lenti-a-contatto/p1/soflens-daily-disposable",
    component: _1b085a39,
    name: "it-IT/product/1"
  }, {
    path: "/lenti-a-contatto/p2/soflens-daily-disposable-90",
    component: _1b085a39,
    name: "it-IT/product/2"
  }, {
    path: "/lenti-a-contatto/p88/soflens-daily-disposable-toric",
    component: _1b085a39,
    name: "it-IT/product/88"
  }, {
    path: "/lenti-a-contatto/p20/soflens-multi-focal-6",
    component: _1b085a39,
    name: "it-IT/product/20"
  }, {
    path: "/lenti-a-contatto/p45/soflens-multi-focal",
    component: _1b085a39,
    name: "it-IT/product/45"
  }, {
    path: "/lenti-a-contatto/p97/soflens-natural-colors",
    component: _1b085a39,
    name: "it-IT/product/97"
  }, {
    path: "/lenti-a-contatto/p19/soflens-toric-6",
    component: _1b085a39,
    name: "it-IT/product/19"
  }, {
    path: "/lenti-a-contatto/p44/soflens-toric",
    component: _1b085a39,
    name: "it-IT/product/44"
  }, {
    path: "/soluzioni-multiuso/p144/solocare-aqua",
    component: _1b085a39,
    name: "it-IT/product/144"
  }, {
    path: "/soluzioni-multiuso/p145/solocare-aqua-2x360ml",
    component: _1b085a39,
    name: "it-IT/product/145"
  }, {
    path: "/gocce-per-occhi/p261/systane-balance-collirio",
    component: _1b085a39,
    name: "it-IT/product/261"
  }, {
    path: "/gocce-per-occhi/p262/systane-gel-drops-collirio",
    component: _1b085a39,
    name: "it-IT/product/262"
  }, {
    path: "/gocce-per-occhi/p117/systane-collirio",
    component: _1b085a39,
    name: "it-IT/product/117"
  }, {
    path: "/gocce-per-occhi/p118/systane-collirio-fiale",
    component: _1b085a39,
    name: "it-IT/product/118"
  }, {
    path: "/gocce-per-occhi/p209/systane-ultra-collirio",
    component: _1b085a39,
    name: "it-IT/product/209"
  }, {
    path: "/gocce-per-occhi/p260/systane-ultra-ud-fiale",
    component: _1b085a39,
    name: "it-IT/product/260"
  }, {
    path: "/spray-per-occhi-e-accessori/p237/thera-pearl-maschera-oculare",
    component: _1b085a39,
    name: "it-IT/product/237"
  }, {
    path: "/soluzioni-per-lenti-rigide/p130/totalcare-cleaner",
    component: _1b085a39,
    name: "it-IT/product/130"
  }, {
    path: "/soluzioni-per-lenti-rigide/p129/totalcare-soluzione",
    component: _1b085a39,
    name: "it-IT/product/129"
  }, {
    path: "/spray-per-occhi-e-accessori/p131/ultrazyme-universal-protein-cleaner",
    component: _1b085a39,
    name: "it-IT/product/131"
  }, {
    path: "/b301/sauflon",
    component: _387cb151,
    name: "it-IT/brand/301"
  }, {
    path: "/account/preferenze-di-comunicazione",
    component: _08e7896a,
    name: "it-IT/communication-preferences"
  }, {
    path: "/c112",
    redirect: "/c112/soluzioni-fisiologiche"
  }, {
    path: "/gocce-per-occhi/p117/systane-collirio-",
    redirect: "/gocce-per-occhi/p117/systane-collirio"
  }, {
    path: "/spray-per-occhi-e-accessori/p205/optrex-actimist-spray-2in1-per-occhi-stanchi-e-arrossati-",
    redirect: "/spray-per-occhi-e-accessori/p205/optrex-actimist-spray-2in1-per-occhi-stanchi-e-arrossati"
  }, {
    path: "/lenti-a-contatto/p221/clariti-monthly-multifocal",
    redirect: "/lenti-a-contatto/p221/clariti-multifocal"
  }, {
    path: "/lenti-a-contatto/p222/clariti-monthly-toric",
    redirect: "/lenti-a-contatto/p222/clariti-toric"
  }, {
    path: "/lenti-a-contatto/p35/proclear-compatibles-toric",
    redirect: "/lenti-a-contatto/p35/proclear-toric"
  }, {
    path: "/contatti",
    component: _974a4b7e,
    name: "it-IT/document/2"
  }, {
    path: "/c104/gocce-per-occhi",
    redirect: "/c104/colliri"
  }, {
    path: "/b173/soluzioni-biotrue",
    component: _387cb151,
    name: "it-IT/brand/173"
  }, {
    path: "/account/dati-personali",
    component: _1502f9db,
    name: "it-IT/profile"
  }, {
    path: "/account/indirizzi",
    component: _89a7e070,
    name: "it-IT/address-book"
  }, {
    path: "/cambia-password",
    component: _3d98be02,
    name: "it-IT/reset-password"
  }, {
    path: "/account/cronologia-ordini",
    component: _829d8442,
    name: "it-IT/orders"
  }, {
    path: "/account",
    component: _b0dd6f12,
    name: "it-IT/account"
  }, {
    path: "/lenti-a-contatto/p104/1-day-acuvue-trueye",
    redirect: "/lenti-a-contatto/p104/1-day-acuvue-trueye-90"
  }, {
    path: "/lenti-a-contatto/p13/1-day-acuvue-moist-30",
    redirect: "/lenti-a-contatto/p13/1-day-acuvue-moist"
  }, {
    path: "/lenti-a-contatto/p14/1-day-acuvue-moist",
    redirect: "/lenti-a-contatto/p14/1-day-acuvue-moist-90"
  }, {
    path: "/lenti-a-contatto/p1/soflens-daily-disposable-30",
    redirect: "/lenti-a-contatto/p1/soflens-daily-disposable"
  }, {
    path: "/lenti-a-contatto/p149/purevision-2-hd-3",
    redirect: "/lenti-a-contatto/p149/purevision-2-hd"
  }, {
    path: "/lenti-a-contatto/p150/purevision-2-hd",
    redirect: "/lenti-a-contatto/p150/purevision-2-hd-6"
  }, {
    path: "/lenti-a-contatto/p191/biofinity-multifocal-3",
    redirect: "/lenti-a-contatto/p191/biofinity-multifocal"
  }, {
    path: "/lenti-a-contatto/p2/soflens-daily-disposable",
    redirect: "/lenti-a-contatto/p2/soflens-daily-disposable-90"
  }, {
    path: "/lenti-a-contatto/p200/biomedics-1-day-extra-30",
    redirect: "/lenti-a-contatto/p200/biomedics-1-day-extra"
  }, {
    path: "/lenti-a-contatto/p201/biomedics-1-day-extra",
    redirect: "/lenti-a-contatto/p201/biomedics-1-day-extra-90"
  }, {
    path: "/lenti-a-contatto/p213/air-optix-aqua",
    redirect: "/lenti-a-contatto/p213/air-optix-aqua-6"
  }, {
    path: "/lenti-a-contatto/p214/air-optix-night-day-aqua",
    redirect: "/lenti-a-contatto/p214/air-optix-night-day-aqua-6"
  }, {
    path: "/lenti-a-contatto/p22/air-optix-night-day-aqua-3",
    redirect: "/lenti-a-contatto/p22/air-optix-night-day-aqua"
  }, {
    path: "/lenti-a-contatto/p225/dailies-total-1-30",
    redirect: "/lenti-a-contatto/p225/dailies-total-1"
  }, {
    path: "/lenti-a-contatto/p226/biotrue-oneday-30",
    redirect: "/lenti-a-contatto/p226/biotrue-oneday"
  }, {
    path: "/lenti-a-contatto/p227/biotrue-oneday",
    redirect: "/lenti-a-contatto/p227/biotrue-oneday-90"
  }, {
    path: "/lenti-a-contatto/p228/purevision-2-hd-for-presbyopia-3",
    redirect: "/lenti-a-contatto/p228/purevision-2-hd-for-presbyopia"
  }, {
    path: "/lenti-a-contatto/p229/purevision-2-hd-for-presbyopia",
    redirect: "/lenti-a-contatto/p229/purevision-2-hd-for-presbyopia-6"
  }, {
    path: "/lenti-a-contatto/p230/myday-30",
    redirect: "/lenti-a-contatto/p230/myday"
  }, {
    path: "/lenti-a-contatto/p251/dailies-total-1",
    redirect: "/lenti-a-contatto/p251/dailies-total-1-90"
  }, {
    path: "/lenti-a-contatto/p252/myday",
    redirect: "/lenti-a-contatto/p252/myday-90"
  }, {
    path: "/lenti-a-contatto/p452/biofinity",
    redirect: "/lenti-a-contatto/p452/biofinity-6"
  }, {
    path: "/lenti-a-contatto/p453/biofinity-toric",
    redirect: "/lenti-a-contatto/p453/biofinity-toric-6"
  }, {
    path: "/lenti-a-contatto/p454/biofinity-multifocal",
    redirect: "/lenti-a-contatto/p454/biofinity-multifocal-6"
  }, {
    path: "/lenti-a-contatto/p455/air-optix-for-astigmatism",
    redirect: "/lenti-a-contatto/p455/air-optix-for-astigmatism-6"
  }, {
    path: "/lenti-a-contatto/p456/air-optix-aqua-multifocal",
    redirect: "/lenti-a-contatto/p456/air-optix-aqua-multifocal-6"
  }, {
    path: "/lenti-a-contatto/p458/acuvue-oasys-1-day-30",
    redirect: "/lenti-a-contatto/p458/acuvue-oasys-1-day"
  }, {
    path: "/lenti-a-contatto/p459/acuvue-oasys-1-day",
    redirect: "/lenti-a-contatto/p459/acuvue-oasys-1-day-90"
  }, {
    path: "/lenti-a-contatto/p466/avaira-vitality-3",
    redirect: "/lenti-a-contatto/p466/avaira-vitality"
  }, {
    path: "/lenti-a-contatto/p467/biofinity-energys-3",
    redirect: "/lenti-a-contatto/p467/biofinity-energys"
  }, {
    path: "/lenti-a-contatto/p469/avaira-vitality",
    redirect: "/lenti-a-contatto/p469/avaira-vitality-6"
  }, {
    path: "/lenti-a-contatto/p48/air-optix-for-astigmatism-3",
    redirect: "/lenti-a-contatto/p48/air-optix-for-astigmatism"
  }, {
    path: "/lenti-a-contatto/p481/biofinity-energys",
    redirect: "/lenti-a-contatto/p481/biofinity-energys-6"
  }, {
    path: "/lenti-a-contatto/p5/dailies-aqua-comfort-plus-30",
    redirect: "/lenti-a-contatto/p5/dailies-aqua-comfort-plus"
  }, {
    path: "/lenti-a-contatto/p6/dailies-aqua-comfort-plus",
    redirect: "/lenti-a-contatto/p6/dailies-aqua-comfort-plus-90"
  }, {
    path: "/lenti-a-contatto/p60/proclear-1-day-30",
    redirect: "/lenti-a-contatto/p60/proclear-1-day"
  }, {
    path: "/lenti-a-contatto/p61/proclear-multifocal-3",
    redirect: "/lenti-a-contatto/p61/proclear-multifocal"
  }, {
    path: "/lenti-a-contatto/p63/biofinity-3",
    redirect: "/lenti-a-contatto/p63/biofinity"
  }, {
    path: "/lenti-a-contatto/p76/1-day-acuvue-trueye-30",
    redirect: "/lenti-a-contatto/p76/1-day-acuvue-trueye"
  }, {
    path: "/lenti-a-contatto/p77/air-optix-aqua-3",
    redirect: "/lenti-a-contatto/p77/air-optix-aqua"
  }, {
    path: "/lenti-a-contatto/p83/proclear-multifocal",
    redirect: "/lenti-a-contatto/p83/proclear-multifocal-6"
  }, {
    path: "/lenti-a-contatto/p84/proclear-1-day",
    redirect: "/lenti-a-contatto/p84/proclear-1-day-90"
  }, {
    path: "/lenti-a-contatto/p87/biofinity-toric-3",
    redirect: "/lenti-a-contatto/p87/biofinity-toric"
  }, {
    path: "/lenti-a-contatto/p90/air-optix-aqua-multifocal-3",
    redirect: "/lenti-a-contatto/p90/air-optix-aqua-multifocal"
  }, {
    path: "/lenti-a-contatto/p94/freshlook-dimensions-2",
    redirect: "/lenti-a-contatto/p94/freshlook-dimensions"
  }, {
    path: "/lenti-a-contatto/p95/freshlook-dimensions",
    redirect: "/lenti-a-contatto/p95/freshlook-dimensions-6"
  }, {
    path: "/vitamine-per-gli-occhi/p241/macushield-30",
    redirect: "/vitamine-per-gli-occhi/p241/macushield"
  }, {
    path: "/vitamine-per-gli-occhi/p242/macushield",
    redirect: "/vitamine-per-gli-occhi/p242/macushield-90"
  }, {
    path: "/lenti-a-contatto/p101/expressions-colour",
    redirect: "/lenti-a-contatto/p101/expressions-colors"
  }, {
    path: "/c1/lenti-a-contatto-gionaliere",
    redirect: "/c1/lenti-a-contatto-giornaliere"
  }, {
    path: "/uso-dei-cookie",
    component: _974a4b7e,
    name: "it-IT/document/124"
  }, {
    path: "/nostri-servizi",
    component: _974a4b7e,
    name: "it-IT/document/1"
  }, {
    path: "/lenti-a-contatto/p476/bausch-and-lomb-ultra-presbyopia-6",
    redirect: "/lenti-a-contatto/p476/bausch-and-lomb-ultra"
  }, {
    path: "/lenti-a-contatto/p478/bausch-and-lomb-ultra-presbyopia",
    redirect: "/lenti-a-contatto/p478/bausch-and-lomb-ultra-presbyopia-6"
  }, {
    path: "/promemoria",
    component: _974a4b7e,
    name: "it-IT/document/15"
  }, {
    path: "/b185/solo-care-aqua",
    component: _387cb151,
    name: "it-IT/brand/185"
  }, {
    path: "/b151/altri-colori",
    component: _387cb151,
    name: "it-IT/brand/151"
  }, {
    path: "/detrazione-fiscale",
    component: _974a4b7e,
    name: "it-IT/document/129"
  }, {
    path: "/b123/lenti-toriche-giornaliere",
    component: _387cb151,
    name: "it-IT/brand/123"
  }, {
    path: "/lenti-giornaliere-per-astigmatismo",
    redirect: "/b123/lenti-toriche-giornaliere"
  }, {
    path: "/lenti-a-contatto/p482/air-optix-plus-hydraglyde-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/482"
  }, {
    path: "/b330/lenti-multifocali-giornaliere",
    component: _387cb151,
    name: "it-IT/brand/330"
  }, {
    path: "/prescrizione-lenti-a-contatto",
    component: _974a4b7e,
    name: "it-IT/document/127"
  }, {
    path: "/lenti-a-contatto/p476/bausch-and-lomb-ultra-presbyopia",
    redirect: "/lenti-a-contatto/p476/bausch-and-lomb-ultra"
  }, {
    path: "/lenti-a-contatto/p477/bausch-and-lomb-ultra",
    redirect: "/lenti-a-contatto/p477/bausch-and-lomb-ultra-for-presbyopia"
  }, {
    path: "/informativa-sulla-privacy",
    component: _974a4b7e,
    name: "it-IT/document/9"
  }, {
    path: "/b123/lenti-giornaliere-per-astigmatismo",
    redirect: "/b123/lenti-toriche-giornaliere"
  }, {
    path: "/b232/lenti-a-contatto-comfilcon",
    component: _387cb151,
    name: "it-IT/brand/232"
  }, {
    path: "/lenstore-vs-adrialenti",
    component: _974a4b7e,
    name: "it-IT/document/130"
  }, {
    path: "/lenti-a-contatto-comfilcon",
    redirect: "/b232/lenti-a-contatto-comfilcon"
  }, {
    path: "/b331/lenstore-vs-adrialenti",
    component: _387cb151,
    name: "it-IT/brand/331"
  }, {
    path: "/b158/alcon-soluzioni-e-accessori",
    component: _387cb151,
    name: "it-IT/brand/158"
  }, {
    path: "/b332/lenstore-vs-lentiamo",
    component: _387cb151,
    name: "it-IT/brand/332"
  }, {
    path: "/b214/codice-sconto",
    component: _387cb151,
    name: "it-IT/brand/214"
  }, {
    path: "/b333/migliori-lenti-a-contatto",
    component: _387cb151,
    name: "it-IT/brand/333"
  }, {
    path: "/b337/quanto-costano-le-lenti",
    component: _387cb151,
    name: "it-IT/brand/337"
  }, {
    path: "/price-comparison-adrialenti",
    redirect: "/b331/lenstore-vs-adrialenti"
  }, {
    path: "/b340/lenstore-vs-adrialenti",
    component: _387cb151,
    name: "it-IT/brand/340"
  }, {
    path: "/lenti-a-contatto/p483/live-1-day",
    component: _1b085a39,
    name: "it-IT/product/483"
  }, {
    path: "/modalita-di-pagamento",
    component: _974a4b7e,
    name: "it-IT/document/132"
  }, {
    path: "/b96/lenti-colorate-graduate",
    component: _387cb151,
    name: "it-IT/brand/96"
  }, {
    path: "/b343/spedizione-gratuita",
    component: _387cb151,
    name: "it-IT/brand/343"
  }, {
    path: "/b129/lenti-a-contatto-blu",
    redirect: "/b129/lenti-a-contatto-azzurre"
  }, {
    path: "/b344/lenti-colorate-giornaliere",
    component: _387cb151,
    name: "it-IT/brand/344"
  }, {
    path: "/lenti-a-contatto/p484/air-optix-plus-hydraglyde-multifocal",
    component: _1b085a39,
    name: "it-IT/product/484"
  }, {
    path: "/b345/lenti-a-contatto-colorate",
    component: _387cb151,
    name: "it-IT/brand/345"
  }, {
    path: "/come-mettere-e-togliere-lenti",
    component: _974a4b7e,
    name: "it-IT/document/134"
  }, {
    path: "/b350/recensioni-trustpilot",
    component: _387cb151,
    name: "it-IT/brand/350"
  }, {
    path: "/coronavirus-6-semplici-consigli",
    component: _974a4b7e,
    name: "it-IT/document/144"
  }, {
    path: "/acquista-lenti-online",
    component: _974a4b7e,
    name: "it-IT/document/145"
  }, {
    path: "/6-verita-lenti-coronavirus",
    component: _974a4b7e,
    name: "it-IT/document/146"
  }, {
    path: "/consigli-cura-occhi-casa",
    component: _974a4b7e,
    name: "it-IT/document/147"
  }, {
    path: "/covid-19-informazione-ai-clienti",
    component: _974a4b7e,
    name: "it-IT/document/148"
  }, {
    path: "/abitudini-sane-occhi-sani",
    component: _974a4b7e,
    name: "it-IT/document/150"
  }, {
    path: "/termini-e-condizioni-del-sondaggio-sulla-frequenza-covid-19",
    component: _974a4b7e,
    name: "it-IT/document/157"
  }, {
    path: "/b348/acquista-lenti-convenienti",
    component: _387cb151,
    name: "it-IT/brand/348"
  }, {
    path: "/b348/acquista-lenti-economiche",
    redirect: "/b348/acquista-lenti-convenienti"
  }, {
    path: "/spray-per-occhi-e-accessori/p237/thera-pearl-maschera-facciale",
    redirect: "/spray-per-occhi-e-accessori/p237/thera-pearl-maschera-oculare"
  }, {
    path: "/b395/trova-prezzi-lenti-a-contatto",
    component: _387cb151,
    name: "it-IT/brand/395"
  }, {
    path: "/trova-prezzi-lenti-a-contatto",
    redirect: "/b395/trova-prezzi-lenti-a-contatto"
  }, {
    path: "/b395/trova-prezzi-lenti-contatto",
    redirect: "/b395/trova-prezzi-lenti-a-contatto"
  }, {
    path: "/lenti-a-contatto-colorate",
    redirect: "/b345/lenti-a-contatto-colorate"
  }, {
    path: "/lenti-a-contatto/p488/miru-1day-flat",
    component: _1b085a39,
    name: "it-IT/product/488"
  }, {
    path: "/lenti-a-contatto/p489/miru-1month",
    component: _1b085a39,
    name: "it-IT/product/489"
  }, {
    path: "/lenti-a-contatto/p490/miru-1month_toric",
    component: _1b085a39,
    name: "it-IT/product/490"
  }, {
    path: "/lenti-a-contatto/p491/miru-1month_multifocal",
    component: _1b085a39,
    name: "it-IT/product/491"
  }, {
    path: "/b354/miru",
    component: _387cb151,
    name: "it-IT/brand/396"
  }, {
    path: "/b359/menicon",
    component: _387cb151,
    name: "it-IT/brand/394"
  }, {
    path: "/uk-efs-ltd-politica-fiscale",
    component: _974a4b7e,
    name: "it-IT/document/115"
  }, {
    path: "/come-pulire-le-tue-lenti-a-contatto",
    component: _974a4b7e,
    name: "it-IT/document/139"
  }, {
    path: "/a-che-eta-posso-indossare-le-lenti-a-contatto",
    component: _974a4b7e,
    name: "it-IT/document/136"
  }, {
    path: "/lenti-a-contatto/p492/precision-1",
    component: _1b085a39,
    name: "it-IT/product/492"
  }, {
    path: "/lenti-a-contatto/p494/precision-1-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/494"
  }, {
    path: "/lenti-a-contatto/p493/precision-1",
    component: _1b085a39,
    name: "it-IT/product/493"
  }, {
    path: "/lenti-a-contatto/p495/precision-1-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/495"
  }, {
    path: "/lenti-a-contatto-giornaliere/p494/precision-1-for-astigmatism",
    redirect: "/lenti-a-contatto/p494/precision-1-for-astigmatism"
  }, {
    path: "/lenti-a-contatto-giornaliere/p495/precision-1-for-astigmatism",
    redirect: "/lenti-a-contatto/p495/precision-1-for-astigmatism"
  }, {
    path: "/lenti-a-contatto-giornaliere/p493/precision-1",
    redirect: "/lenti-a-contatto/p493/precision-1"
  }, {
    path: "/lenti-a-contatto-giornaliere/p492/precision-1",
    redirect: "/lenti-a-contatto/p492/precision-1"
  }, {
    path: "/lenti-a-contatto-colorate/p496/angelic-white-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "it-IT/product/496"
  }, {
    path: "/lenti-a-contatto-colorate/p498/red-vampire-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "it-IT/product/498"
  }, {
    path: "/lenti-a-contatto-colorate/p497/black-out-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "it-IT/product/497"
  }, {
    path: "//lenti-a-contatto-colorate/p496/angelic-white-clearcolor-1day-phantom",
    redirect: "/lenti-a-contatto-colorate/p496/angelic-white-clearcolor-1-day-phantom"
  }, {
    path: "/lenti-a-contatto-colorate/p499/white-out-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "it-IT/product/499"
  }, {
    path: "/lenti-a-contatto-colorate/p500/zombie-yellow-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "it-IT/product/500"
  }, {
    path: "/lenti-a-contatto-colorate/p501/blue-walker-clearcolor-1-day-phantom",
    component: _1b085a39,
    name: "it-IT/product/501"
  }, {
    path: "//lenti-a-contatto-colorate/p501/blue-walker-clearcolor-1day-phantom-",
    redirect: "/lenti-a-contatto-colorate/p501/blue-walker-clearcolor-1-day-phantom"
  }, {
    path: "/lenti-a-contatto-colorate/p504/angelic-red-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/504"
  }, {
    path: "/lenti-a-contatto-colorate/p505/banshee-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/505"
  }, {
    path: "/lenti-a-contatto-colorate/p506/black-wolf-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/506"
  }, {
    path: "/lenti-a-contatto-colorate/p507/lestat-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/507"
  }, {
    path: "/lenti-a-contatto-colorate/p508/manson-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/508"
  }, {
    path: "/lenti-a-contatto-colorate/p509/red-cat-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/509"
  }, {
    path: "/lenti-a-contatto-colorate/p510/red-vampire-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/510"
  }, {
    path: "/lenti-a-contatto-colorate/p511/smiley-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/511"
  }, {
    path: "/lenti-a-contatto-colorate/p512/white-out-clearcolor-phantom",
    component: _1b085a39,
    name: "it-IT/product/512"
  }, {
    path: "/b363/precision-1",
    component: _387cb151,
    name: "it-IT/brand/404"
  }, {
    path: "/soluzioni-multiuso/p513/all-in-one-light-set-da-viaggio-100ml",
    component: _1b085a39,
    name: "it-IT/product/513"
  }, {
    path: "/soluzioni-multiuso/p514/all-in-one-light-250ml",
    component: _1b085a39,
    name: "it-IT/product/514"
  }, {
    path: "/soluzioni-multiuso/p515/all-in-one-light-360ml",
    component: _1b085a39,
    name: "it-IT/product/515"
  }, {
    path: "/soluzioni-multiuso/p516/all-in-one-light-3x250ml",
    component: _1b085a39,
    name: "it-IT/product/516"
  }, {
    path: "/soluzioni-multiuso/p517/hy-care-set-da-viaggio-100ml",
    component: _1b085a39,
    name: "it-IT/product/517"
  }, {
    path: "/soluzioni-perossido/p518/refine-one-step-set-da-viaggio-100ml",
    component: _1b085a39,
    name: "it-IT/product/518"
  }, {
    path: "/soluzioni-perossido/p519/refine-one-step-250ml",
    component: _1b085a39,
    name: "it-IT/product/519"
  }, {
    path: "/soluzioni-perossido/p520/refine-one-step-360ml",
    component: _1b085a39,
    name: "it-IT/product/520"
  }, {
    path: "/soluzioni-perossido/p521/refine-one-step-3x250ml",
    component: _1b085a39,
    name: "it-IT/product/521"
  }, {
    path: "/soluzioni-fisiologiche/p522/saline-set-da-viaggio-100ml",
    component: _1b085a39,
    name: "it-IT/product/522"
  }, {
    path: "/soluzioni-fisiologiche/p523/saline-360ml",
    component: _1b085a39,
    name: "it-IT/product/523"
  }, {
    path: "/soluzioni-per-lenti-rigide/p524/delta-cleaner-20ml",
    component: _1b085a39,
    name: "it-IT/product/524"
  }, {
    path: "/soluzioni-per-lenti-rigide/p525/delta-plus-set-da-viaggio-100ml",
    component: _1b085a39,
    name: "it-IT/product/525"
  }, {
    path: "/soluzioni-per-lenti-rigide/p526/delta-multi-pack",
    component: _1b085a39,
    name: "it-IT/product/526"
  }, {
    path: "/gocce-per-occhi/p527/comfort-drops-20ml",
    component: _1b085a39,
    name: "it-IT/product/527"
  }, {
    path: "/soluzioni-multiuso/p513/all-in-one-light-travel-pack-100ml",
    redirect: "/soluzioni-multiuso/p513/all-in-one-light-set-da-viaggio-100ml"
  }, {
    path: "/soluzioni-multiuso/p517/hy-care-travel-pack-100ml",
    redirect: "/soluzioni-multiuso/p517/hy-care-set-da-viaggio-100ml"
  }, {
    path: "/soluzioni-perossido/p518/refine-one-step-travel-pack-100ml",
    redirect: "/soluzioni-perossido/p518/refine-one-step-set-da-viaggio-100ml"
  }, {
    path: "/soluzioni-fisiologiche/p522/preservative-free-saline-travel-pack-100ml",
    redirect: "/soluzioni-fisiologiche/p522/saline-set-da-viaggio-100ml"
  }, {
    path: "/soluzioni-per-lenti-rigide/p525/delta-plus-travel-pack-100ml",
    redirect: "/soluzioni-per-lenti-rigide/p525/delta-plus-set-da-viaggio-100ml"
  }, {
    path: "/gocce-per-occhi/p527/comf-drops-20ml",
    redirect: "/gocce-per-occhi/p527/comfort-drops-20ml"
  }, {
    path: "/soluzioni-fisiologiche/p523/preservative-free-saline-360ml",
    redirect: "/soluzioni-fisiologiche/p523/saline-360ml"
  }, {
    path: "/soluzioni-fisiologiche/p522/preservative-free-saline-set-da-viaggio-100ml",
    redirect: "/soluzioni-fisiologiche/p522/saline-set-da-viaggio-100ml"
  }, {
    path: "/soluzioni-fisiologiche/p523/saline-solution-360ml",
    redirect: "/soluzioni-fisiologiche/p523/saline-360ml"
  }, {
    path: "/b406/clearlab",
    component: _387cb151,
    name: "it-IT/brand/406"
  }, {
    path: "/b407/clearcolor-phantom",
    component: _387cb151,
    name: "it-IT/brand/407"
  }, {
    path: "/b408/clearcolor-phantom-1-day",
    component: _387cb151,
    name: "it-IT/brand/408"
  }, {
    path: "/soluzioni-multiuso/p516/all-in-one-light-2x250ml",
    redirect: "/soluzioni-multiuso/p516/all-in-one-light-3x250ml"
  }, {
    path: "/lenti-a-contatto-colorate/p496/angelic-white-clearcolor-1day-phantom",
    redirect: "/lenti-a-contatto-colorate/p496/angelic-white-clearcolor-1-day-phantom"
  }, {
    path: "/lenti-a-contatto-colorate/p497/black-out-clearcolor-1day-phantom",
    redirect: "/lenti-a-contatto-colorate/p497/black-out-clearcolor-1-day-phantom"
  }, {
    path: "/lenti-a-contatto-colorate/p498/red-vampire-clearcolor-1day-phantom",
    redirect: "/lenti-a-contatto-colorate/p498/red-vampire-clearcolor-1-day-phantom"
  }, {
    path: "//lenti-a-contatto-colorate/p499/white-out-clearcolor-1day-phantom",
    redirect: "/lenti-a-contatto-colorate/p499/white-out-clearcolor-1-day-phantom"
  }, {
    path: "/lenti-a-contatto-colorate/p499/white-out-clearcolor-1day-phantom",
    redirect: "/lenti-a-contatto-colorate/p499/white-out-clearcolor-1-day-phantom"
  }, {
    path: "/lenti-a-contatto-colorate/p500/zombie-yellow-clearcolor-1day-phanto",
    redirect: "/lenti-a-contatto-colorate/p500/zombie-yellow-clearcolor-1-day-phantom"
  }, {
    path: "//lenti-a-contatto-colorate/p501/blue-walker-clearcolor-1day-phantom",
    redirect: "/lenti-a-contatto-colorate/p501/blue-walker-clearcolor-1-day-phantom"
  }, {
    path: "/b211/lenti-a-contatto-graduate-di-halloween",
    component: _387cb151,
    name: "it-IT/brand/211"
  }, {
    path: "/b405/lenti-a-contatto-halloween",
    component: _387cb151,
    name: "it-IT/brand/405"
  }, {
    path: "/b409/lenti-a-contatto-fashion",
    component: _387cb151,
    name: "it-IT/brand/409"
  }, {
    path: "/lenti-a-contatto/p528/air-optix-plus-hydraglyde-6",
    component: _1b085a39,
    name: "it-IT/product/528"
  }, {
    path: "/lenti-a-contatto/p529/air-optix-plus-hydraglyde-for-astigmatism-6",
    component: _1b085a39,
    name: "it-IT/product/529"
  }, {
    path: "/lenti-a-contatto/p530/air-optix-plus-hydraglyde-multifocal-6",
    component: _1b085a39,
    name: "it-IT/product/530"
  }, {
    path: "/soluzioni-multiuso/p485/acuvue-revitalens-300ml",
    component: _1b085a39,
    name: "it-IT/product/485"
  }, {
    path: "/soluzioni-multiuso/p485/acuvue-revitalens-2x300ml",
    component: _1b085a39,
    name: "it-IT/product/486"
  }, {
    path: "/lenti-a-contatto-colorate/p531/light-blue-clearcolor-1-day",
    component: _1b085a39,
    name: "it-IT/product/531"
  }, {
    path: "/coloured-contact-lenses/light-blue-clearcolor-1-day_p531",
    redirect: "/lenti-a-contatto-colorate/p531/light-blue-clearcolor-1-day"
  }, {
    path: "/lenti-a-contatto-colorate/p532/grey-clearcolor-1-day",
    component: _1b085a39,
    name: "it-IT/product/532"
  }, {
    path: "/lenti-a-contatto-colorate/p533/hazel-clearcolor-1-day",
    component: _1b085a39,
    name: "it-IT/product/533"
  }, {
    path: "/lenti-a-contatto-colorate/p534/blue-clearcolor-1-day",
    component: _1b085a39,
    name: "it-IT/product/534"
  }, {
    path: "/lenti-a-contatto-colorate/p535/green-clearcolor-1-day",
    component: _1b085a39,
    name: "it-IT/product/535"
  }, {
    path: "/b410/soluzioni-coopervision",
    component: _387cb151,
    name: "it-IT/brand/410"
  }, {
    path: "/lenti-a-contatto-colorate/p532/gray-clearcolor-1-day",
    redirect: "/lenti-a-contatto-colorate/p532/grey-clearcolor-1-day"
  }, {
    path: "/lenti-a-contatto/p542/miru-1day-upside",
    component: _1b085a39,
    name: "it-IT/product/542"
  }, {
    path: "/lenti-a-contatto/p547/total30",
    component: _1b085a39,
    name: "it-IT/product/547"
  }, {
    path: "/lenti-a-contatto/p548/total30-6",
    component: _1b085a39,
    name: "it-IT/product/548"
  }, {
    path: "/lenti-a-contatto/p544/bausch-and-lomb-ultra-for-astigmatism-3",
    component: _1b085a39,
    name: "it-IT/product/544"
  }, {
    path: "/lenti-a-contatto/p545/bausch-and-lomb-ultra-for-astigmatism-6",
    component: _1b085a39,
    name: "it-IT/product/545"
  }, {
    path: "/lenti-a-contatto/p546/bausch-and-lomb-ultra-multifocal-for-astigmatism-6",
    component: _1b085a39,
    name: "it-IT/product/546"
  }, {
    path: "/lenti-a-contatto/p549/miru-1day-flat-multifocal",
    component: _1b085a39,
    name: "it-IT/product/549"
  }, {
    path: "/lenti-a-contatto/p550/miru-1day-flat-toric",
    component: _1b085a39,
    name: "it-IT/product/550"
  }, {
    path: "/lenti-a-contatto/p551/miru-1day-multifocal",
    component: _1b085a39,
    name: "it-IT/product/551"
  }, {
    path: "/lenti-a-contatto/p552/bausch-and-lomb-ultra-one-day",
    component: _1b085a39,
    name: "it-IT/product/552"
  }, {
    path: "/lenti-a-contatto/p543/acuvue-oasys-multifocal",
    component: _1b085a39,
    name: "it-IT/product/543"
  }, {
    path: "/lenti-a-contatto/p556/myday-multifocal",
    component: _1b085a39,
    name: "it-IT/product/556"
  }, {
    path: "/lenti-a-contatto/p6/acuvue-oasys-multifocal",
    redirect: "/lenti-a-contatto/p543/acuvue-oasys-multifocal"
  }, {
    path: "/lenti-a-contatto/p/acuvue-oasys-multifocal",
    redirect: "/lenti-a-contatto/p543/acuvue-oasys-multifocal"
  }, {
    path: "/lenti-a-contatto/p550miru-1day-flat-toric",
    redirect: "/lenti-a-contatto/p550/miru-1day-flat-toric"
  }, {
    path: "/lenti-a-contatto/p551miru-1day-multifocal",
    redirect: "/lenti-a-contatto/p551/miru-1day-multifocal"
  }, {
    path: "/login",
    component: _00ef9433,
    name: "it-IT/login"
  }, {
    path: "/registrazione",
    component: _b33f986e,
    name: "it-IT/register"
  }, {
    path: "/pagamento",
    component: _18205bd0,
    name: "it-IT/payment"
  }, {
    path: "/account/cambia-password",
    component: _ccd8623c,
    name: "it-IT/change-password"
  }, {
    path: "/ordini",
    redirect: "/account/cronologia-ordini"
  }, {
    path: "/dati-del-cliente",
    redirect: "/account/dati-personali"
  }, {
    path: "/preferenze-di-comunicazione",
    redirect: "/account/preferenze-di-comunicazione"
  }, {
    path: "/indirizzi",
    redirect: "/account/indirizzi"
  }, {
    path: "/password-dimenticata",
    component: _c4961b62,
    name: "it-IT/forgot-password"
  }, {
    path: "/carrello",
    component: _99c1be28,
    name: "it-IT/basket"
  }, {
    path: "/consegna",
    component: _34d0109a,
    name: "it-IT/delivery"
  }, {
    path: "/conferma-dell-ordine",
    component: _4f90837a,
    name: "it-IT/order-confirmation"
  }, {
    path: "/accedi",
    redirect: "/login"
  }, {
    path: "/lenti-a-contatto/p557/dailies-total-1-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/557"
  }, {
    path: "/lenti-a-contatto/p558/dailies-total-1-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/558"
  }, {
    path: "/gestisci-i-pagamenti",
    component: _6171e290,
    name: "it-IT/payment-management"
  }, {
    path: "/istruzioni-uso",
    component: _974a4b7e,
    name: "it-IT/document/159"
  }, {
    path: "/b2/bausch-lomb",
    redirect: "/b345/lenti-a-contatto-colorate"
  }, {
    path: "/localizzatore-negozi",
    component: _12d211f4,
    name: "it-IT/store-locator"
  }, {
    path: "/lenti-a-contatto/p559/acuvue-oasys-max-1-day",
    component: _1b085a39,
    name: "it-IT/product/559"
  }, {
    path: "/lenti-a-contatto/p560/acuvue-oasys-max-1-day-multifocal",
    component: _1b085a39,
    name: "it-IT/product/560"
  }, {
    path: "/b347/1-day-acuvue-moist",
    component: _387cb151,
    name: "it-IT/brand/347"
  }, {
    path: "/b411/acuvue-oasys-max",
    component: _387cb151,
    name: "it-IT/brand/411"
  }, {
    path: "/programma-di-affiliazione",
    component: _974a4b7e,
    name: "it-IT/document/42"
  }, {
    path: "/b411/acuvue-oasys-max-1-day",
    redirect: "/b411/acuvue-oasys-max"
  }, {
    path: "/gestisci-i-pagamenti/ordine-ricorrente/:id?",
    component: _2030f21f,
    name: "it-IT/payment-management-child"
  }, {
    path: "/lenti-a-contatto/p561/acuvue-oasys-with-transitions",
    component: _1b085a39,
    name: "it-IT/product/561"
  }, {
    path: "/account/ordine-ricorrente",
    component: _0b6e7d80,
    name: "it-IT/auto-reorders"
  }, {
    path: "/collirio/p555/systane-ultra-senza-conservanti",
    component: _1b085a39,
    name: "it-IT/product/555"
  }, {
    path: "/collirio/p553/systane-complete-senza-conservanti",
    component: _1b085a39,
    name: "it-IT/product/553"
  }, {
    path: "/collirio/p554/systane-hydratation-senza-conservanti",
    component: _1b085a39,
    name: "it-IT/product/554"
  }, {
    path: "/systane-complete-senza-conservanti",
    redirect: "/collirio/p553/systane-complete-senza-conservanti"
  }, {
    path: "/systane-hydratation-senza-conservanti",
    redirect: "/collirio/p554/systane-hydratation-senza-conservanti"
  }, {
    path: "/systane-ultra-senza-conservanti",
    redirect: "/collirio/p555/systane-ultra-senza-conservanti"
  }, {
    path: "/collirio/p555/systane-complete-senza-conservanti",
    redirect: "/collirio/p555/systane-ultra-senza-conservanti"
  }, {
    path: "/account/ordine-ricorrente/ordini/:id?",
    component: _74a9990f,
    name: "it-IT/auto-reorders-child"
  }, {
    path: "/sitemap",
    component: _974a4b7e,
    name: "it-IT/document/60"
  }, {
    path: "/lenti-a-contatto/p562/biotrue-oneday-for-presbyopia-90",
    component: _1b085a39,
    name: "it-IT/product/562"
  }, {
    path: "/dsar",
    component: _974a4b7e,
    name: "it-IT/document/161"
  }, {
    path: "/lenti-a-contatto/p562/biotrue-oneday-for-astigmatism-90",
    redirect: "/lenti-a-contatto/p562/biotrue-oneday-for-presbyopia-90"
  }, {
    path: "/soluzioni-multiuso/p563/biotrue-flight-pack-100ml",
    component: _1b085a39,
    name: "it-IT/product/563"
  }, {
    path: "/soluzioni-multiuso/p193/biotrue-flight-pack-100ml",
    redirect: "/soluzioni-multiuso/p563/biotrue-flight-pack-100ml"
  }, {
    path: "/lenti-a-contatto/p564/acuvue-oasys-max-1-day-90",
    component: _1b085a39,
    name: "it-IT/product/564"
  }, {
    path: "/lenti-a-contatto/p565/acuvue-oasys-max-1-day-multifocal-90",
    component: _1b085a39,
    name: "it-IT/product/565"
  }, {
    path: "/grazie",
    component: _974a4b7e,
    name: "it-IT/document/162"
  }, {
    path: "/pagina-sconto-studenti",
    component: _974a4b7e,
    name: "it-IT/document/106"
  }, {
    path: "/pagina-sconto-studenti-unidays",
    redirect: "/pagina-sconto-studenti"
  }, {
    path: "/lenti-a-contatto/p566/total30-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/566"
  }, {
    path: "/lenti-a-contatto/p567/total30-multifocal",
    component: _1b085a39,
    name: "it-IT/product/567"
  }, {
    path: "/lenti-a-contatto/p568/total30-for-astigmatism",
    component: _1b085a39,
    name: "it-IT/product/568"
  }, {
    path: "/lenti-a-contatto/p569/total30-multifocal",
    component: _1b085a39,
    name: "it-IT/product/569"
  }, {
    path: "/lenti-a-contatto/p570/everclear-elite",
    component: _1b085a39,
    name: "it-IT/product/570"
  }, {
    path: "/lenti-a-contatto/p571/everclear-elite-confezione-di-prova",
    component: _1b085a39,
    name: "it-IT/product/571"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
