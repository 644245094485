import Vue from 'vue'

import { defaultParams, couponMaxAge, couponErrorMessages } from '@/utils/constants'

export const state = () => ({
  lines: [],
  dispatchDay: null,
  deliveryDateFrom: null,
  deliveryDateTo: null,
  cutoff: null,
  recommendations: [],
  discount: 0,
  upgradePaths: [],
})

export const getters = {
  totalPrice(state) {
    const basketTotal = state.lines.reduce((total, line) => {
      const { originalUnitPrice, unitPrice, qty } = line
      const itemTotal = unitPrice
        ? parseFloat(unitPrice) * parseInt(qty, 10)
        : parseFloat(originalUnitPrice) * parseInt(qty, 10)
      return total + itemTotal
    }, 0)

    return basketTotal - parseFloat(state.discount)
  },
  totalQuantity(state) {
    return state.lines.reduce((quantity, line) => quantity + parseInt(line.qty, 10), 0)
  },
  totalSave(state) {
    return state.lines.reduce((total, line) => {
      const { originalUnitPrice, unitPrice, qty } = line
      const priceDifference = unitPrice ? parseFloat(originalUnitPrice) - parseFloat(unitPrice) : 0
      const savedAmount = priceDifference * parseInt(qty, 10)

      return total + savedAmount
    }, 0)
  },
  upgradePathById: (state) => (id) => {
    return state.upgradePaths.find((upgradePath) => upgradePath.id === id)
  },
}

export const mutations = {
  SET_BASKET_LINES(state, lines) {
    Vue.set(state, 'lines', [...lines])
  },
  ADD_ITEM(state, item) {
    const itemToUpdateIndex = state.lines.findIndex(
      (line) => line.productId === item.productId && JSON.stringify(line.options) === JSON.stringify(item.options),
    )
    const itemExist = itemToUpdateIndex > -1
    const { qty } = item
    const quantity = itemExist ? state.lines[itemToUpdateIndex].qty + qty : qty
    const updatedItem = { ...item, qty: quantity }

    if (itemExist) {
      Vue.set(state.lines, itemToUpdateIndex, updatedItem)
    } else {
      Vue.set(state, 'lines', [...state.lines, updatedItem])
    }
  },
  UPDATE_ITEM_QUANTITY(state, { item, quantity }) {
    const itemToUpdateIndex = state.lines.findIndex(
      (line) => line.productId === item.productId && JSON.stringify(line.options) === JSON.stringify(item.options),
    )

    if (itemToUpdateIndex > -1) {
      if (quantity <= 0) {
        state.lines.splice(itemToUpdateIndex, 1)
      } else {
        const updatedItem = { ...item, qty: quantity }
        Vue.set(state.lines, itemToUpdateIndex, updatedItem)
      }
    }
  },
  REPLACE_ITEM(state, { fromItem, toItem }) {
    const itemToReplaceIndex = state.lines.findIndex(
      (line) =>
        line.productId === fromItem.productId && JSON.stringify(line.options) === JSON.stringify(fromItem.options),
    )
    if (itemToReplaceIndex > -1) {
      toItem.qty = 1
      Vue.set(state.lines, itemToReplaceIndex, toItem)
    }
  },
  SET_GUESSTIMATION(state, guesstimation) {
    Vue.set(state, 'dispatchDay', guesstimation.dispatchDay)
    Vue.set(state, 'deliveryDateFrom', guesstimation.deliveryDateFrom)
    Vue.set(state, 'deliveryDateTo', guesstimation.deliveryDateTo)
    Vue.set(state, 'cutoff', guesstimation.cutoff)
  },
  SET_RECOMMENDATIONS(state, recommendations) {
    Vue.set(state, 'recommendations', recommendations)
  },
  SET_DISCOUNT(state, discount) {
    Vue.set(state, 'discount', discount)
  },
  RESET_GUESSTIMATION(state) {
    Vue.set(state, 'dispatchDay', null)
    Vue.set(state, 'deliveryDateFrom', null)
    Vue.set(state, 'deliveryDateTo', null)
    Vue.set(state, 'cutoff', null)
  },
  SET_UPGRADE_PATH(state, { id, upgradePath }) {
    const itemToUpdateIndex = state.upgradePaths.findIndex((upgradePath) => upgradePath.id === id)
    const itemExist = itemToUpdateIndex > -1

    if (itemExist) {
      Vue.set(state.upgradePaths, itemToUpdateIndex, { id, upgradePath })
    } else {
      Vue.set(state, 'upgradePaths', [...state.upgradePaths, { id, upgradePath }])
    }
  },
}

export const actions = {
  async addToBasket({ dispatch, rootGetters, rootState, commit }) {
    dispatch('prescription/validateEmptyOptions', null, { root: true })

    if (rootGetters['prescription/isValid']) {
      const { product, prescription } = rootState
      const isAccessory = product.options.length === 0
      const { leftEye, rightEye } = rootGetters['prescription/optionsStrings']
      const itemsOptions = []

      if (isAccessory) {
        itemsOptions.push([])
      }

      if (leftEye) {
        itemsOptions.push(prescription.leftEyeOptions)
      }

      if (rightEye) {
        itemsOptions.push(prescription.rightEyeOptions)
      }

      itemsOptions.forEach((itemOptions) => {
        commit('ADD_ITEM', {
          name: product.name,
          packSize: product.packSize,
          qty: prescription.quantity,
          productId: parseInt(product.id, 10),
          options: product.options.map((option) => {
            const { value, label } = itemOptions[option.name]

            return {
              id: value,
              type: option.name,
              value: label,
            }
          }),
          image: [product.images[0]] || null,
          originalUnitPrice: product.fullPrice,
          unitPrice: product.discountedPrice,
          code: product.code,
          link: product.link,
        })
      })

      await dispatch('saveBasket')
    }
  },
  async addRecommendedToBasket({ commit, dispatch }, recommendation) {
    const { name, productId, images, price, discountPrice, productCode: code, link } = recommendation
    const item = {
      name,
      packSize: '',
      qty: 1,
      productId: parseInt(productId, 10),
      options: [],
      image: images[0] || null,
      originalUnitPrice: price,
      unitPrice: discountPrice,
      code,
      link,
    }

    commit('ADD_ITEM', item)
    await dispatch('saveBasket')
  },
  async updateQuantity({ commit, dispatch }, { item, quantity }) {
    commit('UPDATE_ITEM_QUANTITY', { item, quantity })
    await dispatch('saveBasket')
  },
  async loadLocalBasket({ dispatch }) {
    const lines = await JSON.parse(localStorage.getItem('basket'))

    if (lines?.length) {
      await dispatch('loadBasket', lines)
      await dispatch('getDiscountedBasket')
    }
  },
  async loadBasket({ commit, dispatch }, lines) {
    const extendedLines = await dispatch('extendLinesWithUpgradePath', lines)
    commit('SET_BASKET_LINES', extendedLines)
    await Promise.all([dispatch('getGuesstimation'), dispatch('getRecommendations')])
  },
  async extendLinesWithUpgradePath({ dispatch }, lines) {
    const deduplicatedProductIds = new Set(lines.map((line) => line.productId))

    const productUpgradePathMapping = await Promise.all(
      [...deduplicatedProductIds].map(async (productId) => {
        const upgradePath = await dispatch('getUpgradePathForProductId', productId)
        return upgradePath
      }),
    )

    lines.forEach((line) => {
      line.upgradePath = productUpgradePathMapping.find((mapping) => line.productId === mapping.id)?.upgradePath || null
    })
    return lines
  },
  async getUpgradePathForProductId({ commit, getters }, productId) {
    let upgradePath = getters.upgradePathById(productId)
    if (upgradePath) {
      return upgradePath
    }
    try {
      const response = await this.$axios.get(`/products/${productId}`, { params: defaultParams })
      const { data } = response.data
      upgradePath = data.upgradePath || null
    } catch {
      upgradePath = null
    }

    commit('SET_UPGRADE_PATH', { id: productId, upgradePath })
    return { id: productId, upgradePath }
  },
  async emptyBasket({ commit }) {
    commit('SET_BASKET_LINES', [])
    commit('SET_DISCOUNT', 0)
    commit('RESET_GUESSTIMATION')
    await localStorage.setItem('basket', JSON.stringify([]))
  },
  async saveBasket({ state, rootState, dispatch }) {
    const { orderId } = rootState.order.order

    await localStorage.setItem('basket', JSON.stringify(state.lines))

    if (orderId && rootState.auth.loggedIn) {
      await dispatch('order/updateOrder', { orderContext: 'store/basket saveBasket' }, { root: true })
    } else {
      await await dispatch('getDiscountedBasket')
    }

    if (state.lines.length) {
      if (!this.$auth.loggedIn) {
        await Promise.all([dispatch('getGuesstimation'), dispatch('getRecommendations')])
      }
    } else {
      dispatch('order/setAutoReorderDaysInterval', '', { root: true })
      dispatch('order/setNextOrderDate', '', { root: true })
    }
  },
  async getDiscountedBasket({ state, commit, rootState, dispatch }) {
    const { couponCode } = rootState
    const basket = state.lines.map((item) => {
      const { options, productId, qty } = item
      const option = options.reduce((string, option) => `${string}${option.id}|`, `${productId}:`).slice(0, -1)
      return { option, qty }
    })

    if (!basket.length) {
      return
    }

    commit('REMOVE_ERROR', 'basket', { root: true })
    commit('ADD_LOADING', 'basket', { root: true })

    try {
      const params = { basket: JSON.stringify(basket), ...(couponCode && { couponCode }), ...defaultParams }
      const response = await this.$axios.get('/order/basket', { params })
      const { data } = response.data

      const extendedLines = await dispatch('extendLinesWithUpgradePath', data.lines)
      commit('SET_BASKET_LINES', extendedLines)

      if (data.coupon) {
        commit('SET_COUPON_DETAILS', data.coupon, { root: true })
      }

      commit('SET_DISCOUNT', data.discount)

      await dispatch('getRecommendations')

      if (couponCode) {
        this.$cookies.set('couponCode', couponCode, { maxAge: couponMaxAge })
      }
    } catch (error) {
      if (couponCode) {
        const { errorCode = 'O-100' } = error.response.data
        const errorMessage = couponErrorMessages[errorCode]

        commit('SET_COUPON_ERROR', { couponCode, errorMessage }, { root: true })
      }

      try {
        const params = { basket: JSON.stringify(basket), ...defaultParams }
        const response = await this.$axios.get('/order/basket', { params })
        const { data } = response.data

        const extendedLines = await dispatch('extendLinesWithUpgradePath', data.lines)
        commit('SET_BASKET_LINES', extendedLines)
        commit('SET_DISCOUNT', data.discount)
        await dispatch('getRecommendations')
      } catch (error) {
        commit('ADD_ERROR', { id: 'basket', error }, { root: true })
      }
    }

    commit('REMOVE_LOADING', 'basket', { root: true })
  },
  async getGuesstimation({ state, commit, rootState }) {
    const options = state.lines.map((item) => {
      const { options, productId, qty } = item
      const option = options.reduce((string, option) => `${string}${option.id}|`, `${productId}:`).slice(0, -1)
      return { option, qty }
    })

    commit('REMOVE_ERROR', 'basketGuesstimation', { root: true })
    commit('ADD_LOADING', 'basketGuesstimation', { root: true })

    try {
      const { orderId } = rootState.order.order
      let orderParams = {}
      if (orderId && rootState.auth.loggedIn) {
        orderParams = { calledFrom: 'cart', orderId }
      }
      const params = { options: JSON.stringify(options), ...orderParams, ...defaultParams }
      const response = await this.$axios.get('/stock/guesstimation', { params })
      const { data: guesstimation } = response.data
      const deserializedGuesstimation = {
        dispatchDate: guesstimation.estimatedDispatchDay,
        deliveryDateFrom: guesstimation.estimatedDelivery.from,
        deliveryDateTo: guesstimation.estimatedDelivery.to,
        cutoff: guesstimation.cutoff,
      }

      commit('SET_GUESSTIMATION', deserializedGuesstimation)
    } catch (error) {
      commit('ADD_ERROR', { id: 'basketGuesstimation', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'basketGuesstimation', { root: true })
  },
  async getRecommendations({ commit, state, rootState }) {
    const { couponCode } = rootState
    const productIds = [...new Set(state.lines.map((item) => item.productId))].join(',')
    const params = { productIds, ...(couponCode && { couponCode }), ...defaultParams }

    commit('REMOVE_ERROR', 'basketRecommendations', { root: true })
    commit('ADD_LOADING', 'basketRecommendations', { root: true })

    try {
      const response = await this.$axios.get('/products/recommendations', { params })
      const { data: recommendations } = response.data
      const deserializedRecommendations = recommendations.map((recommendation) => {
        const [image] = recommendation.images
        return { ...recommendation, image: image || null }
      })

      commit('SET_RECOMMENDATIONS', deserializedRecommendations)
    } catch (error) {
      commit('ADD_ERROR', { id: 'basketRecommendations', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'basketRecommendations', { root: true })
  },
  async upgradeItem({ dispatch }, { fromItem, toItem }) {
    const options = await dispatch('getUpgradeItemOptions', { fromItem, toItem })
    if (!options) {
      return
    }

    const replacementItem = { ...toItem, options, productId: toItem.id }
    await dispatch('replaceLineItem', { fromItem, toItem: replacementItem })
  },
  async getUpgradeItemOptions(_context, { fromItem, toItem }) {
    const sourceOptionsIds = JSON.stringify(fromItem.options.map((option) => option.id))
    try {
      const params = {
        sourceOptionsIds,
        ...defaultParams,
      }
      const response = await this.$axios.get(`/products/${fromItem.productId}/packsizeToggleOptions/${toItem.id}`, {
        params,
      })
      const { data: options } = response.data
      return options
    } catch {
      // Fail silently for MVP - if packsizeToggleOptions endpoint returns error response, we just return undefined
    }
  },
  async replaceLineItem({ commit, dispatch }, { fromItem, toItem }) {
    commit('REPLACE_ITEM', { fromItem, toItem })
    await dispatch('saveBasket')
  },
}
