
import { mapState } from 'vuex'
import slugifyMixin from '@/mixins/slugify'

import MenuSpotlight from '@/components/MenuSpotlight'
import ChevronIcon from '@/static/svg/chevron-icon.svg'

export default {
  components: {
    MenuSpotlight,
    ChevronIcon,
  },
  mixins: [slugifyMixin],
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeMenuItem: null,
      menuTimeout: null,
    }
  },
  computed: {
    ...mapState(['app']),
    storeLocatorEnabled() {
      return this.app?.storeLocator?.enabled
    },
  },
  watch: {
    $route() {
      clearTimeout(this.menuTimeout)
      this.activeMenuItem = null
    },
  },
  methods: {
    isActive(menuItem) {
      return menuItem.title === this.activeMenuItem?.title
    },
    openMenuItem(menuItem) {
      this.menuTimeout = setTimeout(() => (this.activeMenuItem = menuItem), 200)
    },
    closeMenu() {
      this.menuTimeout = setTimeout(() => (this.activeMenuItem = null), 500)
    },
    clearTimeout() {
      clearTimeout(this.menuTimeout)
    },
  },
}
