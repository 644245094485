import Vue from 'vue'

import { defaultParams } from '@/utils/constants'

export const state = () => ({
  dispatchDay: null,
  deliveryDateFrom: null,
  deliveryDateTo: null,
  cutoff: null,
  leftEyeStock: {},
  rightEyeStock: {},
  lineItemStock: {},
  accessoryStock: {},
})

export const mutations = {
  SET_GUESSTIMATION(state, guesstimation) {
    Vue.set(state, 'dispatchDay', guesstimation.dispatchDay)
    Vue.set(state, 'deliveryDateFrom', guesstimation.deliveryDateFrom)
    Vue.set(state, 'deliveryDateTo', guesstimation.deliveryDateTo)
    Vue.set(state, 'cutoff', guesstimation.cutoff)
  },
  RESET_GUESSTIMATION(state) {
    Vue.set(state, 'dispatchDay', null)
    Vue.set(state, 'deliveryDateFrom', null)
    Vue.set(state, 'deliveryDateTo', null)
    Vue.set(state, 'cutoff', null)
  },
  SET_LEFT_EYE_STOCK(state, stock) {
    Vue.set(state, 'leftEyeStock', stock)
  },
  SET_RIGHT_EYE_STOCK(state, stock) {
    Vue.set(state, 'rightEyeStock', stock)
  },
  SET_LINE_ITEM_STOCK(state, stock) {
    Vue.set(state, 'lineItemStock', stock)
  },
  SET_ACCESSORY_STOCK(state, stock) {
    Vue.set(state, 'accessoryStock', stock)
  },
  RESET_STOCK(state) {
    Vue.set(state, 'leftEyeStock', {})
    Vue.set(state, 'rightEyeStock', {})
    Vue.set(state, 'accessoryStock', {})
  },
  RESET_LEFT_EYE_STOCK(state) {
    Vue.set(state, 'leftEyeStock', {})
  },
  RESET_RIGHT_EYE_STOCK(state) {
    Vue.set(state, 'rightEyeStock', {})
  },
  RESET_ACCESSORY_STOCK(state) {
    Vue.set(state, 'accessoryStock', {})
  },
  RESET_LINE_ITEM_STOCK(state) {
    Vue.set(state, 'lineItemStock', {})
  },
}

export const actions = {
  async getGuesstimation({ rootGetters, rootState, state, commit }) {
    const { leftEye: leftEyeString, rightEye: rightEyeString } = rootGetters['prescription/optionsStrings']
    const { isLeftEyeSelected, isRightEyeSelected, quantity: qty } = rootState.prescription
    const { limited: isLeftEyeLimited = false, stock: leftEyeStock = 0 } = state.leftEyeStock
    const { limited: isRightEyeLimited = false, stock: rightEyeStock = 0 } = state.rightEyeStock
    const isLeftEyeValid = isLeftEyeSelected && leftEyeString && !(isLeftEyeLimited && leftEyeStock === 0)
    const isRightEyeValid = isRightEyeSelected && rightEyeString && !(isRightEyeLimited && rightEyeStock === 0)
    const options = []

    if (isLeftEyeValid && isRightEyeValid) {
      if (leftEyeString === rightEyeString) {
        options.push({ option: leftEyeString, qty: qty * 2 })
      } else {
        options.push({ option: leftEyeString, qty })
        options.push({ option: rightEyeString, qty })
      }
    } else if (isLeftEyeValid && !isRightEyeSelected) {
      options.push({ option: leftEyeString, qty })
    } else if (isRightEyeValid && !isLeftEyeSelected) {
      options.push({ option: rightEyeString, qty })
    } else if (rootState.product.options.length === 0) {
      options.push({ option: rootState.product.id, qty })
    } else {
      commit('RESET_GUESSTIMATION')
      return
    }

    commit('REMOVE_ERROR', 'guesstimation', { root: true })
    commit('ADD_LOADING', 'guesstimation', { root: true })

    try {
      const params = { options: JSON.stringify(options), ...defaultParams }
      const response = await this.$axios.get('/stock/guesstimation', { params })
      const { data: guesstimation } = response.data
      const deserializedGuesstimation = {
        dispatchDate: guesstimation.estimatedDispatchDay,
        deliveryDateFrom: guesstimation.estimatedDelivery.from,
        deliveryDateTo: guesstimation.estimatedDelivery.to,
        cutoff: guesstimation.cutoff,
      }

      commit('SET_GUESSTIMATION', deserializedGuesstimation)
    } catch (error) {
      commit('ADD_ERROR', { id: 'guesstimation', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'guesstimation', { root: true })
  },

  async getLeftEyeStock({ rootGetters, state, commit, dispatch }) {
    const { leftEye: leftEyeId } = rootGetters['prescription/optionsStrings']

    if (!leftEyeId || leftEyeId === state.leftEyeStock.id) return

    commit('REMOVE_ERROR', 'leftEye', { root: true })
    commit('ADD_LOADING', 'leftEye', { root: true })

    try {
      const params = { options1: leftEyeId, ...defaultParams }
      const response = await this.$axios.get('/stock/livestock', { params })
      const { data: stock = {} } = response.data

      commit('SET_LEFT_EYE_STOCK', { ...stock, id: leftEyeId })
      dispatch('prescription/limitQuantity', null, { root: true })
    } catch (error) {
      commit('ADD_ERROR', { id: 'leftEye', error }, { root: true })
      commit('RESET_LEFT_EYE_STOCK')
    }

    commit('REMOVE_LOADING', 'leftEye', { root: true })
  },

  async getRightEyeStock({ rootGetters, state, commit, dispatch }) {
    const { rightEye: rightEyeId } = rootGetters['prescription/optionsStrings']

    if (!rightEyeId || rightEyeId === state.rightEyeStock.id) return

    commit('REMOVE_ERROR', 'rightEye', { root: true })
    commit('ADD_LOADING', 'rightEye', { root: true })

    try {
      const params = { options1: rightEyeId, ...defaultParams }
      const response = await this.$axios.get('/stock/livestock', { params })
      const { data: stock = {} } = response.data

      commit('SET_RIGHT_EYE_STOCK', { ...stock, id: rightEyeId })
      dispatch('prescription/limitQuantity', null, { root: true })
    } catch (error) {
      commit('ADD_ERROR', { id: 'rightEye', error }, { root: true })
      commit('RESET_RIGHT_EYE_STOCK')
    }

    commit('REMOVE_LOADING', 'rightEye', { root: true })
  },

  async getLineItemStock({ rootGetters, state, commit }) {
    const lineItemId = rootGetters['prescription/lineItemOptionsString']

    if (!lineItemId || lineItemId === state.lineItemStock.id) return

    commit('REMOVE_ERROR', 'lineItem', { root: true })
    commit('ADD_LOADING', 'lineItem', { root: true })

    try {
      const params = { options1: lineItemId, ...defaultParams }
      const response = await this.$axios.get('/stock/livestock', { params })
      const { data: stock = {} } = response.data

      commit('SET_LINE_ITEM_STOCK', { ...stock, id: lineItemId })
    } catch (error) {
      commit('ADD_ERROR', { id: 'lineItem', error }, { root: true })
      commit('RESET_LINE_ITEM_STOCK')
    }

    commit('REMOVE_LOADING', 'lineItem', { root: true })
  },

  async getAccessoryStock({ commit, rootState, dispatch }) {
    commit('REMOVE_ERROR', 'accessory', { root: true })
    commit('ADD_LOADING', 'accessory', { root: true })

    try {
      const params = { options1: rootState.product.id, ...defaultParams }
      const response = await this.$axios.get('/stock/livestock', { params })
      const { data: stock = {} } = response.data

      commit('SET_ACCESSORY_STOCK', stock)
      dispatch('prescription/limitQuantity', null, { root: true })
    } catch (error) {
      commit('ADD_ERROR', { id: 'accessory', error }, { root: true })
      commit('RESET_ACCESSORY_STOCK')
    }

    commit('REMOVE_LOADING', 'accessory', { root: true })
  },

  async getStock({ rootState, dispatch }) {
    if (rootState.product.options.length) {
      await dispatch('getLeftEyeStock')
      await dispatch('getRightEyeStock')
    } else {
      await dispatch('getAccessoryStock')
    }
  },

  resetStock({ commit }) {
    commit('RESET_STOCK')
    commit('RESET_GUESSTIMATION')
  },

  resetLineItemStockData({ commit }) {
    commit('REMOVE_ERROR', 'lineItem', { root: true })
    commit('RESET_LINE_ITEM_STOCK')
  },
}
