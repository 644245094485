import LRU from 'lru-cache'
import { cacheAdapterEnhancer } from 'axios-extensions'

export default function ({ app, $axios, ssrContext, redirect, store }) {
  const defaultCache = process.server
    ? ssrContext.$axiosCache
    : new LRU({ max: process.env.MAX_API_CALLS_TO_CACHE, ttl: process.env.API_CALL_CACHE_DURATION * 60 * 1000 })

  $axios.setBaseURL(app.i18n.localeProperties.api)
  $axios.defaults.adapter = cacheAdapterEnhancer($axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache',
    defaultCache,
  })

  const userId = app.$cookies?.get('ls_lb_uid')
  if (userId) {
    $axios.setHeader('x-api-user-id', userId)
  }

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    const isTokenExpired = error.name === 'ExpiredAuthSessionError'
    const isFailedLogin = error?.response?.config?.url === '/auth/login'

    if ((code === 401 || isTokenExpired) && !isFailedLogin) {
      store.commit('SET_SESSION_EXPIRED', true)
      store.commit('CLEAR_ERRORS')
      store.commit('CLEAR_LOADING')
      redirect('/login')
    }
  })
}
