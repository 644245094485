import Vue from 'vue'

import { defaultParams, orderIdCookieMaxAge } from '@/utils/constants'

export const state = () => ({
  addresses: [],
  deliveryAddressId: null,
  billingAddressId: null,
  latestAddedAddress: null,
})

export const mutations = {
  SET_ADDRESSES(state, addresses) {
    Vue.set(state, 'addresses', addresses)
  },
  SET_DELIVERY_ADDRESS_ID(state, addressId) {
    if (addressId) {
      Vue.set(state, 'deliveryAddressId', addressId)
    }
  },
  SET_BILLING_ADDRESS_ID(state, addressId) {
    if (addressId) {
      Vue.set(state, 'billingAddressId', addressId)
    }
  },
  SET_LATEST_ADDED_ADDRESS(state, address) {
    Vue.set(state, 'latestAddedAddress', address)
  },
}

export const getters = {
  defaultBillingAddress(state) {
    return state.addresses.find((address) => address.features.defaultBilling)
  },
  defaultDeliveryAddress(state) {
    return state.addresses.find((address) => address.features.defaultDelivery)
  },
}

export const actions = {
  setDeliveryAddressId({ commit }, addressId) {
    commit('SET_DELIVERY_ADDRESS_ID', addressId)
    this.$cookies.set('deliveryAddressId', addressId, { maxAge: orderIdCookieMaxAge, path: '/' })
  },
  setBillingAddressId({ commit }, addressId) {
    commit('SET_BILLING_ADDRESS_ID', addressId)
    this.$cookies.set('billingAddressId', addressId, { maxAge: orderIdCookieMaxAge, path: '/' })
  },
  async getAllAddresses({ commit }) {
    commit('REMOVE_ERROR', 'addresses', { root: true })
    commit('ADD_LOADING', 'addresses', { root: true })

    try {
      const response = await this.$axios.get('/address', { params: { ...defaultParams } })
      const { data: addresses } = response.data
      const savedDeliveryAddressId = parseInt(this.$cookies.get('deliveryAddressId'), 10)
      const savedBillingAddressId = parseInt(this.$cookies.get('billingAddressId'), 10)
      const defaultDeliveryAddress = addresses.find((address) => address.features.defaultDelivery)
      const defaultBillingAddress = addresses.find((address) => address.features.defaultBilling)

      const deliveryAddressId = savedDeliveryAddressId || defaultDeliveryAddress.id
      const billingAddressId = savedBillingAddressId || defaultBillingAddress.id

      commit('SET_ADDRESSES', addresses)
      commit('SET_DELIVERY_ADDRESS_ID', deliveryAddressId)
      commit('SET_BILLING_ADDRESS_ID', billingAddressId)
    } catch (error) {
      commit('ADD_ERROR', { id: 'addresses', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'addresses', { root: true })
  },
  async addNewAddress({ commit, dispatch }, address) {
    commit('REMOVE_ERROR', 'address', { root: true })
    commit('ADD_LOADING', 'address', { root: true })

    try {
      const response = await this.$axios.post('/address', address, { params: { ...defaultParams } })
      const { data: newAddress } = response.data

      if (address.features.defaultDelivery) {
        this.$cookies.remove('deliveryAddressId')
      }

      if (address.features.defaultBilling) {
        this.$cookies.remove('billingAddressId')
      }

      commit('SET_LATEST_ADDED_ADDRESS', newAddress)
      await dispatch('getAllAddresses')
    } catch (error) {
      commit('ADD_ERROR', { id: 'address', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'address', { root: true })
  },
  async updateAddress(
    { commit, dispatch, state },
    { addressId, editedAddress, setDefaultBilling = false, setDefaultDelivery = false },
  ) {
    commit('REMOVE_ERROR', 'address', { root: true })
    commit('ADD_LOADING', 'address', { root: true })

    const address =
      editedAddress ||
      JSON.parse(JSON.stringify(state.addresses.find((address) => address.id.toString() === addressId.toString())))

    if (setDefaultBilling) {
      address.features.defaultBilling = true
    }
    if (setDefaultDelivery) {
      address.features.defaultDelivery = true
    }

    try {
      await this.$axios.put(`/address/${addressId}`, { ...address }, { params: { ...defaultParams } })

      await dispatch('getAllAddresses')
    } catch (error) {
      commit('ADD_ERROR', { id: 'address', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'address', { root: true })
  },
  async deleteAddress({ commit, dispatch, state, getters }, address) {
    commit('REMOVE_ERROR', 'address', { root: true })
    commit('ADD_LOADING', 'address', { root: true })

    try {
      await this.$axios.delete(`/address/${address.id}`, { params: { ...defaultParams } })

      if (address.features.defaultDelivery) {
        this.$cookies.remove('deliveryAddressId')
      }

      if (address.features.defaultBilling) {
        this.$cookies.remove('billingAddressId')
      }

      if (
        address.id === state.deliveryAddressId ||
        address.id === parseInt(this.$cookies.get('deliveryAddressId'), 10)
      ) {
        await dispatch('setDeliveryAddressId', getters.defaultDeliveryAddress.id)
      }

      if (address.id === state.billingAddressId || address.id === parseInt(this.$cookies.get('billingAddressId'), 10)) {
        await dispatch('setBillingAddressId', getters.defaultBillingAddress.id)
      }

      await dispatch('getAllAddresses')
    } catch (error) {
      commit('ADD_ERROR', { id: 'address', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'address', { root: true })
  },
}
