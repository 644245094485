
import ModalDialog from '@/components/ModalDialog'
import LiftToLegacyModal from '@/components/LiftToLegacyModal'
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'
import rememberMe from '@/mixins/remember-me'
import layoutHeaders from '@/mixins/layout-headers'

export default {
  name: 'EnclosedLayout',
  components: {
    ModalDialog,
    LiftToLegacyModal,
    GlobalHeader,
    GlobalFooter,
  },
  mixins: [rememberMe, layoutHeaders],
  data() {
    return {
      showLeaveDialog: false,
    }
  },
  methods: {
    confirmLeave() {
      this.$router.push('/')
    },
    cancelLeave() {
      this.$refs.modalDialog.closeModalOverlay()
      this.showLeaveDialog = false
    },
  },
}
