export const genesysScript = (deploymentId, i18n) => ({
  type: 'text/javascript',
  charset: 'UTF-8',
  innerHTML: `
    (function (g, e, n, es, ys) {
      g['_genesysJs'] = e;
      g[e] = g[e] || function () {
        (g[e].q = g[e].q || []).push(arguments)
      };
      g[e].t = 1 * new Date();
      g[e].c = es;
      ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
    })(window, 'Genesys', 'https://apps.mypurecloud.ie/genesys-bootstrap/genesys.min.js', {
      environment: 'prod-euw1',
      deploymentId: '${deploymentId}'
    });
    Genesys("command", "Database.set", {
  messaging: {
    customAttributes: {
      webmsg_country: '${i18n.localeProperties.country}',
      webmsg_language: '${i18n.locale}',
      webmsg_brandcode: 'LS',
    }
  }
});`,
})
