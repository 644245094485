export default ({ app, store }, inject) => {
  inject('getLink', (link, id) => {
    const name = `${app.i18n.locale}${link}`
    const routes = store.$router.options.routes.filter((route) => route?.name?.includes(app.i18n.locale))
    const path = routes.find((item) => item?.name === name)?.path
    let newLink = link

    if (path) {
      newLink = id ? path.replace(':id?', id) : path
    }

    return newLink
  })

  inject('getResourceId', (name) => name?.split('/')?.[2] || -1)

  inject('isLinkFromCurrentLocale', (link) => {
    const localeRoutes = store.$router.options.routes.filter((route) => route?.name?.includes(app.i18n.locale))

    return (
      localeRoutes.some((route) => {
        let isLinkFromLocale = false

        if (route?.path?.includes(':id?')) {
          const pathAsArray = route.path.split('/')
          const linkAsArray = link.split('/')

          pathAsArray.pop()
          linkAsArray.pop()

          isLinkFromLocale = pathAsArray.join('/') === linkAsArray.join('/')
        } else {
          isLinkFromLocale = route?.path === link
        }

        return isLinkFromLocale
      }) ||
      link === '/' ||
      link.includes('search') || // TODO remove once translation is sorted
      link === '/checkout' // TODO remove once translation is sorted
    )
  })

  inject('getExternalLink', (menuItem) => {
    const linksMap = {
      'en-GB': 'externalLink',
      'de-DE': 'externalLinkDE',
      'it-IT': 'externalLinkIT',
      'fr-FR': 'externalLinkFR',
      'es-ES': 'externalLinkES',
    }

    return menuItem[linksMap[app.i18n.locale]] || ''
  })
}
