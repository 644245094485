export default function ({ route, store, app, redirect, error }) {
  const { path: currentPath = '' } = route
  const { loggedIn = false } = store.state.auth

  const orderId = app.$cookies.get('orderId')
  const checkoutPaths = ['/delivery', '/payment', '/order-confirmation']
  const isCheckoutPath = checkoutPaths.some((path) => app.$getLink(path) === app.$getLink(currentPath))
  if (!app.$isLinkFromCurrentLocale(currentPath)) {
    error({
      statusCode: 404,
      statusMessage: 'This page could not be found',
    })
  } else if (loggedIn && currentPath === app.$getLink('/register')) {
    redirect(app.$getLink('/account'))
  } else if (!loggedIn && !orderId && isCheckoutPath) {
    redirect(app.$getLink('/checkout'), { ...route.query })
  } else if (!loggedIn && app.$getLink('/order-confirmation') === app.$getLink(currentPath)) {
    redirect('/')
  }
}
