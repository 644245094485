
import ClickOutside from 'vue-click-outside'
import DeliveryIcon from '@/static/svg/usp-banner/delivery.svg'
import EyeCareIcon from '@/static/svg/usp-banner/eye-care.svg'
import OpticiansIcon from '@/static/svg/usp-banner/opticians.svg'
import GiftIcon from '@/static/svg/usp-banner/gift.svg'
import ReturnIcon from '@/static/svg/usp-banner/return.svg'
import DeliveryOutlineIcon from '@/static/svg/usp-banner/delivery-outline.svg'

export default {
  components: {
    DeliveryIcon,
    EyeCareIcon,
    OpticiansIcon,
    GiftIcon,
    ReturnIcon,
    DeliveryOutlineIcon,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      trustedShopsWidgetOpen: false,
    }
  },
  head() {
    return {
      script: [
        { src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js', async: true },
        { src: '//integrations.etrusted.com/applications/widget.js/v2', async: true },
      ],
    }
  },
  mounted() {
    if (this.$refs.trustpilot) {
      window.Trustpilot?.loadFromElement(this.$refs.trustpilot)
    }
  },
  methods: {
    trustedShopsWidgetIsClosed() {
      this.trustedShopsWidgetOpen = false
    },
  },
}
