import Vue from 'vue'

import { defaultParams } from '@/utils/constants'

export const state = () => ({
  title: '',
  id: null,
  content: '',
  showTitle: true,
  meta: '',
})

export const mutations = {
  SET_CONTENT(state, content) {
    Vue.set(state, 'title', content.name)
    Vue.set(state, 'id', content.id)
    Vue.set(state, 'meta', content.meta)
    Vue.set(state, 'content', content.content)
    Vue.set(state, 'showTitle', !content.features?.hideH1)
  },
}

export const actions = {
  async getContent({ commit }, contentId) {
    commit('REMOVE_ERROR', 'content', { root: true })
    commit('ADD_LOADING', 'content', { root: true })

    try {
      const response = await this.$axios.get(`/content/${contentId}`, { params: defaultParams })
      const { data: content } = response.data

      commit('SET_CONTENT', content)
    } catch (error) {
      if (error.response?.status === 404) {
        this.app.context.error({ statusCode: 404, message: 'Content not found' })
      }

      commit('ADD_ERROR', { id: 'content', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'content', { root: true })
  },
}
