import Vue from 'vue'
import { limitedStockThreshold } from '@/utils/constants'

export const state = () => ({
  isLeftEyeSelected: false,
  isRightEyeSelected: false,
  leftEyeOptions: {},
  rightEyeOptions: {},
  lineItemOptions: {},
  leftEyeErrors: {},
  rightEyeErrors: {},
  warningMessages: {},
  quantity: 1,
})

export const getters = {
  optionsStrings(state, _getters, rootState) {
    let isLeftEyeOptionsValid = true
    let isRightEyeOptionsValid = true

    rootState.product.options.forEach((option) => {
      if (!state.leftEyeOptions[option.name]?.value) {
        isLeftEyeOptionsValid = false
      }

      if (!state.rightEyeOptions[option.name]?.value) {
        isRightEyeOptionsValid = false
      }
    })

    return {
      leftEye:
        state.isLeftEyeSelected && isLeftEyeOptionsValid
          ? getOptionsString(rootState.product, state.leftEyeOptions)
          : '',
      rightEye:
        state.isRightEyeSelected && isRightEyeOptionsValid
          ? getOptionsString(rootState.product, state.rightEyeOptions)
          : '',
    }
  },
  lineItemOptionsString(state, _getters, rootState) {
    let isLineItemOptionsValid = true
    rootState.product.options.forEach((option) => {
      if (!state.lineItemOptions[option.name]?.value) {
        isLineItemOptionsValid = false
      }
    })
    return isLineItemOptionsValid ? getOptionsString(rootState.product, state.lineItemOptions) : ''
  },
  isValid(state) {
    return Object.keys(state.leftEyeErrors).length === 0 && Object.keys(state.rightEyeErrors).length === 0
  },
  isFilled(state, _getters, rootState) {
    return !rootState.product.options.some((option) => {
      return (
        (state.isLeftEyeSelected && !state.leftEyeOptions[option.name]?.value) ||
        (state.isRightEyeSelected && !state.rightEyeOptions[option.name]?.value) ||
        (!state.isLeftEyeSelected && !state.isRightEyeSelected)
      )
    })
  },
  quantityLimit(state, _getters, rootState) {
    const { stock: leftEyeStock, limited: leftEyeStockLimited } = rootState.stock.leftEyeStock
    const { stock: rightEyeStock, limited: rightEyeStockLimited } = rootState.stock.rightEyeStock
    const isLeftEyeLimited = leftEyeStock > 0 && leftEyeStockLimited && state.isLeftEyeSelected
    const isRightEyeLimited = rightEyeStock > 0 && rightEyeStockLimited && state.isRightEyeSelected
    const leftEyeOptions = JSON.stringify(state.leftEyeOptions)
    const rightEyeOptions = JSON.stringify(state.rightEyeOptions)
    const sameOptions = leftEyeOptions === rightEyeOptions
    const isAccessory = !rootState.product.options.length
    const { stock: accessoryStock, limited: accessoryStockLimited = false } = rootState.stock.accessoryStock
    let limit = rootState.maxQuantity

    if (isAccessory && accessoryStockLimited && accessoryStock <= limitedStockThreshold) {
      limit = accessoryStock
    } else if (isLeftEyeLimited && isRightEyeLimited && sameOptions) {
      limit = Math.max(1, Math.floor(leftEyeStock / 2))
    } else if (isLeftEyeLimited && isRightEyeLimited) {
      limit = Math.min(leftEyeStock, rightEyeStock)
    } else if (isLeftEyeLimited) {
      limit = leftEyeStock
    } else if (isRightEyeLimited) {
      limit = rightEyeStock
    }

    return limit
  },
}

export const mutations = {
  SET_VALUES_TO_DEFAULT(state) {
    Vue.set(state, 'isLeftEyeSelected', false)
    Vue.set(state, 'isRightEyeSelected', false)
    Vue.set(state, 'leftEyeOptions', {})
    Vue.set(state, 'rightEyeOptions', {})
    Vue.set(state, 'leftEyeErrors', {})
    Vue.set(state, 'rightEyeErrors', {})
    Vue.set(state, 'warningMessages', {})
    Vue.set(state, 'quantity', 1)
  },
  SET_LEFT_EYE_SELECTION(state, isChecked) {
    Vue.set(state, 'isLeftEyeSelected', isChecked)
  },
  SET_RIGHT_EYE_SELECTION(state, isChecked) {
    Vue.set(state, 'isRightEyeSelected', isChecked)
  },
  SET_LEFT_EYE_OPTION(state, option) {
    const { name, label = '', value, scrollTop, type } = option

    Vue.set(state, 'leftEyeOptions', {
      ...state.leftEyeOptions,
      [name]: { label, type, value: value ? parseInt(value, 10) : null, scrollTop },
    })
  },
  SET_RIGHT_EYE_OPTION(state, option) {
    const { name, label = '', value, scrollTop, type } = option

    Vue.set(state, 'rightEyeOptions', {
      ...state.rightEyeOptions,
      [name]: { label, type, value: value ? parseInt(value, 10) : null, scrollTop },
    })
  },
  SET_LINE_ITEM_OPTION(state, option) {
    const { name, label = '', value, scrollTop, type } = option

    Vue.set(state, 'lineItemOptions', {
      ...state.lineItemOptions,
      [name]: { label, type, value: value ? parseInt(value, 10) : null, scrollTop },
    })
  },
  ADD_LEFT_EYE_ERROR(state, option) {
    Vue.set(state, 'leftEyeErrors', {
      ...state.leftEyeErrors,
      [option.name]: true,
    })
  },
  ADD_RIGHT_EYE_ERROR(state, option) {
    Vue.set(state, 'rightEyeErrors', {
      ...state.rightEyeErrors,
      [option.name]: true,
    })
  },
  ADD_WARNING_MESSAGE(state, option) {
    Vue.set(state, 'warningMessages', {
      ...state.warningMessages,
      [option.name]: option.error,
    })
  },
  REMOVE_LEFT_EYE_ERROR(state, option) {
    if (state.leftEyeErrors[option.name]) {
      const { [option.name]: toRemove, ...rest } = state.leftEyeErrors
      Vue.set(state, 'leftEyeErrors', rest)
    }
  },
  REMOVE_RIGHT_EYE_ERROR(state, option) {
    if (state.rightEyeErrors[option.name]) {
      const { [option.name]: toRemove, ...rest } = state.rightEyeErrors
      Vue.set(state, 'rightEyeErrors', rest)
    }
  },
  CLEAR_LEFT_EYE_ERRORS(state) {
    Vue.set(state, 'leftEyeErrors', {})
  },
  CLEAR_RIGHT_EYE_ERRORS(state) {
    Vue.set(state, 'rightEyeErrors', {})
  },
  REMOVE_WARNING_MESSAGE(state, option) {
    if (state.warningMessages[option.name]) {
      const { [option.name]: toRemove, ...rest } = state.warningMessages
      Vue.set(state, 'warningMessages', rest)
    }
  },
  CLEAR_WARNING_MESSAGES(state) {
    Vue.set(state, 'warningMessages', {})
  },
  SET_QUANTITY(state, quantity) {
    Vue.set(state, 'quantity', quantity)
  },
  DUPLICATE_OPTION(state, optionName) {
    const leftEyeOption = state.leftEyeOptions[optionName]
    const rightEyeOption = state.rightEyeOptions[optionName]

    if (leftEyeOption && !rightEyeOption) {
      Vue.set(state, 'rightEyeOptions', { ...state.rightEyeOptions, [optionName]: { ...leftEyeOption } })
    } else if (rightEyeOption && !leftEyeOption) {
      Vue.set(state, 'leftEyeOptions', { ...state.leftEyeOptions, [optionName]: { ...rightEyeOption } })
    }
  },
  DUPLICATE_SCROLL_POSITION(state, optionName) {
    const leftEyeOption = state.leftEyeOptions[optionName]
    const rightEyeOption = state.rightEyeOptions[optionName]

    if (leftEyeOption && !rightEyeOption) {
      Vue.set(state, 'rightEyeOptions', {
        ...state.rightEyeOptions,
        [optionName]: { ...rightEyeOption, scrollTop: leftEyeOption.scrollTop },
      })
    } else if (rightEyeOption && !leftEyeOption) {
      Vue.set(state, 'leftEyeOptions', {
        ...state.leftEyeOptions,
        [optionName]: { ...leftEyeOption, scrollTop: rightEyeOption.scrollTop },
      })
    }
  },
}

export const actions = {
  setDefaultOptions({ rootState, commit }) {
    const { options } = rootState.product

    commit('SET_VALUES_TO_DEFAULT')

    if (options.length === 0) return

    options.forEach((option) => {
      if (option.items.length === 1) {
        commit('SET_LEFT_EYE_OPTION', { name: option.name, ...option.items[0] })
        commit('SET_RIGHT_EYE_OPTION', { name: option.name, ...option.items[0] })
      }
    })

    commit('SET_LEFT_EYE_SELECTION', true)
    commit('SET_RIGHT_EYE_SELECTION', true)
  },
  toggleEye({ commit, state, dispatch }, eyeId) {
    if (eyeId === 'left') {
      commit('SET_LEFT_EYE_SELECTION', !state.isLeftEyeSelected)
    } else if (eyeId === 'right') {
      commit('SET_RIGHT_EYE_SELECTION', !state.isRightEyeSelected)
    }

    if (!state.isLeftEyeSelected) {
      commit('CLEAR_LEFT_EYE_ERRORS')
      commit('CLEAR_WARNING_MESSAGES')
    }

    if (!state.isRightEyeSelected) {
      commit('CLEAR_RIGHT_EYE_ERRORS')
      commit('CLEAR_WARNING_MESSAGES')
    }

    dispatch('validateConnectedOptions')
  },
  setEyeOption({ commit, dispatch }, eye) {
    const optionsToDuplicate = ['base-curve', 'colour']
    const { name: optionName = '', type: optionType = '' } = eye.option

    if (eye.id === 'left') {
      commit('SET_LEFT_EYE_OPTION', eye.option)
      commit('REMOVE_LEFT_EYE_ERROR', eye.option)
    } else if (eye.id === 'right') {
      commit('SET_RIGHT_EYE_OPTION', eye.option)
      commit('REMOVE_RIGHT_EYE_ERROR', eye.option)
    }

    if (optionsToDuplicate.includes(optionType)) {
      commit('DUPLICATE_OPTION', optionName)
      commit('REMOVE_LEFT_EYE_ERROR', eye.option)
      commit('REMOVE_RIGHT_EYE_ERROR', eye.option)
    } else {
      commit('DUPLICATE_SCROLL_POSITION', optionName)
    }

    dispatch('validateConnectedOptions')
  },
  setLineItemOption({ commit }, option) {
    commit('SET_LINE_ITEM_OPTION', option)
  },
  updateQuantity({ commit }, quantity) {
    commit('SET_QUANTITY', quantity)
  },
  validateEmptyOptions({ state, rootState, commit }) {
    rootState.product.options.forEach((option) => {
      if (state.isLeftEyeSelected && !state.leftEyeOptions[option.name]?.value) {
        commit('ADD_LEFT_EYE_ERROR', option)
      } else {
        commit('REMOVE_LEFT_EYE_ERROR', option)
      }

      if (state.isRightEyeSelected && !state.rightEyeOptions[option.name]?.value) {
        commit('ADD_RIGHT_EYE_ERROR', option)
      } else {
        commit('REMOVE_RIGHT_EYE_ERROR', option)
      }
    })
  },
  validateConnectedOptions({ state, commit }) {
    const { isLeftEyeSelected, isRightEyeSelected } = state

    let leftBaseCurve
    let rightBaseCurve
    let leftPowerSign
    let rightPowerSign

    for (const option in state.leftEyeOptions) {
      if (state.leftEyeOptions[option].type === 'base-curve') leftBaseCurve = state.leftEyeOptions[option].value
      if (state.leftEyeOptions[option].type === 'power')
        leftPowerSign = state.leftEyeOptions[option]?.label?.match(/\+|-/g)?.[0] || []
    }
    for (const option in state.rightEyeOptions) {
      if (state.rightEyeOptions[option].type === 'base-curve') rightBaseCurve = state.rightEyeOptions[option].value
      if (state.rightEyeOptions[option].type === 'power')
        rightPowerSign = state.rightEyeOptions[option]?.label?.match(/\+|-/g)?.[0] || []
    }

    if (isLeftEyeSelected && isRightEyeSelected && leftBaseCurve && rightBaseCurve) {
      if (leftBaseCurve !== rightBaseCurve) {
        commit('ADD_WARNING_MESSAGE', {
          name: 'base-curve',
          error: this.$i18n.t('product.prescription.error.mismatch.baseCurve'),
        })
      } else {
        commit('REMOVE_WARNING_MESSAGE', { name: 'base-curve' })
      }
    }

    if (isLeftEyeSelected && isRightEyeSelected && leftPowerSign && rightPowerSign) {
      if (!leftPowerSign?.length) leftPowerSign = rightPowerSign
      if (!rightPowerSign?.length) rightPowerSign = leftPowerSign

      if (leftPowerSign !== rightPowerSign) {
        commit('ADD_WARNING_MESSAGE', {
          name: 'power',
          error: this.$i18n.t('product.prescription.error.mismatch.power'),
        })
      } else {
        commit('REMOVE_WARNING_MESSAGE', { name: 'power' })
      }
    }
  },
  limitQuantity({ state, getters, commit }) {
    if (state.quantity > getters.quantityLimit) {
      commit('SET_QUANTITY', getters.quantityLimit)
    }
  },
}

function getOptionsString(product, options) {
  return product.options
    .reduce((string, option) => `${string}${options[option.name].value}|`, `${product.id}:`)
    .slice(0, -1)
}
