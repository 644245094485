import { client, dataCollector } from 'braintree-web'
import { defaultParams } from '@/utils/constants'

// Braintree plugin

export default (context, inject) => {
  const pluginName = 'braintree'

  let brainTreeClient = null
  let dataCollectorInstance = null

  const createClient = async () => {
    const response = await context.$axios.post(
      '/payments/braintree/identity/client',
      {},
      {
        params: defaultParams,
      },
    )

    const token = response.data.data.clientToken

    return await client.create({ authorization: token })
  }

  const initDataCollector = async () => {
    if (!brainTreeClient) {
      brainTreeClient = await createClient()
    }

    return await dataCollector.create({ client: brainTreeClient })
  }

  const braintree = {
    async client() {
      if (!brainTreeClient) {
        brainTreeClient = await createClient()
      }
      return brainTreeClient
    },
    async deviceData() {
      if (!dataCollectorInstance) {
        dataCollectorInstance = await initDataCollector()
      }
      return dataCollectorInstance.getDeviceData()
    },
  }

  inject(pluginName, braintree)
}
