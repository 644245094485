import Vue from 'vue'

import { defaultParams } from '@/utils/constants'
import { getDeserializedCategoryProducts } from '@/utils/deserialization'

export const state = () => ({
  id: -1,
  breadcrumb: [],
  name: '',
  description: '',
  collapsedContent: '',
  products: [],
  meta: '',
})

export const mutations = {
  SET_CATEGORY(state, category) {
    Vue.set(state, 'breadcrumb', category.breadcrumb)
    Vue.set(state, 'id', category.id)
    Vue.set(state, 'meta', category.meta)
    Vue.set(state, 'name', category.name)
    Vue.set(state, 'description', category.description)
    Vue.set(state, 'collapsedContent', category.collapsedContent)
    Vue.set(state, 'products', [...category.products])
  },
}

export const actions = {
  async getCategory({ commit, rootState, dispatch }, categoryId) {
    commit('REMOVE_ERROR', 'category', { root: true })
    commit('ADD_LOADING', 'category', { root: true })

    const { couponCode } = rootState
    const params = { ...(couponCode && { couponCode }), ...defaultParams }

    try {
      const response = await this.$axios.get(`category/${categoryId}`, { params })
      const { data: category } = response.data
      const { products } = category
      const deserializedProducts = getDeserializedCategoryProducts(products)
      const deserializedCategories = { ...category, products: deserializedProducts }

      commit('SET_CATEGORY', deserializedCategories)
    } catch (error) {
      if (error.response?.status === 404) {
        this.app.context.error({ statusCode: 404, message: 'Category not found' })
      } else if (error.response?.status === 401) {
        await dispatch('silentLogOut', { source: 'category/getCategory' }, { root: true })

        try {
          const response = await this.$axios.get(`category/${categoryId}`, { params })
          const { data: category } = response.data
          const { products } = category
          const deserializedProducts = getDeserializedCategoryProducts(products)
          const deserializedCategories = { ...category, products: deserializedProducts }

          commit('SET_CATEGORY', deserializedCategories)
        } catch (error) {
          commit('ADD_ERROR', { id: 'category', error }, { root: true })
        }
      }

      commit('ADD_ERROR', { id: 'category', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'category', { root: true })
  },
}
