import { mapState } from 'vuex'
import { genesysScript } from '@/utils/scriptTagsDefinitions'

export default {
  head() {
    if (this.$store.state.app?.cookieProLegacyCleanup?.enabled) {
      const cookieProLegacyDomain = this.$i18n.localeProperties.cookieProLegacyDomain || ''
      const optanonCookie = this.$cookies.get('OptanonConsent', { domain: cookieProLegacyDomain })

      // 'C0001' exists in all SPA-set OptanonConsent cookie values but for none of the Legacy ones
      if (optanonCookie && !optanonCookie.includes('C0001')) {
        this.$cookies.remove('OptanonConsent', { domain: cookieProLegacyDomain })
        this.$cookies.remove('OptanonAlertBoxClosed', { domain: cookieProLegacyDomain })
      }
    }
    const hostUrl = this.$nuxt.context.req?.headers?.host || window.location.host
    return {
      title: this.$store.state.meta?.title,
      htmlAttrs: {
        lang: this.$i18n.localeProperties.iso,
      },
      link: [
        {
          rel: 'canonical',
          href: `https://${hostUrl}${this.$nuxt.$route.path}`,
          hid: 'canonical',
        },
      ],
      script: [
        {
          type: 'text/javascript',
          src: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
          charset: 'UTF-8',
          'data-document-language': true,
          'data-domain-script': this.$i18n.localeProperties.cookieProId || '',
        },
        this.shouldLoadGenesysScript ? genesysScript(this.genesysLivechat.deploymentId, this.$i18n) : {},
      ],
      ...(this.shouldLoadGenesysScript && { __dangerouslyDisableSanitizers: ['script'] }),
    }
  },
  data() {
    return {
      liveChatGenesys: false,
    }
  },
  computed: {
    ...mapState(['genesysLivechat']),
    shouldLoadGenesysScript() {
      return this.genesysLivechat?.enabled && this.liveChatGenesys && this.genesysLivechat?.deploymentId
    },
  },
  async mounted() {
    this.liveChatGenesys = await this.$store.dispatch('split/getSplitTreatment', 'liveChatGenesys')
  },
}
