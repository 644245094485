
import TickIcon from '@/static/svg/prescription/tick-icon.svg'

export default {
  components: {
    TickIcon,
  },
  props: {
    submit: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Button',
    },
    dataCy: {
      type: String,
      default: 'hero-button',
    },
    confirmationText: {
      type: String,
      default: '',
    },
    confirmOnce: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    widthAuto: {
      type: Boolean,
      default: false,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isClicked: false,
      animate: false,
      isConfirmed: false,
    }
  },
  computed: {
    showConfirmationThenHide() {
      return this.confirmationText && !this.confirmOnce
    },
    showConfirmationAndPersist() {
      return this.confirmationText && this.confirmOnce
    },
  },
  methods: {
    onClick() {
      this.isClicked = false
      if (this.disabled) {
        return
      }

      if (this.showConfirmationThenHide) {
        if (!this.animate) {
          this.animate = true
          this.$emit('clicked')
        }
        return
      }

      if (this.showConfirmationAndPersist) {
        this.isConfirmed = true
      }
      this.$emit('clicked')
    },
    resetAnimation() {
      this.animate = false
    },
  },
}
