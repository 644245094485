const middleware = {}

middleware['actions'] = require('../middleware/actions.js')
middleware['actions'] = middleware['actions'].default || middleware['actions']

middleware['auth-ssr'] = require('../middleware/auth-ssr.js')
middleware['auth-ssr'] = middleware['auth-ssr'].default || middleware['auth-ssr']

middleware['coupon-code'] = require('../middleware/coupon-code.js')
middleware['coupon-code'] = middleware['coupon-code'].default || middleware['coupon-code']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['reorder'] = require('../middleware/reorder.js')
middleware['reorder'] = middleware['reorder'].default || middleware['reorder']

middleware['strip-trailing-slashes'] = require('../middleware/strip-trailing-slashes.js')
middleware['strip-trailing-slashes'] = middleware['strip-trailing-slashes'].default || middleware['strip-trailing-slashes']

export default middleware
