export default function ({ app }) {
  app.mounted = async function () {
    const orderId = this.$cookies.get('orderId')
    const setUserType = () => {
      const performanceCookiesAllowed = !!window?.OptanonActiveGroups?.includes('C0002')

      if (performanceCookiesAllowed) {
        const savedUserType = this.$cookies.get('userType')

        if (!savedUserType) {
          const { userType } = this.$store.state

          this.$gtm.push({ event: 'userType', userType })
          this.$cookies.set('userType', userType)
        }
      }
    }

    if (!orderId || !this.$auth.loggedIn) {
      await this.$store.dispatch('basket/loadLocalBasket')
    }

    if (this.$splitClient) {
      try {
        await this.$splitClient.ready()
        this.$gtm.push({
          event: 'split_client_push',
          split_client: this.$splitClient,
        })
      } catch (error) {
        const userId = this.$cookies.get('ls_lb_uid')
        const authorizationKey = this.$splitFactory?.settings?.core?.authorizationKey
        this.$log({
          name: 'SplitError',
          properties: {
            authorizationKey,
            userId,
            reason: 'Split client error in app-mounted client',
            message: error.message,
            error,
          },
        })
      }
    }

    setUserType()

    window.OptanonWrapper = () => {
      if (window.OneTrust && !window.OneTrust.IsAlertBoxClosed()) {
        window.setTimeout(function () {
          const button = document.querySelector('#onetrust-accept-btn-handler')
          button?.focus()
        }, 200)
      }
      window?.OneTrust?.OnConsentChanged(() => setUserType())
    }
  }
}
