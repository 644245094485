import Vue from 'vue'

import { defaultParams, orderStatuses } from '@/utils/constants'

export const state = () => ({
  totalItems: 0,
  page: 1,
  pageSize: 5,
  orders: [],
  retriableOrders: [],
  parentAutoReorders: [],
  retriableTotalItems: 0,
  communicationPreferences: [],
  doubleOptIn: {},
})

export const mutations = {
  SET_TOTAL_ITEMS(state, totalItems) {
    Vue.set(state, 'totalItems', totalItems)
  },
  SET_PAGE(state, page) {
    Vue.set(state, 'page', page)
  },
  SET_PAGE_SIZE(state, pageSize) {
    Vue.set(state, 'pageSize', pageSize)
  },
  SET_ORDERS(state, orders) {
    Vue.set(state, 'orders', [...orders])
  },
  SET_MORE_ORDERS(state, orders) {
    Vue.set(state, 'orders', [...state.orders, ...orders])
  },
  SET_RETRIABLE_ORDERS(state, orders) {
    Vue.set(state, 'retriableOrders', orders)
  },
  FILTER_RETRIABLE_ORDERS(state) {
    const filteredOrders = state.orders.filter((normal) => {
      let filter = true
      state.retriableOrders.every((retriable) => {
        filter = retriable.orderId !== normal.orderId
        return filter
      })
      return filter
    })
    Vue.set(state, 'orders', filteredOrders)
  },
  SET_MORE_RETRIABLE_ORDERS(state, orders) {
    Vue.set(state, 'retriableOrders', [...state.retriableOrders, ...orders])
  },
  SET_RETRIABLE_TOTAL_ITEMS(state, totalItems) {
    Vue.set(state, 'retriableTotalItems', totalItems)
  },
  SET_COMMUNICATION_PREFERENCES(state, communicationPreferences) {
    Vue.set(state, 'communicationPreferences', communicationPreferences)
  },
  SET_UPDATED_COMMUNICATION_PREFERENCES(state, communicationPreferences) {
    Vue.set(state, 'updateCommunicationPreferences', communicationPreferences)
  },
  SET_UPDATED_DOUBLE_OPTIN(state, doubleOptIn) {
    Vue.set(state, 'updateDoubleOptIn', doubleOptIn)
  },
  SET_PARENT_AUTOREORDERS(state, autoReorders) {
    Vue.set(state, 'parentAutoReorders', autoReorders)
  },
}

export const actions = {
  async getAccountOrders(
    { commit, state },
    {
      nextPage = 1,
      showStatus = [
        orderStatuses.AWAITING_PAYMENT,
        orderStatuses.BEING_FULFILLED,
        orderStatuses.DISPATCHED,
        orderStatuses.PAYMENT_DECLINED,
        orderStatuses.FAILED_FRAUD_REVIEW,
        orderStatuses.READY_TO_DISPATCH,
        orderStatuses.PRESCRIPTION_BEING_VERIFIED,
        orderStatuses.PRESCRIPTION_VERIFICATION_FAILED,
        orderStatuses.READY_FOR_COLLECTION,
      ],
      displayCancelledOrders = false,
    },
  ) {
    commit('REMOVE_ERROR', 'accountOrders', { root: true })
    commit('ADD_LOADING', 'accountOrders', { root: true })
    try {
      const { pageSize } = state
      const params = {
        filters: {
          orderStatuses: displayCancelledOrders
            ? [orderStatuses.CANCELLED, ...showStatus].join(',')
            : showStatus.join(','),
        },
        page: nextPage,
        pageSize,
        ...defaultParams,
      }
      const response = await this.$axios.get('/order', { params })
      const { data: accountOrders } = response.data

      nextPage === 1 ? commit('SET_ORDERS', accountOrders.orders) : commit('SET_MORE_ORDERS', accountOrders.orders)

      commit('SET_TOTAL_ITEMS', accountOrders.pagination.totalItems)
    } catch (error) {
      commit('ADD_ERROR', { id: 'accountOrders', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'accountOrders', { root: true })
  },

  async getRetriableOrders({ commit, state }, nextPage = 1) {
    commit('REMOVE_ERROR', 'retriableOrders', { root: true })
    commit('ADD_LOADING', 'retriableOrders', { root: true })

    const retriableParams = {
      filters: { retryableOrders: true },
      pageSize: 5,
      page: nextPage,
      ...defaultParams,
    }
    try {
      const retriableResponse = await this.$axios.get('/order', { params: retriableParams })
      const { orders: retriableOrders } = retriableResponse.data.data

      if (nextPage === 1) {
        commit('SET_RETRIABLE_ORDERS', retriableOrders)
      } else {
        commit('SET_MORE_RETRIABLE_ORDERS', retriableOrders)
      }

      commit('SET_RETRIABLE_TOTAL_ITEMS', retriableResponse.data.data.pagination.totalItems)
    } catch (error) {
      commit('ADD_ERROR', { id: 'retriableOrders', error }, { root: true })
    }
    commit('REMOVE_LOADING', 'retriableOrders', { root: true })
  },

  async getParentAutoReorders({ commit }) {
    commit('REMOVE_ERROR', 'parentAutoReorders', { root: true })
    commit('ADD_LOADING', 'parentAutoReorders', { root: true })

    try {
      const params = { ...defaultParams }
      const response = await this.$axios.get('/payments/autoreorders', { params })
      const { data: autoReorders } = response.data

      commit('SET_PARENT_AUTOREORDERS', autoReorders)
    } catch (error) {
      commit('ADD_ERROR', { id: 'parentAutoReorders', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'parentAutoReorders', { root: true })
  },

  filterRetriableOrders({ commit }) {
    commit('FILTER_RETRIABLE_ORDERS')
  },

  async getCommunicationPreferences({ commit }) {
    commit('REMOVE_ERROR', 'communicationPreferences', { root: true })
    commit('ADD_LOADING', 'communicationPreferences', { root: true })

    try {
      const params = { ...defaultParams }
      const response = await this.$axios.get('/communication-consent', { params })
      const { data: communicationPreferences } = response.data

      commit('SET_COMMUNICATION_PREFERENCES', communicationPreferences)
    } catch (error) {
      commit('ADD_ERROR', { id: 'communicationPreferences', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'communicationPreferences', { root: true })
  },

  async updateCommunicationPreferences({ commit }, payload) {
    const { consent, name } = payload
    const location = window.location.href
    const params = { ...defaultParams }

    commit('REMOVE_ERROR', 'updateCommunicationPreferences', { root: true })
    commit('ADD_LOADING', 'updateCommunicationPreferences', { root: true })

    try {
      const response = await this.$axios.put(
        `/communication-consent/${name}`,
        { consent, message: 'ok', location },
        {
          params,
        },
      )
      const { data: updatedCommunicationPreferences } = response.data

      commit('SET_UPDATED_COMMUNICATION_PREFERENCES', updatedCommunicationPreferences)
    } catch (error) {
      commit('ADD_ERROR', { id: 'updateCommunicationPreferences', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'updateCommunicationPreferences', { root: true })
  },

  async updateDoubleOptIn({ commit }, doubleoptinToken) {
    commit('REMOVE_ERROR', 'updateDoubleOptIn', { root: true })
    commit('ADD_LOADING', 'updateDoubleOptIn', { root: true })

    try {
      const response = await this.$axios.post(
        `/communication-consent/double-optin`,
        { token: doubleoptinToken },
        {
          params: { ...defaultParams },
        },
      )

      const { data: updateDoubleOptIn } = response.data

      commit('SET_UPDATED_DOUBLE_OPTIN', updateDoubleOptIn)
    } catch (error) {
      commit('ADD_ERROR', { id: 'updateDoubleOptIn', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'updateDoubleOptIn', { root: true })
  },

  async cancelOrder({ commit }, orderId) {
    commit('REMOVE_ERROR', 'orderCancellation', { root: true })
    commit('ADD_LOADING', 'orderCancellation', { root: true })
    try {
      await this.$axios.delete(
        `/order/${orderId}`,
        { params: { ...defaultParams } },
        {
          headers: {
            Authorization: this.$auth.strategy.token.get(),
          },
        },
      )
    } catch (error) {
      commit('ADD_ERROR', { id: 'orderCancellation', error }, { root: true })
    }
    commit('REMOVE_LOADING', 'orderCancellation', { root: true })
  },
}
