import Vue from 'vue'

import { defaultParams } from '@/utils/constants'

export const state = () => ({
  selectedStore: {},
  storeLocatorConfig: {},
  coordinates: {
    lat: undefined,
    lng: undefined,
  },
  defaultCoordinates: {
    lat: undefined,
    lng: undefined,
  },
  bounds: {
    Ha: undefined,
    Ua: undefined,
  },
  allStores: [],
  nearestStores: [],
  coreLibrary: null,
  mapsLibrary: null,
  geocodingLibrary: null,
  geometryLibrary: null,
  markerLibrary: null,
  error: {},
  resultsOffsetTop: null,
})

export const mutations = {
  ADD_ERROR(state, error) {
    Vue.set(state, 'error', error)
  },
  CLEAR_ERROR(state) {
    Vue.set(state, 'error', {})
  },
  SET_STORE_LOCATOR_CONFIG(state, config) {
    Vue.set(state, 'storeLocatorConfig', config)
  },
  SET_COORDINATES(state, coordinates) {
    Vue.set(state, 'coordinates', coordinates)
  },
  SET_DEFAULT_COORDINATES(state, defaultCoordinates) {
    Vue.set(state, 'defaultCoordinates', defaultCoordinates)
  },
  SET_BOUNDS(state, bounds) {
    Vue.set(state, 'bounds', bounds)
  },
  SET_CORE_LIBRARY(state, coreLibrary) {
    Vue.set(state, 'coreLibrary', coreLibrary)
  },
  SET_MAPS_LIBRARY(state, mapsLibrary) {
    Vue.set(state, 'mapsLibrary', mapsLibrary)
  },
  SET_GEOCODING_LIBRARY(state, geocodingLibrary) {
    Vue.set(state, 'geocodingLibrary', geocodingLibrary)
  },
  SET_GEOMETRY_LIBRARY(state, geometryLibrary) {
    Vue.set(state, 'geometryLibrary', geometryLibrary)
  },
  SET_MARKER_LIBRARY(state, markerLibrary) {
    Vue.set(state, 'markerLibrary', markerLibrary)
  },
  SET_ALL_STORES(state, allStores) {
    Vue.set(state, 'allStores', allStores)
  },
  SET_NEAREST_STORES(state, nearestStores) {
    Vue.set(state, 'nearestStores', nearestStores)
  },
  SET_SELECTED_STORE(state, selectedStore) {
    Vue.set(state, 'selectedStore', selectedStore)
  },
  SET_RESULTS_OFFSET_TOP(state, resultsOffsetTop) {
    Vue.set(state, 'resultsOffsetTop', resultsOffsetTop)
  },
}

export const actions = {
  async getStoreLocatorConfig({ commit }) {
    try {
      const response = await this.$axios.get('/configuration/store-locator', { params: defaultParams, useCache: true })
      const { data } = response.data
      commit('SET_STORE_LOCATOR_CONFIG', data)
    } catch (error) {
      commit('ADD_ERROR', { type: 'config', error })
    }
  },
}
