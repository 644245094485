
import { mapState } from 'vuex'
import { legacyCookieMaxAge } from '@/utils/constants'

import ModalDialog from '@/components/ModalDialog'

export default {
  components: {
    ModalDialog,
  },
  computed: {
    ...mapState(['liftToLegacy']),
  },
  methods: {
    goToLegacy() {
      let link = ''
      const token = this.$auth.strategy.token.get()
      const tokenParam = this.$auth.loggedIn ? `&token=${token.replace('Bearer ', '')}` : ''
      const legacyBaseURL = process.client ? window.location.origin.replace('spa.', '') : ''
      const legacyReOrderLink = '?_a=login&redir=%3F_a%3DviewOrders'
      const legacyAccountLink = '?_a=login&redir=%3F_a%3Daccount'
      const legacyLoginLink = '?_a=login'
      const legacyAddressBookLink = '?_a=login&redir=%3F_a%3DaccountAddressBook'
      const legacyEyeTestLink = this.$auth.loggedIn
        ? '?_a=login&redir=%3F_a%3DappointmentRequest'
        : '?_a=appointmentRequest'
      const legacyPasswordResetLink = '?_a=forgotPass'
      const legacyAutoReOrderLink = '?_a=login&redir=%3F_a%3DviewAutoReOrders'
      const legacyChangePasswordLink = '?_a=login&redir=%3F_a%3DchangePass'
      const legacyCustomerDetailsLink = '?_a=login&redir=%3F_a%3Dprofile'
      const legacyCommunicationPreferencesLink = '?_a=login&redir=%3F_a%3Dnewsletter'
      const legacyStoreLocatorLink = '?_a=storeLocator'

      switch (this.liftToLegacy) {
        case 'reorder':
        case 'orderHistory':
          link = legacyReOrderLink
          break
        case 'account':
          link = legacyAccountLink
          break
        case 'login':
          link = legacyLoginLink
          break
        case 'addressBook':
          link = legacyAddressBookLink
          break
        case 'eyeTest':
          link = legacyEyeTestLink
          break
        case 'passwordReset':
          link = legacyPasswordResetLink
          break
        case 'passwordChange':
          link = legacyChangePasswordLink
          break
        case 'customerDetails':
          link = legacyCustomerDetailsLink
          break
        case 'communicationPreferences':
          link = legacyCommunicationPreferencesLink
          break
        case 'autoReorder':
          link = legacyAutoReOrderLink
          break
        case 'storeLocator':
          link = legacyStoreLocatorLink
          break
        default:
          link = legacyBaseURL
          break
      }

      this.$gtm.push({
        event: 'lift_notification',
        page: link,
      })

      this.$cookies.set('ls_lb_aid', 'legacy', { maxAge: legacyCookieMaxAge })
      window.open(`${legacyBaseURL}/${link}${tokenParam}`, '_self')
    },
  },
}
