import { defaultParams } from '@/utils/constants'

let cachedFlags = null

export default (context, inject) => {
  const pluginName = 'patchboard'

  const flags = async () => {
    if (cachedFlags) {
      return cachedFlags
    } else {
      const response = await context.$axios.get('/feature-flags', { params: defaultParams })
      cachedFlags = response.data.data
      return response.data.data
    }
  }

  inject(pluginName, { flags })
}
