
import ClickOutside from 'vue-click-outside'

import HeroButton from '@/components/HeroButton'
import AccountIcon from '@/static/svg/nav/account-icon.svg'
import CloseIcon from '@/static/svg/nav/close-icon.svg'

export default {
  directives: {
    ClickOutside,
  },
  components: {
    HeroButton,
    AccountIcon,
    CloseIcon,
  },
  data() {
    return {
      isVisible: false,
    }
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn
    },
    isActive() {
      const isAccountPage = this.$nuxt.$route.name?.includes('account')
      return this.isVisible || isAccountPage
    },
  },
  mounted() {
    this.popupItem = this.$el
    if (!sessionStorage.getItem('accountLoginPopup')) {
      this.startSession()
      this.isVisible = true
    } else {
      this.isVisible = false
    }
  },
  methods: {
    afterEnter(element) {
      if (this.isVisible) {
        element.classList.remove('opacity-0')
      }
    },
    close(event) {
      const isCookieProElement = !!event?.target?.closest('#onetrust-consent-sdk')

      if (!isCookieProElement) {
        this.isVisible = false
      }
    },
    toggle() {
      this.isVisible = !this.isVisible
      this.$emit('toggled', this.isVisible)
    },
    goToAccount() {
      this.isVisible = false
      const path = '/account'

      if (this.loggedIn) {
        this.$router.push(this.$getLink(path))
      } else {
        this.$store.commit('SET_LOGIN_REDIRECT_TO', path)
        this.$router.push(this.$getLink('/login'))
      }
    },
    startSession() {
      sessionStorage.setItem('accountLoginPopup', 'activeSession')
    },
  },
}
