import Vue from 'vue'

Vue.filter('cdnPath', (value, cdnPath, width) => {
  return `${cdnPath}/${width}/${value}`
})

Vue.filter('address', (address, countryNames = []) => {
  const formattedAddress = [
    address?.name.replace(/&#39;/g, "'"),
    address?.company,
    address?.add1,
    address?.add2,
    address?.town,
    address?.county,
    address?.postcode,
    countryNames[address?.countryId],
  ]

  return formattedAddress.filter((value) => value).join(', ')
})
