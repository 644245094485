import cookieUniversal from 'cookie-universal'

export default ({ req, res }, inject) => {
  const pluginName = 'cookies'
  const parseJsonbyDefault = true

  const cookieLib = cookieUniversal(req, res, parseJsonbyDefault)

  const cookieLibWithDefaults = {
    get: (name, options = {}) => {
      return cookieLib.get(name, { ...options })
    },
    set: (name, value, options = {}) => {
      cookieLib.set(name, value, { secure: true, ...options })
    },
    remove: (name, options = {}) => {
      cookieLib.remove(name, { ...options })
    },
  }

  inject(pluginName, cookieLibWithDefaults)
}
