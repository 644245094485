export default {
  methods: {
    slugify(content) {
      try {
        return content
          .toString()
          .normalize('NFD') // split an accented letter in the base letter and the accent
          .replace(/[\u0300-\u036F]/g, '') // remove all previously split accents
          .toLowerCase()
          .trim()
          .replace(/[^a-z0-9 ]/g, '') // remove non-alphanumeric chars
          .replace(/\s+/g, '-')
      } catch (e) {
        return ''
      }
    },
  },
}
