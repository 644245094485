
export default {
  props: {
    duration: {
      type: Number,
      default: 750,
    },
  },
  mounted() {
    this.setDuration(this.$el)
  },
  methods: {
    setDuration(el) {
      if (el.style) {
        el.style.transitionDuration = `${this.duration}ms`
      }
    },
    begin(el) {
      el.style.width = getComputedStyle(el).width
      el.style.position = 'absolute'
      el.style.visibility = 'hidden'
      el.style.height = 'auto'

      const height = getComputedStyle(el).height

      el.style.width = null
      el.style.position = null
      el.style.visibility = null
      el.style.height = 0

      requestAnimationFrame(() => {
        el.style.height = height
      })
    },
    afterBegin(el) {
      el.style.height = 'auto'
    },
    end(el) {
      const height = getComputedStyle(el).height

      el.style.height = height

      requestAnimationFrame(() => {
        el.style.height = 0
      })
      this.$nextTick(() => {
        this.$emit('animation-end')
      })
    },
  },
}
