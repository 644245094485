/*
This split integration (on client side) is used to set up the split client
and send it to GTM (Google Tag Manager) for data tracking (A/B testing)

 -> plugins/app-mounted.client.js -> Line 23
 This is where the functionality happens (Line accuracy might change)
*/

import { SplitFactory } from '@splitsoftware/splitio'

const applicationName = 'SPA'
const userIdCookieName = 'ls_lb_uid'
const applicationCookieName = 'ls_lb_aid'

export default (context, inject) => {
  let userId = context.$cookies.get(userIdCookieName)

  if (!userId) {
    context.$log({
      name: 'SplitError',
      properties: {
        reason: 'no ls_lb_uid cookie available when creating Split Factory, generating a fallback UUID',
      },
    })

    userId = crypto?.randomUUID?.()
    if (!userId) {
      context.$log({
        name: 'SplitError',
        properties: {
          reason: 'unable to generate a fallback UUID',
        },
      })
      return
    }
    context.$log({
      name: 'SplitInfo',
      properties: {
        reason: `created a fallback UUID of ${userId}`,
      },
    })
    const cookiesDomain = context.store?.state?.cookiesDomain
    const cookieOptions = {
      path: '/',
      sameSite: 'none',
      secure: true,
      domain: cookiesDomain,
    }
    context.$cookies.set(userIdCookieName, userId, {
      ...cookieOptions,
      maxAge: 60 * 60 * 24 * 365, // 1 year
    })
    context.$cookies.set(applicationCookieName, 'SPA', {
      ...cookieOptions,
      maxAge: 60 * 60 * 24 * 21, // 3 weeks
    })
  }

  if (!context.store?.state?.splitAuthKey) {
    context.$log({
      name: 'SplitError',
      properties: {
        reason: 'no splitAuthKey available when creating Split Factory',
        userId,
      },
    })
    return
  }

  const splitFactory = SplitFactory({
    core: {
      authorizationKey: context.store.state.splitAuthKey,
      key: userId,
    },
    startup: {
      retriesOnFailureBeforeReady: 3,
    },
  })
  splitFactory.settings.core.authorizationKey = context.store.state.splitAuthKey

  inject('splitFactory', splitFactory)

  const splitClient = splitFactory.client()

  const partnerId = context.store.state.partnerId
  const splitClientAttributes = { application: applicationName, partner_id: partnerId }
  splitClient.setAttributes(splitClientAttributes)

  inject('splitClient', splitClient)
}
