import Vue from 'vue'

export const state = () => ({
  messages: [
    {
      autoReorderId: '',
      title: '',
      description: '',
      type: '',
    },
  ],
})

export const mutations = {
  ADD_MESSAGE(state, message) {
    Vue.set(state, 'messages', [...state.messages, message])
  },
  REMOVE_MESSAGE(state, autoReorderId) {
    Vue.set(
      state,
      'messages',
      state.messages.filter((msg) => msg.autoReorderId !== autoReorderId),
    )
  },
  CLEAR_MESSAGES(state) {
    Vue.set(state, 'messages', [])
  },
}

export const actions = {
  addMessage({ commit }, message) {
    commit('ADD_MESSAGE', message)
  },
  removeMessage({ commit }, autoReorderId) {
    commit('REMOVE_MESSAGE', autoReorderId)
  },
  clearMessages({ commit }) {
    commit('CLEAR_MESSAGES')
  },
}
