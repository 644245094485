import { couponMaxAge } from '@/utils/constants'

export default function ({ route, app, store }) {
  const { d: routeCouponCode = '' } = route.query
  const cookiesCouponCode = app.$cookies.get('couponCode')
  const couponCode = routeCouponCode || cookiesCouponCode

  if (couponCode) {
    store.dispatch('setCouponCode', couponCode)
  }

  if (routeCouponCode) {
    app.$cookies.set('couponCode', routeCouponCode, { maxAge: couponMaxAge })
  }
}
