import { mapRedirects } from '@/utils/constants'

export default function ({ route, redirect, app }) {
  const pageQuery = route?.query?._a

  if (pageQuery && Object.prototype.hasOwnProperty.call(mapRedirects, pageQuery)) {
    const mapped = mapRedirects[pageQuery]
    const query = Object.assign({}, route.query)
    if (mapped.clearAction) {
      delete query._a
    }
    redirect({ name: `${app.i18n.locale}/${mapped.redirect}`, query })
  }
}
