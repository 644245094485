
import CloseIcon from '@/static/svg/nav/close-icon.svg'

export default {
  components: {
    CloseIcon,
  },
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    anchorTop: {
      type: Boolean,
      default: false,
    },
    scrollTop: {
      type: Boolean,
      default: false,
    },
    removeOverlayPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      showContent: false,
      scrollY: 0,
    }
  },
  methods: {
    open() {
      this.isVisible = true
      this.scrollY = window.scrollY

      if (this.scrollTop) window.scroll(0, 0)

      setTimeout(() => {
        this.showContent = true
      }, 199)
    },
    close() {
      this.showContent = false
      setTimeout(() => {
        this.isVisible = false

        if (this.scrollTop) window.scroll(0, this.scrollY)
      }, 200)
    },
  },
}
