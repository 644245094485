
import LiftToLegacyModal from '@/components/LiftToLegacyModal'
import GlobalHeader from '@/components/GlobalHeader'
import UspBanner from '@/components/UspBanner'
import GlobalFooter from '@/components/GlobalFooter'

import rememberMe from '@/mixins/remember-me'
import layoutHeaders from '@/mixins/layout-headers'

export default {
  name: 'DefaultLayout',
  components: {
    LiftToLegacyModal,
    GlobalHeader,
    UspBanner,
    GlobalFooter,
  },
  mixins: [rememberMe, layoutHeaders],
  data() {
    return {
      showUspBanner: false,
    }
  },
  watch: {
    $route() {
      const basketPageRoutes = ['basket', `${this.$i18n.locale}/basket`]
      if (!basketPageRoutes.includes(this.$route.name)) {
        this.$store.dispatch('order/resetOrderIssues')
      }

      // Resets refer a friend page to load external scripts
      // TODO update if better solution found
      if (this.$route.params.id === '126') {
        this.$router.go(0)
      }
    },
  },
  async mounted() {
    this.showUspBanner = await this.$store.dispatch('split/getSplitTreatment', 'uspBannerVisible')
  },
}
