
import debounce from 'debounce'
import ClickOutside from 'vue-click-outside'

import SearchIcon from '@/static/svg/nav/search-icon.svg'
import ClearIcon from '@/static/svg/nav/clear-icon.svg'

export default {
  components: {
    SearchIcon,
    ClearIcon,
  },
  directives: {
    ClickOutside,
  },
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    basket: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 9,
    },
  },
  data() {
    return {
      query: '',
      isValid: true,
      hideResults: true,
    }
  },
  computed: {
    resultsVisible() {
      return this.results.length && this.isValid && this.query.length && !this.hideResults
    },
  },
  watch: {
    query() {
      const allowedCharacters = /^[a-zA-Z0-9 '_-]+$/

      if (this.query.length && !this.query.match(allowedCharacters)) {
        this.isValid = false
      } else {
        this.isValid = true
      }

      if (this.hideResults) {
        this.hideResults = false
        window.addEventListener('scroll', this.close)
      }
    },
    $route() {
      if (!this.$route.path.includes('search')) {
        this.query = ''
      }
    },
  },
  mounted() {
    this.popupItem = this.$el
  },
  methods: {
    search: debounce(function () {
      if (this.isValid && this.query.length > 1) {
        this.$emit('search-updated', this.query)
      }
    }, 50),
    getHighlights(result) {
      return result.replace(new RegExp(this.query, 'ig'), (match) => `||${match}||`).split('||')
    },
    matchQuery(highlight) {
      return highlight.toLowerCase() === this.query.toLowerCase()
    },
    clearQuery() {
      this.query = ''
    },
    goToSearchResults() {
      if (this.isValid) {
        this.close()
        this.$router.push(`/search/${this.query}`)
      }
    },
    goToProduct(href) {
      this.$router.push(`/${href}`)
    },
    close() {
      this.hideResults = true
      window.removeEventListener('scroll', this.close)
    },
  },
}
