import Vue from 'vue'

export default ({ store, $axios, $cookies }, inject) => {
  if (!Object.prototype.hasOwnProperty.call(Vue, '$log')) {
    const eventBuffer = []
    let timer = null

    function sendEvents() {
      if (eventBuffer.length > 0) {
        try {
          $axios.post('/events', { events: eventBuffer })
          eventBuffer.length = 0
        } catch {
          console.error('error sending event logs')
        }
      }
    }

    function removeUserData(event) {
      const billingAddress = store.state.addresses.addresses.find((a) => {
        return a.id === store.state.addresses.billingAddressId
      })
      const { add1, add2, company, county, name, postcode, town } = billingAddress
      const tokensToRemove = [add1, add2, company, county, name, postcode, town]
      tokensToRemove.forEach((token) => {
        event.properties.data = event.properties.data.replaceAll(token, '#'.repeat(token.length))
      })
    }

    inject('log', ({ name, properties }) => {
      const { orderId } = store.state.order.order
      const userId = $cookies.get('ls_lb_uid') || ''
      const event = {
        name,
        properties: {
          userId,
          orderId,
          ...properties,
        },
        time: new Date().toISOString(),
      }
      if (event.properties.data) {
        removeUserData(event)
      }
      eventBuffer.push(event)
      if (!timer) {
        timer = setInterval(sendEvents, 2000)
      }
    })
  }
}
