
import NotFoundIllustration from '@/static/svg/not-found-404.svg'

export default {
  components: {
    NotFoundIllustration,
  },
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
}
