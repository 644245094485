export default ({ store }, inject) => {
  inject('openLiveChat', async () => {
    // Trigger genesys if available!
    const liveChatGenesysEnabled = await store.dispatch('split/getSplitTreatment', 'liveChatGenesys')
    if (liveChatGenesysEnabled) {
      // eslint-disable-next-line no-undef
      Genesys('command', 'Messenger.open')
    }
  })
}
