import Vue from 'vue'

import { defaultParams, bestSellersId } from '@/utils/constants'
import { getDeserializedCategories, getDeserializedTiles } from '@/utils/deserialization'

export const state = () => ({
  categories: [],
  tiles: [],
  activeCategory: 0,
  homepageMeta: '',
})

export const mutations = {
  SET_CATEGORIES(state, categories) {
    Vue.set(state, 'categories', categories)
  },
  SET_TILES(state, tiles) {
    Vue.set(state, 'tiles', tiles)
  },
  SET_ACTIVE_CATEGORY(state, activeCategory) {
    Vue.set(state, 'activeCategory', activeCategory)
  },
  SET_HOMEPAGE_META(state, homepageMeta) {
    Vue.set(state, 'homepageMeta', homepageMeta)
  },
}

export const actions = {
  async getHomepage({ commit, rootState, dispatch }) {
    commit('ADD_LOADING', 'homepage', { root: true })

    const { couponCode } = rootState
    const params = { ...(couponCode && { couponCode }), ...defaultParams }

    try {
      const response = await this.$axios.get('/homepage', { params })
      const { data: homepage } = response.data
      const deserializedCategories = getDeserializedCategories(homepage, bestSellersId)
      const deserializedTiles = getDeserializedTiles(homepage)

      commit('SET_HOMEPAGE_META', homepage.meta)
      commit('SET_CATEGORIES', deserializedCategories)
      commit('SET_TILES', deserializedTiles)
    } catch (error) {
      await dispatch('silentLogOut', { source: 'homepage/getHomepage' }, { root: true })

      try {
        const response = await this.$axios.get('/homepage', { params })
        const { data: homepage } = response.data
        const deserializedCategories = getDeserializedCategories(homepage, bestSellersId)
        const deserializedTiles = getDeserializedTiles(homepage)

        commit('SET_HOMEPAGE_META', homepage.meta)
        commit('SET_CATEGORIES', deserializedCategories)
        commit('SET_TILES', deserializedTiles)
      } catch (error) {
        commit('ADD_ERROR', { id: 'homepage', error }, { root: true })
      }

      commit('ADD_ERROR', { id: 'homepage', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'homepage', { root: true })
  },
  setActiveCategory({ commit }, index) {
    commit('SET_ACTIVE_CATEGORY', index)
  },
}
