export default function ({ route, store, redirect, app }) {
  const reOrderId = route.query?.redir?.replace?.('?refill=', '')

  if (!reOrderId) {
    return
  }

  if (!store.state.auth.loggedIn) {
    return redirect({ path: app.$getLink('/login'), name: `${app.i18n.locale}/login`, query: route.query })
  }

  return redirect({ path: app.$getLink('/delivery'), name: `${app.i18n.locale}/delivery`, query: route.query })
}
