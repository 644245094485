
import ChevronIcon from '@/static/svg/chevron-icon.svg'
import ExpandTransition from '@/components/ExpandTransition'

export default {
  components: {
    ChevronIcon,
    ExpandTransition,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconAlt: {
      type: String,
      default: '',
    },
    styleContent: {
      type: Boolean,
      default: false,
    },
    styleChevron: {
      type: Object,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    scrollToTop: {
      type: Boolean,
      default: true,
    },
    scrollEnd: {
      type: Boolean,
      default: true,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    expanded() {
      if (this.expanded && this.scrollToTop) {
        this.scroll()
      }
    },
  },
  methods: {
    onToggle() {
      this.$emit('toggled')
    },
    scroll() {
      const globalHeaderHeight = document.querySelector('header')?.offsetHeight || 0
      const localHeaderHeight = document.querySelector('[data-cy="accordion-header"]')?.offsetHeight || 0
      const y = this.$el.getBoundingClientRect().top + window.pageYOffset - globalHeaderHeight - localHeaderHeight

      if (this.scrollEnd) {
        window.scrollTo({ top: y, left: 0, behavior: 'smooth' })
      }
    },
  },
}
