import { rememberMeLength } from '@/utils/constants'

export default {
  mounted() {
    this.updateCookieExpiration()
  },
  methods: {
    updateCookieExpiration() {
      const rememberMe = this.$auth.$storage.getCookie('rememberMe')

      if (typeof rememberMe !== 'undefined') {
        const cookies = this.$auth.$storage.getCookies()
        const cookiePrefix = this.$auth.options.cookie.prefix
        const { expires } = this.$auth.$storage.options.cookie.options

        this.$auth.$storage.options.cookie.options.expires = rememberMe ? rememberMeLength : null

        Object.entries(cookies).forEach(([key, value]) => {
          if (key.includes(cookiePrefix)) {
            this.$auth.$storage.setCookie(key.replace(cookiePrefix, ''), value, {
              expires: rememberMe ? expires : null,
            })
          }
        })
      }
    },
  },
}
